import styled from "styled-components"

export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerSerch = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  @media (max-width: 1020px) {
    flex-direction: column;
  }
`

export const Group = styled.div`
  width: calc(100% - 30px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`

export const ContainerTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
