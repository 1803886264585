import React, { type ReactElement } from "react"
import { ContainerLanguage, Label, Icon, ContainerSuboption, Active } from "../_styles"

import { useTranslation } from "react-i18next"
import { GLOBAL_STYLES } from "../../../styles/GLOBAL_STYLE"
import { TRANSLATION_INDEX_GLOBAL } from "../../../config/translation"
import IconArrowDown from "../../../components/Icons/IconArrowDown"
import { useGlobalContext } from "../../../context/GlobalContext"

const Language = (): ReactElement => {
  const { t, i18n } = useTranslation()
  const [activeSubMenu, setActiveSubMenu] = React.useState<boolean>(false)
  const { themeContext } = useGlobalContext()
  const selectLanguage = (language: string): void => {
    void i18n.changeLanguage(language)
    window.location.reload()
  }

  return (
    <ContainerLanguage themeContext={themeContext}>
      <Active
        themeContext={themeContext}
        onClick={() => {
          setActiveSubMenu(!activeSubMenu)
        }}
      >
        <Label themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.LANGUAGE)}</Label>
        <Icon>
          <IconArrowDown size={18} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]} />
        </Icon>
      </Active>
      {activeSubMenu && (
        <>
          <ContainerSuboption
            themeContext={themeContext}
            onClick={() => {
              selectLanguage("es")
            }}
          >
            <Label themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.SPANISH)}</Label>
          </ContainerSuboption>
          <ContainerSuboption
            themeContext={themeContext}
            onClick={() => {
              selectLanguage("en")
            }}
          >
            <Label themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.ENGLISH)}</Label>
          </ContainerSuboption>
        </>
      )}
    </ContainerLanguage>
  )
}

export default Language
