/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { profile } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"
import { type IRatingsGroup } from "../../../interfaces/IRatings"

const initialModuleState: ModuleType = {
  data: [],
  dataGroup: [],
  loading: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  errorCode: ""
}

const reducer = profile.createReducer(
  {
    [types.RATINGS_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.RATINGS_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      let groupData: IRatingsGroup[] = []
      payload.forEach(item => {
        if (!groupData.map(item => item?.organizacion.descripcion).includes(item.organizacion.descripcion)) {
          groupData.push({
            organizacion: item.organizacion,
            puntaje: item.puntaje,
            count: 1
          })
        } else {
          groupData = groupData.map(itemGroup => {
            if (item.organizacion.descripcion === itemGroup.organizacion.descripcion) {
              itemGroup.count += 1
              itemGroup.puntaje = itemGroup.puntaje + item.puntaje
            }
            return { organizacion: itemGroup.organizacion, puntaje: itemGroup.puntaje, count: itemGroup.count }
          })
        }
      })
      return {
        ...state,
        data: payload,
        dataGroup: groupData,
        loading: false
      }
    },
    [types.RATINGS_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    })
  },
  initialModuleState
)

export default reducer
