import { reportCommercial } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"

const initialModuleState: ModuleType = {
  data: [],
  reportCustomer: [],
  reportRankingCustomer: null,
  reportRankingCountry: null,
  reportRankingCarrier: null,
  reportRankingBroker: null,
  reportUncontactCustomer: [],
  loading: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  errorCode: ""
}

const reducer = reportCommercial.createReducer(
  {
    [types.REPORT_COMMERCIAL_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REPORT_COMMERCIAL_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        data: payload,
        loading: false
      }
    },
    [types.REPORT_COMMERCIAL_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.REPORT_CUSTOMER_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REPORT_CUSTOMER_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        reportCustomer: payload,
        loading: false
      }
    },
    [types.REPORT_CUSTOMER_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      reportCustomer: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.REPORT_UNCONTACT_CUSTOMER_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REPORT_UNCONTACT_CUSTOMER_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        reportUncontactCustomer: payload,
        loading: false
      }
    },
    [types.REPORT_UNCONTACT_CUSTOMER_GET_REQUEST]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      reportUncontactCustomer: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.REPORT_RANKING_COUNTRY_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REPORT_RANKING_COUNTRY_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        reportRankingCountry: payload,
        loading: false
      }
    },
    [types.REPORT_RANKING_COUNTRY_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      reportRankingCountry: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.REPORT_RANKING_CARRIER_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REPORT_RANKING_CARRIER_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        reportRankingCarrier: payload,
        loading: false
      }
    },
    [types.REPORT_RANKING_CARRIER_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      reportRankingCarrier: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),

    [types.REPORT_RANKING_CUSTOMER_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REPORT_RANKING_CUSTOMER_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        reportRankingCustomer: payload,
        loading: false
      }
    },
    [types.REPORT_RANKING_CUSTOMER_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      reportRankingCustomer: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.REPORT_RANKING_BROKER_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REPORT_RANKING_BROKER_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        reportRankingBroker: payload,
        loading: false
      }
    },
    [types.REPORT_RANKING_BROKER_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      reportRankingBroker: null,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.REPORT_CLEAR_ALL]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      data: [],
      reportCustomer: [],
      reportRankingCarrier: null,
      reportRankingCountry: null,
      reportRankingCustomer: null,
      reportRankingBroker: null,
      reportUncontactCustomer: [],
      loading: false,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    })
  },
  initialModuleState
)

export default reducer
