import { remenders } from "./_duck"

export const REMENDERS_GET_REQUEST: string = remenders.defineType("REMENDERS_GET_REQUEST")
export const REMENDERS_GET_SUCCESS: string = remenders.defineType("REMENDERS_GET_SUCCESS")
export const REMENDERS_GET_FAILURE: string = remenders.defineType("REMENDERS_GET_FAILURE")

export const REMENDERS_TYPE_GET_REQUEST: string = remenders.defineType("REMENDERS_TYPE_GET_REQUEST")
export const REMENDERS_TYPE_GET_SUCCESS: string = remenders.defineType("REMENDERS_TYPE_GET_SUCCESS")
export const REMENDERS_TYPE_GET_FAILURE: string = remenders.defineType("REMENDERS_TYPE_GET_FAILURE")

export const REMENDERS_CONTACT_GET_REQUEST: string = remenders.defineType("REMENDERS_CONTACT_GET_REQUEST")
export const REMENDERS_CONTACT_GET_SUCCESS: string = remenders.defineType("REMENDERS_CONTACT_GET_SUCCESS")
export const REMENDERS_CONTACT_GET_FAILURE: string = remenders.defineType("REMENDERS_CONTACT_GET_FAILURE")

export const REMENDERS_ADD_REQUEST: string = remenders.defineType("REMENDERS_ADD_REQUEST")
export const REMENDERS_ADD_SUCCESS: string = remenders.defineType("REMENDERS_ADD_SUCCESS")
export const REMENDERS_ADD_FAILURE: string = remenders.defineType("REMENDERS_ADD_FAILURE")

export const REMENDERS_PUT_REQUEST: string = remenders.defineType("REMENDERS_PUT_REQUEST")
export const REMENDERS_PUT_SUCCESS: string = remenders.defineType("REMENDERS_PUT_SUCCESS")
export const REMENDERS_PUT_FAILURE: string = remenders.defineType("REMENDERS_PUT_FAILURE")

export const REMENDERS_DELETE_REQUEST: string = remenders.defineType("REMENDERS_DELETE_REQUEST")
export const REMENDERS_DELETE_SUCCESS: string = remenders.defineType("REMENDERS_DELETE_SUCCESS")
export const REMENDERS_DELETE_FAILURE: string = remenders.defineType("REMENDERS_DELETE_FAILURE")

export const REMENDERS_CLEAR_ALL: string = remenders.defineType("REMENDERS_CLEAR_ALL")
