import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getAuthorization = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/infoautorizar`)

export const postAuthorization = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/infoautorizar`, request)

export const patchAuthorization = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.patch(`/api/backoffice/v1/usuariosapp`, request)

export const deleteAuthorization = async (email): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/usuariosapp/${email}`)
