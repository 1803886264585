/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable prettier/prettier */
import React from "react"
import * as XLSX from "xlsx"
import ButtonCustom from "../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useTranslation } from "react-i18next"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_MENU, TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP } from "../../config/translation"
import { format } from "date-fns"
import { OPTION_STATE } from "../../utils/constants"
import IconExcel from "../../components/Icons/IconExcel"
import { useGlobalContext } from "../../context/GlobalContext"
import { type IUserAppClient } from "../../interfaces/IUserAppClient"
interface IProps {
  data: IUserAppClient[]
  isPending?: boolean
}
const ExportToExcel: React.FC<IProps> = ({ data,isPending }): React.ReactElement => {
  const {setLoadingContext} = useGlobalContext()
  const {t} = useTranslation()
//   const titulo = [{ A: "Reporte de Productos" }, {}]

//   const informacionAdicional = {
//     A: "Creado por: iTana el Martes, 04 de Abril del 2023"
//   }

  const longitudes:number[] = [30, 25, 20, isPending ? 35 : 20, 15, 20, 15,35]

  const handleDownload = (): void => {
    setLoadingContext(true)

    const tabla = [
      {
        A: `${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME)} ${t(TRANSLATION_INDEX_GLOBAL.AND)} ${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME)}`,
        B: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL),
        C: isPending ?t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.JOB) : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.IS_ADMIN),
        D: isPending? t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS_TO_VALIDATE): t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ROLE),
        E:  isPending ? "" : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER),
        F:  isPending ? "" : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.CHANGE_PASS),
        G:  isPending ? "" : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE),
        H:  isPending ? "" : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS)
      }
    ]

    data.forEach(row => {
      tabla.push({
        A: `${row.apellido?.toUpperCase()} ${row.nombre?.toUpperCase()}`,
        B: row.email,
        C: isPending ? row.puesto : row?.clienteAdmin ? t(TRANSLATION_INDEX_GLOBAL.YES)?.toUpperCase() : "NO",
        D: isPending ? row?.organizacionesParaValidar?.toLocaleUpperCase() ?? "" : row?.idRol === 1 ? t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.CUSTOMER)?.toUpperCase() : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER)?.toUpperCase(),
        E: isPending ? "" : row?.despachante?.razonSocial??"",
        F: isPending ? "" : row.debeCambiarClave ? t(TRANSLATION_INDEX_GLOBAL.YES)?.toUpperCase():"NO",
        G: isPending ? "" : row?.estado?.idEstado === OPTION_STATE.ACTIVO ?t(TRANSLATION_INDEX_GLOBAL.ACTIVE)?.toUpperCase():t(TRANSLATION_INDEX_GLOBAL.INACTIVE)?.toUpperCase(),
        H : isPending ? "" : row?.organizaciones?.map(item => {return ` ${item.descripcion}`}).join().toLocaleUpperCase()})
    })

    // const dataFinal = [...titulo, ...tabla, informacionAdicional]
    const dataFinal = [...tabla]

    setTimeout(() => {
      creandoArchivo(dataFinal)
      setLoadingContext(false)
    }, 1000)
  }

  const creandoArchivo = (dataFinal): void => {
    const libro = XLSX.utils.book_new()

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true })

    // hoja["!merges"] = [
    //   XLSX.utils.decode_range("A1:G1"),
    //   XLSX.utils.decode_range("A2:G2"),
    //   XLSX.utils.decode_range("A34:G34")
    // ]

    const propiedades:any[] = []

    longitudes.forEach((col:number) => {
      return propiedades.push({
            width: col
        })
    })

    hoja["!cols"] = propiedades

    XLSX.utils.book_append_sheet(libro, hoja, `${isPending?t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.PENDING_USERS):t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.REGISTERED_USERS)}`)

    XLSX.writeFile(libro, `${t(TRANSLATION_INDEX_MENU.USERS_APP_CLIENTS)??""}_${isPending?t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.PENDING_USERS):t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.REGISTERED_USERS)}_${format(new Date(),"yyyy-MM-dd hh-mm-ss")}.xlsx`)
  }

  return <ButtonCustom width="auto" marginLeft label={t(TRANSLATION_INDEX_GLOBAL.EXPORT)??""} icon={<IconExcel size={13} />} background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE.DARK} backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER.LIGHT} onClick={handleDownload} />
}

export default ExportToExcel
