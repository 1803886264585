import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

// REDUCERS
import login from "./modules/login/reducer"
import userAppClient from "./modules/userClientApp/reducer"
import userBackoffice from "./modules/userBackOffice/reducer"
import profile from "./modules/profile/reducer"
import event from "./modules/event/reducer"
import reference from "./modules/reference/reducer"
import preference from "./modules/preferences/reducer"
import permission from "./modules/permissionByProfile/reducer"
import authorization from "./modules/authorization/reducer"
import quoter from "./modules/Quoter/reducer"
import customer from "./modules/customer/reducer"
import customs from "./modules/customs/reducer"
import ratings from "./modules/ratings/reducer"
import expensiveOrigin from "./modules/expensiveOrigin/reducer"
import expensiveBA from "./modules/expensiveBA/reducer"
import quoteTemplate from "./modules/quoteTemplate/reducer"
import remenders from "./modules/remenders/reducer"
import reportCommercial from "./modules/reportCommercial/reducer"

const createRootReducer = (history: any): any =>
  combineReducers({
    router: connectRouter(history),
    authorization,
    customer,
    customs,
    expensiveOrigin,
    expensiveBA,
    event,
    login,
    permission,
    preference,
    profile,
    quoter,
    quoteTemplate,
    ratings,
    reference,
    userAppClient,
    userBackoffice,
    reportCommercial,
    remenders
  })

export default createRootReducer
