/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP } from "../../../../config/translation"

import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerInputs, ContainerSerch } from "./_styles"
import InputCustom from "../../../../components/InputCustom"
import ButtonCustom from "../../../../components/ButtonCustom"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import SelectCustom from "../../../../components/SelectCustom"
import { STATES, STATES_CLIENT_ADMIN } from "../../../../utils/constants"
import { InfoCustom } from "../../../../components/InfoCustom"
import { filterData } from "../../functios"
import { useMobile } from "../../../../hook/useMobile"
import { useGlobalContext } from "../../../../context/GlobalContext"

const SearchUserClientApp = ({
  setDataFiltered = () => {},
  data,
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  setFilters,
  handleReset,
  handleBlur,
  handleSubmit,
  setClearFilter,
  clearFilter
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const isMobile = useMobile()
  const handleClearFilter = (): void => {
    if (handleReset != null) {
      handleReset()
      setDataFiltered(data)
    }
  }

  const handleSearch = (): void => {
    if (setFilters) setFilters(values)
    setDataFiltered(filterData(data, values))
  }

  React.useEffect(() => {
    handleSearch()
  }, [values])

  React.useEffect(() => {
    if (handleReset) handleReset()
    setDataFiltered(data)
    if (setClearFilter) setClearFilter(false)
  }, [clearFilter])

  return (
    <ContainerSerch>
      <ContainerInputs>
        <InputCustom
          value={values?.search ?? ""}
          handleChange={handleChange}
          name="search"
          iconLeft
          placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH) ?? ""} ${t(
            isUserPending
              ? TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.PENDING_USER
              : TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.REGISTERED_USER
          )}`}
          width={isMobile ? "100%" : "300px"}
          validError={false}
        />
        {!isMobile && (
          <InfoCustom
            info={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)}: ${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME
            )}, ${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME)}, ${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL
            )}, ${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.JOB)} ${t(TRANSLATION_INDEX_GLOBAL.OR2)} ${t(
              isUserPending
                ? TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS_TO_VALIDATE
                : TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS
            )}.`}
            color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}
          />
        )}
        {setFieldValue != null && !isUserPending && (
          <SelectCustom
            validError={false}
            isDisabled={data === null}
            value={values?.estado ?? null}
            handleChange={setFieldValue}
            name="estado"
            width={isMobile ? "100%" : "250px"}
            placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR) ?? ""} ${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE
            )}`}
            marginLeft
            iconLeft
            options={STATES.filter(item => (item?.value ?? 2) !== 2).map(item => {
              return { ...item, label: t(item.label).toUpperCase() }
            })}
          />
        )}

        {setFieldValue != null && !isUserPending && (
          <SelectCustom
            validError={false}
            isDisabled={data === null}
            value={values?.clienteAdmin ?? null}
            handleChange={setFieldValue}
            name="clienteAdmin"
            width={isMobile ? "100%" : "250px"}
            placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR) ?? ""} ${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.IS_ADMIN
            )}`}
            marginLeft
            iconLeft
            options={STATES_CLIENT_ADMIN.map(item => {
              return { ...item, label: t(item.label).toUpperCase() }
            })}
          />
        )}
        {setFieldValue != null && !isUserPending && (
          <SelectCustom
            validError={false}
            isDisabled={data === null}
            value={values?.idRol ?? null}
            handleChange={setFieldValue}
            name="idRol"
            width={isMobile ? "100%" : "250px"}
            placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR) ?? ""} ${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ROLE
            )}`}
            marginLeft
            iconLeft
            options={[
              { label: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.CUSTOMER).toUpperCase() ?? "", value: 1 },
              { label: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.BROKER).toUpperCase() ?? "", value: 2 }
            ]}
          />
        )}
      </ContainerInputs>
      <ButtonCustom
        onClick={() => {
          handleClearFilter()
        }}
        label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
        backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
        background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
        width={isMobile ? "100%" : "100px"}
        marginLeft
      />
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserClientApp)
