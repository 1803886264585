import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getExpensiveOrigin = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/gastosorigen`)

export const addExpensiveOrigin = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/gastosorigen`, request)

export const putExpensiveOrigin = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/gastosorigen`, request)

export const deleteExpensiveOrigin = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/gastosorigen/${request}`)
