export const GLOBAL_STYLES = {
  HEADER: {
    COLOR: {
      DARK: "#fff",
      LIGHT: "#000"
    },
    MENU: {
      BORDER: {
        DARK: "1px #767676 solid",
        LIGHT: "1px #767676 solid"
      },

      BACKGROUND_2: {
        DARK: "#272b30",
        LIGHT: "#fff"
      },
      BACKGROUND_3: {
        DARK: "#131417",
        LIGHT: "#E4E4E4"
      }
    }
  },
  BACKGROUND: {
    TYPE_1: {
      DARK: "#515960",
      LIGHT: "rgba(0, 0, 0, 0.055)"
    },
    TYPE_2: {
      DARK: "#32383e",
      LIGHT: "#FFF"
    },
    TYPE_3: {
      DARK: "#272B30",
      LIGHT: "#fff"
    },
    TYPE_4: {
      DARK: "#F5F5F5",
      LIGHT: "#F5F5F5"
    },
    TYPE_5: {
      DARK: "rgba(255, 255, 255, 0.05)",
      LIGHT: "rgba(0, 0, 0, 0.05)"
    },
    TYPE_6: {
      DARK: "#e9ecef",
      LIGHT: "#e9ecef"
    },
    TYPE_7: {
      DARK: "#3A3F44",
      LIGHT: "#fff"
    },
    TYPE_8: {
      DARK: "#272B30",
      LIGHT: "#00000011"
    }
  },
  FONT: {
    FAMILY: "Source Sans Pro",
    WEIGHT: 400,
    SIZE: "0.9rem"
  },
  BORDER: {
    TYPE_1: {
      DARK: "1px #767676 solid",
      LIGHT: "1px #767676 solid"
    },
    TYPE_2: {
      DARK: "solid 1px rgba(0, 0, 0, 0.8)",
      LIGHT: "solid 1px rgba(205, 205, 205, 0.8)"
    },
    TYPE_3: {
      DARK: "1px solid #c0c0c0",
      LIGHT: "1px solid #c0c0c0"
    },
    TYPE_4: {
      DARK: "1px solid red",
      LIGHT: "1px solid red"
    }
  },
  BOX_SHADOW: {
    TYPE_1: {
      DARK: "3px 3px 16px -6px rgba(0, 0, 0, 0.75)",
      LIGHT: "0px 0px 45px 0px rgba(0, 0, 0, 0.25)"
    },
    TYPE_2: {
      DARK: "0px 1px 5px 1px rgba(255, 255, 255, 0.25)",
      LIGHT: "0px 1px 1px 1px rgba(0, 0, 0, 0.15)"
    },
    TYPE_3: {
      DARK: "0px 1px 5px 0px rgba(255, 255, 255, 0.65)",
      LIGHT: "0px 1px 3px 0px rgba(0, 0, 0, 0.65)"
    },
    TYPE_4: {
      DARK: "0px 10px 25px 5px rgba(0,0,0,0.15)",
      LIGHT: "0px 10px 25px 5px rgba(0,0,0,0.15)"
    },
    TYPE_5: {
      DARK: "",
      LIGHT: "0px 1px 1px 1px rgba(0, 0, 0, 0.15)"
    }
  },
  BUTTON: {
    BORDER_SHADOW_1: {
      DARK: "2px 2px 5px 0px rgba(0, 0, 0, 0.25)",
      LIGHT: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)"
    },
    BORDER_SHADOW_2: {
      DARK: "2px 2px 5px 0px rgba(0, 0, 0, 0.65)",
      LIGHT: "0px 1px 5px 0px rgba(0, 0, 0, 0.65)"
    },
    GRAY_BG_IMAGE: {
      DARK: "linear-gradient(#5A5A5A, #878484 40%, #878484)",
      LIGHT: "linear-gradient(#777777, #929090)"
    },
    GRAY_BG_IMAGE_HOVER: {
      DARK: "linear-gradient(#474747, #5C5C5C 80%, #5C5C5C)",
      LIGHT: "linear-gradient(#6A6A6A, #797979)"
    },
    DANGER_BG: {
      DARK: "#DC5956",
      LIGHT: "#dc3545"
    },
    DANGER_BG_IMAGE: {
      DARK: "linear-gradient(#EB5551, #DC5956 40%, #DC5956)",
      LIGHT: "linear-gradient(#dc3545, #dc3545)"
    },
    DANGER_BG_IMAGE_HOVER: {
      DARK: "linear-gradient(#ea3d38, #ea3d38 80%, #ea3d38)",
      LIGHT: "linear-gradient(#c82333, #c82333)"
    },
    SUCCESS_BG_IMAGE: {
      DARK: "linear-gradient(#78cc78, #62c462 60%, #53be53)",
      LIGHT: "linear-gradient(#28a745, #28a745 )"
    },
    SUCCESS_BG_IMAGE_LOGIN: {
      DARK: "linear-gradient(#78cc78, #62c462 60%, #53be53)",
      LIGHT: "linear-gradient(#12A470, #12A470 )"
    },
    SUCCESS_BG_IMAGE_HOVER: {
      DARK: "linear-gradient(#46ba46, #46ba46 60%, #46ba46)",
      LIGHT: "linear-gradient(#218838, #218838)"
    },
    SUCCESS_BG_IMAGE_HOVER_LOGIN: {
      DARK: "linear-gradient(#46ba46, #46ba46 60%, #46ba46)",
      LIGHT: "linear-gradient(#128B60, #128B60)"
    },
    SUCCESS_BG_COLOR: {
      DARK: "#40BA21",
      LIGHT: "#81E566"
    },
    DISABLED_BG_COLOR: {
      DARK: "grey",
      LIGHT: "#CFCCCC"
    },
    PRIMARY_BG_IMAGE: {
      DARK: "linear-gradient(#74cae3, #5bc0de 60%, #4ab9db)",
      LIGHT: "linear-gradient(#17a2b8, #17a2b8 60%, #17a2b8)"
    },
    YELLOW_BG_IMAGE: {
      DARK: "linear-gradient(#C9C626, #C9C626 60%, #C9C626)",
      LIGHT: "linear-gradient(#C9C626, #C9C626 60%, #C9C626)"
    },
    YELLOW_BG_IMAGE_HOVER: {
      DARK: "linear-gradient(#B8B502, #B8B502, #B8B502)",
      LIGHT: "linear-gradient(#B8B502, #B8B502 , #B8B502)"
    },
    WARNING_BG_IMAGE: {
      DARK: "linear-gradient(#E48600, #E48600 60%, #E48600)",
      LIGHT: "linear-gradient(#AE6804, #AE6804 60%, #AE6804)"
    },
    WARNING_BG_IMAGE_HOVER: {
      DARK: "linear-gradient(#AE6804, #AE6804, #AE6804)",
      LIGHT: "linear-gradient(#AE6804, #AE6804 , #AE6804)"
    },
    PRIMARY_BG_IMAGE_HOVER: {
      DARK: "linear-gradient(#3bb4d8, #3bb4d8, #3bb4d8)",
      LIGHT: "linear-gradient(#138496, #138496, #138496)"
    },
    DARK_BG_IMAGE: {
      DARK: "linear-gradient(#686f74, #888E92 1%, #686f74)",
      LIGHT: "linear-gradient(#007bff, #007bff)"
    },
    DARK_BG_IMAGE_HOVER: {
      DARK: "linear-gradient(#5C5F61, #5C5F61, #5C5F61)",
      LIGHT: "linear-gradient(#0069d9, #0069d9)"
    },
    TAB_BG_IMAGE: {
      DARK: "#515960",
      LIGHT: "#fff"
    },
    TAB_BG_IMAGE_ACTIVE: {
      DARK: "#272B30",
      LIGHT: "rgb(218, 218, 218)"
    },
    PRIMARY_BG_COLOR: {
      DARK: "#0039BC",
      LIGHT: "#457BF9"
    }
  },
  LABEL: {
    TYPE_1: {
      DARK: "#aaaaaa",
      LIGHT: "#212529"
    },
    TYPE_2: {
      DARK: "#FFF",
      LIGHT: "#000"
    },
    TYPE_3: {
      DARK: "#aaaaaa",
      LIGHT: "#212529"
    },
    TYPE_4: {
      DARK: "#ff7171",
      LIGHT: "#fc1900"
    },
    TYPE_5: {
      DARK: "#AAAAAA",
      LIGHT: "#AAAAAA"
    }
  }
}
