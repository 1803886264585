import { profile } from "./_duck"
import * as types from "./types"

export const getProfileRequest = profile.createAction(types.PROFILE_GET_REQUEST)
export const getProfileSuccess = profile.createAction(types.PROFILE_GET_SUCCESS)
export const getProfileFailure = profile.createAction(types.PROFILE_GET_FAILURE)

export const addProfileRequest = profile.createAction(types.PROFILE_ADD_REQUEST)
export const addProfileSuccess = profile.createAction(types.PROFILE_ADD_SUCCESS)
export const addProfileFailure = profile.createAction(types.PROFILE_ADD_FAILURE)

export const putProfileRequest = profile.createAction(types.PROFILE_PUT_REQUEST)
export const putProfileSuccess = profile.createAction(types.PROFILE_PUT_SUCCESS)
export const putProfileFailure = profile.createAction(types.PROFILE_PUT_FAILURE)

export const deleteProfileRequest = profile.createAction(types.PROFILE_DELETE_REQUEST)
export const deleteProfileSuccess = profile.createAction(types.PROFILE_DELETE_SUCCESS)
export const deleteProfileFailure = profile.createAction(types.PROFILE_DELETE_FAILURE)
