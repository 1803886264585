/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_QUOTER
} from "../../config/translation"

import "../../styles/styles.css"
import { ContainerTable, Stepper } from "./_styles"
import { useGlobalContext } from "../../context/GlobalContext"
import ButtonCustom from "../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import Step1 from "./forms/Step1"
import Step2 from "./forms/Step2"
import Step3 from "./forms/Step3"
import Step4 from "./forms/Step4"
import { useDispatch, useSelector } from "react-redux"
import {
  getAssignedCommercialRequest,
  getClassificationRequest,
  getClientTypeRequest,
  getCountryRequest,
  getCustomerClear,
  getCustomerClearAll
} from "../../state/modules/customer/actions"
import { customerSelector } from "../../state/modules/customer/_selectors"
import { type ICustomer } from "../../interfaces/ICustomer"
import { toast } from "react-toastify"
import {
  cleanPDFQuoteTemplate,
  cleanResponseErrorQuoteTemplate,
  createPDFQuoteTemplateRequest,
  getQuoteTemplateRequest,
  postSendQuoteRequest,
  previewPDFQuoteTemplateRequest
} from "../../state/modules/quoteTemplate/actions"
import { quoteTemplateSelector } from "../../state/modules/quoteTemplate/_selectors"
import AlertModal from "../../components/AlertModal"
import { GroupButtom } from "./forms/Step1/_styles"
import { getAuth, isStringEmpty } from "../../utils/functions"
import DataTable from "react-data-table-component"
import { customStylesContext } from "../../styles/_styleDatatable"
import { type ICustomerSendTemplate } from "../../state/modules/quoteTemplate/_definitions"
import { getUserBackofficeImageRequest } from "../../state/modules/userBackOffice/actions"
import { useLocation, useNavigate } from "react-router"
import { ROUTES, STATES_STEP } from "../../utils/constants"
import { useMobile } from "../../hook/useMobile"

const QuoteSend = (): ReactElement => {
  const navigate = useNavigate()
  const isMobile = useMobile()
  const { state } = useLocation()
  const [validStepThree, setValidStepThree] = React.useState(STATES_STEP.INACTIVE)
  const { isResend, quote } = state?.data ?? { isResend: false, quote: null }
  const { t } = useTranslation()
  const { setLoadingContext, themeContext } = useGlobalContext()
  const [currentStep, setCurrentStep] = React.useState(1)
  const customer = useSelector(customerSelector)
  const quoterTemplate = useSelector(quoteTemplateSelector)
  const dispatch = useDispatch()
  const [customerSelectedStep1, setCustomerSelectedStep1] = React.useState<ICustomer[]>([])
  const [temaplateSelectedStep2, setTemaplateSelectedStep2] = React.useState<any>(null)
  const [dataStep3, setDataStep3] = React.useState<any>(null)
  const [dataHtmlStep3, setDataHtmlStep3] = React.useState<any>(isResend ? quote : null)
  const [dataStep4, setDataStep4] = React.useState<any[]>([])
  const [customerWithError, setCustomerWithError] = React.useState<boolean>(false)
  const [confirrmSend, setConfirrmSend] = React.useState<boolean>(false)
  const [sendTest, setSendTest] = React.useState<boolean>(false)

  const handleOpenconfirmSend = (): void => {
    setConfirrmSend(!confirrmSend)
  }
  const handleOpenCustomerError = (): void => {
    setCustomerWithError(!customerWithError)
    dispatch(cleanResponseErrorQuoteTemplate())
    dispatch(cleanPDFQuoteTemplate())
  }

  const steps = [
    {
      id: 1,
      tile: t(TRANSLATION_INDEX_QUOTER.STEP_1),
      description: t(TRANSLATION_INDEX_QUOTER.STEP_1_DESCRIPTION),
      component: <Step1 data={customerSelectedStep1} setDataFiltered={setCustomerSelectedStep1} />,
      isLastStep: false,
      isValidate: customerSelectedStep1.length > 0,
      errorMessage: customerSelectedStep1.length === 0 ? t(TRANSLATION_INDEX_QUOTER.NO_CUSTOMER_SELECTED_2) : "",
      isFirstStep: true
    },
    {
      id: isResend ? 0 : 2,
      tile: t(TRANSLATION_INDEX_QUOTER.STEP_2),
      description: t(TRANSLATION_INDEX_QUOTER.STEP_2_DESCRIPTION),
      component: (
        <Step2
          customers={customerSelectedStep1}
          data={temaplateSelectedStep2}
          setDataFiltered={setTemaplateSelectedStep2}
        />
      ),
      isLastStep: false,
      isValidate: temaplateSelectedStep2 !== null || isResend,
      errorMessage: temaplateSelectedStep2 !== null ? "" : t(TRANSLATION_INDEX_QUOTER.NO_TEMPLATE_SELECTED),
      isFirstStep: false
    },
    {
      id: isResend ? 2 : 3,
      tile: t(isResend ? TRANSLATION_INDEX_QUOTER.STEP_2 : TRANSLATION_INDEX_QUOTER.STEP_3),
      description: t(TRANSLATION_INDEX_QUOTER.STEP_3_DESCRIPTION),
      component: (
        <Step3
          data={dataStep3}
          setDataFiltered={setDataStep3}
          setFilters={setDataHtmlStep3}
          setStateStep={setValidStepThree}
          stateStep={validStepThree}
        />
      ),
      isLastStep: false,
      isValidate: true,
      isFirstStep: false
    },
    {
      id: isResend ? 3 : 4,
      tile: t(TRANSLATION_INDEX_QUOTER.STEP_4),
      description: t(TRANSLATION_INDEX_QUOTER.STEP_4_DESCRIPTION),
      component: <Step4 data={dataStep4} setDataFiltered={setDataStep4} />,
      isLastStep: true,
      isValidate: true,
      isFirstStep: false
    }
  ]

  const request = {
    idsClientes: customerSelectedStep1.map(item => {
      return item.idCliente
    }),
    adjuntos: dataStep4,
    html: dataHtmlStep3,
    cotizacion: dataStep3,
    envioDePrueba: sendTest
  }

  const sendData = (): void => {
    const dataToSend = request
    setLoadingContext(true)
    dispatch(postSendQuoteRequest(dataToSend))
  }

  const previewPDF = (): void => {
    setLoadingContext(true)
    if (customerSelectedStep1.length > 1) toast.error(t(TRANSLATION_INDEX_QUOTER.ONLY_CUSTOMER_TO_DOWNLOAD))
    else {
      const requestCreatePDF = {
        idCliente: customerSelectedStep1[0].idCliente,
        html: dataHtmlStep3,
        cotizacion: dataStep3
      }
      dispatch(previewPDFQuoteTemplateRequest(requestCreatePDF))
    }
  }

  const downloadPDF = (): void => {
    if (customerSelectedStep1.length > 1) toast.error(t(TRANSLATION_INDEX_QUOTER.ONLY_CUSTOMER_TO_DOWNLOAD))
    else {
      const requestCreatePDF = {
        idCliente: customerSelectedStep1[0].idCliente,
        html: dataHtmlStep3,
        cotizacion: dataStep3
      }
      dispatch(createPDFQuoteTemplateRequest(requestCreatePDF))
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: false,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      id: "1",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME),
      selector: (row: ICustomerSendTemplate) => row.razonSocial.toUpperCase(),
      width: `100%`,
      sortable: true
    }
  ]

  React.useEffect(() => {
    if (quoterTemplate.showToast) {
      if (quoterTemplate.hasErrMessage) {
        toast.error(t(quoterTemplate.errorCode))
      } else {
        toast.success(t(quoterTemplate.toastMessage))
      }
    }
  }, [quoterTemplate.showToast])

  React.useEffect(() => {
    if (currentStep === (isResend ? 3 : 4)) {
      setLoadingContext(quoterTemplate.loading)
      if (confirrmSend && !quoterTemplate.loading && quoterTemplate.responseSend) setConfirrmSend(false)
      if (quoterTemplate.responseSend) {
        if (
          quoterTemplate.responseSend.clientesConError.length > 0 ||
          (quoterTemplate.responseSend.clientesSinDestinatarios.length > 0 &&
            quoterTemplate.responseSend.clientesSinDestinatarios.length === customerSelectedStep1.length)
        ) {
          if (isResend) navigate(ROUTES.VIEW_SEND_QUOTE.pathName)
          setCustomerWithError(true)
          if (quoterTemplate.responseSend.clientesSinDestinatarios.length > 0) setCurrentStep(1)
        } else {
          if (quoterTemplate.responseSend.clientesSinDestinatarios.length > 0) {
            setCustomerWithError(true)
          } else setCustomerSelectedStep1([])
          toast.success(t(TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE))
          dispatch(getCustomerClear())
          if (isResend) navigate(ROUTES.VIEW_SEND_QUOTE.pathName)
          if (!sendTest) {
            setCurrentStep(1)
            setDataStep3(null)
            setDataHtmlStep3(null)
            setDataStep4([])
            setTemaplateSelectedStep2(null)
          }
        }
      }
    }
  }, [quoterTemplate])

  React.useEffect(() => {
    setLoadingContext(customer.loading)
  }, [customer])

  React.useEffect(() => {
    if (!isResend) {
      setCustomerSelectedStep1([])
      setCurrentStep(1)
      dispatch(getCustomerClearAll())
    }
  }, [isResend])

  React.useEffect(() => {
    if (validStepThree === STATES_STEP.SUCCESS) {
      setValidStepThree(STATES_STEP.INACTIVE)
      setCurrentStep(item => item + 1)
    }
  }, [validStepThree])

  React.useEffect(() => {
    if (currentStep === (isResend ? 2 : 3)) {
      setValidStepThree(STATES_STEP.ACTIVE)
    } else {
      setValidStepThree(STATES_STEP.INACTIVE)
    }
  }, [currentStep])

  React.useEffect(() => {
    if (!isStringEmpty(quoterTemplate.codePDF)) {
      window.open(
        `${process.env.REACT_APP_API_URL}/api/backoffice/v1/cotizaciones/descargarPDF/${getAuth("*")[1]}/${
          quoterTemplate.codePDF
        }`
      )
      dispatch(cleanPDFQuoteTemplate())
    }
  }, [quoterTemplate])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getClientTypeRequest())
    dispatch(getCountryRequest())
    dispatch(getClassificationRequest())
    dispatch(getAssignedCommercialRequest())
    dispatch(getQuoteTemplateRequest())
    dispatch(getUserBackofficeImageRequest())

    return () => {
      dispatch(getCustomerClearAll())
    }
  }, [])

  React.useEffect(() => {
    const step3 = quoterTemplate?.data
      ? quoterTemplate?.data.find(item => item.idCotizacion === temaplateSelectedStep2?.value)
      : null
    setDataStep3(isResend ? quote : step3)
  }, [temaplateSelectedStep2])

  return (
    <>
      <ContainerPage
        title={`${isResend ? "Re" : ""}${
          isResend ? t(TRANSLATION_INDEX_MENU.SEND_QUOTE).toLocaleLowerCase() : t(TRANSLATION_INDEX_MENU.SEND_QUOTE)
        }`}
        actions={[]}
      >
        <ContainerTable themeContext={themeContext}>
          {customerWithError && (
            <AlertModal
              title={`${t(TRANSLATION_INDEX_GLOBAL.WARNING)}`}
              maxWidth="50%"
              activeCloseModal
              closeModal={handleOpenCustomerError}
            >
              <div>
                {quoterTemplate?.responseSend?.clientesConError &&
                  quoterTemplate?.responseSend?.clientesConError.length > 0 && (
                    <>
                      <div style={{ padding: "15px 25px", textAlign: "center" }}>
                        {t(TRANSLATION_INDEX_QUOTER.CUSTOMER_W_ERROR)}
                      </div>
                      <DataTable
                        data={quoterTemplate.responseSend?.clientesConError ?? []}
                        theme="solarized"
                        columns={columns}
                        dense
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                        striped
                        customStyles={customStylesContext(themeContext)}
                        paginationDefaultPage={1}
                        // paginationResetDefaultPage={resetPage}
                      />
                    </>
                  )}
                {quoterTemplate.responseSend?.clientesSinDestinatarios &&
                  quoterTemplate.responseSend?.clientesSinDestinatarios.length > 0 && (
                    <>
                      <div style={{ padding: "35px 25px", textAlign: "center" }}>
                        {quoterTemplate.responseSend?.clientesSinDestinatarios.length > 0 &&
                        quoterTemplate.responseSend?.clientesSinDestinatarios.length < customerSelectedStep1.length ? (
                          <>
                            <div>{t(TRANSLATION_INDEX_QUOTER.CUSTOMER_WITHOUT_RECIMIENTS2)}</div>
                            <div>{t(TRANSLATION_INDEX_QUOTER.CUSTOMER_WITHOUT_RECIMIENTS3)}</div>
                          </>
                        ) : (
                          t(TRANSLATION_INDEX_QUOTER.CUSTOMER_WITHOUT_RECIMIENTS)
                        )}
                      </div>
                      <DataTable
                        data={quoterTemplate.responseSend?.clientesSinDestinatarios ?? []}
                        theme="solarized"
                        columns={columns}
                        dense
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                        striped
                        customStyles={customStylesContext(themeContext)}
                        paginationDefaultPage={1}
                      />
                    </>
                  )}
              </div>
            </AlertModal>
          )}
          {confirrmSend && (
            <AlertModal
              title={`${t(TRANSLATION_INDEX_GLOBAL.WARNING)}`}
              maxWidth="50%"
              activeCloseModal
              closeModal={handleOpenconfirmSend}
            >
              <div>
                <div style={{ padding: "35px 25px" }}>{t(TRANSLATION_INDEX_QUOTER.CONFIRM_SEND_QUOTE)}</div>
                <GroupButtom themeContext={themeContext}>
                  <ButtonCustom
                    onClick={handleOpenconfirmSend}
                    width="80px"
                    type="button"
                    label={t("global.cancel") ?? ""}
                    background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                    backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                  />
                  <ButtonCustom
                    width="80px"
                    type="button"
                    onClick={sendData}
                    label={t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""}
                    background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                    backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
                    marginLeft
                  />
                </GroupButtom>
              </div>
            </AlertModal>
          )}
          <Stepper>
            {steps
              .filter(item => item.id > 0 && item.id === currentStep && isMobile)
              .map(item => {
                return (
                  <div key={item.tile} style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center"
                      }}
                    >
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <span
                          style={{
                            width: "100px",
                            height: "4px",
                            background: item.isFirstStep
                              ? "transparent"
                              : currentStep >= item.id
                              ? GLOBAL_STYLES.BUTTON.SUCCESS_BG_COLOR[themeContext]
                              : GLOBAL_STYLES.BUTTON.DISABLED_BG_COLOR[themeContext]
                          }}
                        ></span>
                        <div
                          onKeyDown={() => {
                            if (item.id < currentStep) setCurrentStep(item.id)
                          }}
                          tabIndex={0}
                          role="button"
                          onClick={() => {
                            if (item.id < currentStep) setCurrentStep(item.id)
                          }}
                          style={{
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5px",
                            cursor: item.id > currentStep ? "no-drop" : "pointer",
                            color: GLOBAL_STYLES.LABEL.TYPE_2[themeContext],
                            background:
                              currentStep >= item.id
                                ? GLOBAL_STYLES.BUTTON.SUCCESS_BG_COLOR[themeContext]
                                : GLOBAL_STYLES.BUTTON.DISABLED_BG_COLOR[themeContext],
                            borderRadius: "150px",
                            textAlign: "center"
                          }}
                        >
                          {item.tile}
                        </div>
                        <span
                          style={{
                            width: "100px",
                            height: "4px",
                            background: item.isLastStep
                              ? "transparent"
                              : currentStep > item.id
                              ? GLOBAL_STYLES.BUTTON.SUCCESS_BG_COLOR[themeContext]
                              : GLOBAL_STYLES.BUTTON.DISABLED_BG_COLOR[themeContext]
                          }}
                        ></span>
                      </div>
                      {item.description}
                    </div>
                  </div>
                )
              })}
          </Stepper>
          <div>{steps.find(item => item.id === currentStep)?.component}</div>
          <div style={{ marginTop: "25px", display: "flex", justifyContent: "flex-end" }}>
            <ButtonCustom
              width="150px"
              label={t(TRANSLATION_INDEX_GLOBAL.BACK) ?? ""}
              disabled={currentStep === 1}
              onClick={() => {
                if (currentStep > 1) {
                  setCurrentStep(item => item - 1)
                }
              }}
              background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
              backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
            />

            {currentStep === (isResend ? 3 : 4) && (
              <ButtonCustom
                width="150px"
                label={t(TRANSLATION_INDEX_QUOTER.PDF_PREVIEW) ?? ""}
                marginLeft
                onClick={previewPDF}
                background={GLOBAL_STYLES.BUTTON.WARNING_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.WARNING_BG_IMAGE_HOVER[themeContext]}
              />
            )}

            {currentStep === (isResend ? 3 : 4) && (
              <ButtonCustom
                width="150px"
                label={t(TRANSLATION_INDEX_QUOTER.DOWNLOAD_PDF) ?? ""}
                marginLeft
                onClick={downloadPDF}
                background={GLOBAL_STYLES.BUTTON.YELLOW_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.YELLOW_BG_IMAGE_HOVER[themeContext]}
              />
            )}
            {currentStep === (isResend ? 3 : 4) && (
              <ButtonCustom
                width="150px"
                label={t(TRANSLATION_INDEX_QUOTER.SEND_TEST) ?? ""}
                marginLeft
                onClick={() => {
                  setSendTest(true)
                  setConfirrmSend(!confirrmSend)
                }}
                background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
              />
            )}
            <ButtonCustom
              width="150px"
              label={
                currentStep === (isResend ? 3 : 4)
                  ? t(TRANSLATION_INDEX_QUOTER.SEND_QUOTE) ?? ""
                  : t(TRANSLATION_INDEX_GLOBAL.NEXT) ?? ""
              }
              marginLeft
              onClick={() => {
                if (
                  currentStep === (isResend ? 2 : 3) &&
                  (validStepThree === STATES_STEP.ACTIVE || validStepThree === STATES_STEP.ERROR)
                )
                  setValidStepThree(STATES_STEP.VALID_DATA)
                else if (currentStep < (isResend ? 3 : 4)) {
                  if (steps[currentStep - 1].isValidate) setCurrentStep(item => item + 1)
                  else toast.error(steps[currentStep - 1].errorMessage)
                } else {
                  setSendTest(false)
                  setConfirrmSend(!confirrmSend)
                }
              }}
              background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
              backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
            />
          </div>
        </ContainerTable>
      </ContainerPage>
    </>
  )
}

export default QuoteSend
