/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_PROFILE,
  TRANSLATION_INDEX_QUOTER
} from "../../config/translation"
import DataTable from "react-data-table-component"

import AlertModal from "../../components/AlertModal"

import { FORMAT_DATE, PERMISSION, ROUTES } from "../../utils/constants"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { getAuth, isStringEmpty } from "../../utils/functions"
import ButtonCustom from "../../components/ButtonCustom"
import { ContainerIcons, ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import SearchUserClientApp from "./forms/search/index"
import { toast } from "react-toastify"

import FormData from "./forms/addUpdate/FormaddUpdate"
import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { format } from "date-fns"
import { useMobile } from "../../hook/useMobile"
import { quoteTemplateSelector } from "../../state/modules/quoteTemplate/_selectors"
import { type IQuoteSent } from "../../interfaces/IQuoteTemplate"
import { IoMdSend } from "react-icons/io"
import {
  cleanPDFQuoteTemplate,
  getQuoteSentByIdRequest,
  getQuoteSentCustomerRequest,
  getQuoteSentRequest
} from "../../state/modules/quoteTemplate/actions"
import { FaEye } from "react-icons/fa"
import { getCountryRequest } from "../../state/modules/customer/actions"
import { ToolTipRow } from "../CustomerProspect/_styles"
import { useNavigate } from "react-router-dom"

const QuoteViewSentPage = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const quoteTemplate = useSelector(quoteTemplateSelector)
  const [dataFiltered, setDataFiltered] = React.useState<IQuoteSent[]>(quoteTemplate.dataQuoteSent)
  const [isResend, setIsResend] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const { loadingContext, setLoadingContext, themeContext } = useGlobalContext()
  const [action, setAction] = React.useState<string>("")

  const [dataToModal, setDataToModal] = React.useState<any>(null)

  const handleOpenModal = (): void => {
    setOpenModal(!openModal)
    setDataToModal(null)
    setAction("")
    dispatch(cleanPDFQuoteTemplate())
    if (isResend) handleResend("")
  }

  const getDataToModal = (id, type): void => {
    setAction(type)
    setLoadingContext(true)
    if (type === "CUSTOMER") dispatch(getQuoteSentCustomerRequest(id))
    else dispatch(getQuoteSentByIdRequest(id))
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }
  const handleResend = (data): void => {
    navigate(ROUTES.RESEND_QUOTE.pathName, { state: { data } })
  }
  const columns = [
    {
      name: t(TRANSLATION_INDEX_QUOTER.NAME),
      selector: (row: IQuoteSent) => row.nombre.toLocaleUpperCase(),
      sortable: true,
      width: isMobile ? "200px" : `calc(37% - ${getAuth(PERMISSION.COTIZACIONES_ENVIAR)[0] ? "325px" : "260px"})`,
      cell: (row: IQuoteSent) => {
        return <ToolTipRow>{row.nombre.toLocaleUpperCase()}</ToolTipRow>
      }
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE),
      selector: (row: IQuoteSent) =>
        row.modoTransporte === "AIR+SEA"
          ? `${t(TRANSLATION_INDEX_QUOTER.AIR).toLocaleUpperCase()} + ${t(
              TRANSLATION_INDEX_QUOTER.SEA
            ).toLocaleUpperCase()}`
          : t(TRANSLATION_INDEX_QUOTER[row.modoTransporte.toLocaleUpperCase()]).toLocaleUpperCase(),
      sortable: true,
      width: isMobile ? "150px" : `7%`,
      cell: (row: IQuoteSent) => {
        return (
          <ToolTipRow>
            {row.modoTransporte === "AIR+SEA"
              ? `${t(TRANSLATION_INDEX_QUOTER.AIR).toLocaleUpperCase()} + ${t(
                  TRANSLATION_INDEX_QUOTER.SEA
                ).toLocaleUpperCase()}`
              : t(TRANSLATION_INDEX_QUOTER[row.modoTransporte.toLocaleUpperCase()]).toLocaleUpperCase()}
          </ToolTipRow>
        )
      }
    },
    {
      name: `${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ORIGINS_2)}`,
      selector: (row: IQuoteSent) =>
        row.procedencias
          .map(item => {
            return item.descripcion
          })
          .join(", ") ?? "-",
      sortable: true,
      cell: (row: IQuoteSent) => {
        const countries = row.procedencias
          .map(item => {
            return item.descripcion
          })
          .join(", ")
        return <ToolTipRow>{countries}</ToolTipRow>
      },
      width: isMobile ? "150px" : `21%`
    },
    {
      name: `${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.DESTINATIONS_2)}`,
      selector: (row: IQuoteSent) =>
        row.destinos
          .map(item => {
            return item.descripcion
          })
          .join(", ") ?? "-",
      sortable: true,
      width: isMobile ? "150px" : `20%`,
      cell: (row: IQuoteSent) => {
        const countries = row.destinos
          .map(item => {
            return item.descripcion
          })
          .join(", ")
        return <ToolTipRow>{countries}</ToolTipRow>
      }
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.SENDING_DATE),
      selector: (row: IQuoteSent) => row?.fechaEnvio ?? "-",
      cell: (row: IQuoteSent) => {
        return (
          <div>
            <div>{row.usuarioEnvio.toLocaleUpperCase()}</div>
            {row?.fechaEnvio != null ? format(new Date(row?.fechaEnvio), FORMAT_DATE) : "-"}
          </div>
        )
      },
      sortable: true,
      width: isMobile ? "200px" : `calc(13%)`,
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.ACTIONS),
      cell: (row: IQuoteSent) => {
        return (
          <ContainerIcons>
            <ButtonCustom
              label={t(TRANSLATION_INDEX_QUOTER.VIEW_CUSTOMER) ?? ""}
              onClick={() => {
                setIsResend(false)
                getDataToModal(row.idCotizacion, "CUSTOMER")
              }}
              width="140px"
              background={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE[themeContext]}
              backgroundHover={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE_HOVER[themeContext]}
              icon={<FaEye />}
            />
            <ButtonCustom
              label={t(TRANSLATION_INDEX_QUOTER.VIEW_QUOTE) ?? ""}
              marginLeft
              onClick={() => {
                setIsResend(false)
                getDataToModal(row.idCotizacion, "QUOTE_DATA")
              }}
              width="120px"
              background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
              backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              icon={<FaEye />}
            />
            {getAuth(PERMISSION.COTIZACIONES_ENVIAR)[0] && (
              <ButtonCustom
                label={t(TRANSLATION_INDEX_QUOTER.RESEND) ?? ""}
                marginLeft
                onClick={() => {
                  setIsResend(true)
                  getDataToModal(row.idCotizacion, "QUOTE_DATA")
                }}
                width="85px"
                background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                icon={<IoMdSend />}
              />
            )}
          </ContainerIcons>
        )
      },
      center: true,
      omit: !getAuth(PERMISSION.COTIZACIONES_CONSULTAR)[0] && !getAuth(PERMISSION.COTIZACIONES_ENVIAR)[0],
      width: getAuth(PERMISSION.COTIZACIONES_ENVIAR)[0] ? "325px" : "260px"
    }
  ]

  const handleDispatch = (): void => {
    setDataToModal(null)
    setAction("")
    dispatch(cleanPDFQuoteTemplate())
  }

  React.useEffect(() => {
    if (quoteTemplate.showToast) {
      if (quoteTemplate.hasErrMessage) {
        toast.error(t(quoteTemplate.errorCode))
      } else {
        toast.success(t(quoteTemplate.toastMessage))
        handleOpenModal()
        dispatch(getQuoteSentRequest())
      }
    }
  }, [quoteTemplate.showToast])

  React.useEffect(() => {
    setLoadingContext(quoteTemplate.loading)
    setDataFiltered(quoteTemplate?.dataQuoteSent)
  }, [quoteTemplate.dataQuoteSent])

  React.useEffect(() => {
    setLoadingContext(quoteTemplate.loading)
  }, [quoteTemplate])

  React.useEffect(() => {
    if (!isStringEmpty(action)) {
      if (isResend && quoteTemplate.dataQuote != null) handleResend({ quote: quoteTemplate.dataQuote, isResend: true })
      else if (quoteTemplate.dataQuote != null) setDataToModal(quoteTemplate.dataQuote)
      else if (quoteTemplate.dataCustomer != null) setDataToModal(quoteTemplate.dataCustomer)
    } else {
      setDataToModal(null)
    }
  }, [quoteTemplate.dataCustomer, quoteTemplate.dataQuote, action])

  React.useEffect(() => {
    if (dataToModal !== null) setOpenModal(true)
  }, [dataToModal])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getQuoteSentRequest())
    dispatch(getCountryRequest())
  }, [])

  return (
    <>
      <ContainerPage title={`${t(TRANSLATION_INDEX_MENU.VIEW_SEND_QUOTE)}`} actions={[]}>
        <>
          {openModal && !isResend && (
            <AlertModal
              maxWidth={isMobile ? "85%" : action === "CUSTOMER" ? "500px" : "60%"}
              closeModal={() => {
                handleOpenModal()
              }}
              activeCloseModal
              title={`${t(
                action === "CUSTOMER"
                  ? TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER
                  : TRANSLATION_INDEX_QUOTER.QUOTE_DATA
              )} `}
            >
              <FormData
                data={dataToModal}
                action={action}
                closeModal={handleOpenModal}
                loading={loadingContext}
                accept={handleDispatch}
              />
            </AlertModal>
          )}

          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchUserClientApp data={quoteTemplate.dataQuoteSent} setDataFiltered={setDataFiltered} />
            <DataTable
              data={dataFiltered ?? []}
              theme="solarized"
              noDataComponent={<NoData />}
              columns={columns}
              dense
              pagination
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
              defaultSortFieldId={5}
              defaultSortAsc={false}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default QuoteViewSentPage
