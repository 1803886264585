/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_MENU, TRANSLATION_INDEX_PROFILE } from "../../config/translation"
import DataTable from "react-data-table-component"

import AlertModal from "../../components/AlertModal"

import { ACTIONS, FORMAT_DATE, PERMISSION } from "../../utils/constants"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { getAuth } from "../../utils/functions"
import ButtonCustom from "../../components/ButtonCustom"
import { ContainerIcons, ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import SearchUserClientApp from "./forms/search/index"
import { toast } from "react-toastify"
import { profileSelector } from "../../state/modules/profile/_selectors"
import { type IProfile } from "../../interfaces/IProfile"
import {
  addProfileRequest,
  deleteProfileRequest,
  getProfileRequest,
  putProfileRequest
} from "../../state/modules/profile/actions"
import IconEdit from "../../components/Icons/IconEdit"
import IconPlus from "../../components/Icons/IconPlus"
import FormaddUpdate from "./forms/addUpdate/FormaddUpdate"
import IconDelete from "../../components/Icons/IconDelete"
import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { format } from "date-fns"
import { useMobile } from "../../hook/useMobile"
import ExportToExcel from "./ExportToExcel"

const ProfilePage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const profile = useSelector(profileSelector)
  const [dataFiltered, setDataFiltered] = React.useState<IProfile[]>(profile.data)
  const [openModal, setOpenModal] = React.useState(false)
  const { loadingContext, setLoadingContext, themeContext } = useGlobalContext()
  const [userSelected, setUserSelected] = React.useState<IProfile | null>(null)
  const [action, setAction] = React.useState<string>("")

  const handleOpenModal = (action = ""): void => {
    setAction(action)
    setOpenModal(!openModal)
  }

  const handleAdd = (): void => {
    setUserSelected(null)
    handleOpenModal(ACTIONS.ADD.VALUE)
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.ID),
      selector: (row: IProfile) => row.idPerfil,
      width: isMobile ? "60px" : "10%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.DESCRIPTION),
      selector: (row: IProfile) => row.descripcion.toLocaleUpperCase(),
      sortable: true,
      width: isMobile
        ? "150px"
        : `calc(28% - ${
            getAuth(PERMISSION.PERFIL_MODIFICAR)[0] && getAuth(PERMISSION.PERFIL_ELIMINAR)[0] ? "40px" : "40px"
          })`
    },

    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.USER_NAME),
      selector: (row: IProfile) => row.usuarioActualizacion ?? "",
      sortable: true,
      width: isMobile
        ? "130px"
        : `calc(28% - ${
            getAuth(PERMISSION.PERFIL_MODIFICAR)[0] && getAuth(PERMISSION.PERFIL_ELIMINAR)[0] ? "40px" : "40px"
          })`
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.LAST_UPDATE),
      selector: (row: IProfile) => row?.fechaActualizacion?.toString() ?? "-",
      cell: (row: IProfile) => {
        return (
          <div>{row?.fechaActualizacion != null ? format(new Date(row?.fechaActualizacion), FORMAT_DATE) : "-"}</div>
        )
      },
      sortable: true,
      width: isMobile
        ? "200px"
        : `calc(28% - ${
            getAuth(PERMISSION.PERFIL_MODIFICAR)[0] && getAuth(PERMISSION.PERFIL_ELIMINAR)[0] ? "65px" : "40px"
          })`,
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.ACTIONS),
      cell: (row: IProfile) => {
        return (
          <ContainerIcons>
            {getAuth(PERMISSION.PERFIL_MODIFICAR)[0] && (
              <ButtonCustom
                label={t(TRANSLATION_INDEX_GLOBAL.EDIT) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.EDIT.VALUE)
                }}
                width="80px"
                background={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}

            {getAuth(PERMISSION.PERFIL_ELIMINAR)[0] && (
              <ButtonCustom
                marginLeft
                label={t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.DELETE.VALUE)
                }}
                width="85px"
                background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                icon={<IconDelete />}
              />
            )}
          </ContainerIcons>
        )
      },
      center: true,
      omit: !getAuth(PERMISSION.PERFIL_MODIFICAR)[0] && !getAuth(PERMISSION.PERFIL_ELIMINAR)[0],
      width: isMobile
        ? "180px"
        : getAuth(PERMISSION.PERFIL_MODIFICAR)[0] && getAuth(PERMISSION.PERFIL_ELIMINAR)[0]
        ? "200px"
        : "120px"
    }
  ]

  const handleDispatch = (values: any): void => {
    switch (action) {
      case ACTIONS.DELETE.VALUE:
        dispatch(deleteProfileRequest(values.idPerfil))
        break
      case ACTIONS.ADD.VALUE:
        dispatch(addProfileRequest(values))
        break
      case ACTIONS.EDIT.VALUE:
        dispatch(putProfileRequest(values))
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    if (profile.showToast) {
      if (profile.hasErrMessage) {
        toast.error(t(profile.errorCode))
      } else {
        toast.success(t(profile.toastMessage))
        handleOpenModal()
        dispatch(getProfileRequest())
      }
    }
  }, [profile.showToast])

  React.useEffect(() => {
    setLoadingContext(profile.loading)
    setDataFiltered(profile?.data)
  }, [profile])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getProfileRequest())
  }, [])

  return (
    <>
      <ContainerPage
        title={`${t(TRANSLATION_INDEX_MENU.PROFILES)}`}
        actions={[
          {
            label: t(TRANSLATION_INDEX_GLOBAL.ADD),
            icon: <IconPlus size={13} />,
            permission: PERMISSION.PERFIL_CREAR,
            click: () => {
              handleAdd()
            }
          },
          {
            isButton: true,
            component: <ExportToExcel data={dataFiltered} />
          }
        ]}
      >
        <>
          {openModal && (
            <AlertModal
              maxWidth="450px"
              activeCloseModal
              closeModal={() => {
                handleOpenModal()
              }}
              title={`${t(TRANSLATION_INDEX_PROFILE.PROFILE)} | ${t(TRANSLATION_INDEX_GLOBAL[action.toUpperCase()])}`}
            >
              <FormaddUpdate
                data={userSelected}
                action={action}
                closeModal={handleOpenModal}
                loading={loadingContext}
                accept={handleDispatch}
              />
            </AlertModal>
          )}

          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchUserClientApp data={profile.data} setDataFiltered={setDataFiltered} />
            <DataTable
              data={dataFiltered ?? []}
              theme="solarized"
              noDataComponent={<NoData />}
              columns={columns}
              dense
              pagination
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
              defaultSortFieldId={1}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default ProfilePage
