import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const ContainerTable = styled.div<IStyle>`
  width: 100%;
  opacity: ${({ opacity }) => opacity};
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: opacity 3s;
  z-index: 10;
`

export const ContainerGroup = styled.div`
  display: flex;
  justify-content: center;
`

export const ContainerMails = styled.div<IStyle>`
  width: 100%;
  display: flex;
  max-width: 450px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  justify-content: flex-start;
  flex-direction: column;
`

export const ContainerIcon = styled.div<IStyle>`
  margin-left: 15px;
  width: ${({ width }) => (width != null ? width : "15px")};
  height: ${({ width }) => (width != null ? width : "15px")};
  background-image: ${({ background }) => background};
  padding: 8px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-image: ${({ backgroundHover }) => backgroundHover};
  }
`

export const ContainerButom = styled.div<IStyle>`
  width: calc(100% + 10px);
  margin-left: -15px;
  margin-top: 15px;
  margin-bottom: -15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext]};
  border-radius: 0 0 5px 5px;
`
