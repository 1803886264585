import { legacy_createStore as createStore, applyMiddleware } from "redux"
import { createLogger } from "redux-logger"
import { composeWithDevTools } from "redux-devtools-extension"
import createSagaMiddleware from "redux-saga"
import { createBrowserHistory } from "history"
import { routerMiddleware } from "connected-react-router"
import createRootReducer from "./rootReducer"
import rootSaga from "./rootSagas"

let store: any = null
const middlewares: any[] = []

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const loggerMiddleware = createLogger({
  collapsed: true
})

export default function makeStore(initialState = {}): any {
  if (process.env.NODE_ENV === `development`) {
    middlewares.push(loggerMiddleware)
  }

  store = createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware),
      applyMiddleware(routerMiddleware(history)),
      applyMiddleware(...middlewares)
    )
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export { store, makeStore }
