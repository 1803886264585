import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"
import { getAuth } from "../../../../utils/functions"

export const getUserBackoffice = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/usuarios`)

export const getUserBackofficeImage = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/usuarios/imagenFirma/${getAuth("*")[1]}`)

export const getUserBackofficeImageURL = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/usuarios/imagenFirmaPlana/${getAuth("*")[1]}`)

export const getUserBackofficeNotificationMail = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/usuarios/notificaciones/${request}`)

export const addUserBackoffice = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/usuarios/registrar`, request)

export const postUserBackoffice = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/usuarios`, request)

export const deleteUserBackoffice = async ({ request }): Promise<AxiosPromise<any>> =>
  await new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve("")
    }, 2000)
  })
