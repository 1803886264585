// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getAuthorizationFailure,
  getAuthorizationSuccess,
  patchAuthorizationSuccess,
  patchAuthorizationFailure,
  deleteAuthorizationSuccess,
  deleteAuthorizationFailure,
  postAuthorizationSuccess,
  postAuthorizationFailure
} from "../actions"

import { patchAuthorization, deleteAuthorization, getAuthorization, postAuthorization } from "../services"

function* getUsetAppClientWorker(): SagaIterator {
  try {
    const { data } = yield call(getAuthorization)
    yield put(getAuthorizationSuccess(data))
  } catch (error) {
    yield put(getAuthorizationFailure(error))
  }
}

function* patchUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(patchAuthorization, request.payload)
    yield put(patchAuthorizationSuccess(data))
  } catch (error) {
    yield put(patchAuthorizationFailure(error))
  }
}

function* postUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postAuthorization, request.payload)
    yield put(postAuthorizationSuccess(data))
  } catch (error) {
    yield put(postAuthorizationFailure(error))
  }
}

function* deleteUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteAuthorization, request.payload)
    yield put(deleteAuthorizationSuccess(data))
  } catch (error) {
    yield put(deleteAuthorizationFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.AUTHORIZATION_GET_REQUEST, getUsetAppClientWorker)
  yield takeLatest(types.AUTHORIZATION_PATCH_REQUEST, patchUserClientWorker)
  yield takeLatest(types.AUTHORIZATION_POST_REQUEST, postUserClientWorker)
  yield takeLatest(types.AUTHORIZATION_DELETE_REQUEST, deleteUserClientWorker)
}

export const sagas = [listWatcher]
