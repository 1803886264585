/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { useState, type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerSerch } from "./_styles"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import {
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_QUOTER,
  TRANSLATION_INDEX_REPORT
} from "../../../../config/translation"
import { useMobile } from "../../../../hook/useMobile"
import ButtonCustom from "../../../../components/ButtonCustom"
import { useResize } from "../../../../hook/useResize"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { ContainerButtom } from "../../../CustomerProspect/forms/search/_styles"
import SelectCustom from "../../../../components/SelectCustom"
import { ContainerFilter } from "../../../ReportCustomerRanking/forms/search/_styles"
import InputCustom from "../../../../components/InputCustom"
import { toast } from "react-toastify"

const SearchUserClientApp = ({
  setDataFiltered = () => {},
  data,
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit,
  setSearchData,
  setFilters,
  setClearFilter
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const screenSize = useResize()
  const [disabledButton, setDisabledButton] = useState(false)
  const { themeContext } = useGlobalContext()
  const [fromDate, setFromDate] = React.useState<string | Date | null>(null)
  const [toDate, setToDate] = React.useState<string | Date | null>(null)
  const handleSearch = (): void => {
    const request = {
      anio: values?.anio,
      mesDesde: values?.mesDesde,
      mesHasta: values?.mesHasta,
      modoTransporte: values?.modoTransporte,
      tipoOperacion: values?.tipoOperacion
    }
    if (setFilters) setFilters(request)
    if (setSearchData) setSearchData(true)
  }
  const handleResetFilters = (): void => {
    if (setClearFilter) setClearFilter(true)
    if (setFilters && setFieldValue) {
      setFieldValue("anio", null)
      setFieldValue("mesDesde", null)
      setFieldValue("mesHasta", null)
      setFieldValue("tipoOperacion", null)
      setFieldValue("modoTransporte", null)
      setFromDate(null)
      setToDate(null)
      setFilters({
        fechaDesde: null,
        fechaHasta: null,
        idsClientes: null
      })
    }
  }
  if (values?.mesDesde && values?.mesHasta && values?.mesDesde.value > values?.mesHasta.value)
    toast.error(t(TRANSLATION_INDEX_REPORT.ERROR_MONTHS))

  React.useEffect(() => {
    if (values?.anio && setFieldValue) {
      const anio = values.anio.toString().replace(/[^0-9]/g, "")
      setFieldValue("anio", Number(anio))
    } else if (setFieldValue) {
      setFieldValue("anio", "")
    }
  }, [values?.anio])

  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("fechaDesde", fromDate)
      setFieldValue("fechaHasta", toDate)
    }
  }, [fromDate, toDate])
  React.useEffect(() => {
    if (values?.modoTransporte && values?.tipoOperacion)
      if (values?.mesDesde && values?.mesHasta && values?.mesDesde.value > values?.mesHasta.value)
        setDisabledButton(false)
      else setDisabledButton(true)
    else setDisabledButton(false)
  }, [values])
  return (
    <ContainerSerch>
      <ContainerFilter>
        {setFieldValue != null && (
          <SelectCustom
            label={`${t(TRANSLATION_INDEX_QUOTER.OPERATION_TYPE) ?? ""} (*)`}
            width={isMobile ? "100%" : `${screenSize / 5 + 14}px`}
            value={values?.tipoOperacion ?? []}
            handleChange={setFieldValue}
            name="tipoOperacion"
            validError={false}
            placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION)}`}
            errors={errors?.tipoOperacion}
            options={[
              {
                value: "IMPO",
                label: t(TRANSLATION_INDEX_QUOTER.IMPORT_2).toUpperCase()
              },
              {
                value: "EXPO",
                label: t(TRANSLATION_INDEX_QUOTER.EXPORT_2).toUpperCase()
              }
            ]}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={`${t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE) ?? ""} (*)`}
            width={isMobile ? "100%" : `${screenSize / 5 + 14}px`}
            value={values?.modoTransporte ?? []}
            handleChange={setFieldValue}
            name="modoTransporte"
            placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION)}`}
            marginLeft
            validError={false}
            errors={errors?.modoTransporte}
            options={[
              { label: t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase() ?? "", value: "AIR" },
              { label: t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase() ?? "", value: "SEA" }
            ]}
          />
        )}
        <InputCustom
          label={t(TRANSLATION_INDEX_QUOTER.YEAR) ?? ""}
          value={values?.anio ?? ""}
          handleChange={handleChange}
          name="anio"
          marginLeft
          min={0}
          type="number"
          validError={false}
          placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(TRANSLATION_INDEX_QUOTER.YEAR)}` ?? ""}
          width={isMobile ? "100%" : `${screenSize / 5}px`}
        />
        {setFieldValue != null && (
          <SelectCustom
            label={`${t(TRANSLATION_INDEX_QUOTER.MONTH_FROM)}`}
            width={isMobile ? "100%" : `${screenSize / 5 + 14}px`}
            value={values?.mesDesde ?? []}
            handleChange={setFieldValue}
            name="mesDesde"
            placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION)}`}
            marginLeft
            validError={false}
            errors={errors?.mesDesde}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
              return {
                label: item,
                value: item
              }
            })}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={`${t(TRANSLATION_INDEX_QUOTER.MONTH_TO)}`}
            width={isMobile ? "100%" : `${screenSize / 5 + 14}px`}
            value={values?.mesHasta ?? []}
            handleChange={setFieldValue}
            name="mesHasta"
            placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION)}`}
            marginLeft
            validError={false}
            errors={errors?.mesHasta}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
              return {
                label: item,
                value: item
              }
            })}
          />
        )}
        <ContainerButtom themeContext={themeContext} style={{ width: isMobile ? "100%" : `${screenSize / 4 - 15}px` }}>
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
            background={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE_HOVER[themeContext]}
            onClick={handleResetFilters}
            width={isMobile ? "100%" : "110px"}
          />
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.SEARCH) ?? ""}
            background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
            onClick={handleSearch}
            disabled={!disabledButton}
            width={isMobile ? "100%" : "80px"}
            marginLeft
          />
        </ContainerButtom>
      </ContainerFilter>
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserClientApp)
