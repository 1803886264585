import { event } from "./_duck"
import * as types from "./types"

export const getEventRequest = event.createAction(types.EVENT_GET_REQUEST)
export const getEventSuccess = event.createAction(types.EVENT_GET_SUCCESS)
export const getEventFailure = event.createAction(types.EVENT_GET_FAILURE)

export const getTypeEventRequest = event.createAction(types.EVENT_TYPE_GET_REQUEST)
export const getTypeEventSuccess = event.createAction(types.EVENT_TYPE_GET_SUCCESS)
export const getTypeEventFailure = event.createAction(types.EVENT_TYPE_GET_FAILURE)

export const getContactEventRequest = event.createAction(types.EVENT_CONTACT_GET_REQUEST)
export const getContactEventSuccess = event.createAction(types.EVENT_CONTACT_GET_SUCCESS)
export const getContactEventFailure = event.createAction(types.EVENT_CONTACT_GET_FAILURE)

export const addEventRequest = event.createAction(types.EVENT_ADD_REQUEST)
export const addEventSuccess = event.createAction(types.EVENT_ADD_SUCCESS)
export const addEventFailure = event.createAction(types.EVENT_ADD_FAILURE)

export const putEventRequest = event.createAction(types.EVENT_PUT_REQUEST)
export const putEventSuccess = event.createAction(types.EVENT_PUT_SUCCESS)
export const putEventFailure = event.createAction(types.EVENT_PUT_FAILURE)

export const deleteEventRequest = event.createAction(types.EVENT_DELETE_REQUEST)
export const deleteEventSuccess = event.createAction(types.EVENT_DELETE_SUCCESS)
export const deleteEventFailure = event.createAction(types.EVENT_DELETE_FAILURE)

export const clearAllEvents = event.createAction(types.EVENT_CLEAR_ALL)
export const clearContactEvents = event.createAction(types.EVENT_CLEAR_CONTACT)
