import React, { type ReactElement } from "react"

import ButtonCustom from "../../../../components/ButtonCustom"
import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"

import { useTranslation } from "react-i18next"

import { TRANSLATION_INDEX_GLOBAL } from "../../../../config/translation"
import { Container, ContainerMessage, GroupButtom } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { type IUserAppClient } from "../../../../interfaces/IUserAppClient"
import { useGlobalContext } from "../../../../context/GlobalContext"

const FormUserAppClient = ({
  data,
  isUserPending = false,
  loading = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  touched,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  return (
    <Container onSubmit={handleSubmit}>
      <ContainerMessage themeContext={themeContext}>
        {action === ACTIONS.APPROVE.VALUE
          ? t(TRANSLATION_INDEX_GLOBAL.CONFIRM_APPROVE)
          : t(TRANSLATION_INDEX_GLOBAL.CONFIRM_REJECT)}
      </ContainerMessage>

      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        <ButtonCustom
          marginLeft
          width="80px"
          type="button"
          onClick={() => {
            const request: string = action === ACTIONS.REJECT.VALUE ? "RECHAZADO" : "APROBADO"
            accept(request)
          }}
          label={
            t(action === ACTIONS.REJECT.VALUE ? TRANSLATION_INDEX_GLOBAL.REJECT : TRANSLATION_INDEX_GLOBAL.APPROVE) ??
            ""
          }
          background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
        />
      </GroupButtom>
    </Container>
  )
}

export default withFormik<IFormListProps, IUserAppClient>({
  mapPropsToValues: ({ data, action }): any => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }): any => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept, action } }): any => {}
})(FormUserAppClient)
