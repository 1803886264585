/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"

import ButtonCustom from "../../../../components/ButtonCustom"

import { useTranslation } from "react-i18next"

import { TRANSLATION_INDEX_CUSTOMER_PROSPECT, TRANSLATION_INDEX_QUOTER } from "../../../../config/translation"
import { Container, Group, GroupButtom, ContainerGroup } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS, FORMAT_DATE_ONLY } from "../../../../utils/constants"
import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { withFormik } from "formik"
import { deleteScheme, schemaAdd } from "../../_schema"

import { useGlobalContext } from "../../../../context/GlobalContext"
import InputCustom from "../../../../components/InputCustom"
import { format } from "date-fns"

const FormaddUpdate = ({
  data,
  isUserPending = false,
  loading = false,
  action = "",
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  validateForm,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()

  const buttonGroup = (): ReactElement => {
    return (
      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        {/* <ButtonCustom
          width="80px"
          type="submit"
          label={
            action === ACTIONS.DELETE.VALUE
              ? t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""
              : t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""
          }
          background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
          marginLeft
        /> */}
      </GroupButtom>
    )
  }

  return (
    <>
      <Container onSubmit={handleSubmit}>
        <ContainerGroup themeContext={themeContext}>
          <>
            <Group themeContext={themeContext}>
              <InputCustom label={t(TRANSLATION_INDEX_QUOTER.YEAR) ?? ""} value={values?.anio} disabled />
              <InputCustom label={t(TRANSLATION_INDEX_QUOTER.MONTH) ?? ""} value={values?.mes} marginLeft disabled />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.TYPE) ?? ""}
                value={t(TRANSLATION_INDEX_QUOTER[`${values?.tipo}RT_2`]).toUpperCase() ?? ""}
                disabled
              />
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE) ?? ""}
                value={t(TRANSLATION_INDEX_QUOTER[values?.modoTransporte]).toUpperCase() ?? ""}
                marginLeft
              />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.PLACE) ?? ""}
                value={values?.lugar.toUpperCase()}
                disabled
              />
              <InputCustom
                label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER) ?? ""}/${
                  t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT) ?? ""
                }`}
                value={values?.cliente}
                marginLeft
                disabled
              />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.COUNTRY) ?? ""}
                value={values?.pais.descripcion}
                disabled
              />
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.MANIFIEST) ?? ""}
                value={values?.manifiesto}
                marginLeft
                disabled
              />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.CUSTOMS_BROKER) ?? ""}
                value={`${values?.despachante} ${values?.despachanteRazonSocial ?? ""}`}
                disabled
              />
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.DOCUMENT) ?? ""}
                value={values?.documento}
                marginLeft
                disabled
              />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom label={t(TRANSLATION_INDEX_QUOTER.CURRENCY) ?? ""} value={values?.moneda} disabled />
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.FREIGHT) ?? ""}
                value={values?.flete}
                marginLeft
                disabled
              />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom label={t(TRANSLATION_INDEX_QUOTER.WEIGHT) ?? ""} value={values?.peso} disabled />
              <InputCustom label={t(TRANSLATION_INDEX_QUOTER.PACKS) ?? ""} value={values?.bultos} marginLeft disabled />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom label={t(TRANSLATION_INDEX_QUOTER.CARRIER) ?? ""} value={values?.transportista} disabled />
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.OFFICIALIZATION) ?? ""}
                value={values?.oficializacion ? format(new Date(values?.oficializacion), FORMAT_DATE_ONLY) : ""}
                marginLeft
                disabled
              />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.ARRIVAL) ?? ""}
                value={values?.arribo ? format(new Date(values?.arribo), FORMAT_DATE_ONLY) : ""}
                disabled
              />
              <InputCustom
                label={t(TRANSLATION_INDEX_QUOTER.SUPPLIER) ?? ""}
                value={values?.proveedor}
                marginLeft
                disabled
              />
            </Group>
            <Group themeContext={themeContext}>
              <InputCustom
                width="50%"
                label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSGNED_COMMERCIAL)}`}
                value={values?.comercialAsignado}
                disabled
              />
            </Group>
          </>
        </ContainerGroup>

        {buttonGroup()}
      </Container>
    </>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => {
    return {
      anio: data?.anio,
      mes: data?.mes ?? "",
      tipo: data?.tipo ?? "",
      despachanteRazonSocial: data?.despachanteRazonSocial,
      modoTransporte: data?.modoTransporte ?? "",
      lugar: data?.lugar?.descripcion ?? "",
      cliente: `${data?.cliente.razonSocial.toUpperCase()} (${data?.cliente.cuit})` ?? "",
      pais: data?.pais ?? "",
      manifiesto: data?.manifiesto ?? "",
      despachante: data?.despachante ?? "",
      documento: data?.documento ?? "",
      moneda: data?.moneda ?? "",
      flete: data?.flete ?? "",
      peso: data?.peso ?? "",
      bultos: data?.bultos ?? "",
      transportista: data?.transportista ?? "",
      oficializacion: data?.oficializacion ?? "",
      arribo: data?.arribo ?? "",
      proveedor: data?.proveedor ?? "",
      comercialAsignado: data?.comercialAsignado ?? ""
    }
  },
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : schemaAdd),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {}
})(FormaddUpdate)
