// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import { getReferenceFailure, getReferenceSuccess } from "../actions"

import { getReference } from "../services"

function* getUsetAppClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getReference, request.payload)
    yield put(getReferenceSuccess(data))
  } catch (error) {
    yield put(getReferenceFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.REFERENCE_GET_REQUEST, getUsetAppClientWorker)
}

export const sagas = [listWatcher]
