import React from "react"
import { Container, LabelFloat } from "./_styles"
import { type Props } from "./_types"
import { useGlobalContext } from "../../context/GlobalContext"

export const TooltipCustom: React.FC<Props> = ({ value, width = "300px", children }) => {
  const [showTooltip, setShowTooltip] = React.useState(false)

  const { themeContext } = useGlobalContext()
  return (
    <Container
      onMouseEnter={() => {
        setShowTooltip(true)
      }}
      onMouseLeave={() => {
        setShowTooltip(false)
      }}
    >
      {children}
      <div style={{ position: "relative" }}>
        <LabelFloat themeContext={themeContext} width={width} isActive={showTooltip}>
          {value}
        </LabelFloat>
      </div>
    </Container>
  )
}
