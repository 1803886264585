import { reportCommercial } from "./_duck"

export const REPORT_COMMERCIAL_GET_REQUEST: string = reportCommercial.defineType("REPORT_COMMERCIAL_GET_REQUEST")
export const REPORT_COMMERCIAL_GET_SUCCESS: string = reportCommercial.defineType("REPORT_COMMERCIAL_GET_SUCCESS")
export const REPORT_COMMERCIAL_GET_FAILURE: string = reportCommercial.defineType("REPORT_COMMERCIAL_GET_FAILURE")

export const REPORT_CUSTOMER_GET_REQUEST: string = reportCommercial.defineType("REPORT_CUSTOMER_GET_REQUEST")
export const REPORT_CUSTOMER_GET_SUCCESS: string = reportCommercial.defineType("REPORT_CUSTOMER_GET_SUCCESS")
export const REPORT_CUSTOMER_GET_FAILURE: string = reportCommercial.defineType("REPORT_CUSTOMER_GET_FAILURE")

export const REPORT_UNCONTACT_CUSTOMER_GET_REQUEST: string = reportCommercial.defineType(
  "REPORT_UNCONTACT_CUSTOMER_GET_REQUEST"
)
export const REPORT_UNCONTACT_CUSTOMER_GET_SUCCESS: string = reportCommercial.defineType(
  "REPORT_UNCONTACT_CUSTOMER_GET_SUCCESS"
)
export const REPORT_UNCONTACT_CUSTOMER_GET_FAILURE: string = reportCommercial.defineType(
  "REPORT_UNCONTACT_CUSTOMER_GET_FAILURE"
)

export const REPORT_RANKING_CUSTOMER_GET_REQUEST: string = reportCommercial.defineType(
  "REPORT_RANKING_CUSTOMER_GET_REQUEST"
)
export const REPORT_RANKING_CUSTOMER_GET_SUCCESS: string = reportCommercial.defineType(
  "REPORT_RANKING_CUSTOMER_GET_SUCCESS"
)
export const REPORT_RANKING_CUSTOMER_GET_FAILURE: string = reportCommercial.defineType(
  "REPORT_RANKING_CUSTOMER_GET_FAILURE"
)

export const REPORT_RANKING_CARRIER_GET_REQUEST: string = reportCommercial.defineType(
  "REPORT_RANKING_CARRIER_GET_REQUEST"
)
export const REPORT_RANKING_CARRIER_GET_SUCCESS: string = reportCommercial.defineType(
  "REPORT_RANKING_CARRIER_GET_SUCCESS"
)
export const REPORT_RANKING_CARRIER_GET_FAILURE: string = reportCommercial.defineType(
  "REPORT_RANKING_CARRIER_GET_FAILURE"
)

export const REPORT_RANKING_COUNTRY_GET_REQUEST: string = reportCommercial.defineType(
  "REPORT_RANKING_COUNTRY_GET_REQUEST"
)
export const REPORT_RANKING_COUNTRY_GET_SUCCESS: string = reportCommercial.defineType(
  "REPORT_RANKING_COUNTRY_GET_SUCCESS"
)
export const REPORT_RANKING_COUNTRY_GET_FAILURE: string = reportCommercial.defineType(
  "REPORT_RANKING_COUNTRY_GET_FAILURE"
)

export const REPORT_RANKING_BROKER_GET_REQUEST: string = reportCommercial.defineType(
  "REPORT_RANKING_BROKER_GET_REQUEST"
)
export const REPORT_RANKING_BROKER_GET_SUCCESS: string = reportCommercial.defineType(
  "REPORT_RANKING_BROKER_GET_SUCCESS"
)
export const REPORT_RANKING_BROKER_GET_FAILURE: string = reportCommercial.defineType(
  "REPORT_RANKING_BROKER_GET_FAILURE"
)
export const REPORT_CLEAR_ALL: string = reportCommercial.defineType("REPORT_CLEAR_ALL")
