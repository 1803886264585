/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { ContainerFormForgot, GroupButtom } from "../_styles"
import { useDispatch, useSelector } from "react-redux"
import { INPUT_TYPE } from "../../../utils/constants"
import ButtonCustom from "../../../components/ButtonCustom"
import { withFormik } from "formik"
import { loginSelector } from "../../../state/modules/login/_selectors"
import { useTranslation } from "react-i18next"
import { GLOBAL_STYLES } from "../../../styles/GLOBAL_STYLE"
import { type IFormListProps } from "../../../interfaces/IFormListProps"
import { type ILogin_Request } from "../../../interfaces/ILogin"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"
import { useGlobalContext } from "../../../context/GlobalContext"
import InputCustom from "../../../components/InputCustom"
import { postLoginForgotRequest } from "../../../state/modules/login/actions"
import FormSchema from "../_schemaUser"

const FormForgot = ({
  values,
  accept,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  isActiveModal = false,
  closeModal,
  touched
}: IFormListProps): ReactElement => {
  const dispatch = useDispatch()
  const [errorUser, seterrorUser] = React.useState("")
  const { t } = useTranslation()
  const login = useSelector(loginSelector)
  const { setLoadingContext, themeContext } = useGlobalContext()

  const handleRequest = (): void => {
    if (!values?.usuario || values?.usuario.lenght === 0)
      seterrorUser(t(TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER) ?? "")
    else if (values?.usuario.includes("@")) seterrorUser(t(TRANSLATION_INDEX_RESPONSE_CODE.NOT_ARROBA) ?? "")
    else {
      seterrorUser("")
      dispatch(postLoginForgotRequest({ usuario: values?.usuario }))
    }
  }

  React.useEffect(() => {
    setLoadingContext(login?.loading)
  }, [login])

  return (
    <ContainerFormForgot>
      <InputCustom
        label={t("login.enterUserName") ?? ""}
        name="usuario"
        type={INPUT_TYPE.TEXT}
        value={values?.usuario ?? ""}
        handleChange={handleChange}
        placeHolder={t("login.userName") ?? ""}
        errors={errorUser}
        touched={touched?.usuario}
        borderRadius="10px"
        width="90%"
        style={{ marginBottom: "5px" }}
        colorType="LIGHT"
      />

      <GroupButtom>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        <ButtonCustom
          width="80px"
          onClick={handleRequest}
          type="button"
          label={t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""}
          background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
          marginLeft
        />
      </GroupButtom>
    </ContainerFormForgot>
  )
}

export default withFormik<IFormListProps, ILogin_Request>({
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: FormSchema,
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) accept(values)
    setSubmitting(false)
  }
})(FormForgot)
