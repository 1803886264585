/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"

import { ACTIONS } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"

import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"
import { Container } from "./_styles"
import FormaddUpdate from "../../../QuotesTemplate/forms/addUpdate/FormaddUpdate"
import AlertModal from "../../../../components/AlertModal"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_QUOTER } from "../../../../config/translation"
import { useTranslation } from "react-i18next"

const Step3 = ({
  data,
  setDataFiltered,
  setFilters,
  stateStep,
  setStateStep,
  isUserPending = false,
  loading = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const [activeModal, setActiveModal] = React.useState(false)
  const { t } = useTranslation()
  const handleOpenModal = (): void => {
    setActiveModal(!activeModal)
  }

  React.useEffect(() => {
    const today = new Date().getTime()
    if (data.vigenciaDesde > today || data.vigenciaHasta < today) setActiveModal(true)
  }, [])

  return (
    <Container onSubmit={handleSubmit}>
      {activeModal && (
        <AlertModal
          maxWidth="50%"
          title={`${t(TRANSLATION_INDEX_GLOBAL.WARNING)}`}
          closeModal={handleOpenModal}
          activeCloseModal
        >
          <div style={{ padding: "30px 20px" }}>{t(TRANSLATION_INDEX_QUOTER.VALIDITY)}</div>
        </AlertModal>
      )}
      <FormaddUpdate
        isSend={true}
        data={data}
        setDataFiltered={setDataFiltered}
        setFilters={setFilters}
        stateStep={stateStep}
        setStateStep={setStateStep}
      />
    </Container>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) accept(values)
    setSubmitting(false)
  }
})(Step3)
