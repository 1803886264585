/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"
import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP
} from "../../config/translation"
import DataTable from "react-data-table-component"
import { ContainerIcons, ContainerTable } from "./_styles"
import AlertModal from "../../components/AlertModal"
import { type IUserBackoffice } from "../../interfaces/IUserBackoffice"
import FormUserBackoffice from "./forms/addUpdate/FormAddUpdate"
import SearchUserBackoffice from "./forms/search/index"
import { ACTIONS, FORMAT_DATE, OPTION_STATE, PERMISSION } from "../../utils/constants"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { getAuth } from "../../utils/functions"
import ButtonCustom from "../../components/ButtonCustom"
import { customStylesContext } from "../../styles/_styleDatatable"
import IconEdit from "../../components/Icons/IconEdit"
import { format } from "date-fns"
import { useDispatch, useSelector } from "react-redux"
import {
  addUserBackofficeRequest,
  deleteUserBackofficeRequest,
  getUserBackofficeRequest,
  postUserBackofficeRequest
} from "../../state/modules/userBackOffice/actions"
import { userBackofficeSelector } from "../../state/modules/userBackOffice/_selectors"
import { toast } from "react-toastify"
import { filterData } from "./functios"
import { NoData } from "../../components/NoData"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { getProfileRequest } from "../../state/modules/profile/actions"
import IconPlus from "../../components/Icons/IconPlus"
import { useMobile } from "../../hook/useMobile"
import ExportToExcel from "./ExportToExcel"
import { STORAGE } from "../../utils/localStorage"

const UserBackofficePage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { themeContext, setLoadingContext } = useGlobalContext()
  const dispatch = useDispatch()
  const userBackoffice = useSelector(userBackofficeSelector)
  const [dataFiltered, setDataFiltered] = React.useState(userBackoffice.data)
  const [filters, setFilters] = React.useState(null)

  const [openModal, setOpenModal] = React.useState(false)
  const [userSelected, setUserSelected] = React.useState<IUserBackoffice | null>(null)
  const [action, setAction] = React.useState<string>("")

  const handleOpenModal = (action = ""): void => {
    setAction(action)
    setOpenModal(!openModal)
  }

  const handleAdd = (): void => {
    setUserSelected(null)
    handleOpenModal(ACTIONS.ADD.VALUE)
  }

  const handleDispatch = (values: any): void => {
    switch (action) {
      case ACTIONS.DELETE.VALUE:
        dispatch(deleteUserBackofficeRequest(values))
        break
      case ACTIONS.ADD.VALUE:
        dispatch(addUserBackofficeRequest(values))
        break
      case ACTIONS.EDIT.VALUE:
        dispatch(postUserBackofficeRequest(values))
        if (getAuth()[1] === values.usuario) STORAGE.set(STORAGE.NOTIFICATION, values.notificacionesPorMail)
        break
      default:
        break
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.USER_NAME),
      selector: row => row.usuario.toUpperCase(),
      cell: (row: IUserBackoffice) => {
        return <ContainerLabelTwoRow label={row.usuario} />
      },
      width: isMobile ? "150px" : `calc(18% - 70px)`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME),
      selector: row => row.apellido.toUpperCase(),
      width: isMobile ? "100px" : "11%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME),
      selector: row => row.nombre.toUpperCase(),
      width: isMobile ? "150px" : "11%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL),
      selector: row => row.email,
      cell: (row: IUserBackoffice) => {
        return <ContainerLabelTwoRow label={row.email} />
      },
      width: isMobile ? "220px" : "calc(10% - 50px)%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.PROFILE),
      selector: row => row?.perfil?.descripcion,
      cell: (row: IUserBackoffice) => {
        return <div style={{ width: "100%", textAlign: "center" }}>{row?.perfil?.descripcion?.toUpperCase() ?? ""}</div>
      },
      width: isMobile ? "150px" : "12%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE),
      selector: row => row.estado.idEstado,
      width: isMobile ? "100px" : "10%",
      cell: (row: IUserBackoffice) => {
        return row.estado.idEstado === OPTION_STATE.ACTIVO ? (
          <div style={{ width: "100%", textAlign: "center" }}>{t(TRANSLATION_INDEX_GLOBAL.ACTIVE).toUpperCase()}</div>
        ) : (
          <div style={{ width: "100%", textAlign: "center" }}>{t(TRANSLATION_INDEX_GLOBAL.INACTIVE).toUpperCase()}</div>
        )
      },
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_UPDATE),
      selector: row => `${row.usuarioActualizacion} ${row?.fechaActualizacion.toString() ?? "-"} `,
      cell: (row: IUserBackoffice) => {
        const label = `${row?.usuarioActualizacion ?? ""} ${
          row?.fechaActualizacion ? format(new Date(row?.fechaActualizacion), FORMAT_DATE) : "-"
        }`
        if (screen.width < 1367)
          return (
            <span>
              <div>{row?.usuarioActualizacion ?? ""}</div>
              <div>{row?.fechaActualizacion ? format(new Date(row?.fechaActualizacion), FORMAT_DATE) : "-"}</div>
            </span>
          )
        else return <ContainerLabelTwoRow label={label === null ? "-" : label} />
      },
      width: isMobile ? "150px" : "15%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ACTIONS),
      cell: row => {
        return (
          <ContainerIcons>
            {getAuth(PERMISSION.USUARIO_BACKOFFICE_MODIFICAR)[0] && (
              <ButtonCustom
                label={t(TRANSLATION_INDEX_GLOBAL.EDIT) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.EDIT.VALUE)
                }}
                width="auto"
                background={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}
          </ContainerIcons>
        )
      },
      omit: !getAuth(PERMISSION.USUARIO_BACKOFFICE_MODIFICAR)[0],
      width: "110px"
    }
  ]

  React.useEffect(() => {
    if (userBackoffice.showToast) {
      if (userBackoffice.hasErrMessage) {
        toast.error(t(userBackoffice.errorCode))
      } else {
        toast.success(t(userBackoffice.toastMessage))
        handleOpenModal()
        dispatch(getUserBackofficeRequest())
      }
    }
  }, [userBackoffice.showToast])

  React.useEffect(() => {
    setLoadingContext(userBackoffice.loading)
    setDataFiltered(filterData(userBackoffice.data, filters))
  }, [userBackoffice])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getUserBackofficeRequest())
    dispatch(getProfileRequest())
  }, [])

  return (
    <ContainerPage
      title={t(TRANSLATION_INDEX_MENU.USERS_BACKOFFICE)}
      actions={[
        {
          label: t(TRANSLATION_INDEX_GLOBAL.ADD),
          width: "auto",
          icon: <IconPlus size={13} />,
          permission: PERMISSION.USUARIO_BACKOFFICE_CREAR,
          click: () => {
            handleAdd()
          }
        },
        {
          isButton: true,
          component: <ExportToExcel data={dataFiltered} />
        }
      ]}
    >
      <>
        {openModal && (
          <AlertModal
            maxWidth={isMobile ? "90%" : action === ACTIONS.DELETE.VALUE ? "450px" : "70%"}
            activeCloseModal
            closeModal={() => {
              handleOpenModal()
            }}
            title={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.USER_BACKOFFICE)} | ${t(
              TRANSLATION_INDEX_GLOBAL[action.toUpperCase()]
            )}`}
          >
            <FormUserBackoffice
              data={userSelected}
              action={action}
              closeModal={handleOpenModal}
              loading={userBackoffice.loading}
              accept={handleDispatch}
            />
          </AlertModal>
        )}
        <ContainerTable>
          <SearchUserBackoffice data={userBackoffice.data} setDataFiltered={setDataFiltered} setFilters={setFilters} />
          <DataTable
            data={dataFiltered}
            columns={columns}
            noDataComponent={<NoData />}
            dense
            pagination
            striped
            customStyles={customStylesContext(themeContext)}
            paginationComponentOptions={paginationComponentOptions}
            defaultSortFieldId={1}
          />
        </ContainerTable>
      </>
    </ContainerPage>
  )
}

export default UserBackofficePage
