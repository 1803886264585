import { permission } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"
import { sectionByCategoryV2 } from "../../../utils/constants"

const initialModuleState: ModuleType = {
  data: [],
  permissionByProfileSelected: [],
  loading: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  errorCode: ""
}

const reducer = permission.createReducer(
  {
    [types.PERMISSION_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.PERMISSION_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      const newData = payload
        .filter(item => sectionByCategoryV2.map(section => section.id).includes(item.categoria))
        .map(item => {
          return {
            ...item,
            group: sectionByCategoryV2.find(section => section.id === item.categoria)?.value
          }
        })
      return {
        ...state,
        data: newData,
        loading: false
      }
    },
    [types.PERMISSION_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.PERMISSION_GET_BY_PROFILE_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.PERMISSION_GET_BY_PROFILE_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      const newData = payload
        .filter(item => sectionByCategoryV2.map(section => section.id).includes(item.categoria))
        .map(item => {
          return {
            ...item,
            group: sectionByCategoryV2.find(section => section.id === item.categoria)?.value
          }
        })
      return {
        ...state,
        permissionByProfileSelected: newData,
        loading: false
      }
    },
    [types.PERMISSION_GET_BY_PROFILE_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.PERMISSION_ADD_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.PERMISSION_ADD_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_ADD,
        loading: false
      }
    },
    [types.PERMISSION_ADD_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.PERMISSION_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.PERMISSION_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_UPDATE
      }
    },
    [types.PERMISSION_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    }),
    [types.PERMISSION_DELETE_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.PERMISSION_DELETE_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        loading: false,
        showToast: true,
        toastMessage: TRANSLATION_INDEX_GLOBAL.SUCCESS_DELETE
      }
    },
    [types.PERMISSION_DELETE_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    })
  },
  initialModuleState
)

export default reducer
