import React from "react"

export const useMobile = (): boolean => {
  const [isMobile, setIsMobile] = React.useState(false)
  const handleResize = (): void => {
    setIsMobile(window.innerWidth < 901)
  }
  React.useLayoutEffect(() => {
    setIsMobile(window.innerWidth < 901)
    window.addEventListener("resize", handleResize)
  }, [])

  return isMobile
}
