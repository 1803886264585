import styled from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../interfaces/IStyle"

export const Container = styled.div<IStyle>`
  position: absolute;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]};
  box-shadow: ${({ themeContext }) => GLOBAL_STYLES.BOX_SHADOW.TYPE_5[themeContext]};
  height: 50px;
  color: ${({ themeContext }) => GLOBAL_STYLES.HEADER.COLOR[themeContext]};
  top: 0;
  z-index: 999;
`

export const ContainerLogout = styled.div<IStyle>`
  position: relative;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  color: ${({ themeContext }) => GLOBAL_STYLES.HEADER.COLOR[themeContext]};
  top: 0px;
  z-index: 999;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_3[themeContext]};
  :hover {
    background: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_8[themeContext]};
    color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  }
`

export const ContainerLanguage = styled.div<IStyle>`
  position: relative;
  width: calc(100% - 20px);
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  top: 0px;
  z-index: 999;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_3[themeContext]};
  :hover {
    background: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_8[themeContext]};
    color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  }
`

export const ContainerMenu = styled.div`
  position: relative;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Gretting = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  font-size: 17px;
  line-height: 1rem;
  cursor: pointer;
  font-weight: 600;
`

export const Icon = styled.span`
  font-size: 25px;
  margin-left: 10px;
`

export const Image = styled.img`
  position: relative;
  cursor: pointer;
  height: 35px;
`

export const Menu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 25px;
`

export const Label = styled.div<IStyle>`
  background-color: ${({ background }) => background};
  box-sizing: content-box;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  white-space: nowrap;
`

export const ContainerSuboption = styled.div<IStyle>`
  display: flex;
  justify-content: flex-start;
  height: 25px;
  width: calc(100% - 10px);
  padding-left: 30px;
  margin-left: -10px;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]};
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_3[themeContext]};
  :hover {
    background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_8[themeContext]};
    color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  }
`

export const Active = styled.div<IStyle>`
  position: relative;
  display: flex;
  height: 30px;
  justify-content: flex-start;
  align-items: center;
`

export const LabelLanguage = styled.div<IStyle>`
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  box-shadow: ${({ boxShadow }) => boxShadow};
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50%;
  width: 50%;
  white-space: nowrap;
  z-index: 20;
`

export const ContainerTheme = styled.div<IStyle>`
  position: relative;
  width: calc(100% - 20px);
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  top: 0px;
  z-index: 999;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_3[themeContext]};
  :hover {
    background: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_8[themeContext]};
    color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  }
`

export const Title = styled.div`
  width: 100%;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  top: 0;
`

export const ContainerMenuMobile = styled.div`
  cursor: pointer;
  margin-right: 25px;
`

export const DisableInput = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: grey;
  opacity: 0.2;
  z-index: 999999;
`
