import { profile } from "./_duck"

export const PROFILE_GET_REQUEST: string = profile.defineType("PROFILE_GET_REQUEST")
export const PROFILE_GET_SUCCESS: string = profile.defineType("PROFILE_GET_SUCCESS")
export const PROFILE_GET_FAILURE: string = profile.defineType("PROFILE_GET_FAILURE")

export const PROFILE_ADD_REQUEST: string = profile.defineType("PROFILE_ADD_REQUEST")
export const PROFILE_ADD_SUCCESS: string = profile.defineType("PROFILE_ADD_SUCCESS")
export const PROFILE_ADD_FAILURE: string = profile.defineType("PROFILE_ADD_FAILURE")

export const PROFILE_PUT_REQUEST: string = profile.defineType("PROFILE_PUT_REQUEST")
export const PROFILE_PUT_SUCCESS: string = profile.defineType("PROFILE_PUT_SUCCESS")
export const PROFILE_PUT_FAILURE: string = profile.defineType("PROFILE_PUT_FAILURE")

export const PROFILE_DELETE_REQUEST: string = profile.defineType("PROFILE_DELETE_REQUEST")
export const PROFILE_DELETE_SUCCESS: string = profile.defineType("PROFILE_DELETE_SUCCESS")
export const PROFILE_DELETE_FAILURE: string = profile.defineType("PROFILE_DELETE_FAILURE")
