import { TRANSLATION_INDEX_MENU, TRANSLATION_INDEX_PERMISSION } from "../../config/translation"
import { ROUTES } from "../../utils/constants"
import { type IMenu } from "./_types"

export const menu: IMenu[] = [
  {
    label: TRANSLATION_INDEX_MENU.COMMERCIAL,
    permission: [
      ROUTES.CUSTOMER_PROSPECT.permision,
      ROUTES.EXPENSES_ORIGIN.permision,
      ROUTES.EXPENSES_BA.permision,
      ROUTES.QUOTE_TEMPLATE.permision,
      ROUTES.SEND_QUOTE.permision,
      ROUTES.VIEW_SEND_QUOTE.permision,
      ROUTES.CUSTOMS.permision,
      ROUTES.EVENTS.permision,
      ROUTES.REMINDER.permision,
      ROUTES.REPORT_CUSTOMER_WITHOUT_CONTACT.permision,
      ROUTES.REPORT_EVENT_BY_COMMERCIAL.permision,
      ROUTES.REPORT_EVENT_BY_CUSTOMER.permision,
      ROUTES.COMMERCIAL_BULK_ASSOCIATION.permision
    ],
    route: "",
    subMenu: [
      {
        label: TRANSLATION_INDEX_MENU.PROSPECTS,
        permission: [ROUTES.CUSTOMER_PROSPECT.permision],
        route: ROUTES.CUSTOMER_PROSPECT.pathName
      },
      {
        label: TRANSLATION_INDEX_PERMISSION.ASIGNACION_MASIVA_COMERCIAL,
        permission: [ROUTES.COMMERCIAL_BULK_ASSOCIATION.permision],
        route: ROUTES.COMMERCIAL_BULK_ASSOCIATION.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.EXPENSES_ORIGIN,
        permission: [ROUTES.EXPENSES_ORIGIN.permision],
        route: ROUTES.EXPENSES_ORIGIN.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.EXPENSES_BA,
        permission: [ROUTES.EXPENSES_BA.permision],
        route: ROUTES.EXPENSES_BA.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.QQUOTES_TEMPLATE,
        permission: [ROUTES.QUOTE_TEMPLATE.permision],
        route: ROUTES.QUOTE_TEMPLATE.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.SEND_QUOTE,
        permission: [ROUTES.SEND_QUOTE.permision],
        route: ROUTES.SEND_QUOTE.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.VIEW_SEND_QUOTE,
        permission: [ROUTES.VIEW_SEND_QUOTE.permision],
        route: ROUTES.VIEW_SEND_QUOTE.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.CUSTOMS,
        permission: [ROUTES.CUSTOMS.permision],
        route: ROUTES.CUSTOMS.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.EVENTS,
        permission: [ROUTES.EVENTS.permision],
        route: ROUTES.EVENTS.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.REMINDERS,
        permission: [ROUTES.REMINDER.permision],
        route: ROUTES.REMINDER.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.REPORT,
        permission: [
          ROUTES.REPORT_CUSTOMER_WITHOUT_CONTACT.permision,
          ROUTES.REPORT_EVENT_BY_COMMERCIAL.permision,
          ROUTES.REPORT_EVENT_BY_CUSTOMER.permision
        ],
        route: "",
        submenu: [
          {
            label: TRANSLATION_INDEX_PERMISSION.REPORTE_EVENTOS_X_CLIENTE,
            permission: [ROUTES.REPORT_EVENT_BY_CUSTOMER.permision],
            route: ROUTES.REPORT_EVENT_BY_CUSTOMER.pathName
          },
          {
            label: TRANSLATION_INDEX_PERMISSION.REPORTE_EVENTOS_X_COMERCIAL,
            permission: [ROUTES.REPORT_EVENT_BY_COMMERCIAL.permision],
            route: ROUTES.REPORT_EVENT_BY_COMMERCIAL.pathName
          },
          {
            label: TRANSLATION_INDEX_PERMISSION.REPORTE_CLIENTES_SIN_CONTACTAR,
            permission: [ROUTES.REPORT_CUSTOMER_WITHOUT_CONTACT.permision],
            route: ROUTES.REPORT_CUSTOMER_WITHOUT_CONTACT.pathName
          },
          {
            label: TRANSLATION_INDEX_PERMISSION.REPORTE_RANKING_PAISES,
            permission: [ROUTES.REPORT_COUNTRY_RANKING.permision],
            route: ROUTES.REPORT_COUNTRY_RANKING.pathName
          },
          {
            label: TRANSLATION_INDEX_PERMISSION.REPORTE_RANKING_TRANSPORTISTAS,
            permission: [ROUTES.REPORT_CARRIER_RANKING.permision],
            route: ROUTES.REPORT_CARRIER_RANKING.pathName
          },
          {
            label: TRANSLATION_INDEX_PERMISSION.REPORTE_RANKING_CLIENTES,
            permission: [ROUTES.REPORT_CUSTOMER_RANKING.permision],
            route: ROUTES.REPORT_CUSTOMER_RANKING.pathName
          },
          {
            label: TRANSLATION_INDEX_PERMISSION.REPORTE_RANKING_CLIENTES_DESPACHANTES,
            permission: [ROUTES.REPORT_BROKER_RANKING.permision],
            route: ROUTES.REPORT_BROKER_RANKING.pathName
          }
        ]
      }
    ]
  },
  {
    label: TRANSLATION_INDEX_MENU.OPERATION,
    permission: [
      ROUTES.AUTHORIZE_INFORMATION_TO_CLIENTS.permision,
      ROUTES.QUOTER.permision,
      ROUTES.REFERENCE_CONSULT.permision,
      ROUTES.RATINGS.permision
    ],
    route: "",
    subMenu: [
      {
        label: TRANSLATION_INDEX_MENU.AUTHORIZE_INFORMATION,
        permission: [ROUTES.AUTHORIZE_INFORMATION_TO_CLIENTS.permision],
        route: ROUTES.AUTHORIZE_INFORMATION_TO_CLIENTS.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.QUOTE_GENERATION,
        permission: [ROUTES.QUOTER.permision],
        route: ROUTES.QUOTER.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.CONSULT_REFERENCE,
        permission: [ROUTES.REFERENCE_CONSULT.permision],
        route: ROUTES.REFERENCE_CONSULT.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.RATINGS,
        permission: [ROUTES.RATINGS.permision],
        route: ROUTES.RATINGS.pathName
      }
    ]
  },
  {
    label: TRANSLATION_INDEX_MENU.SETTING,
    permission: [
      ROUTES.USERS_BACKOFFICE.permision,
      ROUTES.USERS_APP_CLIENTS.permision,
      ROUTES.PROFILES.permision,
      ROUTES.PERMISSION_BY_PROFILES.permision,
      ROUTES.PREFERENCES.permision
    ],
    route: "",
    subMenu: [
      {
        label: TRANSLATION_INDEX_MENU.USERS_BACKOFFICE,
        permission: [ROUTES.USERS_BACKOFFICE.permision],
        route: ROUTES.USERS_BACKOFFICE.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.USERS_APP_CLIENTS,
        permission: [ROUTES.USERS_APP_CLIENTS.permision],
        route: ROUTES.USERS_APP_CLIENTS.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.PROFILES,
        permission: [ROUTES.PROFILES.permision],
        route: ROUTES.PROFILES.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.PERMISSION_BY_PROFILES,
        permission: [ROUTES.PERMISSION_BY_PROFILES.permision],
        route: ROUTES.PERMISSION_BY_PROFILES.pathName
      },
      {
        label: TRANSLATION_INDEX_MENU.PREFERENCES,
        permission: [ROUTES.PREFERENCES.permision],
        route: ROUTES.PREFERENCES.pathName
      }
    ]
  }
]
