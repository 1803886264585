/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { useEffect, type ReactElement } from "react"
import { Container, Gretting } from "./_styles"
import { useGlobalContext } from "../../context/GlobalContext"
import { useDispatch, useSelector } from "react-redux"
import { getRemendersRequest } from "../../state/modules/remenders/actions"
import { remendersSelector } from "../../state/modules/remenders/_selectors"
import { getAuth } from "../../utils/functions"
import { PERMISSION } from "../../utils/constants"
import TableRemender from "./componets/TableRemender"

const HomePage = (): ReactElement => {
  const { t } = useTranslation()
  const { themeContext, setLoadingContext } = useGlobalContext()
  const dispatch = useDispatch()
  const remenders = useSelector(remendersSelector)
  useEffect(() => {
    setLoadingContext(remenders.loading)
  }, [remenders])

  React.useEffect(() => {
    const date = new Date()
    const toodayData = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`).getTime()
    setLoadingContext(true)
    dispatch(getRemendersRequest(`?fechaDesde=${toodayData}&fechaHasta=${toodayData + 86400000 - 1}`))
    window.scroll({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  return (
    <Container>
      {remenders?.data != null && remenders?.data?.length > 0 && getAuth(PERMISSION.RECORDATORIOS_CONSULTAR)[0] ? (
        <TableRemender />
      ) : (
        <Gretting themeContext={themeContext}>¡{t("gretting.welcome")}!</Gretting>
      )}
    </Container>
  )
}

export default HomePage
