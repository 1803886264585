/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { ContainerForm, Logo, ErrorLogin, Label } from "../_styles"
import { useDispatch, useSelector } from "react-redux"
import { INPUT_TYPE } from "../../../utils/constants"
import ButtonCustom from "../../../components/ButtonCustom"
import { withFormik } from "formik"
import FormSchema from "../_schema"
import { loginSelector } from "../../../state/modules/login/_selectors"
import { useTranslation } from "react-i18next"
import logo from "../../../assets/logo.png"
import { GLOBAL_STYLES } from "../../../styles/GLOBAL_STYLE"
import { type IFormListProps } from "../../../interfaces/IFormListProps"
import { type ILogin_Request } from "../../../interfaces/ILogin"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"
import { useGlobalContext } from "../../../context/GlobalContext"
import InputCustom from "../../../components/InputCustom"
import AlertModal from "../../../components/AlertModal"
import FormForgot from "./FormForgot"
import { toast } from "react-toastify"
import { cleanError } from "../../../state/modules/login/actions"
import { isStringEmpty } from "../../../utils/functions"

const LoginPage = ({
  values,
  accept,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  touched
}: IFormListProps): ReactElement => {
  const [openModal, setOpenModal] = React.useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const login = useSelector(loginSelector)
  const { loadingContext, setLoadingContext } = useGlobalContext()
  const handleOpenModal = (): void => {
    if (openModal && !isStringEmpty(login?.errorCode ?? "")) dispatch(cleanError())
    setOpenModal(!openModal)
  }
  React.useEffect(() => {
    setLoadingContext(login?.loading)
  }, [login])

  React.useEffect(() => {
    if (login?.showToast) {
      if (login.hasErrMessage) {
        toast.error(t(TRANSLATION_INDEX_RESPONSE_CODE[login.errorCode ?? "GENERIC_ERROR"]))
      } else {
        toast.success(t(login.toastMessage))
        handleOpenModal()
      }
      dispatch(cleanError())
    }
  }, [login?.showToast])

  return (
    <ContainerForm onSubmit={handleSubmit}>
      {openModal && (
        <AlertModal
          activeCloseModal
          maxWidth="450px"
          closeModal={() => {
            handleOpenModal()
          }}
          title={t(`login.recoverPassword`)}
          background="#fff"
        >
          <FormForgot closeModal={handleOpenModal} loading={loadingContext} isActiveModal={openModal} />
        </AlertModal>
      )}
      <Logo src={logo} alt="Logo CARGO SA" />
      <InputCustom
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        name="usuario"
        type={INPUT_TYPE.TEXT}
        value={values?.usuario ?? ""}
        handleChange={handleChange}
        placeHolder={t("login.userName") ?? ""}
        errors={errors?.usuario}
        touched={touched?.usuario}
        borderRadius="10px"
        width="90%"
        style={{ marginBottom: "5px" }}
      />

      <InputCustom
        name="clave"
        type={INPUT_TYPE.PASSWORD}
        value={values?.clave ?? ""}
        handleChange={handleChange}
        placeHolder={t("login.password") ?? ""}
        errors={errors?.clave}
        touched={touched?.usuario}
        width="90%"
        borderRadius="10px"
        iconRight
      />
      <Label
        onClick={() => {
          handleOpenModal()
        }}
      >
        {t("login.forgotYourPassword")}
      </Label>
      <ButtonCustom
        style={{ marginTop: "15px" }}
        type="submit"
        label={t(TRANSLATION_INDEX_GLOBAL.LOGIN) ?? ""}
        height="40px"
        background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_LOGIN.LIGHT}
        backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER_LOGIN.LIGHT}
        width="90%"
      />

      {(login?.hasErrMessage ?? false) && !openModal && (
        <ErrorLogin>{t(TRANSLATION_INDEX_RESPONSE_CODE[login?.errorCode ?? "GENERIC_ERROR"])}</ErrorLogin>
      )}
    </ContainerForm>
  )
}

export default withFormik<IFormListProps, ILogin_Request>({
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: FormSchema,
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) accept(values)
    setSubmitting(false)
  }
})(LoginPage)
