import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getEvent = async (): Promise<AxiosPromise<any>> =>
  // await new Promise<any>((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({ data: mockListEvent })
  //   }, 2000)
  // })
  await httpModule.get(`/api/backoffice/v1/eventos`)

export const addEvent = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/eventos`, request)

export const putEvent = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/eventos`, request)

export const deleteEvent = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/eventos/${request}`)

export const getTypeEvent = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/eventos/tipos`)

export const getContactByCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/clientes/${request}/contactos`)
