import styled from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../interfaces/IStyle"

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center !important;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
`

export const ContainerTable = styled.div<IStyle>`
  width: 100%;
  opacity: ${({ opacity }) => opacity};
  word-wrap: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: opacity 3s;
  margin-top: -2px;
  margin-left: 2px;
  z-index: 10;
  padding: 20px 0;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`
