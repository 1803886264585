import React from "react"
import { GlobalContext } from "./GlobalContext"
import { STORAGE } from "../utils/localStorage"
import { isStringEmpty } from "../utils/functions"

// eslint-disable-next-line react/prop-types
export const ContextProvider = ({ children }): React.ReactElement => {
  const [loadingContext, setLoadingContext] = React.useState<boolean>(false)
  const [openAllCollansePermission, setOpenAllCollansePermission] = React.useState<boolean>(false)
  const [openMenuMobile, setOpenMenuMobile] = React.useState(false)
  const [activeModal, setActiveModal] = React.useState(false)
  const theme = STORAGE.get(STORAGE.THEME)
  if (isStringEmpty(theme)) STORAGE.set(STORAGE.THEME, "LIGHT")
  const [themeContext, setThemeContext] = React.useState<string>(isStringEmpty(theme) ? "LIGHT" : theme)
  return (
    <GlobalContext.Provider
      value={{
        loadingContext,
        setLoadingContext,
        openAllCollansePermission,
        setOpenAllCollansePermission,
        openMenuMobile,
        setOpenMenuMobile,
        themeContext,
        setThemeContext,
        activeModal,
        setActiveModal
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
