import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getUserAppClientFailure,
  getUserAppClientSuccess,
  patchUserAppClientSuccess,
  patchUserAppClientFailure,
  deleteUserAppClientSuccess,
  deleteUserAppClientFailure,
  postUserAppClientSuccess,
  postUserAppClientFailure
} from "../actions"

import { patchUserAppClient, deleteUserAppClient, getUserAppClient, postUserAppClient } from "../services"

function* getUsetAppClientWorker(): SagaIterator {
  try {
    const { data } = yield call(getUserAppClient)
    yield put(getUserAppClientSuccess(data))
  } catch (error) {
    yield put(getUserAppClientFailure(error))
  }
}

function* patchUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(patchUserAppClient, request.payload)
    yield put(patchUserAppClientSuccess(data))
  } catch (error) {
    yield put(patchUserAppClientFailure(error))
  }
}

function* postUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postUserAppClient, request.payload)
    yield put(postUserAppClientSuccess(data))
  } catch (error) {
    yield put(postUserAppClientFailure(error))
  }
}

function* deleteUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteUserAppClient, request.payload)
    yield put(deleteUserAppClientSuccess(data))
  } catch (error) {
    yield put(deleteUserAppClientFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.USER_APP_CLIENT_GET_REQUEST, getUsetAppClientWorker)
  yield takeLatest(types.USER_APP_CLIENT_PATCH_REQUEST, patchUserClientWorker)
  yield takeLatest(types.USER_APP_CLIENT_POST_REQUEST, postUserClientWorker)
  yield takeLatest(types.USER_APP_CLIENT_DELETE_REQUEST, deleteUserClientWorker)
}

export const sagas = [listWatcher]
