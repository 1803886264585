import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getPermission = async (): Promise<AxiosPromise<any>> => await httpModule.get(`/api/backoffice/v1/permisos`)

export const getPermissionByProfile = async (id): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/permisos/${id}`)

export const addPermission = async ({ request }): Promise<AxiosPromise<any>> =>
  await new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve("")
    }, 2000)
  })

export const postPermission = async ({ perfil, permisos }): Promise<AxiosPromise<any>> => {
  return await httpModule.put(`/api/backoffice/v1/permisos`, { perfil, permisos })
}

export const deletePermission = async ({ request }): Promise<AxiosPromise<any>> =>
  await new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve("")
    }, 2000)
  })
