// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getReportCommercialFailure,
  getReportCommercialSuccess,
  getReportCustomerFailure,
  getReportCustomerSuccess,
  getReportRankingBrokerFailure,
  getReportRankingBrokerSuccess,
  getReportRankingCarrierFailure,
  getReportRankingCarrierSuccess,
  getReportRankingCountryFailure,
  getReportRankingCountrySuccess,
  getReportRankingCustomerFailure,
  getReportRankingCustomerSuccess,
  getReportUncontactCustomerFailure,
  getReportUncontactCustomerSuccess
} from "../actions"

import {
  postReportCommercial,
  postReportCustomer,
  postReportRankingBroker,
  postReportRankingCarrier,
  postReportRankingCountry,
  postReportRankingCustomer,
  postReportUncontactCustomer
} from "../services"

function* getReportCommercialWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportCommercial, request.payload)
    yield put(getReportCommercialSuccess(data))
  } catch (error) {
    yield put(getReportCommercialFailure(error))
  }
}

function* getReportCustomerWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportCustomer, request.payload)
    yield put(getReportCustomerSuccess(data))
  } catch (error) {
    yield put(getReportCustomerFailure(error))
  }
}

function* getReportRankingCarrierWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportRankingCarrier, request.payload)
    yield put(getReportRankingCarrierSuccess(data))
  } catch (error) {
    yield put(getReportRankingCarrierFailure(error))
  }
}

function* getReportRankingClientWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportRankingCountry, request.payload)
    yield put(getReportRankingCountrySuccess(data))
  } catch (error) {
    yield put(getReportRankingCountryFailure(error))
  }
}

function* getReportRankingCustomerWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportRankingCustomer, request.payload)
    yield put(getReportRankingCustomerSuccess(data))
  } catch (error) {
    yield put(getReportRankingCustomerFailure(error))
  }
}

function* getReportRankingBrokerWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportRankingBroker, request.payload)
    yield put(getReportRankingBrokerSuccess(data))
  } catch (error) {
    yield put(getReportRankingBrokerFailure(error))
  }
}

function* getReportUncontactCustomerWorker(request): SagaIterator {
  try {
    const { data } = yield call(postReportUncontactCustomer, request.payload)
    yield put(getReportUncontactCustomerSuccess(data))
  } catch (error) {
    yield put(getReportUncontactCustomerFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.REPORT_COMMERCIAL_GET_REQUEST, getReportCommercialWorker)
  yield takeLatest(types.REPORT_CUSTOMER_GET_REQUEST, getReportCustomerWorker)
  yield takeLatest(types.REPORT_RANKING_CARRIER_GET_REQUEST, getReportRankingCarrierWorker)
  yield takeLatest(types.REPORT_RANKING_COUNTRY_GET_REQUEST, getReportRankingClientWorker)
  yield takeLatest(types.REPORT_RANKING_CUSTOMER_GET_REQUEST, getReportRankingCustomerWorker)
  yield takeLatest(types.REPORT_RANKING_BROKER_GET_REQUEST, getReportRankingBrokerWorker)
  yield takeLatest(types.REPORT_UNCONTACT_CUSTOMER_GET_REQUEST, getReportUncontactCustomerWorker)
}

export const sagas = [listWatcher]
