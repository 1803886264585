/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { TRANSLATION_INDEX_AUTHORIZE_INFORMACION, TRANSLATION_INDEX_GLOBAL } from "../../../../config/translation"

import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerInputs, ContainerSerch } from "./_styles"
import InputCustom from "../../../../components/InputCustom"
import ButtonCustom from "../../../../components/ButtonCustom"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { InfoCustom } from "../../../../components/InfoCustom"
import { type IAuthorizeInformation } from "../../../../interfaces/IAuthorizeInformation"
import { useMobile } from "../../../../hook/useMobile"
import { useGlobalContext } from "../../../../context/GlobalContext"

const Search = ({
  setDataFiltered = () => {},
  data,
  values,
  handleChange,
  handleReset,
  setClearFilter,
  clearFilter
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { themeContext } = useGlobalContext()
  const handleClearFilter = (): void => {
    if (handleReset != null) {
      handleReset()
      setDataFiltered(data)
    }
  }

  const handleSearch = (): void => {
    let newList: IAuthorizeInformation[] = []
    if (values?.search && values?.search.length > 0 && values?.estado && values?.clienteAdmin) {
      newList = data?.filter(
        item =>
          values?.search &&
          values?.search.length > 0 &&
          (item.email?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.nombre?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.puesto?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.apellido?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.numeroCelular?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.organizaciones
              .map((org: { descripcion: string }) => org.descripcion?.toLowerCase())
              .some((org: string | string[]) => org.includes(values?.search?.toLowerCase()))) &&
          item.estado?.idEstado === values?.estado?.value &&
          values?.clienteAdmin &&
          item.clienteAdmin === values?.clienteAdmin?.value
      )
      setDataFiltered(newList)
    } else if (values?.search && values?.search?.length > 0 && values?.estado) {
      newList = data?.filter(
        item =>
          values?.search &&
          values?.search.length > 0 &&
          (item.email?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.nombre?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.puesto?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.apellido?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.numeroCelular?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.organizaciones
              .map((org: { descripcion: string }) => org.descripcion?.toLowerCase())
              .some((org: string | string[]) => org.includes(values?.search?.toLowerCase()))) &&
          item.estado.idEstado === values?.estado?.value
      )
      setDataFiltered(newList)
    } else if (values?.search && values?.search.length > 0 && values?.clienteAdmin) {
      newList = data?.filter(
        item =>
          values?.search &&
          values?.search.length > 0 &&
          (item.email?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.nombre?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.puesto?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.apellido?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.numeroCelular?.toLowerCase().includes(values.search?.toLowerCase()) ||
            item.organizaciones
              .map((org: { descripcion: string }) => org.descripcion?.toLowerCase())
              .some((org: string | string[]) => org.includes(values?.search?.toLowerCase()))) &&
          values?.clienteAdmin &&
          item.clienteAdmin === values?.clienteAdmin?.value
      )
      setDataFiltered(newList)
    } else if (values?.estado && values?.clienteAdmin) {
      newList = data?.filter(
        item =>
          item.estado.idEstado === values?.estado?.value &&
          values?.clienteAdmin &&
          item.clienteAdmin === values?.clienteAdmin?.value
      )
      setDataFiltered(newList)
    } else if (values?.search && values?.search.length > 0) {
      newList = data?.filter(
        item =>
          item?.descripcionOrganizacion.toLowerCase().includes(values.search?.toLowerCase()) ||
          item.idEmbarque?.toLowerCase().includes(values.search?.toLowerCase()) ||
          item.tipoAutorizacion?.toLowerCase().includes(values.search?.toLowerCase()) ||
          item.usuarioResolucion?.toLowerCase().includes(values.search?.toLowerCase())
      )
      setDataFiltered(newList)
    } else if (values?.clienteAdmin) {
      newList = data?.filter(item => values?.clienteAdmin && item.clienteAdmin === values?.clienteAdmin?.value)
      setDataFiltered(newList)
    } else if (values?.estado) {
      newList = data?.filter(item => values?.estado && item.estado.idEstado === values?.estado?.value)
      setDataFiltered(newList)
    } else {
      setDataFiltered(data)
    }
  }

  React.useEffect(() => {
    handleSearch()
  }, [values])

  React.useEffect(() => {
    if (handleReset) handleReset()
    setDataFiltered(data)
    if (setClearFilter) setClearFilter(false)
  }, [clearFilter])

  return (
    <ContainerSerch>
      <ContainerInputs>
        <InputCustom
          value={values?.search ?? ""}
          handleChange={handleChange}
          name="search"
          iconLeft
          placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH) ?? ""}`}
          width={isMobile ? "100%" : "300px"}
          validError={false}
        />
        {!isMobile && (
          <InfoCustom
            info={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH)} ${t(
              TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SHIPMENT_ID
            )}, ${t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.ORGANIZATION)} ${t(
              TRANSLATION_INDEX_GLOBAL.AND
            )} ${t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.TYPE)}.`}
            color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}
          />
        )}
      </ContainerInputs>
      <ButtonCustom
        onClick={() => {
          handleClearFilter()
        }}
        label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
        backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
        background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
        width={isMobile ? "100%" : "100px"}
        marginLeft
      />
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(Search)
