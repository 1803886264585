/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"
import { Container, Title, ModalContainer, ContainerBlur, ContainerHeader, ModalBody, CloseIcon } from "./_styles"
import { type AlertModalProps } from "./_types"
import { ContainerAction } from "../ContainerPage/_styles"
import ButtonCustom from "../ButtonCustom"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useTranslation } from "react-i18next"
import { AiOutlineClose } from "react-icons/ai"
import { useGlobalContext } from "../../context/GlobalContext"
const AlertModal: React.FC<AlertModalProps> = ({
  title,
  closeModal,
  children,
  maxWidth = "1020px",
  minHeight = "auto",
  background = "",
  activeCloseModal = false,
  actions,
  level = 1
}): ReactElement => {
  const { t } = useTranslation()
  const levelIndex = parseInt(level.toString(), 10) + 999
  const { themeContext, setActiveModal } = useGlobalContext()
  React.useEffect(() => {
    setActiveModal(true)

    return () => {
      setActiveModal(false)
    }
  }, [])

  return (
    <Container themeContext={themeContext} data-testid="alert-modal" level={levelIndex}>
      <ContainerBlur onClick={closeModal}></ContainerBlur>
      <ModalContainer themeContext={themeContext} background={background} width={maxWidth} minHeight={minHeight}>
        <ContainerHeader themeContext={themeContext} background={background}>
          <Title themeContext={themeContext} background={background}>
            {title}
          </Title>

          {actions != null && actions.length > 0 && (
            <ContainerAction>
              {actions.map(action => {
                return (
                  <ButtonCustom
                    icon={action.icon}
                    key={action.label}
                    marginLeft
                    background={
                      action.background ? action.background : GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]
                    }
                    backgroundHover={
                      action.backgroundHover
                        ? action.backgroundHover
                        : GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]
                    }
                    width="auto"
                    label={t(action.label) ?? ""}
                    onClick={action.click}
                  />
                )
              })}
            </ContainerAction>
          )}
          {activeCloseModal && (
            <CloseIcon themeContext={themeContext} onClick={closeModal}>
              <AiOutlineClose size={18} />
            </CloseIcon>
          )}
        </ContainerHeader>
        <ModalBody>
          <div>{children}</div>
        </ModalBody>
      </ModalContainer>
    </Container>
  )
}

export default AlertModal
