import { type AxiosPromise } from "axios"

import httpModuleMaerk from "../../../../utils/httpMaerk"
import httpModuleQuoter from "../../../../utils/httpQuoter"

export const getCommodities = async (): Promise<AxiosPromise<any>> => await httpModuleMaerk.get(`/?brand=MAEU`)

export const postExport = async (request): Promise<AxiosPromise<any>> =>
  await httpModuleQuoter.post(`/quote/export2`, request)

export const postQuoter = async (request): Promise<AxiosPromise<any>> =>
  await httpModuleQuoter.post(`/quote/generate`, request)

export const getProccessInfo = async (): Promise<AxiosPromise<any>> => await httpModuleQuoter.get(`/quote/processinfo`)

export const getInProccess = async (): Promise<AxiosPromise<any>> => await httpModuleQuoter.get(`/quote/inprogress`)
