/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { Container, ContainerLoader, Content } from "./_styles"
import { type Props } from "./_types"
import { useSelector } from "react-redux"
import { quoterSelector } from "../../state/modules/Quoter/_selectors"
import girando from "../../assets/girando.gif"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import LoaderDots from "../LoaderDots"
import { t } from "i18next"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_QUOTER } from "../../config/translation"
import { useGlobalContext } from "../../context/GlobalContext"

const Loader: React.FC<Props> = ({ width, background = "rgba(0, 0, 0, 0.2)" }): ReactElement => {
  const quoter = useSelector(quoterSelector)
  const { themeContext } = useGlobalContext()
  return (
    <Container themeContext={themeContext} data-testid="loader" background={background}>
      {quoter?.loadingQuoter ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center",
            background: GLOBAL_STYLES.BACKGROUND.TYPE_2[themeContext],
            fontSize: "1.2rem",
            color: GLOBAL_STYLES.LABEL.TYPE_1[themeContext],
            padding: "15px",
            borderRadius: "5px"
          }}
        >
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <div>{t(TRANSLATION_INDEX_QUOTER.WAIT_PLEASE)}</div>

            <div style={{ margin: "15px 0", display: "flex", justifyContent: "center", flexDirection: "row" }}>
              <div>
                {t(TRANSLATION_INDEX_QUOTER.PROCESS_QUOTER)}{" "}
                {quoter?.proccessInfo?.quotesInProgress &&
                  `${quoter?.proccessInfo?.quotesInProgress}  ${t(TRANSLATION_INDEX_GLOBAL.OF)} ${
                    quoter?.proccessInfo?.totalQuotes
                  }`}
              </div>
              <LoaderDots />
            </div>
          </div>
          <img style={{ width: "200px" }} src={girando} alt="Logo CARGO SA" />
        </div>
      ) : (
        <Content themeContext={themeContext} width={width}>
          <ContainerLoader themeContext={themeContext} borderTop="transparent" time={1.2} width={99} color="#096344" />
          <ContainerLoader
            themeContext={themeContext}
            borderRight="transparent"
            time={0.8}
            width={80}
            color="#12A470"
          />
          <ContainerLoader
            themeContext={themeContext}
            borderBottom="transparent"
            time={0.5}
            width={50}
            color="#096344"
          />
        </Content>
      )}
    </Container>
  )
}

export default Loader
