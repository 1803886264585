import * as Yup from "yup"
import {
  schemeArrayRequired,
  schemeEmailRequired,
  schemeObject,
  schemeStringRequired,
  schemeStringRequiredWithoutSpaceInitial
} from "../../utils/constants"

export const deleteScheme = Yup.object().shape({})

export const addUpdateScheme = Yup.object().shape({
  apellido: schemeStringRequiredWithoutSpaceInitial(),
  nombre: schemeStringRequiredWithoutSpaceInitial(),
  dni: schemeStringRequired(),
  email: schemeEmailRequired(),
  numeroCelular: schemeStringRequired(),
  puesto: schemeStringRequired(),
  organizacionesFormik: schemeArrayRequired(),
  clienteAdminFormik: schemeObject(),
  estado: schemeObject()
})
