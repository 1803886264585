/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { type ReactElement } from "react"
import { INPUT_TYPE } from "../../utils/constants"
import { Container, Input, Label, ContainerIcon, ContainerInput, TextArea } from "./_styles"
import { type Props } from "./_types"
import { HiEyeSlash, HiEye } from "react-icons/hi2"
import { isStringEmpty } from "../../utils/functions"
import IconSearch from "../Icons/IconSearch"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useTranslation } from "react-i18next"
import { FormHelperText } from "@mui/material"
import { useGlobalContext } from "../../context/GlobalContext"
const InputCustom = ({
  name,
  label = "",
  value = "",
  handleChange,
  subLabel = "",
  type = INPUT_TYPE.TEXT,
  placeHolder = "",
  width = "100%",
  marginLeft = false,
  disabled = false,
  iconLeft = false,
  iconRight = false,
  min,
  max,
  errors = "",
  autoFocus = false,
  touched,
  onBlur,
  validError = true,
  isTextArea = false,
  style,
  height = "30px",
  borderRadius = "5px",
  colorType = "",
  handleKeyPressEnter
}: Props): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false)
  const inputRef = React.useRef(null)

  const handleKey = (name, e): void => {
    if (e.keyCode === 13) e.preventDefault()
    handleKeyPressEnter != null ? handleKeyPressEnter(name, e.keyCode) : {}
  }

  return (
    <Container themeContext={themeContext} width={width} marginLeft={marginLeft} data-testid="containerInput">
      {!isStringEmpty(label) && (
        <Label themeContext={themeContext} color={colorType}>
          <div>{label}</div>
          <div
            style={{
              fontSize: "0.75rem"
            }}
          >
            {subLabel}
          </div>
        </Label>
      )}
      <ContainerInput>
        {iconLeft && (
          <ContainerIcon
            themeContext={themeContext}
            iconLeft={iconLeft}
            borderRadius={borderRadius}
            background={iconLeft && GLOBAL_STYLES.BACKGROUND.TYPE_6[themeContext]}
          >
            <IconSearch color="#495057" />
          </ContainerIcon>
        )}
        {isTextArea ? (
          <TextArea
            themeContext={themeContext}
            value={value ?? ""}
            height={height}
            disabled={disabled}
            onChange={handleChange}
            name={name}
          />
        ) : (
          <>
            <Input
              onBlur={onBlur}
              themeContext={themeContext}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              ref={inputRef}
              onKeyDown={e => {
                handleKey(name, e)
              }}
              borderRadius={borderRadius}
              min={min}
              max={max}
              // onBlurCapture={() => {
              //   if (handleKeyPressEnter != null) handleKeyPressEnter(name, 13)
              // }}
              style={style}
              iconRight={iconRight}
              iconLeft={iconLeft}
              autoComplete="off"
              name={name}
              disabled={disabled}
              value={value ?? ""}
              onChange={handleChange}
              type={type === INPUT_TYPE.PASSWORD && passwordVisible ? INPUT_TYPE.TEXT : type}
              placeholder={placeHolder}
              data-testid="input"
              border={
                !isStringEmpty(errors)
                  ? GLOBAL_STYLES.BORDER.TYPE_4[themeContext]
                  : GLOBAL_STYLES.BORDER.TYPE_3[themeContext]
              }
            />
            {iconRight && (
              <ContainerIcon
                themeContext={themeContext}
                borderRadius={borderRadius}
                border={
                  !isStringEmpty(errors)
                    ? GLOBAL_STYLES.BORDER.TYPE_4[themeContext]
                    : GLOBAL_STYLES.BORDER.TYPE_3[themeContext]
                }
                iconRight={iconRight}
                onClick={() => {
                  setPasswordVisible(!passwordVisible)
                }}
              >
                {passwordVisible ? <HiEye size={20} /> : <HiEyeSlash size={20} />}
              </ContainerIcon>
            )}
          </>
        )}
      </ContainerInput>
      {validError && (
        <FormHelperText style={{ color: GLOBAL_STYLES.LABEL.TYPE_4[themeContext] }}>{t(errors)}</FormHelperText>
      )}
    </Container>
  )
}

export default InputCustom
