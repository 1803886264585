/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import {
  TRANSLATION_INDEX_AUTHORIZE_INFORMACION,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_PROFILE,
  TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP
} from "../../../../config/translation"

import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerInputs, ContainerSerch } from "./_styles"

import ButtonCustom from "../../../../components/ButtonCustom"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"
import OrganizationService from "../../../../services/OrganizationService"
import { useDispatch, useSelector } from "react-redux"
import { getReferenceRequest } from "../../../../state/modules/reference/actions"
import InputCustom from "../../../../components/InputCustom"
import { referenceSelector } from "../../../../state/modules/reference/_selectors"
import { useMobile } from "../../../../hook/useMobile"
import { useGlobalContext } from "../../../../context/GlobalContext"

const Search = ({
  setDataFiltered = () => {},
  data,
  values,
  errors,
  setFilters,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { themeContext } = useGlobalContext()
  const isMobile = useMobile()
  const reference = useSelector(referenceSelector)
  const promiseOptions = async (inputValue: string): Promise<any> => {
    return await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(OrganizationService(inputValue))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
  }
  const clearFilter = (): void => {
    if (handleReset != null) {
      handleReset()
    }
  }

  const handleSearch = (): void => {
    if (values?.search && values?.search.length > 0) {
      const list = reference.data.filter(
        item =>
          item.referencia.toLowerCase().includes(values?.search.toLowerCase()) ||
          item.shipmentId.toLowerCase().includes(values?.search.toLowerCase())
      )
      setDataFiltered(list)
    } else {
      setDataFiltered(reference.data)
    }
  }

  React.useEffect(() => {
    handleSearch()
  }, [values?.search])

  React.useEffect(() => {
    handleSearch()
  }, [])

  React.useEffect(() => {
    if (values?.organizacionesFormik !== null) dispatch(getReferenceRequest(values?.organizacionesFormik?.value))
    else if (handleReset != null) {
      if (setFieldValue) setFieldValue("organizacionesFormik", null)
      handleReset()
    }
  }, [values?.organizacionesFormik])

  return (
    <ContainerSerch>
      <ContainerInputs>
        {setFieldValue != null && (
          <SelectAsyncCustom
            listTop={false}
            width={isMobile ? "100%" : "400px"}
            value={values?.organizacionesFormik ?? null}
            handleChange={setFieldValue}
            name="organizacionesFormik"
            validError={false}
            placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS) ?? ""}
            promiseOptions={promiseOptions}
          />
        )}
        <InputCustom
          disabled={reference?.data?.length === 0}
          value={values?.search ?? ""}
          handleChange={handleChange}
          name="search"
          iconLeft
          placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(
            TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SHIPMENT_ID
          )} ${t(TRANSLATION_INDEX_GLOBAL.AND)} ${t(TRANSLATION_INDEX_PROFILE.COLUMNS.REFERENCE)}.`}
          width={isMobile ? "100%" : "350px"}
          validError={false}
          marginLeft
        />
      </ContainerInputs>
      <ButtonCustom
        onClick={() => {
          clearFilter()
        }}
        label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
        backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
        background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
        width={isMobile ? "100%" : "100px"}
        marginLeft
      />
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(Search)
