import * as Yup from "yup"
import { TRANSLATION_INDEX_GLOBAL } from "../../config/translation"
import { schemeObject } from "../../utils/constants"

export const deleteScheme = Yup.object().shape({})

export const addUpdateScheme = Yup.object().shape({
  fecha: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  tipoEvento: schemeObject(),
  cliente: schemeObject()
})
