import React, { type ReactElement } from "react"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { Container } from "./_styles"
import { type Props } from "./_types"
import { useNavigate } from "react-router-dom"
import { getAuth, isStringEmpty } from "../../utils/functions"
import { useGlobalContext } from "../../context/GlobalContext"
import { IoMdArrowDropright } from "react-icons/io"
import Item from "./Item"
// import { ContainerSubMenu } from "../MenuItem/_styles"
import { useTranslation } from "react-i18next"

const SubMenuItem: React.FC<Props> = ({ label, route, setHoverActiveMain, submenu, permission }): ReactElement => {
  const [hoverActive, setHoverActive] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const handleRoute = (): void => {
    if (route !== undefined && !isStringEmpty(route)) {
      setHoverActiveMain(false)
      navigate(route)
    }
  }
  if (!permission.some(item => getAuth()[2].includes(item))) return <></>
  return (
    <Container
      showPaddingRight={!(hoverActive && submenu != null && submenu?.length > 0)}
      themeContext={themeContext}
      data-testid="submenu-item"
      onMouseEnter={() => {
        setHoverActive(true)
      }}
      onMouseLeave={() => {
        setHoverActive(false)
      }}
      background={
        hoverActive ? GLOBAL_STYLES.BACKGROUND.TYPE_8[themeContext] : GLOBAL_STYLES.BACKGROUND.TYPE_7[themeContext]
      }
      color={GLOBAL_STYLES.LABEL.TYPE_2[themeContext]}
      onClick={() => {
        handleRoute()
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
        {label}
        {submenu != null && submenu?.length > 0 && (
          <IoMdArrowDropright fontSize={20} style={{ marginLeft: "2px", marginTop: "3px" }} />
        )}
        {hoverActive && submenu != null && submenu?.length > 0 && (
          <div
            style={{
              position: "relative",
              marginLeft: "20px"
            }}
          >
            <div
              style={{
                position: "absolute",
                background: "white",
                top: 0,
                boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 1)"
              }}
            >
              {submenu.map(value => {
                return (
                  <Item
                    key={value.label}
                    label={t(value.label)}
                    route={value.route}
                    permission={value.permission}
                    submenu={value.submenu}
                    setHoverActiveMain={setHoverActiveMain}
                  />
                )
              })}
            </div>
          </div>
          // </ContainerSubMenu>
        )}
      </div>
    </Container>
  )
}

export default SubMenuItem
