import { type IEvent } from "../../../../interfaces/IEvent"

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
export const filterDataEvent = (data, values): IEvent[] => {
  let newList: IEvent[] = data

  if (values?.assignedCommercial?.value)
    newList = newList?.filter(item =>
      item.nombreUsuario?.toLowerCase().includes(values.assignedCommercial?.value?.toLowerCase())
    )

  if (values?.eventType?.value)
    newList = newList?.filter(item => item?.tipoEvento?.idTipoEvento === values?.eventType?.value)

  if (values?.dateFrom) newList = newList?.filter(item => item.fecha >= new Date(values.dateFrom).getTime())

  if (values?.dateTo)
    newList = newList?.filter(item => item.fecha <= new Date(values.dateTo).getTime() + (24 * 60 * 60 * 1000 - 1))

  if (values?.clientes?.value) newList = newList?.filter(item => item?.cliente?.idCliente === values?.clientes?.value)

  return newList
}
