import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getCustoms = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/aduana/operaciones`, request)

export const postImport = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/aduana/importar`, request)

export const getCustomById = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/aduana/operaciones/${request}`)

export const getCustomsPlaces = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/aduana/lugares `)
