import styled from "styled-components"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../../../interfaces/IStyle"

export const Container = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 0.8rem;
`
export const Title = styled.div`
  font-weight: 700;
`

export const Label = styled.div`
  margin-left: 5px;
`

export const ContainerValue = styled.div<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  display: flex;
  justify-content: flex-start;
  margin: 4px 0;
`

export const ContainerColumn = styled.div``

export const ContainerRow = styled.div`
  width: 95%;
  display: flex;
  padding: 0px 10px;
  margin: -3px 0;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const Thead = styled.thead<IStyle>`
  border-left: 10px solid red;
  border-right: 1px solid red;
`

export const Tbody = styled.tbody<IStyle>`
  border-left: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  border-right: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
`

export const Td = styled.td<IStyle>`
  background: ${({ background }) => background};
  border-bottom: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  text-align: center;
  font-size: 0.8rem;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`
export const Tr = styled.tr``

export const Th = styled.th<IStyle>`
  max-width: 150px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-size: 0.8rem;
  text-align: center;
  border-bottom: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
`

export const ErrorMessage = styled.div<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_4[themeContext]};
  text-align: center;
  font-weight: 700;
  margin-top: 15px;
`
