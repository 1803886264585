import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  width: ${({ width }) => width};
  margin: 5px 0 0px 0;
  margin-left: ${({ marginLeft }) => (marginLeft ?? false ? "10px" : "0px")};
`

export const Label = styled.label<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-weight: 500;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  font-size: 0.8rem !important;
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const TextArea = styled.textarea<IStyle>`
  width: calc(100% - ${({ iconLeft, iconRight }) => (iconLeft === true || iconRight === true ? 40 : 0)}px);
  height: 30px;
  font-size: 0.8rem !important;
  padding: 5px 13px 5px 3px !important;
  margin: 0;
  border: ${({ border }) => border};
  color: #000 !important;
  font-weight: 400;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
  border-radius: ${({ iconLeft, iconRight }) =>
    iconRight === true ? "5px 0px 0px 5px" : iconLeft === true ? "0px 5px 5px 0px" : "5px"};
  :hover {
    box-shadow: ${({ disabled }) => (disabled === true ? "" : "0px 0px 0px 1px #bebebe")};
  }
  :focus {
    box-shadow: 0px 0px 1px 1px #bebebe;
    outline: none;
  }
  :disabled {
    background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_4[themeContext]};
  }
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const Input = styled.input<IStyle>`
  width: calc(100% - ${({ iconLeft, iconRight }) => (iconLeft === true || iconRight === true ? 40 : 0)}px);
  height: 30px;
  font-size: 0.8rem !important;
  padding: 5px 3px 5px 10px !important;
  margin: 0;
  border: ${({ border }) => border};
  color: #000 !important;
  font-weight: 400;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
  border-radius: ${({ iconLeft, iconRight, borderRadius }) =>
    iconRight === true
      ? `${borderRadius} 0px 0px ${borderRadius}`
      : iconLeft === true
      ? `0px ${borderRadius} ${borderRadius} 0px`
      : `${borderRadius}`};
  :hover {
    box-shadow: ${({ disabled }) => (disabled === true ? "" : "0px 0px 0px 1px #bebebe")};
  }
  :focus {
    box-shadow: 0px 0px 1px 1px #bebebe;
    outline: none;
  }
  :disabled {
    background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext]};
  }
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const ContainerIcon = styled.div<IStyle>`
  margin-left: 15px;
  width: ${({ width }) => (width != null ? width : "15px")};
  height: ${({ width }) => (width != null ? width : "15px")};
  background-image: ${({ background }) => background};
  padding: 8px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-image: ${({ backgroundHover }) => backgroundHover};
  }
`

export const ContainerInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ContainerError = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin-top: 0px;
  color: red;
`
