/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { type IRatingsGroup, type IRatings } from "../../interfaces/IRatings"
import { isStringEmpty } from "../../utils/functions"

export const filterData = (data, filters): any => {
  let newList: IRatings[] = []
  const datToNew = filters?.dateTo ? new Date(filters?.dateTo).getTime() + 86400000 : filters?.dateTo
  // new Date(filters?.dateTo).getTime() +
  // (new Date(filters?.dateFrom).getTime() === new Date(filters?.dateTo).getTime() ? 86400000 : 0)
  if (filters?.search != null && filters?.search.length > 0 && !isStringEmpty(filters?.puntaje) && filters?.dateTo) {
    newList = data?.filter(
      (item: IRatings) =>
        (item.email?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.idEmbarque?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.comentario?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.organizacion.descripcion?.toLowerCase().includes(filters?.search.toLowerCase())) &&
        item.puntaje === filters?.puntaje &&
        (item?.fecha ?? 0) > new Date(filters.dateFrom).getTime() &&
        datToNew > (item?.fecha ?? 0)
    )
    return newList
  } else if (filters?.search != null && filters?.search.length > 0 && !isStringEmpty(filters?.puntaje)) {
    newList = data?.filter(
      (item: IRatings) =>
        (item.email?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.comentario?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.idEmbarque?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.organizacion.descripcion?.toLowerCase().includes(filters?.search.toLowerCase())) &&
        item.puntaje === filters?.puntaje
    )
    return newList
  } else if (filters?.search != null && filters?.search.length > 0 != null && filters?.dateTo) {
    newList = data?.filter(
      (item: IRatings) =>
        (item.email?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.comentario?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.idEmbarque?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.organizacion.descripcion?.toLowerCase().includes(filters?.search.toLowerCase())) &&
        (item?.fecha ?? 0) > new Date(filters.dateFrom).getTime() &&
        (item?.fecha ?? 0) < datToNew
    )
    return newList
  } else if (!isStringEmpty(filters?.puntaje) && filters?.dateTo) {
    newList = data?.filter(
      (item: IRatings) =>
        item.puntaje === filters?.puntaje &&
        (item?.fecha ?? 0) > new Date(filters.dateFrom).getTime() &&
        (item?.fecha ?? 0) < datToNew
    )
    return newList
  } else if (filters?.search && filters?.search.length > 0) {
    newList = data?.filter(
      (item: IRatings) =>
        item.email?.toLowerCase().includes(filters?.search.toLowerCase()) ||
        item.comentario?.toLowerCase().includes(filters?.search.toLowerCase()) ||
        item.idEmbarque?.toLowerCase().includes(filters?.search.toLowerCase()) ||
        item.organizacion.descripcion?.toLowerCase().includes(filters?.search.toLowerCase())
    )

    return newList
  } else if (filters?.dateTo) {
    newList = data?.filter(
      (item: IRatings) => (item?.fecha ?? 0) > new Date(filters.dateFrom).getTime() && (item?.fecha ?? 0) < datToNew
    )
    return newList
  } else if (!isStringEmpty(filters?.puntaje)) {
    newList = data?.filter((item: IRatings) => item.puntaje === filters?.puntaje)
    return newList
  } else {
    return data
  }
}

export const filterDataGroup = (data, filters): any => {
  let newList: IRatingsGroup[] = []
  if (filters?.search && filters?.search.length > 0) {
    newList = data?.filter((item: IRatingsGroup) =>
      item.organizacion.descripcion?.toLowerCase().includes(filters?.search.toLowerCase())
    )

    return newList
  } else {
    return data
  }
}
