import styled, { keyframes } from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../interfaces/IStyle"

export const Container = styled.div<IStyle>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${({ level }) => level ?? 9999};
`

const openMenu = keyframes`
  from{
    transform: {width:0%};
  }
  to{
    transform: {width:100%};
  }
`

export const ContainerBlur = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${openMenu} 20s;
`

export const ContainerHeader = styled.div<IStyle>`
  padding: 0.2rem 0.9rem;
  width: calc(100% - 30px);
  color: ${({ background, themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[background === "" ? "LIGHT" : themeContext]};
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ background, themeContext }) =>
    GLOBAL_STYLES.BORDER.TYPE_2[background !== "" ? "LIGHT" : themeContext]};
`

export const Title = styled.label<IStyle>`
  color: ${({ background, themeContext }) =>
    background !== "" ? GLOBAL_STYLES.LABEL.TYPE_1.LIGHT : GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  width: 100%;
  padding: 1rem 0;
  display: flex;
  font-size: 1.3rem;
  align-items: center;
`

export const ModalContainer = styled.div<IStyle>`
  width: 95%;
  max-height: 95%;
  min-height: ${({ minHeight }) => minHeight};
  max-width: ${({ width }) => width};
  background-color: ${({ background, themeContext }) =>
    background !== "" ? background : GLOBAL_STYLES.BACKGROUND.TYPE_2[themeContext]};
  display: flex;
  flex-direction: column;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  border-radius: 10px;
  z-index: 999;
  ::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    margin-top: 5px;
    background-color: #ececec;
    border-radius: 270px;
  }
  @media (max-width: 1024px) {
    max-height: 85%;
    margin-top: 0%;
  }
`

export const ModalBody = styled.div``

export const CloseIcon = styled.div<IStyle>`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_5[themeContext]};
  :hover {
    color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  }
`
