import React from "react"
import { Container, LabelFloat } from "./_styles"
import { type Props } from "./_types"
import IconQuestion from "../Icons/IconQuestion"
import { useGlobalContext } from "../../context/GlobalContext"

export const InfoCustom: React.FC<Props> = ({ info, width = "300px", height = "", color = "#fff" }) => {
  const [showTooltip, setShowTooltip] = React.useState(false)
  const { themeContext } = useGlobalContext()

  return (
    <Container themeContext={themeContext} height={height}>
      <span
        onMouseEnter={() => {
          setShowTooltip(true)
        }}
        onMouseLeave={() => {
          setShowTooltip(false)
        }}
      >
        <IconQuestion color={color} />
      </span>
      <div style={{ position: "relative" }}>
        <LabelFloat themeContext={themeContext} width={width} isActive={showTooltip}>
          {info}
        </LabelFloat>
      </div>
    </Container>
  )
}
