/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_PROFILE,
  TRANSLATION_INDEX_QUOTER
} from "../../config/translation"
import DataTable from "react-data-table-component"

import { ACTIONS, FORMAT_DATE, FORMAT_DATE_ONLY, PERMISSION } from "../../utils/constants"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { getAuth } from "../../utils/functions"
import ButtonCustom from "../../components/ButtonCustom"
import { ContainerIcons, ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import SearchUserClientApp from "./forms/search/index"
import { toast } from "react-toastify"
import { quoteTemplateSelector } from "../../state/modules/quoteTemplate/_selectors"
import { type IQuoteTemplate } from "../../interfaces/IQuoteTemplate"
import {
  addQuoteTemplateRequest,
  deleteQuoteTemplateRequest,
  getQuoteTemplateRequest,
  putQuoteTemplateRequest
} from "../../state/modules/quoteTemplate/actions"
import IconEdit from "../../components/Icons/IconEdit"
import IconPlus from "../../components/Icons/IconPlus"
import FormaddUpdate from "./forms/addUpdate/FormaddUpdate"
import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { format } from "date-fns"
import { useMobile } from "../../hook/useMobile"
import IconDeleteCross from "../../components/Icons/IconDeleteCross"
import AlertModal from "../../components/AlertModal"
import { getExpensiveOriginRequest } from "../../state/modules/expensiveOrigin/actions"
import { getExpensiveBARequest } from "../../state/modules/expensiveBA/actions"
import IconTrash from "../../components/Icons/IconTrash"
import { getCountryRequest } from "../../state/modules/customer/actions"
import { getUserBackofficeImageRequest } from "../../state/modules/userBackOffice/actions"
import { ToolTipRow } from "../CustomerProspect/_styles"

const QuotesTemplatePage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const quoteTemplate = useSelector(quoteTemplateSelector)

  const [dataFiltered, setDataFiltered] = React.useState<IQuoteTemplate[]>(quoteTemplate?.data)
  const [openModal, setOpenModal] = React.useState(false)
  const { loadingContext, setLoadingContext, themeContext } = useGlobalContext()
  const [userSelected, setUserSelected] = React.useState<IQuoteTemplate | null>(null)
  const [action, setAction] = React.useState<string>("")
  const buttomsToAction =
    Number(getAuth(PERMISSION.COTIZACIONES_TEMPLATE_MODIFICAR)[0] ? 1 : 0) +
    Number(getAuth(PERMISSION.COTIZACIONES_TEMPLATE_ELIMINAR)[0] ? 1 : 0) +
    Number(getAuth(PERMISSION.COTIZACIONES_TEMPLATE_CREAR)[0] ? 1 : 0)
  const handleOpenModal = (action = ""): void => {
    setAction(action)
    setOpenModal(!openModal)
  }

  const handleAdd = (): void => {
    setUserSelected(null)
    handleOpenModal(ACTIONS.ADD.VALUE)
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.ID),
      selector: (row: IQuoteTemplate) => row?.idCotizacion,
      width: isMobile ? "60px" : "7%",
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.NAME),
      selector: (row: IQuoteTemplate) => row.nombre?.toLocaleUpperCase(),
      sortable: true,
      cell: (row: IQuoteTemplate) => {
        return <ToolTipRow>{row.nombre?.toLocaleUpperCase()}</ToolTipRow>
      },
      width: isMobile ? "200px" : `calc(38% - ${buttomsToAction * 100}px)`
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE),
      selector: (row: IQuoteTemplate) =>
        row?.modoTransporte === "AIR+SEA"
          ? `${t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase()}+${t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase()}`
          : t(TRANSLATION_INDEX_QUOTER[row.modoTransporte?.toLocaleUpperCase()])?.toLocaleUpperCase(),
      sortable: true,
      cell: (row: IQuoteTemplate) => {
        return (
          <ToolTipRow>
            {row?.modoTransporte === "AIR+SEA"
              ? `${t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase()}+${t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase()}`
              : t(TRANSLATION_INDEX_QUOTER[row.modoTransporte?.toLocaleUpperCase()])?.toLocaleUpperCase()}
          </ToolTipRow>
        )
      },
      width: isMobile ? "150px" : `calc(12%)`,
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.VALID_SINCE),
      selector: (row: IQuoteTemplate) => row?.vigenciaDesde?.toString() ?? "-",
      cell: (row: IQuoteTemplate) => {
        return <div>{row?.vigenciaDesde != null ? format(new Date(row?.vigenciaDesde), FORMAT_DATE_ONLY) : "-"}</div>
      },
      sortable: true,
      width: isMobile ? "100px" : `calc(8%)`,
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.VALID_UNTIL),
      selector: (row: IQuoteTemplate) => row?.vigenciaHasta?.toString() ?? "-",
      cell: (row: IQuoteTemplate) => {
        return <div>{row?.vigenciaHasta != null ? format(new Date(row?.vigenciaHasta), FORMAT_DATE_ONLY) : "-"}</div>
      },
      sortable: true,
      width: isMobile ? "100px" : `calc(8%)`,
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.USER_NAME),
      selector: (row: IQuoteTemplate) => row.usuarioActualizacion ?? "",
      sortable: true,
      width: isMobile ? "130px" : `calc(11%)`
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.LAST_UPDATE),
      selector: (row: IQuoteTemplate) => row?.fechaActualizacion?.toString() ?? "-",
      cell: (row: IQuoteTemplate) => {
        return (
          <div>{row?.fechaActualizacion != null ? format(new Date(row?.fechaActualizacion), FORMAT_DATE) : "-"}</div>
        )
      },
      sortable: true,
      width: isMobile ? "180px" : `calc(15%)`,
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.ACTIONS),
      cell: (row: IQuoteTemplate) => {
        return (
          <ContainerIcons>
            {getAuth(PERMISSION.COTIZACIONES_TEMPLATE_MODIFICAR)[0] && (
              <ButtonCustom
                label={t(TRANSLATION_INDEX_GLOBAL.EDIT) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.EDIT.VALUE)
                }}
                width="100px"
                background={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}
            {getAuth(PERMISSION.COTIZACIONES_TEMPLATE_CREAR)[0] && (
              <ButtonCustom
                label={t(TRANSLATION_INDEX_GLOBAL.DUPLICATE) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.ADD.VALUE)
                }}
                marginLeft
                width="100px"
                background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}

            {getAuth(PERMISSION.COTIZACIONES_TEMPLATE_ELIMINAR)[0] && (
              <ButtonCustom
                marginLeft
                label={t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.DELETE.VALUE)
                }}
                width="100px"
                background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                icon={<IconTrash />}
              />
            )}
          </ContainerIcons>
        )
      },
      center: true,
      omit:
        !getAuth(PERMISSION.COTIZACIONES_TEMPLATE_MODIFICAR)[0] &&
        !getAuth(PERMISSION.COTIZACIONES_TEMPLATE_CREAR)[0] &&
        !getAuth(PERMISSION.COTIZACIONES_TEMPLATE_ELIMINAR)[0],
      width: isMobile ? "310px" : `${buttomsToAction * 100}px`
    }
  ]

  const handleDispatch = (values: any): void => {
    switch (action) {
      case ACTIONS.DELETE.VALUE:
        dispatch(deleteQuoteTemplateRequest(values.idCotizacion))
        break
      case ACTIONS.ADD.VALUE:
        dispatch(addQuoteTemplateRequest(values))
        break
      case ACTIONS.EDIT.VALUE:
        dispatch(putQuoteTemplateRequest(values))
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    if (quoteTemplate?.showToast) {
      if (quoteTemplate?.hasErrMessage) {
        toast.error(t(quoteTemplate?.errorCode))
      } else {
        toast.success(t(quoteTemplate?.toastMessage))
        handleOpenModal()
        dispatch(getQuoteTemplateRequest())
      }
    }
  }, [quoteTemplate?.showToast])

  React.useEffect(() => {
    setLoadingContext(quoteTemplate?.loading)
    setDataFiltered(quoteTemplate?.data)
  }, [quoteTemplate])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getQuoteTemplateRequest())
    dispatch(getExpensiveOriginRequest())
    dispatch(getExpensiveBARequest())
    dispatch(getCountryRequest())
    dispatch(getUserBackofficeImageRequest())
  }, [])

  return (
    <>
      <ContainerPage
        title={
          !openModal
            ? `${t(TRANSLATION_INDEX_MENU.QQUOTES_TEMPLATE)}`
            : `${t(TRANSLATION_INDEX_QUOTER.QUOTE_DATA)} | ${t(TRANSLATION_INDEX_GLOBAL[action.toUpperCase()])}`
        }
        actions={
          !openModal
            ? [
                {
                  label: t(TRANSLATION_INDEX_GLOBAL.ADD),
                  icon: <IconPlus size={13} />,
                  permission: PERMISSION.COTIZACIONES_TEMPLATE_CREAR,
                  click: () => {
                    handleAdd()
                  }
                }
              ]
            : [
                {
                  label: t(TRANSLATION_INDEX_GLOBAL.CANCEL),
                  icon: <IconDeleteCross size={13} />,
                  background: GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext],
                  backgroundHover: GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext],
                  // permission: PERMISSION.COTIZACIONES_TEMPLATE_CREAR,
                  click: () => {
                    handleOpenModal()
                  }
                }
              ]
        }
      >
        <>
          {openModal && action === ACTIONS.DELETE.VALUE && (
            <AlertModal
              title={`${t(TRANSLATION_INDEX_QUOTER.QUOTE_DATA)} | ${t(TRANSLATION_INDEX_GLOBAL[action.toUpperCase()])}`}
              closeModal={handleOpenModal}
              activeCloseModal
              maxWidth="600px"
            >
              <FormaddUpdate
                data={userSelected}
                action={action}
                closeModal={handleOpenModal}
                loading={loadingContext}
                accept={handleDispatch}
              />
            </AlertModal>
          )}
          {openModal && action !== ACTIONS.DELETE.VALUE ? (
            <FormaddUpdate
              data={userSelected}
              action={action}
              closeModal={handleOpenModal}
              loading={loadingContext}
              accept={handleDispatch}
            />
          ) : (
            <ContainerTable themeContext={themeContext} opacity={1}>
              <SearchUserClientApp data={quoteTemplate?.data} setDataFiltered={setDataFiltered} />
              <DataTable
                data={dataFiltered ?? []}
                theme="solarized"
                noDataComponent={<NoData />}
                columns={columns}
                dense
                pagination
                paginationComponentOptions={paginationComponentOptions}
                striped
                customStyles={customStylesContext(themeContext)}
                defaultSortFieldId={1}
              />
            </ContainerTable>
          )}
        </>
      </ContainerPage>
    </>
  )
}

export default QuotesTemplatePage
