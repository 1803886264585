import { TRANSLATION_INDEX_GLOBAL } from "../../config/translation"
import { MENU_TYPE } from "../../utils/constants"

export const menuConfig = [
  {
    label: TRANSLATION_INDEX_GLOBAL.LANGUAGE,
    permission: ["*"],
    route: "",
    menuType: MENU_TYPE.LANGUAJE,
    subMenu: [
      {
        label: TRANSLATION_INDEX_GLOBAL.SPANISH,
        permission: ["*"],
        value: "es"
      },
      {
        label: TRANSLATION_INDEX_GLOBAL.ENGLISH,
        permission: ["*"],
        value: "en"
      }
    ]
  },
  {
    label: TRANSLATION_INDEX_GLOBAL.MODE,
    permission: ["*"],
    route: "",
    menuType: MENU_TYPE.THEME,
    subMenu: [
      {
        label: TRANSLATION_INDEX_GLOBAL.LIGHT,
        permission: ["*"],
        value: "LIGHT"
      },
      {
        label: TRANSLATION_INDEX_GLOBAL.DARK,
        permission: ["*"],
        value: "DARK"
      }
    ]
  },
  {
    label: "login.notification",
    permission: ["*"],
    route: "",
    menuType: MENU_TYPE.NOTIFICATION,
    subMenu: []
  },
  {
    label: "login.logout",
    permission: ["*"],
    route: "",
    menuType: MENU_TYPE.LOGOUT,
    subMenu: []
  }
]
