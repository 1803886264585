/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"
import InputCustom from "../../../../components/InputCustom"

import ButtonCustom from "../../../../components/ButtonCustom"
import { withFormik } from "formik"
import FormSchema from "../../_schema"

import { useTranslation } from "react-i18next"

import { type IUserBackoffice } from "../../../../interfaces/IUserBackoffice"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP } from "../../../../config/translation"
import { Container, Group, ContainerGroup, ContainerMessage, GroupButtom } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import SelectCustom from "../../../../components/SelectCustom"
import { ACTIONS, INPUT_TYPE, STATES } from "../../../../utils/constants"
import { t } from "i18next"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import OrganizationService from "../../../../services/OrganizationService"
import { useSelector } from "react-redux"
import { profileSelector } from "../../../../state/modules/profile/_selectors"
import { useGlobalContext } from "../../../../context/GlobalContext"
import IconDeleteCross from "../../../../components/Icons/IconDeleteCross"

const FormAddUpdate = ({
  data,
  action,
  loading = false,
  closeModal = () => {},
  accept = () => {},
  touched,
  setFieldValue,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  validateForm
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const profile = useSelector(profileSelector)
  const { themeContext } = useGlobalContext()
  const promiseOptions = async (inputValue: string): Promise<any> => {
    return await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(OrganizationService(inputValue))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
  }

  const convertToBase64 = (file): void => {
    const reader = new FileReader()
    reader.readAsDataURL(file[0])
    reader.onload = function () {
      const base64 = reader.result
      if (setFieldValue != null) setFieldValue("imagenFirmaContenido", base64)
    }
  }

  React.useEffect(() => {
    if (touched.usuario === true && Object.keys(errors ?? {}).length > 0) validateForm()
  }, [values])

  React.useEffect(() => {
    if (setFieldValue != null && values?.perfil?.label === "")
      setFieldValue("perfil", { label: profile.data[0].descripcion ?? "", value: profile.data[0].idPerfil ?? "1" })
  }, [])

  return (
    <Container onSubmit={handleSubmit}>
      {action === ACTIONS.DELETE.VALUE ? (
        <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
      ) : (
        <ContainerGroup themeContext={themeContext}>
          <Group>
            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.USER_NAME) ?? ""} (*)`}
              value={values?.usuario ?? ""}
              handleChange={handleChange}
              name="usuario"
              placeHolder={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME) ?? ""}_${
                t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME) ?? ""
              }`}
              errors={errors?.usuario}
              touched={touched?.usuario}
            />
            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL) ?? ""} (*)`}
              value={values?.email ?? ""}
              handleChange={handleChange}
              name="email"
              type={INPUT_TYPE.TEXT}
              placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL) ?? ""}
              marginLeft
              errors={errors?.email}
              touched={touched?.email}
            />
          </Group>
          <Group>
            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME) ?? ""} (*)`}
              value={values?.apellido ?? ""}
              handleChange={handleChange}
              name="apellido"
              placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME) ?? ""}
              errors={errors?.apellido}
              touched={touched?.apellido}
            />

            <InputCustom
              label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME) ?? ""} (*)`}
              value={values?.nombre ?? ""}
              handleChange={handleChange}
              name="nombre"
              placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME) ?? ""}
              marginLeft
              errors={errors?.nombre}
              touched={touched?.nombre}
            />
          </Group>
          <Group>
            {setFieldValue != null && (
              <SelectCustom
                label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.PROFILE) ?? ""} (*)`}
                value={values?.perfil ?? null}
                handleChange={setFieldValue}
                name="perfil"
                placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_A)} ${t(
                  TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.PROFILE
                )}`}
                options={profile.data.map(item => {
                  return {
                    value: item.idPerfil,
                    label: item.descripcion.toUpperCase()
                  }
                })}
                errors={errors?.perfil}
              />
            )}
            {setFieldValue != null && (
              <SelectCustom
                isDisabled={action === ACTIONS.ADD.VALUE}
                label={`${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE) ?? ""} (*)`}
                value={values?.estado ?? null}
                handleChange={setFieldValue}
                name="estado"
                placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SELECT_A)} ${t(
                  TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE
                )}`}
                marginLeft
                errors={errors?.estado}
                options={STATES.filter(item => (item?.value ?? 2) !== 2 && (item?.value ?? 2) !== null).map(item => {
                  return { ...item, label: t(item?.label) }
                })}
              />
            )}
          </Group>
          <Group>
            {setFieldValue != null && (
              <SelectAsyncCustom
                listTop={true}
                isMulti
                label={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS) ?? ""}
                value={values?.organizacionesFormik ?? null}
                handleChange={setFieldValue}
                name="organizacionesFormik"
                placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ORGANIZATIONS) ?? ""}
                promiseOptions={promiseOptions}
              />
            )}
          </Group>
          <Group>
            <div
              style={{
                height: "32px",
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
              }}
            >
              <input
                type="checkbox"
                name="notificacionesPorMail"
                checked={values?.notificacionesPorMail}
                value={values?.notificacionesPorMail}
                onChange={e => {
                  if (setFieldValue) setFieldValue("notificacionesPorMail", e.target.checked)
                }}
              />
              <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.RECEIVE_MAIL)}</span>
            </div>
          </Group>
          <Group>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <div style={{ marginBottom: "10px" }}>
                {t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.IMAGE_SIGNATURE)}
              </div>
              {values?.imagenFirmaContenido && (
                <div style={{ width: "100%", position: "relative" }}>
                  <div
                    role="button"
                    onKeyDown={() => {
                      if (setFieldValue) setFieldValue("imagenFirmaContenido", null)
                    }}
                    tabIndex={0}
                    onClick={() => {
                      if (setFieldValue) setFieldValue("imagenFirmaContenido", null)
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#7D7D7D",
                      color: "white",
                      position: "absolute",
                      top: "5px",
                      borderRadius: "50px",
                      right: "5px",
                      cursor: "pointer"
                    }}
                  >
                    <IconDeleteCross />
                  </div>
                  <img style={{ width: "100%" }} src={`${values?.imagenFirmaContenido}`} alt="test" />
                </div>
              )}
              <div style={{ marginBottom: "10px" }}>
                <input
                  type="file"
                  name="file-1"
                  id="file-1"
                  accept="image/jpeg"
                  style={{
                    width: "0.1px",
                    height: "0.1px",
                    opacity: "0",
                    position: "absolute",
                    zIndex: -1
                  }}
                  className="inputfile inputfile-1"
                  data-multiple-caption="{count} archivos seleccionados"
                  onChange={e => {
                    convertToBase64(e.target.files)
                  }}
                />
                <label
                  htmlFor="file-1"
                  style={{
                    borderRadius: "5px",
                    backgroundImage: GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext],
                    color: "white",
                    marginTop: "20px",
                    padding: "5px",
                    cursor: "pointer"
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "normal"
                    }}
                  >
                    {values?.imagenFirmaContenido
                      ? t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.CHANGE_IMAGE)
                      : t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.LOAD_IMAGE)}
                  </span>
                </label>
              </div>
            </div>
          </Group>
        </ContainerGroup>
      )}
      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        <ButtonCustom
          marginLeft
          width="80px"
          type="submit"
          label={
            action === ACTIONS.DELETE.VALUE
              ? t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""
              : t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""
          }
          background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
        />
      </GroupButtom>
    </Container>
  )
}

export default withFormik<IFormListProps, IUserBackoffice>({
  mapPropsToValues: ({ data }): any => ({
    usuario: data?.usuario,
    notificacionesPorMail: data?.notificacionesPorMail === 1,
    imagenFirmaContenido: data?.imagenFirmaContenido,
    usuarioAnterior: data?.usuario,
    nombre: data?.nombre,
    apellido: data?.apellido,
    email: data?.email,
    perfil:
      data?.perfil === undefined
        ? null
        : {
            label: data?.perfil.descripcion.toUpperCase() ?? "",
            value: data?.perfil.idPerfil ?? null
          },
    estado: {
      label: t(STATES.find(item => item.value === data?.estado?.idEstado)?.label ?? TRANSLATION_INDEX_GLOBAL.ACTIVE),
      value: STATES.find(item => item.value === data?.estado?.idEstado)?.value ?? STATES[1].value
    },
    organizacionesFormik: data?.organizaciones?.map(item => {
      return { value: item?.idOrganizacion, label: item.descripcion }
    })
  }),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: FormSchema,
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) {
      const request: IUserBackoffice = {
        estado: {
          idEstado: values?.estado?.value ?? 1,
          descripcion: values?.estado?.label?.toUpperCase() ?? "ACTIVO"
        },
        notificacionesPorMail: values?.notificacionesPorMail ? 1 : 0,
        usuario: values.usuario.replaceAll(" ", ""),
        usuarioAnterior: values.usuarioAnterior,
        nombre: values.nombre,
        apellido: values.apellido,
        imagenFirmaContenido: values?.imagenFirmaContenido,
        email: values.email,
        perfil: {
          idPerfil: values?.perfil?.value ?? 1,
          descripcion: values?.perfil?.label ?? "Administrador"
        },
        organizaciones:
          values?.organizacionesFormik != null
            ? values?.organizacionesFormik.map(item => {
                return { idOrganizacion: String(item.value), descripcion: item?.label }
              })
            : [],
        usuarioActualizacion: null,
        fechaActualizacion: null
      }
      accept(request)
    }
    setSubmitting(false)
  }
})(FormAddUpdate)
