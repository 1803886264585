/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerButtom, ContainerSerch, Group } from "./_styles"
import InputCustom from "../../../../components/InputCustom"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_QUOTER
} from "../../../../config/translation"
import { useMobile } from "../../../../hook/useMobile"
import SelectCustom from "../../../../components/SelectCustom"
import { useSelector } from "react-redux"
import { customerSelector } from "../../../../state/modules/customer/_selectors"
import { DatePickerCustom } from "../../../../components/DatePickerCustom/DatePickerCustom/Index"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"
import CustomerService from "../../../../services/CustomerService"

import ButtonCustom from "../../../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { useResize } from "../../../../hook/useResize"

const SearchUserClientApp = ({
  setDataFiltered = () => {},
  data,
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  setClearFilter,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const screenSize = useResize()
  const isMobile = useMobile()
  const customers = useSelector(customerSelector)
  const [fromDate, setFromDate] = React.useState<string | Date | null>(null)
  const [toDate, setToDate] = React.useState<string | Date | null>(null)

  const promiseOptions = async (inputValue: string): Promise<any> => {
    const response = await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(CustomerService(inputValue, values?.razonSocial))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
    return response
  }

  const handleResetFilters = (): void => {
    if (handleReset) handleReset()
    if (setClearFilter) setClearFilter(true)
  }

  const handleSearch = (): void => {
    let newList = data
    if (values?.name) newList = newList.filter(item => item.nombre.toLowerCase().includes(values.name.toLowerCase()))

    if (values?.modoTransporte?.value)
      newList = newList.filter(item => item.modoTransporte.toLowerCase() === values.modoTransporte.value.toLowerCase())

    if (values?.dateFrom) newList = newList.filter(item => item.fechaEnvio >= new Date(values.dateFrom).getTime())

    if (values?.dateTo)
      newList = newList.filter(item => item.fechaEnvio <= new Date(values.dateTo).getTime() + (24 * 60 * 60 * 1000 - 1))

    if (values?.destino?.value)
      newList = newList.filter(item => item.destinos?.map(item => item.idPais).includes(values.destino.value))

    if (values?.clientes && values?.clientes.length > 0)
      newList = newList.filter(item =>
        item?.idsClientes?.some(cli => values.clientes.map(val => val.value).includes(cli))
      )

    if (values?.procedencia?.value)
      newList = newList.filter(item => item.procedencias?.map(item => item.idPais).includes(values.procedencia.value))

    setDataFiltered(newList)
  }

  React.useEffect(() => {
    handleSearch()
  }, [values])

  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("dateFrom", fromDate)
      setFieldValue("dateTo", toDate)
    }
  }, [fromDate, toDate])

  return (
    <ContainerSerch>
      <Group themeContext={themeContext}>
        <InputCustom
          label={t(TRANSLATION_INDEX_QUOTER.NAME) ?? ""}
          value={values?.name ?? ""}
          handleChange={handleChange}
          name="name"
          placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(TRANSLATION_INDEX_QUOTER.NAME)}` ?? ""}
          width={isMobile ? "100%" : `${screenSize / 4}px`}
          iconLeft
        />
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE) ?? ""}
            value={values?.modoTransporte ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="modoTransporte"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 4}px`}
            marginLeft
            validError={false}
            options={[
              { label: t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase() ?? "", value: "AIR" },
              {
                label: `${t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase()}+${t(
                  TRANSLATION_INDEX_QUOTER.SEA
                ).toUpperCase()}`,
                value: "AIR+SEA"
              },
              { label: t(TRANSLATION_INDEX_QUOTER.ROA).toUpperCase() ?? "", value: "ROA" },
              { label: t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase() ?? "", value: "SEA" }
            ].sort((a, b) => (a.label > b.label ? 1 : -1))}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ORIGIN_2) ?? ""}
            value={values?.procedencia ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="procedencia"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 4}px`}
            marginLeft
            validError={false}
            options={customers.countries.map(item => {
              return {
                value: item.idPais,
                label: item.descripcion.toUpperCase()
              }
            })}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.DESTINATION_2) ?? ""}
            value={values?.destino ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="destino"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 4}px`}
            marginLeft
            validError={false}
            options={customers.countries.map(item => {
              return {
                value: item.idPais,
                label: item.descripcion.toUpperCase()
              }
            })}
          />
        )}
      </Group>
      <Group themeContext={themeContext} alignItems="flex-end">
        <DatePickerCustom
          iconLeft
          value={fromDate}
          placeHolder={t(TRANSLATION_INDEX_QUOTER.SENT_DATE_FROM) ?? ""}
          label={t(TRANSLATION_INDEX_QUOTER.SENT_DATE_FROM) ?? ""}
          setValue={setFromDate}
          dateFormat="dd/MM/yyyy"
          width={isMobile ? "100%" : `${screenSize / 4 - 12}px`}
          marginRight
        />
        <DatePickerCustom
          iconLeft
          value={toDate}
          placeHolder={t(TRANSLATION_INDEX_QUOTER.SENT_DATE_TO) ?? ""}
          label={t(TRANSLATION_INDEX_QUOTER.SENT_DATE_TO) ?? ""}
          setValue={setToDate}
          marginLeft
          dateFormat="dd/MM/yyyy"
          width={isMobile ? "100%" : `${screenSize / 4 - 12}px`}
          marginRight
        />
        {setFieldValue != null && (
          <SelectAsyncCustom
            isMulti
            marginLeft
            listTop={false}
            iconLeft
            width={isMobile ? "100%" : `${screenSize / 2.7}px`}
            label={`${t(TRANSLATION_INDEX_MENU.PROSPECT)}`}
            value={values?.clientes}
            handleChange={setFieldValue}
            name="clientes"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_MORE) ?? ""}
            promiseOptions={promiseOptions}
            validError={false}
          />
        )}
        <ContainerButtom width={`${screenSize / 7.3}px`} themeContext={themeContext}>
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
            background={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE_HOVER[themeContext]}
            onClick={handleResetFilters}
            width={isMobile ? "100%" : "110px"}
          />
        </ContainerButtom>
      </Group>
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserClientApp)
