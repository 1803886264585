/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { ContainerSubItem, Icon, MenuLevel1, SubMenu } from "../../_styles"
import { useTranslation } from "react-i18next"
import { type IProps } from "./_types"
import { useNavigate } from "react-router"
import { getAuth } from "../../../../utils/functions"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { useDispatch } from "react-redux"
import { logout } from "../../../../state/modules/login/actions"
import { STORAGE } from "../../../../utils/localStorage"
import { MENU_TYPE, ROUTES } from "../../../../utils/constants"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import IconArrowDown from "../../../Icons/IconArrowDown"
import { getUserBackofficeNotificationRequest } from "../../../../state/modules/userBackOffice/actions"
import SubMenuLevel2 from "../SubMenuLevel2"

const SubItem: React.FC<IProps> = ({ menu }): ReactElement => {
  const [activeSubMenu, setactiveSubMenu] = React.useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { setOpenMenuMobile, themeContext } = useGlobalContext()
  const [activeNotification, setActiveNotification] = React.useState(STORAGE.get(STORAGE.NOTIFICATION) === 1)

  const logoutHandle = (): void => {
    setOpenMenuMobile(false)
    dispatch(logout())
    STORAGE.remove(STORAGE.TOKEN)
    STORAGE.remove(STORAGE.NOTIFICATION)
    STORAGE.remove(STORAGE.CHANGE_PASSWORD)
    navigate(ROUTES.LOGIN.pathName)
  }
  const notificationHandle = (val): void => {
    setActiveNotification(val)
    dispatch(getUserBackofficeNotificationRequest(val ? 1 : 0))
  }

  if (menu.permission[0] !== "*" && !menu.permission.some(item => getAuth()[2].includes(item))) return <></>
  return (
    <ContainerSubItem themeContext={themeContext} key={menu.label}>
      <MenuLevel1
        onClick={() => {
          if (menu.menuType === MENU_TYPE.LOGOUT) {
            logoutHandle()
          } else if (menu.menuType === MENU_TYPE.NOTIFICATION) {
            notificationHandle(!activeNotification)
          } else {
            setactiveSubMenu(!activeSubMenu)
          }
        }}
      >
        {t(menu.label)}
        {menu.menuType === MENU_TYPE.NOTIFICATION && (
          <input
            type="checkbox"
            checked={activeNotification}
            style={{ marginLeft: "10px" }}
            onChange={e => {
              notificationHandle(e.target.checked)
            }}
          />
        )}
        {menu.subMenu.length > 0 && (
          <Icon>
            <IconArrowDown size={18} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]} />
          </Icon>
        )}
      </MenuLevel1>
      {activeSubMenu && (
        <SubMenu themeContext={themeContext}>
          {menu.subMenu.map(subItem => {
            return <SubMenuLevel2 key={subItem.label} menu={menu} subItem={subItem} />
          })}
        </SubMenu>
      )}
    </ContainerSubItem>
  )
}

export default SubItem
