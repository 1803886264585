import { reportCommercial } from "./_duck"
import * as types from "./types"

export const getReportCommercialRequest = reportCommercial.createAction(types.REPORT_COMMERCIAL_GET_REQUEST)
export const getReportCommercialSuccess = reportCommercial.createAction(types.REPORT_COMMERCIAL_GET_SUCCESS)
export const getReportCommercialFailure = reportCommercial.createAction(types.REPORT_COMMERCIAL_GET_FAILURE)

export const getReportCustomerRequest = reportCommercial.createAction(types.REPORT_CUSTOMER_GET_REQUEST)
export const getReportCustomerSuccess = reportCommercial.createAction(types.REPORT_CUSTOMER_GET_SUCCESS)
export const getReportCustomerFailure = reportCommercial.createAction(types.REPORT_CUSTOMER_GET_FAILURE)

export const getReportRankingCarrierRequest = reportCommercial.createAction(types.REPORT_RANKING_CARRIER_GET_REQUEST)
export const getReportRankingCarrierSuccess = reportCommercial.createAction(types.REPORT_RANKING_CARRIER_GET_SUCCESS)
export const getReportRankingCarrierFailure = reportCommercial.createAction(types.REPORT_RANKING_CARRIER_GET_FAILURE)

export const getReportRankingCountryRequest = reportCommercial.createAction(types.REPORT_RANKING_COUNTRY_GET_REQUEST)
export const getReportRankingCountrySuccess = reportCommercial.createAction(types.REPORT_RANKING_COUNTRY_GET_SUCCESS)
export const getReportRankingCountryFailure = reportCommercial.createAction(types.REPORT_RANKING_COUNTRY_GET_FAILURE)

export const getReportRankingCustomerRequest = reportCommercial.createAction(types.REPORT_RANKING_CUSTOMER_GET_REQUEST)
export const getReportRankingCustomerSuccess = reportCommercial.createAction(types.REPORT_RANKING_CUSTOMER_GET_SUCCESS)
export const getReportRankingCustomerFailure = reportCommercial.createAction(types.REPORT_RANKING_CUSTOMER_GET_FAILURE)

export const getReportRankingBrokerRequest = reportCommercial.createAction(types.REPORT_RANKING_BROKER_GET_REQUEST)
export const getReportRankingBrokerSuccess = reportCommercial.createAction(types.REPORT_RANKING_BROKER_GET_SUCCESS)
export const getReportRankingBrokerFailure = reportCommercial.createAction(types.REPORT_RANKING_BROKER_GET_FAILURE)

export const getReportUncontactCustomerRequest = reportCommercial.createAction(
  types.REPORT_UNCONTACT_CUSTOMER_GET_REQUEST
)
export const getReportUncontactCustomerSuccess = reportCommercial.createAction(
  types.REPORT_UNCONTACT_CUSTOMER_GET_SUCCESS
)
export const getReportUncontactCustomerFailure = reportCommercial.createAction(
  types.REPORT_UNCONTACT_CUSTOMER_GET_FAILURE
)

export const getReportClearAll = reportCommercial.createAction(types.REPORT_CLEAR_ALL)
