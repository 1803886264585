/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import { TRANSLATION_INDEX_MENU } from "../../config/translation"

import { ContainerTable } from "./_styles"
import { useDispatch, useSelector } from "react-redux"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { customerSelector } from "../../state/modules/customer/_selectors"
import { getTermsRequest } from "../../state/modules/customer/actions"
import ReactHtmlParser from "react-html-parser"

const TermsPage = (): ReactElement => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const customer = useSelector(customerSelector)
  const { setLoadingContext, themeContext } = useGlobalContext()
  React.useEffect(() => {
    setLoadingContext(customer.loading)
  }, [customer])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getTermsRequest())
  }, [])

  return (
    <>
      <ContainerPage title={`${t(TRANSLATION_INDEX_MENU.TERMS)}`} actions={[]}>
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            {customer?.terms != null &&
              ReactHtmlParser(
                customer?.terms[i18n.language.toLocaleLowerCase().includes("es") ? "textoEs" : "textoEn"].replaceAll(
                  "\n",
                  "<br />"
                )
              )}
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default TermsPage
