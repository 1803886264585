import React from "react"

import { type Props } from "./_types"
import edit_DARK from "../../assets/edit_DARK.png"
import edit_LIGHT from "../../assets/edit_LIGHT.png"
const IconEditCustom: React.FC<Props> = ({ size = 15, color = "#fff" }): React.ReactElement => {
  return <img src={color === "DARK" ? edit_DARK : edit_LIGHT} alt="edit icon" />
}

export default IconEditCustom
