/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerButtom, ContainerSerch, Group } from "./_styles"
import { useSelector } from "react-redux"
import { useMobile } from "../../../../hook/useMobile"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { customerSelector } from "../../../../state/modules/customer/_selectors"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { PERMISSION, STATES_YES_NO } from "../../../../utils/constants"
import { getAuth } from "../../../../utils/functions"
import {
  TRANSLATION_INDEX_AUTHORIZE_INFORMACION,
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL
} from "../../../../config/translation"
import InputCustom from "../../../../components/InputCustom"
import SelectCustom from "../../../../components/SelectCustom"
import ButtonCustom from "../../../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { useResize } from "../../../../hook/useResize"

const Search = ({
  setDataFiltered = () => {},
  setFilters,
  setClearFilter,
  resetFilter,
  data,
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const hideCheck = false
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { themeContext } = useGlobalContext()
  const screenSize = useResize()
  const customer = useSelector(customerSelector)
  const handleSearch = (): void => {
    if (setFieldValue) setFieldValue("search", !values?.search)
    if (setFilters) setFilters(values)
  }

  const handleResetFilters = (): void => {
    if (setClearFilter) setClearFilter(true)
  }

  React.useEffect(() => {
    if (resetFilter && handleReset) handleReset()
    const user = getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)
    if (!user[0] && setFieldValue) {
      const valueUser = customer.assignedCommercial.find(item => item.nombreUsuario === user[1])
      setFieldValue("assignedCommercial", {
        value: valueUser?.nombreUsuario,
        label: `${valueUser?.apellido.toLocaleUpperCase()} ${valueUser?.nombre.toLocaleUpperCase()}`
      })
    }
  }, [resetFilter])

  React.useEffect(() => {
    const user = getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)
    if (!user[0] && setFieldValue) {
      const valueUser = customer.assignedCommercial.find(item => item.nombreUsuario === user[1])
      setFieldValue("assignedCommercial", {
        value: valueUser?.nombreUsuario,
        label: `${valueUser?.apellido.toLocaleUpperCase()} ${valueUser?.nombre.toLocaleUpperCase()}`
      })
    }
  }, [customer.assignedCommercial])

  React.useEffect(() => {
    if (setFieldValue) setFieldValue("search", false)
  }, [])

  return (
    <ContainerSerch>
      <Group themeContext={themeContext}>
        <InputCustom
          label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME) ?? ""}
          value={values?.companyName ?? ""}
          handleChange={handleChange}
          name="companyName"
          placeHolder={
            `${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME)}` ?? ""
          }
          width={isMobile ? "100%" : `${screenSize / 5}px`}
          iconLeft
        />
        <InputCustom
          label="CUIT"
          value={values?.cuit ?? ""}
          handleChange={handleChange}
          name="cuit"
          placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} CUIT` ?? ""}
          width={isMobile ? "100%" : `${screenSize / 5}px`}
          iconLeft
          marginLeft
        />
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.TYPE) ?? ""}
            value={values?.clientType ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="clientType"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 5}px`}
            marginLeft
            validError={false}
            options={customer.clientType.map(item => {
              return {
                value: item.idTipoCliente,
                label: t(
                  item.idTipoCliente === 1
                    ? TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER
                    : TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT
                ).toUpperCase()
              }
            })}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSGNED_COMMERCIAL)} / ${t(
              TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSOCIATE_USER
            )}`}
            isDisabled={!getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)[0]}
            value={values?.assignedCommercial ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="assignedCommercial"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 5}px`}
            marginLeft
            validError={false}
            options={customer.assignedCommercial.map(item => {
              return {
                value: item.nombreUsuario,
                label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
              }
            })}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CIF_CARGO) ?? ""}
            value={values?.cifCargo ?? null}
            iconLeft
            marginLeft
            handleChange={setFieldValue}
            name="cifCargo"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 5}px`}
            validError={false}
            options={STATES_YES_NO.filter(item => (item?.value ?? 2) !== 2).map(item => {
              return { ...item, label: t(item.label).toUpperCase() }
            })}
          />
        )}
      </Group>
      <Group themeContext={themeContext}>
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ORIGINS) ?? ""}
            value={values?.origins ?? null}
            isMulti
            iconLeft
            handleChange={setFieldValue}
            name="origins"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 2}px`}
            validError={false}
            options={customer.countries.map(item => {
              return { label: item?.descripcion.toUpperCase(), value: item.idPais }
            })}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.DESTINATIONS) ?? ""}
            value={values?.destinations ?? null}
            iconLeft
            isMulti
            marginLeft
            handleChange={setFieldValue}
            name="destinations"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 2}px`}
            validError={false}
            options={customer.countries.map(item => {
              return { label: item?.descripcion.toUpperCase(), value: item.idPais }
            })}
          />
        )}
      </Group>
      <Group themeContext={themeContext} alignItems="flex-end">
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.IS_IMPORTER) ?? ""}
            value={values?.isImporter ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="isImporter"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 5}px`}
            validError={false}
            options={STATES_YES_NO.filter(item => (item?.value ?? 2) !== 2).map(item => {
              return { ...item, label: t(item.label).toUpperCase() }
            })}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.IS_EXPORTER) ?? ""}
            value={values?.isExporter ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="isExporter"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 5}px`}
            marginLeft
            validError={false}
            options={STATES_YES_NO.filter(item => (item?.value ?? 2) !== 2).map(item => {
              return { ...item, label: t(item.label).toUpperCase() }
            })}
          />
        )}
        {getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)[0] && !hideCheck && (
          <div
            style={{
              width: `${isMobile ? "100%" : `${screenSize / 5}px`}`,
              marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="sinComercialAsignado"
              value={values?.sinComercialAsignado}
              checked={values?.sinComercialAsignado}
              onChange={e => {
                if (setFieldValue) setFieldValue("sinComercialAsignado", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.WO_COMMERCIAL_ASSIGMENT)}</span>
          </div>
        )}
        <ContainerButtom
          width={`${
            screenSize / (getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)[0] && !hideCheck ? 2.5 : 1.65)
          }px`}
          themeContext={themeContext}
          style={{ marginLeft: "20px", paddingTop: `${isMobile ? "25px" : ""}` }}
        >
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
            background={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE_HOVER[themeContext]}
            onClick={handleResetFilters}
            width={isMobile ? "100%" : "110px"}
          />
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.SEARCH) ?? ""}
            background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
            onClick={handleSearch}
            width={isMobile ? "100%" : "80px"}
            marginLeft
          />
        </ContainerButtom>
      </Group>
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(Search)
