import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const postReportCommercial = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/eventosPorComercial`, request)

export const postReportCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/eventosPorCliente`, request)

export const postReportUncontactCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/clientesSinContactar`, request)

export const postReportRankingCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/rankingProcedencias`, request)

export const postReportRankingCountry = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/rankingPaises`, request)

export const postReportRankingCarrier = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/rankingTotalesTransportistas`, request)

export const postReportRankingBroker = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/reportes/rankingDespachantes`, request)
