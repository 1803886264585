export const TRANSLATION_INDEX_GLOBAL = {
  ACCEPT: "global.accept",
  AND: "global.and",
  CLICK_OR_DRAG: "global.clickOrDrag",
  ATTANCHMENT: "global.attachments",
  APPROVE: "global.approve",
  DUPLICATE: "global.duplicate",
  EXPORT: "global.export",
  REJECT: "global.reject",
  APPROVED: "global.approved",
  CONSULT: "global.consult",
  REJECTED: "global.rejected",
  CONFIRM: "global.confirm",
  AVERAGE: "global.average",
  LOGIN: "global.login",
  ROWS_PER_PAGE: "global.rowsPerPageText",
  OF: "global.of",
  MAX_50: "global.max50",
  MAX_100: "global.max100",
  ERROR_DATE: "global.errorDate",
  ERROR_DATE_SOME: "global.errorDateSome",
  ERROR_DATE_DIFFERENTE_DAY: "global.errorDateDifferentDay",
  MAX_255: "global.max255",
  OR1: "global.or1",
  OR2: "global.or2",
  ALL: "global.all",
  QUANTITY: "global.quantity",
  PERCENT: "global.percent",
  QUANTITY_OPERATIONS: "global.quantityOperations",
  CANCEL: "global.cancel",
  SPANISH: "global.spanish",
  ENGLISH: "global.english",
  NEXT: "global.next",
  BACK: "global.back",
  DARK: "global.dark",
  LIGHT: "global.light",
  SUCCESS: "global.success",
  ACTIVE: "global.active",
  GO_BACK: "global.goBack",
  LANGUAGE: "global.language",
  IMPORTANT: "global.important",
  MODE: "global.mode",
  YES: "global.yes",
  GENERATE: "global.generate",
  AUTHORIZATION_PENDING: "global.authorizationPending",
  AUTHORIZED: "global.authorized",
  INACTIVE: "global.inactive",
  LOADING: "global.loading",
  ENTER_THREE_CHARACTER: "global.enterThreeCharacters",
  ENTER_FIVE_CHARACTER: "global.enterFiveCharacters",
  NO_OPTIONS: "global.noOptions",
  DISABLED: "global.disaled",
  DELETED: "global.deleted",
  DELETE: "global.delete",
  SEARCH: "global.search",
  SEARCH_FOR: "global.searchFor",
  SELECT_A: "global.selectA",
  SELECT_MORE: "global.selectOptionMore",
  SELECT_ALL: "global.selectAll",
  SELECT_COLUMNS: "global.selectColumn",
  CLEAR: "global.clear",
  ADD: "global.add",
  VALIDATE: "global.validate",
  NO_DATA: "global.noData",
  NO_DATA_2: "global.noData2",
  ADDED: "global.added",
  EDIT: "global.edit",
  UPDATED: "global.updated",
  UPDATE: "global.update",
  SELECT_OPTION: "global.selectOption",
  SUCCESS_UPDATE: "global.successUpdate",
  SUCCESS_DOWNLOAD: "global.successDownload",
  SUCCESS_ADD: "global.successAdd",
  SUCCESS_DELETE: "global.successDelete",
  REQUIRED: "global.required",
  EMAIL_FORMAT_INCORRECT: "global.emailFormatIncorrect",
  FORMAT_INCORRECT: "global.formatIncorrect",
  DATE_GREATER_THAN_TODAY: "global.dateGreaterThanToday",
  CONFIRM_DELETE: "global.confirmDelete",
  CONFIRM_APPROVE: "global.confirmApprove",
  CONFIRM_REJECT: "global.confirmReject",
  TIME: "global.time",
  THERE_IS_NO: "global.thereIsNo",
  WARNING: "global.warning",
  COLUMN_TITLE: "global.columnTitle",
  USE_SEARCH_TO_GET_RESULTS: "global.useSearchToGetResults",
  VIEW: "global.view"
}

export const TRANSLATION_INDEX_PREFERENCE = {
  CHANGE_ALERT_EMAILS: "preference.changeAlertEmails",
  USER_WITHOUT_LOGIN_EMAIILS: "preference.userWithoutLoginEmails",
  USER_REGISTRATION_EMAILS: "preference.userRegistrationEmails",
  PRICING_FOR_CIF: "preference.pricingForCif",
  NEW_CUSTOMER: "preference.newCustomer",
  REQUEST_INVOICE: "preference.requestInvoice",
  QUANTITY_DAYS_CHANGES: "preference.quantityDaysChanges",
  QUANTITY_DAYS_CHANGES_SUBTITLE: "preference.quantityDaysChangesSubtitle",
  CUSTOMER_WO_EVENTS: "preference.clienteDiasSinEventos",
  CUSTOMER_WO_CONTACTS: "preference.clienteDiasSinContacto",
  CUSTOMER_WO_ASSIGMENT_COMMERCIAL: "preference.mailsClienteSinComercialAsignado",
  CUSTOMER_ALERT_WO_CONTACT: "preference.mailsClienteAlertaSinContacto"
}

export const TRANSLATION_INDEX_MENU = {
  AUTHORIZE_INFORMATION: "menu.authorizeInformation",
  AUTHORIZE_INFORMATION_2: "menu.authorizeInformation_2",
  OPERATION: "menu.operation",
  PREFERENCES: "menu.preferences",
  PROFILES: "menu.profiles",
  QUOTER: "menu.quoter",
  QUOTE_GENERATION: "menu.quoteGeneration",
  CONSULT_REFERENCE: "menu.consultReference",
  SETTING: "menu.settings",
  PERMISSION_BY_PROFILES: "menu.permissionProfiles",
  USERS_APP_CLIENTS: "menu.usersAppClients",
  USERS_BACKOFFICE: "menu.usersBackoffice",
  COMMERCIAL: "menu.commercial",
  PROSPECTS: "menu.prospects",
  PROSPECT: "menu.prospect",
  TERMS: "menu.terms",
  RATINGS: "menu.ratings",
  EXPENSES_ORIGIN: "menu.expensesOrigin",
  EXPENSES_BA: "menu.expensesBA",
  QQUOTES_TEMPLATE: "menu.quotesTemplate",
  SEND_QUOTE: "menu.sendQuote",
  VIEW_SEND_QUOTE: "menu.viewSendQuote",
  SEND_QUOTE_ALL_CUSTOMER: "menu.sendQuoteAllCustomer",
  CUSTOMS: "menu.customs",
  REMINDERS: "menu.reminders",
  REPORT: "menu.report",
  EVENTS: "menu.events"
}

export const TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP = {
  COLUMNS: {
    ACTIONS: "userBackoffice.columns.actions",
    REGISTRATION_DATE: "userBackoffice.columns.registrationDate",
    ID: "userBackoffice.columns.id",
    USER_NAME: "userBackoffice.columns.userName",
    CHANGE_PASS: "userBackoffice.columns.changePass",
    NAME: "userBackoffice.columns.name",
    MOBILE_NUMBER: "userBackoffice.columns.mobileNumber",
    LAST_NAME: "userBackoffice.columns.lastName",
    EMAIL: "userBackoffice.columns.email",
    PROFILE: "userBackoffice.columns.profile",
    STATE: "userBackoffice.columns.state",
    ORGANIZATIONS: "userBackoffice.columns.organizations",
    ORGANIZATIONS_TO_VALIDATE: "userBackoffice.columns.organizationsToValidate",
    LAST_UPDATE: "userBackoffice.columns.lastUpdate",
    USER_APPROVE: "userBackoffice.columns.userApprove",
    JOB: "userBackoffice.columns.job",
    ADMINISTRATOR: "userBackoffice.columns.administrator",
    IS_ADMIN: "userBackoffice.columns.isAdmin",
    ROLE: "userBackoffice.columns.role"
  },
  CUSTOMER: "userBackoffice.customer",
  BROKER: "userBackoffice.broker",
  REGISTERED_USERS: "userBackoffice.registeredUsers",
  PENDING_USERS: "userBackoffice.pendingUsers",
  REGISTERED_USER: "userBackoffice.registeredUser",
  PENDING_USER: "userBackoffice.pendingUser",
  USER_BACKOFFICE: "userBackoffice.userBackoffice",
  USER_APP_CLIENT: "userBackoffice.userAppClient",
  SEARCH_USER: "userBackoffice.searchUser",
  SELECT_ROL: "userBackoffice.selectRol",
  MENMBER: "userBackoffice.member",
  IMAGE_SIGNATURE: "userBackoffice.imageSignature",
  CHANGE_IMAGE: "userBackoffice.changeImage",
  LOAD_IMAGE: "userBackoffice.loadImage",
  RECEIVE_MAIL: "userBackoffice.receiveMail"
}

export const TRANSLATION_INDEX_AUTHORIZE_INFORMACION = {
  COLUMNS: {
    ACTIONS: "authorizeInformation.columns.actions",
    SHIPMENT_ID: "authorizeInformation.columns.shipmentId",
    ORGANIZATION: "authorizeInformation.columns.organizations",
    TYPE: "authorizeInformation.columns.type",
    PREVIOUS_DATA: "authorizeInformation.columns.previousDate",
    NEW_DATE: "authorizeInformation.columns.newDate",
    DAYS_DIFFERENCE: "authorizeInformation.columns.daysDifference",
    CHANGE_DATE_ETAORETD: "authorizeInformation.columns.changeDateETAorETD",
    CHANGE_DATE: "authorizeInformation.columns.changeDate",
    STATUS: "authorizeInformation.columns.status",
    SCORE: "authorizeInformation.columns.score",
    COMMENT: "authorizeInformation.columns.comment",
    DATE: "authorizeInformation.columns.date",
    DATE_BETWEEN: "authorizeInformation.columns.dateBetween",
    RATINGS_AVERAGE: "authorizeInformation.columns.ratingsAverage",
    GROUP_BY_CLIENT: "authorizeInformation.columns.groupByClient"
  },
  RESOLVED: "authorizeInformation.resolved",
  RESOLVED_BY: "authorizeInformation.resolvedBy",
  PENDING: "authorizeInformation.pending"
}

export const TRANSLATION_INDEX_PROFILE = {
  COLUMNS: {
    ACTIONS: "profile.columns.actions",
    ID: "profile.columns.id",
    USER_NAME: "profile.columns.userName",
    DESCRIPTION: "profile.columns.description",
    REFERENCE: "profile.columns.reference",
    LAST_UPDATE: "profile.columns.lastUpdate"
  },
  PROFILE: "profile.profile"
}

export const TRANSLATION_INDEX_PERMISSION = {
  OPERATION: "permission.operations",
  SETTING: "permission.settings",
  USUARIO_BACKOFFICE: "permission.userBackoffice",
  USUARIO_BACKOFFICE_CONSULTAR: "permission.userBackofficeConsut",
  USUARIO_BACKOFFICE_CREAR: "permission.userBackofficeCreate",
  USUARIO_BACKOFFICE_MODIFICAR: "permission.userBackofficeEdit",
  USUARIO_APP: "permission.userAppClient",
  USUARIO_APP_CONSULTAR: "permission.userAppClientConsult",
  USUARIO_APP_MODIFICAR: "permission.userAppClientEdit",
  PERFIL: "permission.profile",
  PERFIL_CONSULTAR: "permission.profileConsult",
  PERFIL_CREAR: "permission.profileCreate",
  PERFIL_MODIFICAR: "permission.profileEdit",
  PERFIL_ELIMINAR: "permission.profileDelete",
  PERMISO_PERFIL: "permission.permissionByProfile",
  PERMISO_PERFIL_CONSULTAR: "permission.permissionByProfileConsult",
  PERMISO_PERFIL_MODIFICAR: "permission.permissionByProfileEdit",
  PREFERENCIAS: "permission.preferences",
  PREFERENCIAS_CONSULTAR: "permission.preferenciesConsult",
  PREFERENCIAS_MODIFICAR: "permission.preferenciesEdit",
  AUT_INFOCLIENTES: "permission.authInfoClient",
  AUT_INFOCLIENTES_CONSULTAR: "permission.authInfoClientConsult",
  AUT_INFOCLIENTES_MODIFICAR: "permission.authInfoClientEdit",
  COTIZADOR: "permission.quote",
  COTIZADOR_CONSULTAR: "permission.quoterConsult",
  COTIZADOR_COTIZAR: "permission.quoteQuote",
  REFERENCIA_CLIENTE: "permission.referenceClient",
  REFERENCIA_CLIENTE_CONSULTAR: "permission.referenceClientConsult",
  CLIENTE_AGREGAR_ASOCIADOS: "permission.clientAddAsociate",
  CLIENTE_CONSULTAR: "permission.clientConsult",
  CLIENTE_CONSULTAR_OTROS_USUARIOS: "permission.clientConsultOther",
  COTIZACIONES_ENVIAR_OTROS_CLIENTES: "permission.quoteSendAllCustomer",
  COTIZACIONES_ENVIAR: "permission.quoteSend",
  CLIENTE_CREAR: "permission.clientCreate",
  CLIENTE_MODIFICAR: "permission.clientUpdate",
  CLIENTE_ELIMINAR: "permission.clientDelete",
  CALIFICACIONES_CONSULTAR: "permission.ratingsConsult",
  GASTOS_ORIGEN_CONSULTAR: "permission.expenseOriginConsult",
  GASTOS_ORIGEN_CREAR: "permission.expenseOriginCreate",
  GASTOS_ORIGEN_MODIFICAR: "permission.expenseOriginEdit",
  GASTOS_ORIGEN_ELIMINAR: "permission.expenseOriginDelete",
  GASTOS_BSAS_CONSULTAR: "permission.expenseBaConsult",
  GASTOS_BSAS_CREAR: "permission.expenseBaCreate",
  GASTOS_BSAS_MODIFICAR: "permission.expenseBaEdit",
  GASTOS_BSAS_ELIMINAR: "permission.expenseBaDelete",
  COTIZACIONES_TEMPLATE_CONSULTAR: "permission.quoteTemplateConsult",
  COTIZACIONES_TEMPLATE_CREAR: "permission.quoteTemplateCreate",
  COTIZACIONES_TEMPLATE_MODIFICAR: "permission.quoteTemplateEdit",
  COTIZACIONES_TEMPLATE_ELIMINAR: "permission.quoteTemplateDelete",
  COTIZACIONES_CONSULTAR: "permission.quoterConsultSent",
  COTIZACIONES_CONSULTAR_OTROS_USUARIOS: "permission.quoterConsultSentOther",
  ADUANA_CONSULTAR: "permission.customsConsult",
  ADUANA_IMPORTAR: "permission.customsImport",
  ASIGNACION_MASIVA_COMERCIAL: "permission.massiveAssignment",
  EVENTOS_CONSULTAR: "permission.eventConsult",
  EVENTOS_CREAR: "permission.eventCreate",
  EVENTOS_MODIFICAR: "permission.eventEdit",
  EVENTOS_ELIMINAR: "permission.eventDelete",
  EVENTOS_CONSULTAR_OTROS_USUARIOS: "permission.eventConsultAll",
  RECORDATORIOS_CONSULTAR: "permission.reminderConsult",
  RECORDATORIOS_CREAR: "permission.reminderCreate",
  RECORDATORIOS_MODIFICAR: "permission.reminderEdit",
  RECORDATORIOS_ELIMINAR: "permission.reminderDelete",
  REPORTE_EVENTOS_X_COMERCIAL: "permission.eventCommercial",
  REPORTE_EVENTOS_X_CLIENTE: "permission.eventCustomer",
  REPORTE_RANKING_CLIENTES: "permission.CustomerRanking",
  REPORTE_RANKING_CLIENTES_DESPACHANTES: "permission.brokerRanking",
  REPORTE_RANKING_TRANSPORTISTAS: "permission.carrierRanking",
  REPORTE_RANKING_PAISES: "permission.countryRanking",
  REPORTE_CLIENTES_SIN_CONTACTAR: "permission.eventUncontacted"
}

export const TRANSLATION_INDEX_RESPONSE_CODE = {
  USER_NOT_ACTIVE: "responseCode.userNotActive",
  ENTER_USER: "responseCode.enterUser",
  NOT_ARROBA: "responseCode.notArroba",
  USER_NOT_VALID: "responseCode.userNotValid",
  PASSWORD_DIFFERENT: "responseCode.passwordDifferent",
  EMAIL_MISSING: "responseCode.emailMissing",
  PASSWORD_NOT_VALID: "responseCode.passwordNotValid",
  REQUEST_SUPPORT_CARGO: "responseCode.emailMissing",
  INVALID_CREDENTIALS: "responseCode.invalidCredentials",
  GENERIC_ERROR: "responseCode.genericError",
  USERNAME_ALREADY_IN_USE: "responseCode.userNameAlreadyInUse",
  REQUIRED_DATA_ERROR: "responseCode.missingRequiredData",
  MISSING_REQUIRED_DATA: "responseCode.missingRequiredData",
  USERNAME_NOT_FOUND: "responseCode.userNameFound",
  USER_NOT_PENDING: "responseCode.userNotPending",
  ROLE_NAME_ALREADY_IN_USE: "responseCode.roleNameAlreadyInUse",
  DATA_LENGTH_ERROR: "responseCode.dataLengthError",
  ROLE_NOT_FOUND: "responseCode.roleNotFound",
  ROLE_IN_USE: "responseCode.roleInUse",
  CUIT_ALREADY_IN_USE: "responseCode.cuitInUse",
  EMAIL_LOGIN_SEND: "responseCode.emailLoginSend",
  REQUEST_DATA_INVALID: "responseCode.dataInvalid",
  GASTO_ORIGEN_ALREADY_EXISTS: "responseCode.expenseOrigenExist",
  GASTO_ORIGEN_IN_USE: "responseCode.expenseOrigenInUse",
  GASTO_BA_IN_USE: "responseCode.expensebaInUse",
  GASTO_BSAS_ALREADY_EXISTS: "responseCode.expenseBAExist",
  TEMPLATE_NAME_ALREADY_EXISTS: "responseCode.templateNameAlreadyExists",
  FILE_ALREADY_PROCESSED: "responseCode.fileAlreadyProcessed",
  FILE_NAME_INVALID: "responseCode.filaNameInvalid",
  OPERATION_TYPE_INVALID: "responseCode.operationTypeInvalid",
  PLACE_NOT_EXISTS: "responseCode.placeNotExists",
  INVALID_MONTH: "responseCode.invalidMonth",
  INVALID_YEAR: "responseCode.invalidYear",
  ERROR_IN_EXCEL_DATA: "responseCode.errorInExcelData",
  LIMIT_TO_EXPORT: "responseCode.limitToExport"
}

export const TRANSLATION_INDEX_QUOTER = {
  COMPANY_TO_SEARCH: "quoter.companyToSearch",
  SOURCE_PORT: "quoter.sourcePort",
  DESTINATION_PORT: "quoter.destinationPort",
  BY_THE_CUSTOMER: "quoter.byTheCustomer",
  BY_THE_SHIPPING_COMPANY: "quoter.byTheShippingCompany",
  COMMOODITY: "quoter.commodity",
  CONTAINER_TYPE_HL: "quoter.containerTypeHapagLloyd",
  CONTAINER_TYPE_MK: "quoter.containerTypeMaersk",
  CONTAINER_QUANTITY: "quoter.containerQuantity",
  WAIT_PLEASE: "quoter.waitPlease",
  PROCESS_QUOTER: "quoter.proccessQuoter",
  PROCESS_NOT: "quoter.proccessNot",
  EXPORT_EXCEL: "quoter.exportExcel",
  RESULTS: "quoter.results",
  WEIGHT: "quoter.weight",
  NAME: "quoter.name",
  TRANSPORT_MODE: "quoter.transportMode",
  AIR: "quoter.air",
  SEA: "quoter.sea",
  ROA: "quoter.roa",
  QUOTE_DATA: "quoter.quoteData",
  QUOTE_NAME: "quoter.quoteName",
  HEADER_TEXT: "quoter.headertext",
  WO_TOTAL: "quoter.withoutTotalizer",
  ADD_QUOTE: "quoter.addQuotesTable",
  W_TOTAL: "quoter.withTotalizer",
  ADD_QUOTE_WO_TOTAL: "quoter.addQuotesTableWithoutTotalizer",
  ADD_QUOTE_W_TOTAL: "quoter.addQuotesTableWithTotalizer",
  DELETE_TABLE: "quoter.deleteTable",
  ADD_ROW: "quoter.addRow",
  TABLE_LEGEND: "quoter.tableLegend",
  CURRENCY: "quoter.currency",
  KG_PER_M3: "quoter.kgPerM3",
  VOLUME: "quoter.volume",
  COLUMN_FOR_CALCULATIONS: "quoter.columnForCalculations",
  SHOW_GRAND_TOTAL: "quoter.showGrandTotal",
  EXPENSE_AT_ORIGIN: "quoter.expensesAtOrigin",
  EXPENSE_AT_BA: "quoter.expensesAtBuenosAires",
  CONCEPT: "quoter.concept",
  AMOUNT: "quoter.amount",
  LEGEND_OF_EXPENSE_ORIGEN: "quoter.legendOfExpensesAtOrigin",
  LEGEND_EXPENSE_BA: "quoter.legendOfexpensesAtBuenosAires",
  CONDITION: "quoter.conditions",
  TERMS: "quoter.terms",
  FINAL_TEXT: "quoter.finalText",
  ADD_COL: "quoter.addCol",
  DELETE_COL: "quoter.deleteCol",
  DELETE_ROW: "quoter.deleteRow",
  COLUMN_ERROR_NUMERIC: "quoter.columnErrorNumeric",
  COLUMN_TO_CALC: "quoter.columnToCalc",
  COLUMN_EXIST: "quoter.columnExist",
  SEND_QUOTE: "quoter.sendQuote",
  SEND_TEST: "quoter.sendTest",
  DOWNLOAD_PDF: "quoter.downLoadPDF",
  STEP_1: "quoter.step1",
  STEP_2: "quoter.step2",
  STEP_3: "quoter.step3",
  STEP_4: "quoter.step4",
  STEP_1_DESCRIPTION: "quoter.step1Description",
  STEP_2_DESCRIPTION: "quoter.step2Description",
  STEP_3_DESCRIPTION: "quoter.step3Description",
  STEP_4_DESCRIPTION: "quoter.step4Description",
  VIEW_SELECTED_CUSTOMER: "quoter.viewSelectedCustomers",
  SELECTED_CUSTOMER: "quoter.selectedCustomer",
  FILTER_RESULT: "quoter.filterResults",
  NO_CUSTOMER_SELECTED: "quoter.noCustomerSelected",
  NO_CUSTOMER_SELECTED_2: "quoter.noCustomerSelected2",
  NO_TEMPLATE_SELECTED: "quoter.noTemplateSelected",
  RELATED_CUSTOMER: "quoter.relatedCustomerSelected",
  RELATED_CUSTOMER_TITLE: "quoter.relatedCustomer",
  SELECT_TEMPLATE_TO_SEND: "quoter.selectTemplateToSend",
  VAR_USE: "quoter.varUse",
  VAR_COMPANY: "quoter.varCompany",
  VAR_USER: "quoter.varUser",
  VALID_SINCE: "quoter.validSince",
  VALID_UNTIL: "quoter.validUntil",
  VALIDITY: "quoter.validity",
  CONFIRM_SEND_QUOTE: "quoter.confirmSendQuote",
  CUSTOMER_WO_RECIPIENTS: "quoter.customerWORecipients",
  CUSTOMER_W_ERROR: "quoter.customerWError",
  WAIT_RESPONSE_SEND_QUOTE: "quoter.waitResponseSendQuote",
  CUSTOMER_WITHOUT_RECIMIENTS: "quoter.customerWithoutRecimients",
  CUSTOMER_WITHOUT_RECIMIENTS2: "quoter.customerWithoutRecimients2",
  CUSTOMER_WITHOUT_RECIMIENTS3: "quoter.customerWithoutRecimients3",
  CUSTOMER_WITH_ERROR: "quoter.customerWithError",
  ONLY_CUSTOMER_TO_DOWNLOAD: "quoter.onlyneCustomerToDownload",
  SENDING_DATE: "quoter.sendingDate",
  SENDING_USER: "quoter.sendingUser",
  VIEW_CUSTOMER: "quoter.viewCustomer",
  VIEW_QUOTE: "quoter.viewQuote",
  MAXIMUM_CUSTOMER_TO_SEND: "quoter.maximumCustomerToSend",
  SENT_DATE_FROM: "quoter.sentDateFrom",
  SENT_DATE_TO: "quoter.sentDateTo",
  DATE: "quoter.date",
  TYPE: "quoter.type",
  PLACE: "quoter.place",
  COMPANY_NAME: "quoter.companyName",
  COUNTRY: "quoter.country",
  COUNTRY_CODE: "quoter.countryCode",
  VIEW_DETAILS: "quoter.viewDetails",
  IMPORT: "quoter.import",
  EXPORT: "quoter.export",
  IMPORT_2: "quoter.import2",
  EXPORT_2: "quoter.export2",
  CLEAR_FILTERS: "quoter.clearFilters",
  OPERATION_TYPE: "quoter.operationType",
  YEAR: "quoter.year",
  MONTH: "quoter.month",
  MONTH_FROM: "quoter.monthFrom",
  MONTH_TO: "quoter.monthTo",
  MANIFIEST: "quoter.manifiest",
  BROKER: "quoter.broker",
  CUSTOMS_BROKER: "quoter.customsBroker",
  DOCUMENT: "quoter.document",
  FREIGHT: "quoter.freight",
  PACKS: "quoter.packs",
  CARRIER: "quoter.carries",
  OFFICIALIZATION: "quoter.offcialization",
  ARRIVAL: "quoter.arrival",
  SUPPLIER: "quoter.supplier",
  FILE_NAME: "quoter.fileName",
  ERRORS: "quoter.errors",
  IMPORT_ERROR: "quoter.importError",
  SHOW_CUSTOMERS: "quoter.showCustomers",
  TITLE: "quoter.title",
  SUBJECT: "quoter.subject",
  RESEND: "quoter.resend",
  PDF_PREVIEW: "quoter.pdfPreview"
}

export const TRANSLATION_INDEX_CUSTOMER_PROSPECT = {
  ADD_ASSOCIATED: "customerProspect.addAssociated",
  COMPANY_NAME: "customerProspect.companyName",
  PHONE: "customerProspect.phone",
  CUSTOMER: "customerProspect.customer",
  PROSPECT: "customerProspect.prospect",
  ADRESS: "customerProspect.address",
  SOURCE_CHANNEL: "customerProspect.sourceChannel",
  ASSOCIATE_USERS: "customerProspect.associatedUsers",
  ASSOCIATE_USER: "customerProspect.associatedUser",
  RELATED_CUSTOMERS: "customerProspect.relatedCustomers",
  ADD_CONTACTS: "customerProspect.addContact",
  POSITION: "customerProspect.position",
  PHONES: "customerProspect.phones",
  NO_CONTACTS: "customerProspect.noContacts",
  CONTACTS: "customerProspect.contacts",
  CONTACT: "customerProspect.contact",
  LAST_NAME: "customerProspect.lastName",
  NAME: "customerProspect.name",
  CLIENT_TYPE: "customerProspect.clientType",
  ASSGNED_COMMERCIAL: "customerProspect.assignedCommercial",
  CIF_CARGO: "customerProspect.cifCargo",
  IS_IMPORTER: "customerProspect.isImporter",
  IS_EXPORTER: "customerProspect.isExporter",
  USER_UPDATE: "customerProspect.userUpdate",
  NO_MAILS: "customerProspect.noMails",
  NO_PHONES: "customerProspect.noPhones",
  LAST_uPDATE: "customerProspect.lastUpdate",
  ORIGIN: "customerProspect.origin",
  ORIGINS: "customerProspect.origins",
  ORIGIN_2: "customerProspect.origin2",
  ORIGINS_2: "customerProspect.origins2",
  SUPPLIER: "customerProspect.supplier",
  CUSTOMER_CLASIFICATION: "customerProspect.customerClassification",
  DESTINATION: "customerProspect.destination",
  DESTINATION_2: "customerProspect.destination2",
  DESTINATIONS: "customerProspect.destinations",
  DESTINATIONS_2: "customerProspect.destinations2",
  HOW_ARRIVE_CUSTOMER: "customerProspect.howArriveCustomer",
  WO_CONTACTS: "customerProspect.woContact",
  WO_COMMERCIAL_ASSIGMENT: "customerProspect.woCommercialAssigment",
  COMMERCIAL_TO_BE_ASSIGNED: "customerProspect.commercialToBeAssigned",
  BULK_ASSIGNMENT: "customerProspect.bulkAssignment",
  COMMERCIAL_REASSIGMENT: "customerProspect.commercialReassignment",
  CURRENT_COMMERCIAL: "customerProspect.currentCommercial",
  NEW_COMMERCIAL: "customerProspect.newCommercial",
  COMMERCIAL_NO_ADD: "customerProspect.commercialNoAdd",
  WARNING_TO_CONTINUE_ASSIGNMENT: "customerProspect.warningToContinueAssigment"
}

export const TRANSLATION_INDEX_EVENT = {
  EVENT_TYPE: "event.eventType",
  CONTACT: "event.contact",
  USER: "event.user",
  COMMENT: "event.comment",
  DATE_FROM: "event.dateFrom",
  DATE_TO: "event.dateTo",
  DATE: "event.date",
  PRESENTIAL_MEETING: "event.presentialMeeting",
  VIDEO_CALL: "event.videoCall",
  PHONE: "event.phone",
  QUOTE_SUBMISSION: "event.quoteSubmission",
  CUSTOMER_PROSPECT: "event.customeProspect"
}

export const TRANSLATION_INDEX_CALENDAR = {
  COMMENTS: "calendar.comments",
  ADD_EVENT: "calendar.addEvent",
  DELETE_REMENDER: "calendar.deleteRemender",
  DATE_FROM: "calendar.dateFrom",
  DATE_TO: "calendar.dateTo",
  TASKS_OF_THE_DAY: "calendar.tasksOfTheDay"
}

export const TRANSLATION_INDEX_REPORT = {
  USER: "report.user",
  COUNT_EVENT: "report.countEvent",
  AIR_IMPORTS: "report.airImports",
  ERROR_MONTHS: "report.errorMonths"
}
