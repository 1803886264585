import { TRANSLATION_INDEX_QUOTER } from "../config/translation"
import { type ICustomerData } from "../interfaces/ICustomer"
import { type IQuoteTemplate } from "../interfaces/IQuoteTemplate"
import { type IOrganizationOption } from "../interfaces/IUserBackoffice"
import { PERMISSION } from "../utils/constants"

export const mockProfile = [
  PERMISSION.USUARIO_BACKOFFICE_CONSULTAR,
  PERMISSION.USUARIO_BACKOFFICE_CREAR,
  PERMISSION.USUARIO_BACKOFFICE_MODIFICAR,

  PERMISSION.USUARIO_APP_CONSULTAR,
  PERMISSION.USUARIO_APP_MODIFICAR,

  PERMISSION.PERFIL_CONSULTAR,
  PERMISSION.PERFIL_CREAR,
  PERMISSION.PERFIL_MODIFICAR,
  PERMISSION.PERFIL_ELIMINAR,

  PERMISSION.PERMISO_PERFIL_CONSULTAR,
  PERMISSION.PERMISO_PERFIL_MODIFICAR
]

export const clientTypeMock = [
  { idTipoCliente: 1, descripcion: "CLIENTE" },
  { idTipoCliente: 2, descripcion: "PROSPECT" }
]

export const assignedCommercialMock = [
  { nombreUsuario: "martin_britos", nombre: "Martin", apellido: "Britos" },
  { nombreUsuario: "trini_mamud", nombre: "Trinidad", apellido: "Mamud" }
]

export const quoterTemplateAdd = {
  // idCotizacion: 0,
  nombre: "AÉREO NY",
  textoCabecera: "Señores:\r\n\r\nDe mi mayor consideración...",
  modoTransporte: "AIR", // o "SEA"
  condiciones: "Tarifas válidas para...",
  terminos: "La presente cotización no incluye...",
  textoFinal: "Sin otro particular...",
  mostrarGastosOrigen: true,
  gastosOrigen: [
    { idGastoOrigen: null, textoLibre: "Gasto1", valor: "15 USD" },
    { idGastoOrigen: 11, textoLibre: "Gasto2", valor: "18 USD" }
  ],
  leyendaGastosOrigen: "",
  mostrarGastosBuenosAires: true,
  gastosBuenosAires: [
    { idGastoBuenosAires: null, textoLibre: "Gasto1", valor: "15 USD" },
    {
      idGastoBuenosAires: 11,
      textoLibre: "Gasto2",
      valor: "18 USD"
    }
  ],

  leyendaGastosBuenosAires: "",

  tablas: [
    {
      conTotalizador: false,
      leyendaTabla: "",
      columnas: ["AOL", "100 kg", "RUTA"],
      filas: [
        ["JFK (PAX)", "1.25", "JFK-EZE"],
        ["LGA (PAX)", "1.30", "LGA-EZE"]
      ],
      moneda: null,
      kgPorM3: null,
      peso: null,
      volumen: null,
      columnaCalculos: null,
      mostrarTotalGeneral: null
    },
    {
      conTotalizador: true,
      leyendaTabla: "",
      columnas: ["AOL", "105 kg", "RUTA"],
      filas: [
        ["JFK (PAX)", "1.25", "JFK-EZE"],
        ["LGA (PAX)", "1.30", "LGA-EZE"]
      ],
      moneda: "USD",
      kgPorM3: 157,
      peso: 123,
      volumen: 456,
      columnaCalculos: "105 kg",
      mostrarTotalGeneral: false
    }
  ]
}
export const quoterTemplateMock: IQuoteTemplate[] = [
  {
    idCotizacion: 18,
    nombre: "AÉREO NY",
    textoCabecera: "Señores:\r\n\r\nDe mi mayor consideración...",
    modoTransporte: "AIR",
    condiciones: "Tarifas válidas para...",
    terminos: "La presente cotización no incluye...",
    textoFinal: "Sin otro particular...",
    mostrarGastosOrigen: true,
    gastosOrigen: [
      { idGastoOrigen: null, textoLibre: "Gasto1", valor: "15 USD" },
      {
        idGastoOrigen: 11,
        textoLibre: "Gasto2",
        valor: "18 USD"
      }
    ],
    leyendaGastosOrigen: "",

    mostrarGastosBuenosAires: true,

    gastosBuenosAires: [
      { idGastoBuenosAires: null, textoLibre: "Gasto1", valor: "15 USD" },

      {
        idGastoBuenosAires: 11,
        textoLibre: "Gasto2",

        valor: "18 USD"
      }
    ],

    leyendaGastosBuenosAires: "",

    tablas: [
      {
        conTotalizador: false,

        leyendaTabla: "",

        columnas: ["AOL", "100 kg", "RUTA"],

        filas: [
          ["JFK (PAX)", "1.25", "JFK-EZE"],
          ["LGA (PAX)", "1.30", "LGA-EZE"]
        ],

        moneda: null,

        kgPorM3: null,

        peso: null,

        volumen: null,

        columnaCalculos: null,

        mostrarTotalGeneral: null
      },

      {
        conTotalizador: true,

        leyendaTabla: "",

        columnas: ["AOL", "105 kg", "RUTA"],

        filas: [
          ["JFK (PAX)", "1.25", "JFK-EZE"],
          ["LGA (PAX)", "1.30", "LGA-EZE"]
        ],

        moneda: "USD",

        kgPorM3: 157,

        peso: 123,

        volumen: 456,

        columnaCalculos: "105 kg",

        mostrarTotalGeneral: true
      }
    ]
  }
]

export const clientesEmptyMock: ICustomerData = {
  totalRegistros: 0,
  clientes: []
}

export const ORGANIZAIONS: IOrganizationOption[] = [
  {
    idOrganizacion: "1",
    descripcion: "Helen Chufe"
  },
  {
    idOrganizacion: "2",
    descripcion: "Zacarias Flores Del Campo"
  },
  {
    idOrganizacion: "3",
    descripcion: "Josefa Nática"
  },
  {
    idOrganizacion: "4",
    descripcion: "Lola Mento"
  },
  {
    idOrganizacion: "5",
    descripcion: "Helen Chufe 2"
  },
  {
    idOrganizacion: "6",
    descripcion: "Armando Bronca Segura"
  },
  {
    idOrganizacion: "7",
    descripcion: "Organizacion 7"
  },
  {
    idOrganizacion: "8",
    descripcion: "Organizacion 8"
  }
]
export const RadioOptionQuoter = [
  {
    label: TRANSLATION_INDEX_QUOTER.BY_THE_CUSTOMER,
    value: "CY"
  },
  {
    label: TRANSLATION_INDEX_QUOTER.BY_THE_SHIPPING_COMPANY,
    value: "SD"
  }
]

export const ContainerTypeHapagLloydMOCK = [
  {
    containerCode: "22GP",
    description: "20' General Purpose"
  },
  {
    containerCode: "42GP",
    description: "40' General Purpose"
  },
  {
    containerCode: "45GP",
    description: "40' General Purpose High Cube"
  },
  {
    containerCode: "45RT_NOR",
    description: "40' Non-operating Reefer"
  }
]

export const containerTypeMaerskMOCK = [
  {
    description: "20 Dry Standard",
    containerCode: "20 Dry Standard",
    group: "REGULAR SIZED CARGO"
  },
  {
    description: "40 Dry Standard",
    containerCode: "40 Dry Standard",
    group: "REGULAR SIZED CARGO"
  },
  {
    description: "40 Dry High",
    containerCode: "40 Dry High",
    group: "REGULAR SIZED CARGO"
  },
  {
    description: "45 Dry High",
    containerCode: "45 Dry High",
    group: "REGULAR SIZED CARGO"
  },
  {
    description: "20 Reefer Standard",
    containerCode: "20 Reefer Standard",
    group: "REEFER EQUIPMENT"
  },
  {
    description: "40 Reefer High",
    containerCode: "40 Reefer High",
    group: "REEFER EQUIPMENT"
  },
  {
    description: "20 Open Top",
    containerCode: "20 Open Top",
    group: "IN-GAUGE CARGO"
  },
  {
    description: "40 Open Top",
    containerCode: "40 Open Top",
    group: "IN-GAUGE CARGO"
  },
  {
    description: "40 Open Top High",
    containerCode: "40 Open Top High",
    group: "IN-GAUGE CARGO"
  },
  {
    description: "40 Flat Standard",
    containerCode: "40 Flat Standard",
    group: "IN-GAUGE CARGO"
  },
  {
    description: "40 Flat High",
    containerCode: "40 Flat High",
    group: "IN-GAUGE CARGO"
  },
  {
    description: "20 Tank",
    containerCode: "20 Tank",
    group: "MISCELLANEOUS"
  },
  {
    description: "40 Tank",
    containerCode: "40 Tank",
    group: "MISCELLANEOUS"
  },
  {
    description: "20 Flat",
    containerCode: "20 Flat",
    group: "MISCELLANEOUS"
  }
]

export const PortMOCK = [
  {
    unLocCode: "ARBUE",
    cityName: "Buenos Aires",
    regionName: "Buenos Aires",
    countryName: "Argentina"
  },
  {
    unLocCode: "CNSHA",
    cityName: "Shanghai",
    regionName: "Shanghai",
    countryName: "China"
  },
  {
    unLocCode: "HKHKG",
    cityName: "Hong Kong",
    regionName: "",
    countryName: "Hong Kong China"
  },
  {
    unLocCode: "CNTAO",
    cityName: "Qingdao",
    regionName: "Shandong",
    countryName: "China"
  },
  {
    unLocCode: "CNGGZ",
    cityName: "Guangzhou",
    regionName: "Guangdong",
    countryName: "China"
  },
  {
    unLocCode: "CNSHK",
    cityName: "Shekou",
    regionName: "Guangdong",
    countryName: "China"
  },
  {
    unLocCode: "CNYTN",
    cityName: "Yantian Free Trade Zone Guangdong",
    regionName: "Guangdong",
    countryName: "China"
  },
  {
    unLocCode: "CNNGB",
    cityName: "Ningbo",
    regionName: "Zhejiang",
    countryName: "China"
  },
  {
    unLocCode: "CNXIP",
    cityName: "Xingang",
    regionName: "Tianjin",
    countryName: "China"
  },
  {
    unLocCode: "CNXMN",
    cityName: "Xiamen",
    regionName: "Fujian",
    countryName: "China"
  },
  {
    unLocCode: "TWKHH",
    cityName: "Kaohsiung",
    regionName: "Taiwan",
    countryName: "China"
  },
  {
    unLocCode: "TWKEL",
    cityName: "Keelung",
    regionName: "",
    countryName: "Taiwan China"
  },
  {
    unLocCode: "KRPUS",
    cityName: "Busan",
    regionName: "",
    countryName: "Korea, South"
  },
  {
    unLocCode: "ITZHT",
    cityName: "Livorno Ferraris",
    regionName: "Vercelli",
    countryName: "Italy"
  },
  {
    unLocCode: "ESVLC",
    cityName: "Valencia",
    regionName: "",
    countryName: "Spain"
  },
  {
    unLocCode: "ESBCN",
    cityName: "Barcelona",
    regionName: "",
    countryName: "Spain"
  },
  {
    unLocCode: "ESBIO",
    cityName: "Bilbao",
    regionName: "",
    countryName: "Spain"
  },
  {
    unLocCode: "PTLEI",
    cityName: "Leixoes",
    regionName: "",
    countryName: "Portugal"
  },
  {
    unLocCode: "FRLEH",
    cityName: "Le Havre",
    regionName: "Seine-Maritime",
    countryName: "France"
  },
  {
    unLocCode: "NLRTM",
    cityName: "Rotterdam",
    regionName: "Zuid-Holland",
    countryName: "Netherlands"
  },
  {
    unLocCode: "ARROS",
    cityName: "Rosario",
    regionName: "Santa Fe",
    countryName: "Argentina"
  },
  {
    unLocCode: "UYMVD",
    cityName: "Montevideo",
    regionName: "",
    countryName: "Uruguay"
  }
]

export const companyMock = [
  {
    label: "Hapag Lloyd",
    value: "HLL"
  },
  {
    label: "Maersk",
    value: "MAEU"
  },
  {
    label: "Sealand Maersk",
    value: "SEAU"
  }
]
