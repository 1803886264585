// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getCustomsByIdFailure,
  getCustomsByIdSuccess,
  getCustomsFailure,
  getCustomsPlacesFailure,
  getCustomsPlacesSuccess,
  getCustomsSuccess,
  postCustomsImportFailure,
  postCustomsImportSuccess
} from "../actions"

import { getCustomById, getCustoms, getCustomsPlaces, postImport } from "../services"

function* getCustomsWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getCustoms, request.payload)
    yield put(getCustomsSuccess(data))
  } catch (error) {
    yield put(getCustomsFailure(error))
  }
}

function* getCustomsPlacesWorker(): SagaIterator {
  try {
    const { data } = yield call(getCustomsPlaces)
    yield put(getCustomsPlacesSuccess(data))
  } catch (error) {
    yield put(getCustomsPlacesFailure(error))
  }
}

function* getCustomByIdWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getCustomById, request.payload)
    yield put(getCustomsByIdSuccess(data))
  } catch (error) {
    yield put(getCustomsByIdFailure(error))
  }
}

function* postImportWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postImport, request.payload)
    yield put(postCustomsImportSuccess(data))
  } catch (error) {
    yield put(postCustomsImportFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.CUSTOMS_GET_REQUEST, getCustomsWorker)
  yield takeLatest(types.CUSTOMS_PLACES_GET_REQUEST, getCustomsPlacesWorker)
  yield takeLatest(types.CUSTOMS_BY_ID_GET_REQUEST, getCustomByIdWorker)
  yield takeLatest(types.CUSTOMS_POST_IMPORT_REQUEST, postImportWorker)
}

export const sagas = [listWatcher]
