/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_PERMISSION,
  TRANSLATION_INDEX_QUOTER
} from "../../config/translation"
import DataTable from "react-data-table-component"

import { ContainerTable } from "./_styles"
import { customStylesContext } from "../../styles/_styleDatatable"
import { useDispatch, useSelector } from "react-redux"
import SearchUserClientApp from "./forms/search/index"

import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { useMobile } from "../../hook/useMobile"
import { getReportClearAll, getReportRankingCarrierRequest } from "../../state/modules/reportCommercial/actions"
import { reportCommercialSelector } from "../../state/modules/reportCommercial/_selectors"
import IconExcel from "../../components/Icons/IconExcel"
import { type IReportCarrierRanking } from "../../interfaces/IReportCustomer"
import { getCountryRequest } from "../../state/modules/customer/actions"

const ReportCarrierRankingPage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const reportCustomer = useSelector(reportCommercialSelector)
  const [pagination, setPagination] = React.useState({ pagina: 1, registrosPorPagina: 10 })
  const [resetPage, setResetPage] = React.useState(false)
  const [dataFiltered, setDataFiltered] = React.useState<IReportCarrierRanking[]>()
  const [filters, setFilters] = React.useState<any>()
  const [clearFilter, setClearFilter] = React.useState(false)
  const [searchData, setSearchData] = React.useState(false)
  const { setLoadingContext, themeContext } = useGlobalContext()
  const { loadingContext } = useGlobalContext()

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: false,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }
  const getRequest = (): any => {
    return {
      anio: (filters?.anio && filters?.anio) > 0 ? filters?.anio : null,
      mesDesde: filters?.mesDesde?.value ?? null,
      mesHasta: filters?.mesHasta?.value ?? null,
      tipoOperacion: filters?.tipoOperacion?.value ?? null,
      modoTransporte: filters?.modoTransporte?.value ?? null,
      idsPaises: filters?.countries?.length > 0 ? filters?.countries?.map(item => item.value) ?? null : null,
      paginado: pagination
    }
  }
  function formatNumberWithCommas(number: number): string {
    return number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replaceAll(".", "--")
      .replaceAll(",", ".")
      .replaceAll("--", ",")
  }
  const columns = [
    {
      name: t(TRANSLATION_INDEX_QUOTER.COUNTRY),
      selector: (row: IReportCarrierRanking) => row?.pais ?? "",
      width: isMobile ? "60px" : "10%"
    },
    {
      name: "ORDEN",
      selector: (row: IReportCarrierRanking) => `${row.orden ?? ""}`,
      width: isMobile ? "200px" : "8%",
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.CARRIER),
      selector: (row: IReportCarrierRanking) => `${row.transportista ?? ""}`,
      width: isMobile ? "200px" : "22%"
    },

    {
      name: t(TRANSLATION_INDEX_QUOTER.WEIGHT),
      selector: (row: IReportCarrierRanking) => `${row.peso ? formatNumberWithCommas(Number(row.peso ?? 0)) : ""}`,
      width: isMobile ? "300px" : "15%",
      center: true
    },
    {
      name: "FLETE",
      selector: (row: IReportCarrierRanking) => `${row.flete ? formatNumberWithCommas(Number(row.flete ?? 0)) : ""}`,
      width: isMobile ? "300px" : "12%",
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.PACKS),
      selector: (row: IReportCarrierRanking) => `${row.bultos ? formatNumberWithCommas(Number(row.bultos ?? 0)) : ""}`,
      width: isMobile ? "300px" : "15%",
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.OPERATION_TYPE),
      selector: (row: IReportCarrierRanking) => `${row.tipoOperacion ?? ""}`,
      width: isMobile ? "300px" : "8%",
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE),
      selector: (row: IReportCarrierRanking) => `${row.modoTransporte ?? ""}`,
      width: isMobile ? "300px" : "8%",
      center: true
    }
  ]
  const handlePageChange = (page: number): void => {
    setPagination({ ...pagination, pagina: page })
  }
  const getData = (): void => {
    dispatch(getReportRankingCarrierRequest(getRequest()))
  }
  const handlePerRowsChange = (newPerPage: number, page: number): void => {
    setResetPage(true)
    setPagination({ registrosPorPagina: newPerPage, pagina: 1 })
  }
  const handleExcel = (): void => {
    let queryParam = ""
    if (filters.anio) queryParam = queryParam + `anio=${filters?.anio}&`
    if (filters.mesDesde) queryParam = queryParam + `mesDesde=${filters?.mesDesde.value}&`
    if (filters.mesHasta) queryParam = queryParam + `mesHasta=${filters?.mesHasta.value}&`
    if (filters.modoTransporte) queryParam = queryParam + `modoTransporte=${filters?.modoTransporte.value}&`
    if (filters.tipoOperacion) queryParam = queryParam + `tipoOperacion=${filters?.tipoOperacion.value}&`
    if (filters.countries && filters.countries.length > 0)
      queryParam = queryParam + `idsPaises=${filters?.countries?.map(item => item.value).join(",")}&`

    window.open(
      `${process.env.REACT_APP_API_URL}/api/backoffice/v1/reportes/rankingTotalesTransportistas/exportar?${queryParam}`
    )
  }

  React.useEffect(() => {
    setLoadingContext(reportCustomer?.loading)
    setDataFiltered(reportCustomer?.reportRankingCarrier?.transportistasRanking)
  }, [reportCustomer])

  React.useEffect(() => {
    if (searchData) {
      setLoadingContext(true)
      getData()
      setSearchData(false)
    }
  }, [searchData])

  React.useEffect(() => {
    if (clearFilter) {
      setDataFiltered([])
      dispatch(getReportClearAll())
      setClearFilter(false)
    }
  }, [clearFilter])

  React.useEffect(() => {
    dispatch(getCountryRequest())
    return () => dispatch(getReportClearAll())
  }, [])
  React.useEffect(() => {
    if (filters) getData()
  }, [pagination])

  React.useEffect(() => {
    if (filters) {
      setResetPage(true)
      setPagination({ ...pagination, pagina: 1 })
    }
  }, [filters])

  return (
    <>
      <ContainerPage
        title={`${t(TRANSLATION_INDEX_PERMISSION.REPORTE_RANKING_TRANSPORTISTAS)}`}
        actions={
          reportCustomer?.reportRankingCarrier &&
          reportCustomer?.reportRankingCarrier?.transportistasRanking?.length > 0
            ? [
                {
                  label: t(TRANSLATION_INDEX_GLOBAL.EXPORT),
                  icon: <IconExcel size={13} />,
                  permission: "*",
                  click: handleExcel
                }
              ]
            : []
        }
      >
        <>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <SearchUserClientApp
              data={reportCustomer?.reportRankingCarrier}
              setDataFiltered={setDataFiltered}
              setFilters={setFilters}
              setSearchData={setSearchData}
              setClearFilter={setClearFilter}
            />
            <DataTable
              data={dataFiltered ?? []}
              theme="solarized"
              noDataComponent={
                <NoData
                  label={
                    loadingContext
                      ? ""
                      : filters
                      ? TRANSLATION_INDEX_GLOBAL.NO_DATA_2
                      : TRANSLATION_INDEX_GLOBAL.USE_SEARCH_TO_GET_RESULTS
                  }
                />
              }
              columns={columns}
              dense
              pagination
              paginationComponentOptions={paginationComponentOptions}
              striped
              customStyles={customStylesContext(themeContext)}
              paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
              paginationServer
              paginationTotalRows={reportCustomer?.reportRankingCarrier?.totalRegistros ?? 0}
              paginationDefaultPage={1}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationResetDefaultPage={resetPage}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default ReportCarrierRankingPage
