/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { type IProps } from "./interfaces"
import ContainerIconLabel from "../../../../components/ContainerCheckIconLabel"
import { ItemList, List } from "../../_styles"
import { TRANSLATION_INDEX_PERMISSION } from "../../../../config/translation"
import { useTranslation } from "react-i18next"
import { PERMISSION_RELATIONAL } from "../../../../utils/constants"
import { useGlobalContext } from "../../../../context/GlobalContext"

const ContainerGroupItemList: React.FC<IProps> = ({
  handleAdd,
  handleDelete,
  permissionSelected,
  category,
  section,
  permission,
  label
}) => {
  const { t } = useTranslation()
  const { openAllCollansePermission, themeContext } = useGlobalContext()
  const [activeChildren, setActiveChilren] = React.useState<boolean>(false)
  const [collapse, setCollapse] = React.useState<boolean>(false)
  const permissionByCategory = permission.filter(item => item.categoria === category)
  const handleAddArray = (): void => {
    handleAdd(null, permissionByCategory)
  }
  React.useEffect(() => {
    if (openAllCollansePermission) {
      setCollapse(true)
    }
  }, [openAllCollansePermission])
  return (
    <List themeContext={themeContext}>
      <ItemList>
        <ContainerIconLabel
          active={
            PERMISSION_RELATIONAL.all[section]?.items[category]?.count ===
            permissionSelected.filter(item => item.group === section && item.categoria === category).length
          }
          label={label}
          handleAddArray={handleAddArray}
          setCollapse={setCollapse}
          activeCollanse={collapse}
          activeChildren={activeChildren}
          setChildren={setActiveChilren}
          marginLeft
        />
      </ItemList>
      {collapse &&
        permission
          .filter(item => item.categoria === category)
          .map(item => {
            return (
              <ItemList
                key={item.idPermiso}
                onClick={() => {
                  permissionSelected.some(per => per.idPermiso === item.idPermiso)
                    ? handleDelete(item)
                    : handleAdd(item)
                }}
              >
                <ContainerIconLabel
                  active={permissionSelected.some(per => per.idPermiso === item.idPermiso)}
                  label={t(TRANSLATION_INDEX_PERMISSION[item.codigo]) ?? ""}
                />
              </ItemList>
            )
          })}
    </List>
  )
}

export default ContainerGroupItemList
