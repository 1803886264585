import { customer } from "./_duck"
import * as types from "./types"

export const getTermsRequest = customer.createAction(types.TERMS_GET_REQUEST)
export const getTermsSuccess = customer.createAction(types.TERMS_GET_SUCCESS)
export const getTermsFailure = customer.createAction(types.TERMS_GET_FAILURE)

export const getCustomerRequest = customer.createAction(types.CUSTOMER_GET_REQUEST)
export const getCustomerSuccess = customer.createAction(types.CUSTOMER_GET_SUCCESS)
export const getCustomerFailure = customer.createAction(types.CUSTOMER_GET_FAILURE)

export const getCustomerExcelRequest = customer.createAction(types.CUSTOMER_EXCEL_GET_REQUEST)
export const getCustomerExcelSuccess = customer.createAction(types.CUSTOMER_EXCEL_GET_SUCCESS)
export const getCustomerExcelFailure = customer.createAction(types.CUSTOMER_EXCEL_GET_FAILURE)

export const getCustomerByFilterClear = customer.createAction(types.CUSTOMER_BY_FILTER_CLEAR)
export const getCustomerClear = customer.createAction(types.CUSTOMER_CLEAR)
export const getCustomerClearAll = customer.createAction(types.CUSTOMER_CLEAR_ALL)

export const getCustomerByFilterRequest = customer.createAction(types.CUSTOMER_BY_FILTER_GET_REQUEST)
export const getCustomerByFilterSuccess = customer.createAction(types.CUSTOMER_BY_FILTER_GET_SUCCESS)
export const getCustomerByFilterFailure = customer.createAction(types.CUSTOMER_BY_FILTER_GET_FAILURE)

export const getClientTypeRequest = customer.createAction(types.CLIENT_TYPE_GET_REQUEST)
export const getClientTypeSuccess = customer.createAction(types.CLIENT_TYPE_GET_SUCCESS)
export const getClientTypeFailure = customer.createAction(types.CLIENT_TYPE_GET_FAILURE)

export const getCountryRequest = customer.createAction(types.COUNTRY_GET_REQUEST)
export const getCountrySuccess = customer.createAction(types.COUNTRY_GET_SUCCESS)
export const getCountryFailure = customer.createAction(types.COUNTRY_GET_FAILURE)

export const getClassificationRequest = customer.createAction(types.CLASSIFICATION_GET_REQUEST)
export const getClassificationSuccess = customer.createAction(types.CLASSIFICATION_GET_SUCCESS)
export const getClassificationFailure = customer.createAction(types.CLASSIFICATION_GET_FAILURE)

export const getAssignedCommercialRequest = customer.createAction(types.ASSIGNED_COMMERCIAL_GET_REQUEST)
export const getAssignedCommercialSuccess = customer.createAction(types.ASSIGNED_COMMERCIAL_GET_SUCCESS)
export const getAssignedCommercialFailure = customer.createAction(types.ASSIGNED_COMMERCIAL_GET_FAILURE)

export const addCustomerRequest = customer.createAction(types.CUSTOMER_ADD_REQUEST)
export const addCustomerSuccess = customer.createAction(types.CUSTOMER_ADD_SUCCESS)
export const addCustomerFailure = customer.createAction(types.CUSTOMER_ADD_FAILURE)

export const putCustomerRequest = customer.createAction(types.CUSTOMER_PUT_REQUEST)
export const putCustomerSuccess = customer.createAction(types.CUSTOMER_PUT_SUCCESS)
export const putCustomerFailure = customer.createAction(types.CUSTOMER_PUT_FAILURE)

export const postBulkAssignmentRequest = customer.createAction(types.BULK_ASSIGMENT_POST_REQUEST)
export const postBulkAssignmentSuccess = customer.createAction(types.BULK_ASSIGMENT_POST_SUCCESS)
export const postBulkAssignmentFailure = customer.createAction(types.BULK_ASSIGMENT_POST_FAILURE)

export const postReBulkAssignmentRequest = customer.createAction(types.REBULK_ASSIGMENT_POST_REQUEST)
export const postReBulkAssignmentSuccess = customer.createAction(types.REBULK_ASSIGMENT_POST_SUCCESS)
export const postReBulkAssignmentFailure = customer.createAction(types.REBULK_ASSIGMENT_POST_FAILURE)

export const deleteCustomerRequest = customer.createAction(types.CUSTOMER_DELETE_REQUEST)
export const deleteCustomerSuccess = customer.createAction(types.CUSTOMER_DELETE_SUCCESS)
export const deleteCustomerFailure = customer.createAction(types.CUSTOMER_DELETE_FAILURE)

export const getCustomerByIdRequest = customer.createAction(types.CUSTOMER_BY_ID_GET_REQUEST)
export const getCustomerByIdSuccess = customer.createAction(types.CUSTOMER_BY_ID_GET_SUCCESS)
export const getCustomerByIdFailure = customer.createAction(types.CUSTOMER_BY_ID_GET_FAILURE)
