/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import { TRANSLATION_INDEX_MENU, TRANSLATION_INDEX_QUOTER } from "../../config/translation"

import { AlertMessage, ContainerTable } from "./_styles"
import { useDispatch, useSelector } from "react-redux"
import FormaddUpdate from "./forms/addUpdate/FormaddUpdate"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import {
  clearDataQuoter,
  getCommoditiesRequest,
  getInProccessRequest,
  getProccessInfoRequest,
  postExportRequest,
  postQuoterRequest
} from "../../state/modules/Quoter/actions"
import { quoterSelector } from "../../state/modules/Quoter/_selectors"
import AlertModal from "../../components/AlertModal"
import ReportQuote from "./forms/ReportQuote/ReportQuote"
import { toast } from "react-toastify"
import { BsDownload } from "react-icons/bs"

const QuoterPage = (): ReactElement => {
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [openModalInProccess, setOpenModalInProccess] = React.useState<boolean>(false)
  const [request, setRequest] = React.useState<any>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const quoter = useSelector(quoterSelector)

  const { setLoadingContext, themeContext } = useGlobalContext()

  const handleDispatch = (values): void => {
    setRequest(values)
    dispatch(getInProccessRequest())
  }
  const closeModal = (): void => {
    setOpenModal(false)
    setOpenModalInProccess(false)
    dispatch(clearDataQuoter())
  }

  const exportExcel = (): void => {
    dispatch(postExportRequest(quoter.data))
  }

  React.useEffect(() => {
    setLoadingContext(quoter.loading)
    if (quoter.data != null && !quoter.loading) setOpenModal(true)
  }, [quoter])

  React.useEffect(() => {
    let interval: any
    if (quoter.loadingQuoter && quoter.inProccess === 0)
      interval = setInterval(() => {
        dispatch(getProccessInfoRequest())
      }, 2500)
    if (quoter.data != null && !quoter.loadingQuoter) clearInterval(interval)
    return () => {
      clearInterval(interval)
    }
  }, [quoter.loadingQuoter, quoter.inProccess])

  React.useEffect(() => {
    dispatch(getCommoditiesRequest())
  }, [])

  React.useEffect(() => {
    if (quoter.showToast && quoter.hasErrMessage) toast.error(quoter.toastMessage)
  }, [quoter.showToast])

  React.useEffect(() => {
    if (quoter.inProccess === 1) {
      setOpenModalInProccess(true)
    } else if (quoter.inProccess === 0) {
      dispatch(postQuoterRequest(request))
    }
  }, [quoter.inProccess])

  return (
    <>
      <ContainerPage title={`${t(TRANSLATION_INDEX_MENU.QUOTE_GENERATION)}`} actions={[]}>
        <>
          {openModal && (
            <AlertModal
              title={t(TRANSLATION_INDEX_QUOTER.RESULTS)}
              closeModal={closeModal}
              maxWidth="100vw"
              activeCloseModal
              actions={[
                {
                  label: TRANSLATION_INDEX_QUOTER.EXPORT_EXCEL,
                  with: "400px",
                  icon: <BsDownload size={13} />,
                  click: exportExcel
                }
              ]}
            >
              <>
                <ReportQuote />
              </>
            </AlertModal>
          )}
          {openModalInProccess && (
            <AlertModal title="Alerta" closeModal={closeModal} maxWidth="350px" activeCloseModal>
              <AlertMessage>{t(TRANSLATION_INDEX_QUOTER.PROCESS_NOT)}</AlertMessage>
            </AlertModal>
          )}
          <ContainerTable themeContext={themeContext} opacity={1}>
            <FormaddUpdate accept={handleDispatch} />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default QuoterPage
