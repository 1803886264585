import { isStringEmpty } from "../utils/functions"
import httpModule from "../utils/http"

const CarrierService = async (inputValue, comapny = ""): Promise<any[]> => {
  const request = {
    ordenamiento: { columna: 1, direccion: "asc" },
    paginado: {
      pagina: 1,
      registrosPorPagina: 310
    },
    filtro: {
      tipo: "null",
      modoTransporte: "null",
      idTipoCliente: "null",
      paises: null,
      clientes: null,
      lugar: null,
      anio: null,
      mesDesde: null,
      mesHasta: null,
      sinComercialAsignado: null,
      comercialAsignado: null,
      transportista: inputValue
    }
  }
  return await httpModule.post(`/api/backoffice/v1/aduana/transportistas`, request).then(res => {
    return res.data.transportistas
      .filter(item => isStringEmpty(comapny) || item.razonSocial.toLowerCase() !== comapny.toLowerCase())
      .map(item => {
        return { value: item, label: item }
      })
  })
}

export default CarrierService
