/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP } from "../../../../config/translation"

import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { STATES } from "../../../../utils/constants"
import { ContainerInputs, ContainerSerch } from "./_styles"
import InputCustom from "../../../../components/InputCustom"
import SelectCustom from "../../../../components/SelectCustom"
import ButtonCustom from "../../../../components/ButtonCustom"
import { InfoCustom } from "../../../../components/InfoCustom"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { filterData } from "../../functios"
import { useMobile } from "../../../../hook/useMobile"
import { useGlobalContext } from "../../../../context/GlobalContext"

const SearchUserBackoffice = ({
  setDataFiltered = () => {},
  data,
  values,
  errors,
  setFilters,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const { themeContext } = useGlobalContext()
  const clearFilter = (): void => {
    if (handleReset != null) {
      handleReset()
    }
  }

  const handleSearch = (): void => {
    if (setFilters) setFilters(values)
    setDataFiltered(filterData(data, values))
  }

  React.useEffect(() => {
    handleSearch()
  }, [values])

  return (
    <ContainerSerch>
      <ContainerInputs>
        <InputCustom
          value={values?.search ?? ""}
          handleChange={handleChange}
          name="search"
          iconLeft
          placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.SEARCH_USER) ?? ""}
          width={isMobile ? "100%" : "350px"}
          validError={false}
        />
        {!isMobile && (
          <InfoCustom
            info={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH)} ${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.USER_NAME
            )}, ${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.NAME)}, ${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.LAST_NAME
            )}, ${t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.EMAIL)}, ${t(
              TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.PROFILE
            )}.`}
            color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            value={values?.estado ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="estado"
            placeHolder={t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.STATE) ?? ""}
            width={isMobile ? "100%" : "350px"}
            marginLeft
            validError={false}
            options={STATES.filter(item => (item?.value ?? 2) !== 2).map(item => {
              return { ...item, label: t(item.label).toUpperCase() }
            })}
          />
        )}
      </ContainerInputs>
      <ButtonCustom
        onClick={() => {
          clearFilter()
        }}
        label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
        backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
        background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
        width={isMobile ? "100%" : "100px"}
        marginLeft
      />
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserBackoffice)
