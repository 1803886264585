import React, { type ReactElement } from "react"
import { IoMdArrowDropdown } from "react-icons/io"
import { Container, ContainerMenu, Icon, UserMenu } from "./_styles"

import { getAuth } from "../../../../utils/functions"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import Logout from "../Logout"
import Language from "../Language"
import Theme from "../Theme"
import { useGlobalContext } from "../../../../context/GlobalContext"
import Notification from "../Notification"

const UserContainer = (): ReactElement => {
  const [active, setActive] = React.useState(false)
  const [, userName] = getAuth("*")
  const { themeContext } = useGlobalContext()
  return (
    <Container themeContext={themeContext}>
      <UserMenu
        themeContext={themeContext}
        onMouseEnter={() => {
          setActive(true)
        }}
        onMouseLeave={() => {
          setActive(false)
        }}
        background={
          active ? GLOBAL_STYLES.BACKGROUND.TYPE_8[themeContext] : GLOBAL_STYLES.BACKGROUND.TYPE_3[themeContext]
        }
      >
        {userName}
        <Icon>
          <IoMdArrowDropdown fontSize={20} />
        </Icon>
        {active && (
          <ContainerMenu themeContext={themeContext}>
            <Language />
            <Theme />
            <Notification />
            <Logout />
          </ContainerMenu>
        )}
      </UserMenu>
    </Container>
  )
}

export default UserContainer
