/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerSerch } from "./_styles"
import InputCustom from "../../../../components/InputCustom"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_QUOTER } from "../../../../config/translation"
import { useMobile } from "../../../../hook/useMobile"
import SelectCustom from "../../../../components/SelectCustom"

const SearchUserClientApp = ({
  setDataFiltered = () => {},
  data,
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const handleSearch = (): void => {
    // TODO: hacer dispatch de busqueda
    let newList = data
    if (values?.nombre) newList = newList.filter(item => item?.nombre?.toLowerCase().includes(values.nombre))
    if (values?.modoTransporte)
      newList = newList.filter(
        item => item?.modoTransporte?.toUpperCase() === values?.modoTransporte?.value?.toUpperCase()
      )

    setDataFiltered(newList)
  }

  React.useEffect(() => {
    handleSearch()
  }, [values])

  return (
    <ContainerSerch>
      <InputCustom
        label={t(TRANSLATION_INDEX_QUOTER.NAME) ?? ""}
        value={values?.nombre ?? ""}
        handleChange={handleChange}
        name="nombre"
        placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(TRANSLATION_INDEX_QUOTER.NAME)}` ?? ""}
        width={isMobile ? "100%" : "300px"}
        iconLeft
      />
      {setFieldValue != null && (
        <SelectCustom
          label={t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE) ?? ""}
          value={values?.modoTransporte ?? null}
          iconLeft
          handleChange={setFieldValue}
          name="modoTransporte"
          placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
          width={isMobile ? "100%" : "25%"}
          marginLeft
          validError={false}
          options={[
            { label: t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase() ?? "", value: "AIR" },
            {
              label: `${t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase()}+${t(
                TRANSLATION_INDEX_QUOTER.SEA
              ).toUpperCase()}`,
              value: "AIR+SEA"
            },
            { label: t(TRANSLATION_INDEX_QUOTER.ROA).toUpperCase() ?? "", value: "ROA" },
            { label: t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase() ?? "", value: "SEA" }
          ].sort((a, b) => (a.label > b.label ? 1 : -1))}
        />
      )}
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserClientApp)
