import { authorization } from "./_duck"
import * as types from "./types"

export const getAuthorizationRequest = authorization.createAction(types.AUTHORIZATION_GET_REQUEST)
export const getAuthorizationSuccess = authorization.createAction(types.AUTHORIZATION_GET_SUCCESS)
export const getAuthorizationFailure = authorization.createAction(types.AUTHORIZATION_GET_FAILURE)

export const patchAuthorizationRequest = authorization.createAction(types.AUTHORIZATION_PATCH_REQUEST)
export const patchAuthorizationSuccess = authorization.createAction(types.AUTHORIZATION_PATCH_SUCCESS)
export const patchAuthorizationFailure = authorization.createAction(types.AUTHORIZATION_PATCH_FAILURE)

export const postAuthorizationRequest = authorization.createAction(types.AUTHORIZATION_POST_REQUEST)
export const postAuthorizationSuccess = authorization.createAction(types.AUTHORIZATION_POST_SUCCESS)
export const postAuthorizationFailure = authorization.createAction(types.AUTHORIZATION_POST_FAILURE)

export const deleteAuthorizationRequest = authorization.createAction(types.AUTHORIZATION_DELETE_REQUEST)
export const deleteAuthorizationSuccess = authorization.createAction(types.AUTHORIZATION_DELETE_SUCCESS)
export const deleteAuthorizationFailure = authorization.createAction(types.AUTHORIZATION_DELETE_FAILURE)
