// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import { getRatingsFailure, getRatingsSuccess } from "../actions"

import { getRatings } from "../services"

function* getRatingsWorker(): SagaIterator {
  try {
    const { data } = yield call(getRatings)
    yield put(getRatingsSuccess(data))
  } catch (error) {
    yield put(getRatingsFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.RATINGS_GET_REQUEST, getRatingsWorker)
}

export const sagas = [listWatcher]
