/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import ButtonCustom from "../../../../components/ButtonCustom"
import { useTranslation } from "react-i18next"
import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_QUOTER
} from "../../../../config/translation"
import {
  Container,
  Group,
  GroupButtom,
  ContainerGroup,
  ContainerMessage,
  Label,
  Input,
  ContainerAll,
  ContainerScroll
} from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS, FORMAT_DATE_ONLY, INPUT_TYPE, STATES_STEP, STATES_YES_NO } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"
import { useGlobalContext } from "../../../../context/GlobalContext"
import InputCustom from "../../../../components/InputCustom"
import SelectCustom from "../../../../components/SelectCustom"
import { t } from "i18next"
import IconPlus from "../../../../components/Icons/IconPlus"
import { TooltipCustom } from "../../../../components/TooltipCustom"
import ReactHtmlParser from "react-html-parser"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { getAuth, isStringEmpty } from "../../../../utils/functions"
import IconTrash from "../../../../components/Icons/IconTrash"
import { useMobile } from "../../../../hook/useMobile"
import { format } from "date-fns"
import { DatePickerCustom } from "../../../../components/DatePickerCustom/DatePickerCustom/Index"
import { userBackofficeSelector } from "../../../../state/modules/userBackOffice/_selectors"
import { customerSelector } from "../../../../state/modules/customer/_selectors"
import { formatNumberToCalc, formatNumberToTable, formattedNumber } from "../../functions"
import { type IOption } from "../../../../interfaces/IOption"
import TableOriginExpensive from "./TableOriginExpensive"
import TableBAExpensive from "./TableBAExpensive"
import RichTextCustom from "../../../../components/RichTextCustom"

const FormaddUpdate = ({
  data,
  setDataFiltered,
  setFilters,
  isUserPending = false,
  loading = false,
  isSend = false,
  noEdit = false,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  validateForm,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  stateStep,
  setStateStep,
  handleSubmit
}: IFormListProps): ReactElement => {
  const fontSiseToHtml ="0.8rem"
  const [validForm, setValidForm] = React.useState(false)
  const customer = useSelector(customerSelector)
  const [errorDate, setErrorDate] = React.useState(false)
  const showButton = !(isSend || noEdit)
  const isMobile = useMobile()
  const { t } = useTranslation()
  const userBackoffice = useSelector(userBackofficeSelector)
  const [renderHtml, setRenderHtml] = React.useState(true)
  const { themeContext } = useGlobalContext()
  const [fromDate, setFromDate] = React.useState<string | Date | null>(
    data?.vigenciaDesde ? new Date(data?.vigenciaDesde) : null
  )
  const [toDate, setToDate] = React.useState<string | Date | null>(
    data?.vigenciaHasta ? new Date(data?.vigenciaHasta) : null
  )

  const getDataToSend = (): any => {
    const htmlInner = document.querySelector("#container-preview")?.innerHTML
    if (typeof htmlInner !== "undefined")
      if (setFilters) {
        setFilters(htmlInner.replaceAll("&quot;", ""))
      }

    const response = {
      idCotizacion: null,
      nombre: values?.nombre,
      asuntoEmail: values?.asuntoEmail,
      vigenciaDesde: new Date(values?.vigenciaDesde).getTime(),
      vigenciaHasta: new Date(values?.vigenciaHasta).getTime(),
      textoCabecera: values?.textoCabecera,
      modoTransporte: values?.modoTransporte?.value,
      procedencias: values?.procedencias?.map(item=> item.value) ?? null,
      destinos: values?.destinos?.map(item=> item.value) ?? null,
      condiciones: values?.condiciones,
      terminos: values?.terminos,
      textoFinal: values?.textoFinal,
      fechaActualizacion: values?.fechaActualizacion,
      usuarioActualizacion: values?.usuarioActualizacion,
      tablas: values?.tablas?.map(table => {
        return {
          ...table,
          mostrarGastosOrigen: table?.gastosOrigen.length > 0 ? table?.mostrarGastosOrigen?.value : false,
          mostrarGastosBuenosAires: table?.gastosBuenosAires.length > 0 ? table?.mostrarGastosBuenosAires?.value : false,
          leyendaGastosOrigen: table?.leyendaGastosOrigen,
          leyendaGastosBuenosAires: table?.leyendaGastosBuenosAires,
          gastosBuenosAires: table?.mostrarGastosBuenosAires?.value
          ? table.gastosBuenosAires
              .filter(item => !isStringEmpty(item.textoLibre))
              .map(item => {
                return {
                  idGastoBuenosAires: item?.idGastoBuenosAires,
                  textoLibre: item.textoLibre,
                  valor: item.valor
                }
              })
          : [],
          gastosOrigen: table?.mostrarGastosOrigen?.value
          ? table?.gastosOrigen
              .filter(item => !isStringEmpty(item.textoLibre))
              .map(item => {
                return {
                  idGastoOrigen: item?.idGastoOrigen,
                  textoLibre: item.textoLibre,
                  valor: item.valor
                }
              })
          : [],
          kgPorM3: table.kgPorM3 ? table.kgPorM3.toString().replaceAll(".", "").replaceAll(",", ".") : null,
          peso: table.peso ? table.peso.toString().replaceAll(".", "").replaceAll(",", ".") : null,
          volumen: table.volumen ? table.volumen.toString().replaceAll(".", "").replaceAll(",", ".") : null,
          moneda: table.moneda?.value,
          columnaCalculos: table.columnaCalculos?.value,
          mostrarTotalGeneral: table.mostrarTotalGeneral?.value,
          columnas: table.columnas.map(col => {
            return col?.value
          }),
          filas: table.filas.map(fil => {
            return fil?.value.map(filCol => {
              return filCol?.value
            })
          })
        }
      })
    }
    return response
  }

  const handleAddTable = (withTotal = false): void => {
    const newTable = {
      id: values?.tablas?.length ?? 0,
      gastosOrigen: [],
      gastosBuenosAires: [],
      leyendaGastosOrigen:"",
      leyendaGastosBuenosAires:"",
      titulo:"",
      mostrarGastosOrigen: {label: 'NO', value: false},
      mostrarGastosBuenosAires: {label: 'NO', value: false},
      conTotalizador: withTotal,
      leyendaTabla: "",
      columnas: [
        { value: "", id: 0 },
        { value: "", id: 1 },
        { value: "", id: 2 },
        { value: "", id: 3 }
      ],
      filas: [
        {
          value: [
            {
              value: "",
              id: 0
            },
            {
              value: "",
              id: 1
            },
            {
              value: "",
              id: 2
            },
            {
              value: "",
              id: 3
            }
          ],
          id: 0
        }
      ],
      moneda: "",
      kgPorM3: "167,00",
      peso: null,
      volumen: null,
      columnaCalculos: null,
      mostrarTotalGeneral: null
    }
    if (setFieldValue) setFieldValue("tablas", [...values?.tablas, newTable])
    if (values?.tablas.length > 0) {
      const getMeTo = document.getElementById(`tableEditTotal-${values?.tablas[values.tablas.length - 1].id}`)
      getMeTo?.scrollIntoView({ behavior: "smooth" })
    }
  }

  const handleChangeTableCols = (table, col, value): void => {
    const newTables = values?.tablas?.map(item => {
      return {
        ...item,
        columnas: item.columnas.map((colRow, index) => {
          if (item?.id === table && index === col) {
            if (colRow.value === item?.columnaCalculos?.value) toast.error(t(TRANSLATION_INDEX_QUOTER.COLUMN_TO_CALC))
            else colRow.value = value
          }
          return colRow
        })
      }
    })
    if (setFieldValue) setFieldValue("tablas", newTables)
  }

  const handleBlurTableCols = (table, col, value, idHtml): void => {
    const colums = values?.tablas
      .find(tab => tab.id === table)
      .columnas.filter(colu => colu.id !== col && !isStringEmpty(colu.value))
    if (colums.some(colu => colu.value.toUpperCase() === value.toUpperCase())) {
      toast.error(t(TRANSLATION_INDEX_QUOTER.COLUMN_EXIST))
      const element = document.getElementById(idHtml)
      element?.focus()
      element?.focus({})
    }
  }

  const handleChangeTableRows = (table, col, fil, value, isNumber = false): void => {
    const newTables = values?.tablas?.map(item => {
      return {
        ...item,
        filas: item.filas.map(row => {
          if (row?.id === col && item?.id === table)
            row.value = row?.value.map(rowCol => {
              if (rowCol?.id === fil) rowCol.value = isNumber ? formattedNumber(value) : value
              return rowCol
            })
          return row
        })
      }
    })
    if (setFieldValue) setFieldValue("tablas", newTables)
  }

  const handleAddRowTable = (table): void => {
    const newData = values?.tablas?.map(item => {
      if (table === item?.id) {
        item.filas = [
          ...item.filas,
          {
            id: item?.filas?.length ?? 0,
            value: item.columnas.map(col => {
              return { value: "", id: col?.id }
            })
          }
        ]
      }
      return item
    })
    if (setFieldValue) setFieldValue("tablas", newData)
  }

  const handleDeleteRowTable = (table, row,code="Enter"): void => {
    if(code==="Enter"){
      const newData = values?.tablas?.map(item => {
        if (table === item?.id) {
          item.filas = item.filas.filter(rowCol => rowCol?.id !== row)
        }
        return item
      })
      if (setFieldValue) setFieldValue("tablas", newData)
    }
  }

  const handleDeleteTable = (tableId): void => {
    const newData = values?.tablas?.filter(item => item?.id !== tableId)
    if (setFieldValue) setFieldValue("tablas", newData)
  }

  const handleDeleteColumn = (table, columnId,code="Enter"): void => {
    if(code==="Enter"){
    const newData = values?.tablas?.map(item => {
      if (table === item?.id) {
        if (item.columnas.find(colum => colum.id === columnId)?.value === item?.columnaCalculos?.value) {
          toast.error(t(TRANSLATION_INDEX_QUOTER.COLUMN_FOR_CALCULATIONS))
        } else {
          item.columnas = item.columnas.filter(colum => colum.id !== columnId)
          item.filas = item.filas.map(row => {
            row.value = row.value.filter(colum => colum.id !== columnId)
            row.value = row.value.map((colum, index) => {
              colum.id = index
              return colum
            })
            return row
          })
          item.columnas = item.columnas.map((colum, index) => {
            colum.id = index
            return colum
          })
        }
      }
      return item
    })
    if (setFieldValue) setFieldValue("tablas", newData)
  }
  }

  const handleAddColumn = (table,code="Enter"): void => {
    if(code==="Enter"){
      const newData = values?.tablas?.map(item => {
        if (table === item?.id) {
          item.columnas = [...item.columnas, { value: "", id: Number(item.columnas.length) }]
          item.filas = item.filas.map(row => {
            row.value = [...row.value, { id: Number(item.columnas.length), value: "" }]
            return row
          })
        }
        return item
      })
      if (setFieldValue) setFieldValue("tablas", newData)
    }
  }

  const handleChangeTableInputNumber = (tableId, prop, value): void => {
    const newTable = values?.tablas?.map(table => {
      if (table?.id === tableId) table[prop] = formattedNumber(value)
      return table
    })
    if (setFieldValue) setFieldValue("tablas", newTable)
  }

  const handleChangeTableInput = (tableId, prop, value): void => {
    const newTable = values?.tablas?.map(table => {
      if (table?.id === tableId) table[prop] = value
      return table
    })
    if (setFieldValue) setFieldValue("tablas", newTable)
  }

  const handleChangeTableRichText = (name, value): void => {
    const valueTable = name.split(".")

    const newTable = values?.tablas?.map(table => {
      if (table?.id === Number(valueTable[0])) table[valueTable[1]] = value
      return table
    })
    if (setFieldValue) setFieldValue("tablas", newTable)
  }

  const handleChangeTableSelect = (tableIdProp, value): void => {
    const data = tableIdProp.split(".")
    let isNumericAllFields = true

    if (data[1] === "columnaCalculos") {
      const newTable = values?.tablas?.find(table => table?.id === Number(data[0]))
      const filas = newTable.filas
      const columnas = newTable.columnas

      if (value) {
        const columnId = columnas.find(tableColId => tableColId?.value === value?.value)?.id
        filas.forEach(element => {
          if (isNaN(element.value[columnId]?.value.replaceAll(".", "").replaceAll(",", "."))) isNumericAllFields = false
          else element.value[columnId].value = formatNumberToTable(element.value[columnId]?.value)
        })
      }
      if (isNumericAllFields) {
        const newTable = values?.tablas?.map(table => {
          if (table?.id === Number(data[0])) table[data[1]] = value
          return table
        })
        if (setFieldValue) setFieldValue("tablas", newTable)
      } else {
        toast.error(t(TRANSLATION_INDEX_QUOTER.COLUMN_ERROR_NUMERIC))
      }
    } else {
      const newTable = values?.tablas?.map(table => {
        if (table?.id === Number(data[0])) table[data[1]] = value
        return table
      })
      if (setFieldValue) setFieldValue("tablas", newTable)
    }
  }

  React.useEffect(() => {
    if (!renderHtml) setRenderHtml(true)
  }, [renderHtml])
  
  React.useEffect(() => {
    if (!renderHtml) {
      setRenderHtml(true)
    } else {
      setRenderHtml(false)
    }
    if (setDataFiltered && isSend) setDataFiltered(getDataToSend())
  }, [values])


  React.useEffect(() => {
    if (setFieldValue) {
      setFieldValue("vigenciaDesde", fromDate)
      setFieldValue("vigenciaHasta", toDate)
      if (fromDate && toDate && touched.nombre === true) {
        if (
          (fromDate && typeof fromDate === "object" ? fromDate?.getTime() : 0) >
          (toDate && typeof toDate === "object" ? toDate?.getTime() : 0)
        )
          setErrorDate(true)
        else setErrorDate(false)
      } else setErrorDate(false)
    }
  }, [fromDate, toDate, touched.nombre])

  React.useEffect(() => {
    if (touched.nombre === true && Object.keys(errors ?? {}).length > 0) validateForm()
  }, [values])

  React.useEffect(() => {
    if(stateStep===STATES_STEP.VALID_DATA){
      validateForm()
      setValidForm(true)

    }
  }, [stateStep])
  
  React.useEffect(() => {
    if(validForm &&setStateStep){
      setValidForm(false)
      if(Object.keys(errors??{}).length>0 ){
        setStateStep(STATES_STEP.ERROR)  
      }else
        setStateStep(STATES_STEP.SUCCESS)
    }
  }, [errors,validForm])

  React.useEffect(() => {
    // let newDestino = values?.destino
    // if (newDestino) {
    //   newDestino = {
    //     value: newDestino.value,
    //     label: customer.countries.find(country => country.idPais === newDestino.value)?.descripcion
    //   }
    //   if (setFieldValue) setFieldValue("destino", newDestino)
    // }
    // let newProcedencia = values?.procedencia
    // if (newProcedencia) {
    //   newProcedencia = {
    //     value: newProcedencia.value,
    //     label: customer.countries.find(country => country.idPais === newProcedencia.value)?.descripcion
    //   }
    //   if (setFieldValue) setFieldValue("procedencia", newProcedencia)
    // }
    
    let newDestinos = values?.destinos
    if (newDestinos) {
      newDestinos = newDestinos.map(item=> {
        return {
          value: item.value,
          label: customer.countries.find(country => country.idPais === item.value)?.descripcion
        }
      }) 
      if (setFieldValue) setFieldValue("destinos", newDestinos)
    }
    let newProcedencias = values?.procedencias
    if (newProcedencias) {
      newProcedencias = newProcedencias.map(item=> {
        return {
          value: item.value,
          label: customer.countries.find(country => country.idPais === item.value)?.descripcion
        }
      }) 
      if (setFieldValue) setFieldValue("procedencias", newProcedencias)
    }
  }, [])

  return (
    <Container onSubmit={handleSubmit}>
      {action === ACTIONS.DELETE.VALUE ? (
        <ContainerMessage themeContext={themeContext}>{t(TRANSLATION_INDEX_GLOBAL.CONFIRM_DELETE)}</ContainerMessage>
      ) : (
        <ContainerAll>
          {!noEdit && (
            <ContainerGroup width={isMobile ? "100%" : "40%"} themeContext={themeContext}>
              <ContainerScroll>
                <div style={{ marginBottom: "15px", textAlign: "center" }}>
                  <b>{t(TRANSLATION_INDEX_QUOTER.VAR_USE)}</b>
                </div>
                <div style={{ marginBottom: "10px", width: "100%" }}>
                  <b>@RAZON_SOCIAL@</b>: {t(TRANSLATION_INDEX_QUOTER.VAR_COMPANY)}
                </div>
                <div style={{ marginBottom: "15px", width: "100%" }}>
                  <b>@NOMBRE_USUARIO@</b>: {t(TRANSLATION_INDEX_QUOTER.VAR_USER)}
                </div>
                <Group themeContext={themeContext}>
                  <InputCustom
                    label={`${t(TRANSLATION_INDEX_QUOTER.QUOTE_NAME) ?? ""} (*)`}
                    value={values?.nombre}
                    handleChange={handleChange}
                    name="nombre"
                    errors={errors?.nombre}
                    touched={touched?.nombre}
                  />
                </Group>
                <Group themeContext={themeContext}>
                  <InputCustom
                    label={`${t(TRANSLATION_INDEX_QUOTER.SUBJECT) ?? ""} (*)`}
                    value={values?.asuntoEmail}
                    handleChange={handleChange}
                    name="asuntoEmail"
                    errors={errors?.asuntoEmail}
                    touched={touched?.asuntoEmail}
                  />
                </Group>
                <Group themeContext={themeContext}>
                  <DatePickerCustom
                    disabled={isSend}
                    value={fromDate}
                    placeHolder={t(TRANSLATION_INDEX_QUOTER.VALID_SINCE) ?? ""}
                    label={`${t(TRANSLATION_INDEX_QUOTER.VALID_SINCE)} (*)` ?? ""}
                    setValue={setFromDate}
                    errors={errors?.vigenciaDesde}
                    name="vigenciaDesde"
                    validError
                    dateFormat="dd/MM/yyyy"
                    width={isMobile ? "100%" : "calc(50% )"}
                  />
                  <DatePickerCustom
                    disabled={isSend}
                    value={toDate}
                    placeHolder={t(TRANSLATION_INDEX_QUOTER.VALID_UNTIL) ?? ""}
                    label={`${t(TRANSLATION_INDEX_QUOTER.VALID_UNTIL)} (*)` ?? ""}
                    setValue={setToDate}
                    name="vigenciaHasta"
                    dateFormat="dd/MM/yyyy"
                    errors={errorDate ? TRANSLATION_INDEX_GLOBAL.ERROR_DATE : errors?.vigenciaHasta}
                    validError
                    marginLeft
                    width={isMobile ? "100%" : "50%"}
                  />
                </Group>
                {setFieldValue != null && (
                  <Group themeContext={themeContext}>
                    <SelectCustom
                      name="modoTransporte"
                      label={t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE) ?? ""}
                      value={values?.modoTransporte}
                      handleChange={setFieldValue}
                      options={[
                        { label: t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase() ?? "", value: "AIR" },
                        { label: t(TRANSLATION_INDEX_QUOTER.ROA).toUpperCase() ?? "", value: "ROA" },
                        { label: `${t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase()}+${t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase()}`, value: "AIR+SEA" },
                        { label: t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase() ?? "", value: "SEA" }
                      ].sort(((a:IOption, b:IOption) => a.label > b.label? 1 : -1))}
                    />
                  </Group>
                )}
                 <Group themeContext={themeContext}>
                  {setFieldValue != null && (
                    <Group themeContext={themeContext}>
                      <SelectCustom
                        name="procedencias"
                        isMulti
                        label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ORIGINS_2) ?? ""} (*)`}
                        value={values?.procedencias}
                        handleChange={setFieldValue}
                        options={customer.countries.map(item => {
                          return { label: item?.descripcion.toUpperCase(), value: item.idPais }
                        })}
                        errors={errors?.procedencias}
                      />
                    </Group>
                  )}                 
                </Group>
                <Group themeContext={themeContext}>
                {setFieldValue != null && (
                    <Group themeContext={themeContext}>
                      <SelectCustom
                        name="destinos"
                        
                        isMulti
                        label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.DESTINATIONS_2) ?? ""} (*)`}
                        value={values?.destinos}
                        handleChange={setFieldValue}
                        options={customer.countries.map(item => {
                          return { label: item?.descripcion.toUpperCase(), value: item.idPais }
                        })}
                        errors={errors?.destinos}
                      />
                    </Group>
                  )}                
                </Group>
                {
                  setFieldValue &&
                    <Group themeContext={themeContext}>
                      <RichTextCustom                      
                        label={t(TRANSLATION_INDEX_QUOTER.HEADER_TEXT) ?? ""}
                        value={values?.textoCabecera}
                        handleChange={setFieldValue}
                        height="100px"
                        name="textoCabecera"
                        />
                    </Group>
                  }
                
                <Group themeContext={themeContext}>
                  <hr style={{ width: "100%", margin: "15px 0", border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }} />
                </Group>
                <Group themeContext={themeContext}>
                  <div style={{ width: "auto" }}>{t(TRANSLATION_INDEX_QUOTER.ADD_QUOTE)}</div>

                  <ButtonCustom
                    label={t(TRANSLATION_INDEX_QUOTER.WO_TOTAL) ?? ""}
                    marginLeft
                    type="button"
                    onClick={() => {
                      handleAddTable()
                    }}
                    width="120px"
                    background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                    backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                  />
                  <ButtonCustom
                    label={t(TRANSLATION_INDEX_QUOTER.W_TOTAL) ?? ""}
                    width="120px"
                    type="button"
                    marginLeft
                    onClick={() => {
                      handleAddTable(true)
                    }}
                    background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                    backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                  />
                </Group>
                {values?.tablas &&
                  values.tablas?.map(item => {
                    return (
                      <>
                        <Group themeContext={themeContext}>
                          <hr
                            style={{
                              width: "100%",
                              margin: "15px 0",
                              border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext]
                            }}
                          />
                        </Group>
                        <Group
                          style={{
                            padding: "10px 0"
                          }}
                          themeContext={themeContext}
                          key={item?.id}
                        >
                          <div
                            style={{
                              display: "block",
                              width: "calc(100%)"
                            }}
                          >
                            <div style={{ margin: "10px 0", width: "calc(100% - 15px)" }}>
                              <Label themeContext={themeContext} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}>
                                {t(TRANSLATION_INDEX_QUOTER.TITLE)}
                              </Label>
                              <Input
                                autoComplete="off"
                                borderRadius="5px"
                                onKeyDown={(e) => {
                                  if (e?.key === "Enter") e.preventDefault()
                                }}
                                themeContext={themeContext}
                                type={INPUT_TYPE.TEXT}
                                border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
                                value={item.titulo}
                                onChange={e => {
                                  handleChangeTableInput(item?.id, "titulo", e.target?.value)
                                }}
                              />
                            </div>
                            <table
                              style={{
                                width: "100%",
                                overflowX: "scroll"
                              }}
                            >
                              <thead>
                                <tr>
                                  {item.columnas.map(col => {
                                    return (
                                      <th key={col?.id}>
                                        <TooltipCustom value={t(TRANSLATION_INDEX_QUOTER.DELETE_COL)} width="80px">
                                          <div
                                            onClick={() => {
                                              handleDeleteColumn(item?.id, col?.id)
                                            }}
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                              handleDeleteColumn(item?.id, col?.id,e.key)
                                            }}
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "center",
                                              alignItems: "center"
                                            }}
                                          >
                                            <IconTrash color={GLOBAL_STYLES.BUTTON.DANGER_BG[themeContext]} size={20} />
                                          </div>
                                        </TooltipCustom>
                                      </th>
                                    )
                                  })}
                                </tr>
                              </thead>
                              <thead>
                                <tr>
                                  {item.columnas.map(col => {
                                    const idInput = `inputColumn-${item.id}-${col.id}`
                                    return (
                                      <th key={col?.id}>
                                        <Input
                                          autoComplete="off"
                                          id={idInput}
                                          onKeyDown={(e) => {
                                            if (e?.key === "Enter") e.preventDefault()
                                          }}
                                          style={{
                                            background: GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext],
                                            width: "calc(100% - 15px)",
                                            color: GLOBAL_STYLES.LABEL.TYPE_2[themeContext]
                                          }}
                                          placeholder={t(TRANSLATION_INDEX_GLOBAL.COLUMN_TITLE) ?? ""}
                                          borderRadius="5px"
                                          themeContext={themeContext}
                                          onBlur={e => {
                                            handleBlurTableCols(item?.id, col?.id, e.target?.value, idInput)
                                          }}
                                          type={INPUT_TYPE.TEXT}
                                          border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
                                          value={col?.value}
                                          onChange={e => {
                                            handleChangeTableCols(item?.id, col?.id, e.target?.value)
                                          }}
                                        />
                                      </th>
                                    )
                                  })}
                                  <th>
                                    <TooltipCustom value={t(TRANSLATION_INDEX_QUOTER.ADD_COL)} width="80px">
                                      <div
                                        onClick={() => {
                                          handleAddColumn(item?.id)
                                        }}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                          handleAddColumn(item?.id,e.key)
                                        }}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginLeft: "2px"
                                        }}
                                      >
                                        <IconPlus
                                          color={GLOBAL_STYLES.BUTTON.SUCCESS_BG_COLOR[themeContext]}
                                          size={20}
                                        />
                                      </div>
                                    </TooltipCustom>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.filas.map(row => {
                                  return (
                                    <tr key={`${row?.id}-row1`}>
                                      {row?.value.map(rowCol => {
                                        const columnId = item.columnas.find(
                                          tableColId => tableColId?.value === item.columnaCalculos?.value
                                        )?.id
                                        return (
                                          <td key={`${rowCol?.id}-col1`}>
                                            <Input
                                              autoComplete="off"
                                              style={{
                                                width: "calc(100% - 15px)"
                                              }}
                                              borderRadius="5px"
                                              themeContext={themeContext}
                                              // type={rowCol?.id === columnId ? INPUT_TYPE.NUMBER : INPUT_TYPE.TEXT}
                                              border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
                                              onKeyDown={(e) => {
                                                if (e?.key === "Enter") e.preventDefault()
                                              }}
                                              onChange={e => {
                                                handleChangeTableRows(
                                                  item?.id,
                                                  row?.id,
                                                  rowCol?.id,
                                                  e.target?.value,
                                                  rowCol?.id === columnId
                                                )
                                              }}
                                              value={rowCol?.value}
                                            />
                                          </td>
                                        )
                                      })}
                                      <td>
                                        <TooltipCustom value={t(TRANSLATION_INDEX_QUOTER.DELETE_ROW)} width="80px">
                                          <div
                                            onClick={() => {
                                              handleDeleteRowTable(item?.id, row?.id)
                                            }}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center"
                                            }}
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                              handleDeleteRowTable(item?.id, row?.id,e.key)
                                            }}
                                          >
                                            <IconTrash size={20} color={GLOBAL_STYLES.BUTTON.DANGER_BG[themeContext]} />
                                          </div>
                                        </TooltipCustom>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={item.columnas.length}>
                                    <ButtonCustom
                                      type="button"
                                      label={t(TRANSLATION_INDEX_QUOTER.ADD_ROW) ?? ""}
                                      background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                                      backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
                                      onClick={() => {
                                        handleAddRowTable(item?.id)
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={item.columnas.length}>
                                    <ButtonCustom
                                      type="button"
                                      label={t(TRANSLATION_INDEX_QUOTER.DELETE_TABLE) ?? ""}
                                      background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                                      backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                                      onClick={() => {
                                        handleDeleteTable(item?.id)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            <div style={{ margin: "10px 0", width: "calc(100% - 15px)" }}>
                              <RichTextCustom                          
                                label={t(TRANSLATION_INDEX_QUOTER.TABLE_LEGEND) ?? ""}
                                value={item.leyendaTabla}
                                name={`${item.id}.leyendaTabla`}
                                handleChange={handleChangeTableRichText}
                                height="100px"                            
                              />
                            </div>
                            {item.conTotalizador && (
                              <>
                                <div style={{ margin: "10px 0" }}>
                                  <Label themeContext={themeContext} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}>
                                    {t(TRANSLATION_INDEX_QUOTER.CURRENCY)}
                                  </Label>
                                  <SelectCustom
                                    name={`${item?.id}.moneda`}
                                    value={item.moneda}
                                    handleChange={handleChangeTableSelect}
                                    options={["USD", "EUR"].map(item => {
                                      return { value: item, label: item }
                                    })}
                                  />
                                </div>
                                <div style={{ margin: "10px 0", width: "calc(100% - 15px)" }}>
                                  <Label themeContext={themeContext} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}>
                                    {t(TRANSLATION_INDEX_QUOTER.KG_PER_M3)}
                                  </Label>
                                  <Input
                                  onKeyDown={(e) => {
                                    if (e?.key === "Enter") e.preventDefault()
                                  }}
                                    autoComplete="off"
                                    borderRadius="5px"
                                    themeContext={themeContext}
                                    // type={INPUT_TYPE.NUMBER}
                                    border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
                                    min={0}
                                    value={formattedNumber(item.kgPorM3)}
                                    onChange={e => {
                                      handleChangeTableInputNumber(item?.id, "kgPorM3", e.target?.value)
                                    }}
                                  />
                                </div>
                                <div style={{ margin: "10px 0", width: "calc(100% - 15px)" }}>
                                  <Label themeContext={themeContext} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}>
                                    {t(TRANSLATION_INDEX_QUOTER.WEIGHT)}
                                  </Label>
                                  <Input
                                    autoComplete="off"
                                    onKeyDown={(e) => {
                                      if (e?.key === "Enter") e.preventDefault()
                                    }}
                                    borderRadius="5px"
                                    themeContext={themeContext}
                                    // type={INPUT_TYPE.NUMBER}
                                    border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
                                    min={0}
                                    value={formattedNumber(item.peso)}
                                    onChange={e => {
                                      handleChangeTableInputNumber(item?.id, "peso", e.target?.value)
                                    }}
                                  />
                                </div>
                                <div style={{ margin: "10px 0", width: "calc(100% - 15px)" }}>
                                  <Label themeContext={themeContext} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}>
                                    {t(TRANSLATION_INDEX_QUOTER.VOLUME)}
                                  </Label>
                                  <Input
                                    autoComplete="off"
                                    onKeyDown={(e) => {
                                      if (e?.key === "Enter") e.preventDefault()
                                    }}
                                    borderRadius="5px"
                                    themeContext={themeContext}
                                    // type={INPUT_TYPE.NUMBER}
                                    min={0}
                                    border={GLOBAL_STYLES.BORDER.TYPE_3[themeContext]}
                                    value={formattedNumber(item.volumen)}
                                    onChange={e => {
                                      handleChangeTableInputNumber(item?.id, "volumen", e.target?.value)
                                    }}
                                  />
                                </div>
                                <div style={{ margin: "10px 0", width: "100%" }}>
                                  <Label themeContext={themeContext} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}>
                                    {t(TRANSLATION_INDEX_QUOTER.COLUMN_FOR_CALCULATIONS)}
                                  </Label>
                                  <SelectCustom
                                    name={`${item?.id}.columnaCalculos`}
                                    value={item.columnaCalculos}
                                    width="100%"
                                    handleChange={handleChangeTableSelect}
                                    options={item.columnas
                                      .filter(itemColFil => !isStringEmpty(itemColFil?.value))
                                      .map(itemOpc => {
                                        return { value: itemOpc?.value, label: itemOpc?.value }
                                      })}
                                  />
                                </div>
                                <div style={{ margin: "10px 0" }}>
                                  <Label themeContext={themeContext} color={GLOBAL_STYLES.LABEL.TYPE_1[themeContext]}>
                                    {t(TRANSLATION_INDEX_QUOTER.SHOW_GRAND_TOTAL)}
                                  </Label>
                                  <SelectCustom
                                    name={`${item?.id}.mostrarTotalGeneral`}
                                    value={item.mostrarTotalGeneral}
                                    handleChange={handleChangeTableSelect}
                                    options={STATES_YES_NO.filter(item => item?.value !== null).map(item => {
                                      return {
                                        label: t(item.label).toUpperCase(),
                                        value: Boolean(item?.value)
                                      }
                                    })}
                                  />
                                </div>
                              </>
                            )}                            
                            <TableOriginExpensive setFieldValue={setFieldValue} table={item} setRenderHtml={setRenderHtml} tables={values.tablas} handleChangeTableInput={handleChangeTableInput}/>             
                            <TableBAExpensive setFieldValue={setFieldValue} table={item} setRenderHtml={setRenderHtml} tables={values.tablas} handleChangeTableInput={handleChangeTableInput}/>
                            <div id={`tableEditTotal-${item.id}`}></div>
                          </div>
                        </Group>
                      </>
                    )
                  })}

                <Group themeContext={themeContext}>
                  <hr style={{ width: "100%", margin: "15px 0", border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }} />
                </Group>
                <Group themeContext={themeContext}>
                 
                  {
                      setFieldValue &&
                        <Group themeContext={themeContext}>
                          <RichTextCustom
                          
                            label={t(TRANSLATION_INDEX_QUOTER.CONDITION) ?? ""}
                            value={values?.condiciones}
                            handleChange={setFieldValue}
                            height="100px"
                            name="condiciones"
                            />
                        </Group>
                      }
                </Group>
                <Group themeContext={themeContext}>
                  <hr style={{ width: "100%", margin: "15px 0", border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }} />
                </Group>
                <Group themeContext={themeContext}>
       
                   {
                      setFieldValue &&
                        <Group themeContext={themeContext}>
                          <RichTextCustom
                          
                            label={t(TRANSLATION_INDEX_QUOTER.TERMS) ?? ""}
                            value={values?.terminos}
                            handleChange={setFieldValue}
                            height="100px"
                            name="terminos"
                            />
                        </Group>
                      }
                </Group>
                <Group themeContext={themeContext}>
                  <hr style={{ width: "100%", margin: "15px 0", border: GLOBAL_STYLES.BORDER.TYPE_1[themeContext] }} />
                </Group>
                <Group themeContext={themeContext}>            
                  {
                      setFieldValue &&
                        <Group themeContext={themeContext}>
                          <RichTextCustom                          
                            label={t(TRANSLATION_INDEX_QUOTER.FINAL_TEXT) ?? ""}
                            value={values?.textoFinal}
                            name="textoFinal"
                            handleChange={setFieldValue}
                            height="100px"                            
                          />
                        </Group>
                      }
                </Group>

                <Group themeContext={themeContext} style={{ marginTop: "25px" }}></Group>
              </ContainerScroll>
            </ContainerGroup>
          )}
          <ContainerGroup
            width={isMobile ? "100%" : noEdit ? "100%" : "60%"}
            themeContext={themeContext}
            background="white"
          >
            {renderHtml && (
              <ContainerScroll>                
                <div id="container-preview" style={{ marginLeft: "1%" }}>
                  <div
                    style={{
                      width: "97%",
                      padding: "1%",
                      color: "#1c1c1c",
                      background: "white",
                      fontFamily: "Nunito, sans-serif",
                      fontSize: fontSiseToHtml
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        color: "#1c1c1c",
                        fontFamily: "Nunito, sans-serif",
                        fontSize: fontSiseToHtml
                      }}
                    >
                      <tbody
                        style={{
                          width: "100%",
                          color: "#1c1c1c",
                          fontFamily: "Nunito, sans-serif",
                          fontSize: fontSiseToHtml
                        }}
                      >
                        <tr style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                          <td style={{ width: "50%", display: "flex", justifyContent: "flex-start" }}>
                            <img
                              height={80}
                              // style={{ width: "100%" }}
                              src="https://backofficecargo-sa.com/cargo-quote.png"
                              alt="cargo s.a."
                            />
                          </td>
                          <td style={{ width: "50%", position: "relative" }}>
                            <div style={{ position: "relative", width: "100%", textAlign: "right" }}>                              
                              {values?.modoTransporte && (                                
                                <img
                                  // style={{ position: "absolute", right: "0px" }}
                                  height={80}
                                  src={`https://backofficecargo-sa.com/modo${values?.modoTransporte?.value}.png`}
                                  alt="cargo sa"
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div style={{ textAlign: "right", width: "100%" }}>
                              Buenos Aires, {format(new Date(), FORMAT_DATE_ONLY)}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* </div> */}

                    <div>{ReactHtmlParser(values?.textoCabecera?.replaceAll("\n", "<br />"))}</div>
                    <div style={{ margin: "30px 0" }}>
                      {values?.tablas?.map(table => {
                        let totalColumn = 0

                        return (
                          <div key={table?.id} style={{ margin: "10px 0" }}>
                            {table.titulo && (
                              <div style={{ fontWeight: "700",marginBottom:"5px" }}>
                              <u>{table?.titulo}</u>
                            </div>
                            )   
                            }   
                            {table.conTotalizador && (
                              <div>
                                Peso: {table.peso} Kg. Volumen: {table.volumen} M3
                              </div>  )   
                            }                       
                            <table
                              style={{
                                width: "100%",
                                color: "#1c1c1c",
                                background: "white",
                                fontFamily: "Nunito, sans-serif",
                                fontSize: fontSiseToHtml
                              }}
                            >
                              <thead>
                                <tr>
                                  {table.columnas.map(col => {
                                    return (
                                      <th
                                        style={{
                                          width: "120px",
                                          background: "#355239",
                                          color: "white",
                                          height: "25px"
                                        }}
                                        key={col?.id}
                                      >
                                        {col?.value}
                                      </th>
                                    )
                                  })}
                                  {table.conTotalizador && (
                                    <th
                                      style={{ width: "120px", background: "#355239", color: "white", height: "25px" }}
                                    >
                                      Total
                                    </th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {table.filas.map(row => {
                                  const columnId = table.columnas.find(
                                    tableColId => tableColId?.value === table.columnaCalculos?.value
                                  )?.id

                                  const columTotal = formatNumberToCalc(row?.value[columnId]?.value)
                                  const peso = formatNumberToCalc(table.peso)
                                  const kgPorM3 = formatNumberToCalc(table.kgPorM3)
                                  const volumen = formatNumberToCalc(table.volumen)
                           
                                  const totalRow =
                                    peso === 0 || volumen === 0
                                      ? 0
                                      : peso >= kgPorM3 * volumen
                                      ? peso * columTotal
                                      : columTotal * (kgPorM3 * volumen)
                                  totalColumn += totalRow
                                  return (
                                    <tr key={row?.id}>
                                      {row?.value.map(rowCol => {
                                        const isColumCalc = rowCol.id === columnId
                                        return (
                                          <td
                                            style={{ border: "solid 1px black", textAlign: "center", height: "25px" }}
                                            key={rowCol?.id}
                                          >
                                            {`${
                                              typeof table?.moneda?.label !== "undefined"
                                                ? isColumCalc
                                                  ? table?.moneda?.value ?? ""
                                                  : ""
                                                : ""
                                            } ${isColumCalc ? formattedNumber(rowCol?.value) : rowCol?.value}`}
                                          </td>
                                        )
                                      })}
                                      {table.conTotalizador && (
                                        <td style={{ border: "solid 1px black", textAlign: "center", height: "25px" }}>
                                          {`${
                                            typeof table?.moneda?.label !== "undefined"
                                              ? table?.moneda?.label ?? ""
                                              : ""
                                          } ${formattedNumber(totalRow.toFixed(2))}`}
                                        </td>
                                      )}
                                    </tr>
                                  )
                                })}
                                {table.mostrarTotalGeneral?.value && (
                                  <tr>
                                    {table.columnas.map(item => {
                                      return <td key={`${item?.id}-total-table`}></td>
                                    })}
                                    <td style={{ border: "solid 1px black", textAlign: "center", height: "25px" }}>
                                      {`${
                                        (typeof table?.moneda?.label !== "undefined" ? table?.moneda?.label : "") ?? ""
                                      } ${formattedNumber(totalColumn.toFixed(2))}`}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>  
                            <div style={{marginTop:"7px"}}>{ReactHtmlParser(table?.leyendaTabla?.replaceAll("\n", "<br />"))}</div>                         
                  
                            {table?.mostrarGastosOrigen?.value && (
                              <div style={{ margin: "30px 0", maxWidth: "100%" }}>
                                <div style={{ fontWeight: "700" }}>
                                  <u>{t(TRANSLATION_INDEX_QUOTER.EXPENSE_AT_ORIGIN)}</u>
                                </div>
                                <div style={{height:"5px"}}/>
                                <table
                                  style={{
                                    width: "100%",
                                    fontFamily: "Nunito, sans-serif",
                                    color: "#1c1c1c",
                                    background: "white",
                                    fontSize: fontSiseToHtml
                                  }}
                                >
                                  <thead style={{ width: "100%" }}>
                                    <tr>
                                      <th style={{ width: "50%", background: "#355239", color: "white", height: "25px" }}>
                                        {t(TRANSLATION_INDEX_QUOTER.CONCEPT)}
                                      </th>
                                      <th style={{ width: "50%", background: "#355239", color: "white", height: "25px" }}>
                                        {t(TRANSLATION_INDEX_QUOTER.AMOUNT)}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {table.gastosOrigen.map(row => {
                                      return (
                                        <tr key={`${row?.id}-row1`}>
                                          <td style={{ border: "solid 1px black", textAlign: "center", height: "25px" }}>
                                            {row.textoLibre}
                                          </td>
                                          <td style={{ border: "solid 1px black", textAlign: "center", height: "25px" }}>
                                            {row.valor}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                            
                                <div style={{marginTop:"7px"}}>{table.leyendaGastosOrigen}</div>
                              </div>
                            )}
                            {table?.mostrarGastosBuenosAires?.value && (
                              <div style={{ margin: "30px 0" }}>
                                <div style={{ fontWeight: "700" }}>
                                  <u>{t(TRANSLATION_INDEX_QUOTER.EXPENSE_AT_BA)}</u>
                                </div>                   
                                <table
                                  style={{
                                    width: "100%",
                                    color: "#1c1c1c",
                                    background: "white",
                                    marginTop:"7px",
                                    fontFamily: "Nunito, sans-serif",
                                    fontSize: fontSiseToHtml
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ width: "50%", background: "#355239", color: "white", height: "25px" }}>
                                        {t(TRANSLATION_INDEX_QUOTER.CONCEPT)}
                                      </th>
                                      <th style={{ width: "50%", background: "#355239", color: "white", height: "25px" }}>
                                        {t(TRANSLATION_INDEX_QUOTER.AMOUNT)}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {table.gastosBuenosAires.map(row => {
                                      return (
                                        <tr key={row?.id}>
                                          <td style={{ border: "solid 1px black", textAlign: "center", height: "25px" }}>
                                            {row.textoLibre}
                                          </td>
                                          <td style={{ border: "solid 1px black", textAlign: "center", height: "25px" }}>
                                            {row.valor}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                        
                                <div style={{marginTop:"7px"}}>{table.leyendaGastosBuenosAires}</div>
                              </div>
                            )} 
                            
                          </div>
                        )
                      })}
                    </div>

                    <br />
                    <div>{ReactHtmlParser(values?.condiciones?.replaceAll("\n", "<br />"))}</div>
                    <br />
                    <div>{ReactHtmlParser(values?.terminos?.replaceAll("\n", "<br />"))}</div>
                    <br />
                    <div>{ReactHtmlParser(values?.textoFinal?.replaceAll("\n", "<br />"))}</div>                   
                    <img
                      style={{ width: "auto",maxWidth:"calc(100% - 10px)", marginTop: "15px" }}
                      src={
                        (isSend && !isStringEmpty(userBackoffice?.imageCurrentUser ?? ""))
                          ? `${process.env.REACT_APP_API_URL}/api/backoffice/v1/usuarios/imagenFirmaPlana/${getAuth("*")[1]}`
                          : `https://backofficecargo-sa.com/firmaTemplate.jpg`
                      }
                      alt="firma cargo s.a."
                    />
                  </div>
                </div>
              </ContainerScroll>
            )}
          </ContainerGroup>
        </ContainerAll>
      )}
      {showButton && (
        <GroupButtom themeContext={themeContext}>
          <ButtonCustom
            onClick={closeModal}
            width="80px"
            type="button"
            label={t("global.cancel") ?? ""}
            background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
          />
          <ButtonCustom
            width="80px"
            type="submit"
            label={
              action === ACTIONS.DELETE.VALUE
                ? t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""
                : t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""
            }
            background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
            marginLeft
          />
        </GroupButtom>
      )}
    </Container>
  )
}
export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({
    idCotizacion: data?.idCotizacion,
    vigenciaDesde: data?.vigenciaDesde,
    asuntoEmail: data?.asuntoEmail,
    procedencias: data?.procedencias ? data?.procedencias.map(item=>{ return { value: item, Label: "" }}) : null,
    destinos: data?.destinos ? data?.destinos.map(item=>{ return { value: item, Label: "" }}) : null,
    vigenciaHasta: data?.vigenciaHasta,
    nombre: data?.nombre,
    textoCabecera: data?.textoCabecera,
    fechaActualizacion: data?.fechaActualizacion,
    usuarioActualizacion: data?.usuarioActualizacion,
    modoTransporte: data?.modoTransporte
      ? {
          label: data?.modoTransporte === "AIR+SEA" ? `${t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase()}+${t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase()}` : t(TRANSLATION_INDEX_QUOTER[data?.modoTransporte.toUpperCase()]).toUpperCase(),
          value: data?.modoTransporte.toUpperCase()
        }
      : null,
    condiciones: data?.condiciones,
    terminos: data?.terminos,
    textoFinal: data?.textoFinal,
    tablas: data?.tablas
      ? data?.tablas?.map((item, index) => {
          item.id = index
          return {
            ...item,
            mostrarGastosOrigen: item?.mostrarGastosOrigen
          ? {
              label: t(STATES_YES_NO.find(itemOpc => itemOpc?.value === item?.mostrarGastosOrigen)?.label ?? "").toUpperCase(),
              value: Boolean(item?.mostrarGastosOrigen ?? false)
            }
          : {
              label: t(STATES_YES_NO[1].label).toUpperCase(),
              value: STATES_YES_NO[1]?.value
            },
            mostrarGastosBuenosAires: item?.mostrarGastosBuenosAires
              ? {
                  label: t(
                    STATES_YES_NO.find(itemOpc => itemOpc?.value === item?.mostrarGastosBuenosAires)?.label ?? ""
                  ).toUpperCase(),
                  value: item?.mostrarGastosBuenosAires ?? false
                }
              : {
                  label: t(STATES_YES_NO[1].label),
                  value: STATES_YES_NO[1]?.value
                },
            leyendaGastosBuenosAires: item?.leyendaGastosBuenosAires,
            leyendaGastosOrigen: item?.leyendaGastosOrigen,
            gastosOrigen: item?.gastosOrigen ?? [],
            gastosBuenosAires: item?.gastosBuenosAires ?? [],
            kgPorM3: item.kgPorM3 ? formattedNumber(item.kgPorM3) : null,
            peso: item.peso ? formattedNumber(item.peso) : null,
            volumen: item.volumen ? formattedNumber(item.volumen) : null,
            moneda: { value: item.moneda, label: item.moneda },
            mostrarTotalGeneral: item?.mostrarTotalGeneral
              ? {
                  label: t(
                    STATES_YES_NO.find(itemState => itemState?.value === item?.mostrarTotalGeneral)?.label ?? ""
                  ).toUpperCase(),
                  value: Boolean(item?.mostrarTotalGeneral ?? false)
                }
              : {
                  label: t(STATES_YES_NO[1].label).toUpperCase(),
                  value: STATES_YES_NO[1]?.value
                },
            columnaCalculos: { value: item.columnaCalculos, label: item.columnaCalculos },
            filas: item.filas.map((fil, indexRow1) => {
              const newRow = { value: "", id: 0 }
              newRow.id = indexRow1
              newRow.value = fil.map((filRow, indexFil) => {
                const newObj = { value: "", id: 0 }
                newObj.value = filRow
                newObj.id = indexFil
                return newObj
              })
              return newRow
            }),
            columnas: item.columnas.map((col, indexCol) => {
              const newObj = { value: "", id: 0 }
              newObj.value = col
              newObj.id = indexCol
              return newObj
            })
          }
        })
      : []
  }),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    const response = {
      idCotizacion: values?.idCotizacion,
      nombre: values.nombre,
      asuntoEmail: values?.asuntoEmail,
      // idPaisProcedencia: values?.procedencia?.value ?? null,
      // idPaisDestino: values?.destino?.value ?? null,
      procedencias: values?.procedencias?.map(item=> item.value) ?? null,
      destinos: values?.destinos?.map(item=> item.value) ?? null,
      vigenciaDesde: values?.vigenciaDesde,
      vigenciaHasta: values?.vigenciaHasta,
      textoCabecera: values.textoCabecera,
      modoTransporte: values.modoTransporte?.value.toUpperCase(),
      condiciones: values.condiciones,
      terminos: values.terminos,
      textoFinal: values.textoFinal,      
      fechaActualizacion: values.fechaActualizacion,
      usuarioActualizacion: values.usuarioActualizacion,
      tablas: values.tablas?.map(table => {
        return {
          ...table,
          mostrarGastosOrigen: table.gastosOrigen.length > 0 ? table.mostrarGastosOrigen?.value : false,
          leyendaGastosOrigen: table.leyendaGastosOrigen,
          gastosOrigen: table.mostrarGastosOrigen?.value
          ? table.gastosOrigen
              .filter(item => !isStringEmpty(item.textoLibre))
              .map(item => {
                return {
                  idGastoOrigen: item?.idGastoOrigen,
                  textoLibre: item.textoLibre,
                  valor: item.valor
                }
              })
          : [],
          mostrarGastosBuenosAires: table.gastosBuenosAires.length > 0 ? table.mostrarGastosBuenosAires?.value : false,
          gastosBuenosAires: table.mostrarGastosBuenosAires?.value
          ? table.gastosBuenosAires
              .filter(item => !isStringEmpty(item.textoLibre))
              .map(item => {
                return {
                  idGastoBuenosAires: item?.idGastoBuenosAires,
                  textoLibre: item.textoLibre,
                  valor: item.valor
                }
              })
          : [],
          leyendaGastosBuenosAires: table.leyendaGastosBuenosAires,
          kgPorM3: table.kgPorM3 ? table.kgPorM3.toString().replaceAll(".", "").replaceAll(",", ".") : null,
          peso: table.peso ? table.peso.toString().replaceAll(".", "").replaceAll(",", ".") : null,
          volumen: table.volumen ? table.volumen.toString().replaceAll(".", "").replaceAll(",", ".") : null,
          moneda: table.moneda?.value,
          columnaCalculos: table.columnaCalculos?.value,
          mostrarTotalGeneral: table.mostrarTotalGeneral?.value,
          columnas: table.columnas.map(col => {
            return col?.value
          }),
          filas: table.filas.map(fil => {
            return fil?.value.map(filCol => {
              return filCol?.value
            })
          })
        }
      })
    }
    if (accept != null && response.vigenciaDesde < response.vigenciaHasta) accept(response)
    setSubmitting(false)
  }
})(FormaddUpdate)
