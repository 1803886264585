/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_AUTHORIZE_INFORMACION,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP
} from "../../config/translation"
import DataTable from "react-data-table-component"

import AlertModal from "../../components/AlertModal"

import { ACTIONS, FORMAT_DATE, FORMAT_DATE_ONLY, PERMISSION } from "../../utils/constants"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { daysDiffernce, getAuth } from "../../utils/functions"
import ButtonCustom from "../../components/ButtonCustom"
import { ContainerIcons, ContainerTable, ContainerTab, Tab } from "./_styles"
import { useDispatch, useSelector } from "react-redux"
import { getAuthorizationRequest, postAuthorizationRequest } from "../../state/modules/authorization/actions"
import { authorizationSelector } from "../../state/modules/authorization/_selectors"
import FormUserAppClient from "./forms/addUpdate/FormUserAppClient"
import { toast } from "react-toastify"
import IconEdit from "../../components/Icons/IconEdit"
import { customStylesContext } from "../../styles/_styleDatatable"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"
import { NoData } from "../../components/NoData"
import { useGlobalContext } from "../../context/GlobalContext"
import "../../styles/styles.css"
import { type IAuthorizeInformation } from "../../interfaces/IAuthorizeInformation"
import Search from "./forms/search/index"
import { format } from "date-fns"
import { useMobile } from "../../hook/useMobile"

const AuthorizeInformationPage = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMobile = useMobile()
  const authorization = useSelector(authorizationSelector)
  const [dataFiltered, setDataFiltered] = React.useState<IAuthorizeInformation[]>([])
  const [clearFilter, setClearFilter] = React.useState<boolean>(false)
  const [openModal, setOpenModal] = React.useState(false)
  const { setLoadingContext, themeContext } = useGlobalContext()
  const [userSelected, setUserSelected] = React.useState<IAuthorizeInformation | null>(null)
  const [action, setAction] = React.useState<string>("")
  const [openResolved, setOpenResolved] = React.useState(false)
  const [openPending, setOpenPending] = React.useState(true)

  const handleLoading = (): void => {
    setLoadingContext(true)
    setTimeout(() => {
      setLoadingContext(false)
    }, 500)
  }

  const handleOpenModal = (action = ""): void => {
    setAction(action)
    setOpenModal(!openModal)
  }

  const handleOpenRegistered = (): void => {
    if (openPending) {
      setClearFilter(true)
      handleLoading()

      setOpenResolved(true)
      setOpenPending(false)
    }
  }

  const handleOpenPending = (): void => {
    if (openResolved) {
      setClearFilter(true)
      handleLoading()
      setOpenPending(true)
      setOpenResolved(false)
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: true,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const columns = [
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.ORGANIZATION),
      selector: (row: IAuthorizeInformation) => row.descripcionOrganizacion?.toUpperCase(),
      cell: (row: IAuthorizeInformation) => {
        return <ContainerLabelTwoRow label={row.descripcionOrganizacion?.toUpperCase()} />
      },
      width: isMobile ? "120px" : `${openPending ? "calc(20% - 120px" : "12%"}`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.SHIPMENT_ID),
      selector: (row: IAuthorizeInformation) => row.idEmbarque?.toUpperCase(),
      cell: (row: IAuthorizeInformation) => {
        return <ContainerLabelTwoRow label={row.idEmbarque?.toUpperCase()} />
      },
      width: isMobile ? "120px" : `${openPending ? "12%" : "10%"}`,
      sortable: true
    },

    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.TYPE),
      selector: (row: IAuthorizeInformation) => row.tipoAutorizacion?.toUpperCase(),
      width: isMobile ? "120px" : `${openPending ? "12%" : "11%"}`,
      sortable: true,
      center: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.PREVIOUS_DATA),
      selector: (row: IAuthorizeInformation) => row.fechaAnterior,
      cell: (row: IAuthorizeInformation) => {
        return (
          <ContainerLabelTwoRow
            label={row?.fechaAnterior ? format(new Date(row?.fechaAnterior), FORMAT_DATE_ONLY) : ""}
          />
        )
      },
      center: true,
      width: isMobile ? "120px" : `${openPending ? "13%" : "11%"}`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.NEW_DATE),
      selector: (row: IAuthorizeInformation) => row.fechaNueva,
      cell: (row: IAuthorizeInformation) => {
        return (
          <ContainerLabelTwoRow label={row?.fechaNueva ? format(new Date(row?.fechaNueva), FORMAT_DATE_ONLY) : "-"} />
        )
      },
      center: true,
      width: isMobile ? "120px" : `${openPending ? "13%" : "11%"}`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.DAYS_DIFFERENCE),
      selector: (row: IAuthorizeInformation) =>
        row?.fechaAnterior ? Number(daysDiffernce(row?.fechaAnterior, row?.fechaNueva)) : -99999999,
      cell: (row: IAuthorizeInformation) => {
        return row?.fechaAnterior ? daysDiffernce(row?.fechaAnterior, row?.fechaNueva) : "-"
      },
      width: isMobile ? "120px" : `${openPending ? "8%" : "7%"}`,
      center: true,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.CHANGE_DATE),
      selector: (row: IAuthorizeInformation) => row.fechaCambio,
      cell: (row: IAuthorizeInformation) => {
        return (
          <span style={{ width: "100%", textAlign: "right" }}>
            <ContainerLabelTwoRow label={format(new Date(row?.fechaCambio), FORMAT_DATE)} />
          </span>
        )
      },
      width: isMobile ? "120px" : `${openPending ? "15%" : "14%"}`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.COLUMNS.STATUS),
      selector: (row: IAuthorizeInformation) =>
        row.idEstadoInfoAutorizacion === 1
          ? t(TRANSLATION_INDEX_GLOBAL.APPROVED).toUpperCase()
          : t(TRANSLATION_INDEX_GLOBAL.REJECTED).toUpperCase(),
      width: isMobile ? "120px" : `${openPending ? "10%" : "8%"}`,
      omit: openPending,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.RESOLVED_BY),
      selector: (row: IAuthorizeInformation) => row.fechaCambio,
      cell: (row: IAuthorizeInformation) => {
        const label = `${row?.usuarioResolucion ?? ""} ${
          row?.fechaResolucion ? format(new Date(row?.fechaResolucion), FORMAT_DATE) : "-"
        }`
        if (screen.width < 1367)
          return (
            <div>
              <div>{row?.usuarioResolucion ?? ""}</div>
              <div>{row?.fechaResolucion ? format(new Date(row?.fechaResolucion), FORMAT_DATE) : "-"}</div>
            </div>
          )
        else return <ContainerLabelTwoRow label={label === null ? "-" : label} />
      },
      width: isMobile ? "180px" : `${openPending ? "15%" : "15%"}`,
      omit: openPending,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_USER_BACKOFFICE_CLIENT_APP.COLUMNS.ACTIONS),
      cell: (row: IAuthorizeInformation) => {
        return (
          <ContainerIcons>
            {getAuth(PERMISSION.AUT_INFOCLIENTES_MODIFICAR)[0] && (
              <ButtonCustom
                label={t(TRANSLATION_INDEX_GLOBAL.APPROVE) ?? ""}
                onClick={() => {
                  setUserSelected(row)
                  handleOpenModal(ACTIONS.APPROVE.VALUE)
                }}
                width="auto"
                background={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.DARK_BG_IMAGE_HOVER[themeContext]}
                icon={<IconEdit />}
              />
            )}
          </ContainerIcons>
        )
      },
      omit: !getAuth(PERMISSION.AUT_INFOCLIENTES_MODIFICAR)[0] || !openPending,
      minWidth: `${openPending ? "120px" : "120px"}`
    }
  ]

  const handleDispatch = (value): void => {
    dispatch(postAuthorizationRequest({ idInfoAutorizacion: userSelected?.idInfoAutorizacion, accion: value }))
  }

  React.useEffect(() => {
    if (authorization.showToast) {
      if (authorization.hasErrMessage) {
        toast.error(t(authorization.errorCode))
      } else {
        toast.success(t(authorization.toastMessage))
        handleOpenModal()
        dispatch(getAuthorizationRequest())
      }
    }
  }, [authorization.showToast])

  React.useEffect(() => {
    setLoadingContext(authorization.loading)
    setDataFiltered(authorization.data)
  }, [authorization])

  React.useEffect(() => {
    setLoadingContext(true)
    dispatch(getAuthorizationRequest())
  }, [])

  return (
    <>
      <ContainerPage title={`${t(TRANSLATION_INDEX_MENU.AUTHORIZE_INFORMATION_2)}`} actions={[]}>
        <>
          {openModal && (
            <AlertModal
              activeCloseModal
              maxWidth={"450px"}
              closeModal={() => {
                handleOpenModal()
              }}
              title={`${t(TRANSLATION_INDEX_MENU.AUTHORIZE_INFORMATION)} | ${t(
                TRANSLATION_INDEX_GLOBAL[action.toUpperCase()]
              )}`}
            >
              <FormUserAppClient
                data={userSelected}
                isUserPending={openPending}
                action={action}
                closeModal={handleOpenModal}
                loading={authorization.loading}
                accept={handleDispatch}
              />
            </AlertModal>
          )}
          <ContainerTab>
            <Tab
              themeContext={themeContext}
              background={
                openPending
                  ? GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE_ACTIVE[themeContext]
                  : GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE[themeContext]
              }
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                handleOpenPending()
              }}
              onClick={() => {
                handleOpenPending()
              }}
            >
              {t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.PENDING)}
            </Tab>
            <Tab
              themeContext={themeContext}
              background={
                openResolved
                  ? GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE_ACTIVE[themeContext]
                  : GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE[themeContext]
              }
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                handleOpenRegistered()
              }}
              onClick={() => {
                handleOpenRegistered()
              }}
            >
              {t(TRANSLATION_INDEX_AUTHORIZE_INFORMACION.RESOLVED)}
            </Tab>
          </ContainerTab>
          <ContainerTable themeContext={themeContext} opacity={1}>
            <Search
              data={authorization.data}
              setDataFiltered={setDataFiltered}
              isUserPending={openPending}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />
            <DataTable
              data={
                dataFiltered?.filter(
                  item =>
                    (openResolved && item?.idEstadoInfoAutorizacion !== 0) ||
                    (openPending && item?.idEstadoInfoAutorizacion === 0)
                ) ?? []
              }
              paginationComponentOptions={paginationComponentOptions}
              columns={columns}
              dense
              pagination
              striped
              noDataComponent={<NoData />}
              customStyles={customStylesContext(themeContext, GLOBAL_STYLES.BUTTON.TAB_BG_IMAGE_ACTIVE[themeContext])}
              defaultSortFieldId={1}
            />
          </ContainerTable>
        </>
      </ContainerPage>
    </>
  )
}

export default AuthorizeInformationPage
