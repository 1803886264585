import { reference } from "./_duck"
import * as types from "./types"

// Types
import { type PayloadAction } from "../../types"
import { type ModuleType } from "./_definitions"
import { TRANSLATION_INDEX_RESPONSE_CODE } from "../../../config/translation"

const initialModuleState: ModuleType = {
  data: [],
  loading: false,
  showToast: false,
  toastMessage: "",
  hasErrMessage: false,
  errorCode: ""
}

const reducer = reference.createReducer(
  {
    [types.REFERENCE_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      loading: true,
      showToast: false,
      toastMessage: "",
      hasErrMessage: false,
      errorCode: ""
    }),
    [types.REFERENCE_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => {
      return {
        ...state,
        data: payload,
        loading: false
      }
    },
    [types.REFERENCE_GET_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      data: [],
      showToast: true,
      toastMessage: payload?.response?.data?.message ?? "GENERIC_ERROR",
      loading: false,
      hasErrMessage: true,
      errorCode: TRANSLATION_INDEX_RESPONSE_CODE[payload?.response?.data?.message ?? "GENERIC_ERROR"]
    })
  },
  initialModuleState
)

export default reducer
