// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import { getPreferenceFailure, getPreferenceSuccess, putPreferenceSuccess, putPreferenceFailure } from "../actions"

import { getPreference, putPreference } from "../services"

function* getUsetAppClientWorker(): SagaIterator {
  try {
    const { data } = yield call(getPreference)
    yield put(getPreferenceSuccess(data))
  } catch (error) {
    yield put(getPreferenceFailure(error))
  }
}

function* putUserClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(putPreference, request.payload)
    yield put(putPreferenceSuccess(data))
  } catch (error) {
    yield put(putPreferenceFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.PREFERENCE_GET_REQUEST, getUsetAppClientWorker)
  yield takeLatest(types.PREFERENCE_PUT_REQUEST, putUserClientWorker)
}

export const sagas = [listWatcher]
