import { permission } from "./_duck"

export const PERMISSION_GET_REQUEST: string = permission.defineType("PERMISSION_GET_REQUEST")
export const PERMISSION_GET_SUCCESS: string = permission.defineType("PERMISSION_GET_SUCCESS")
export const PERMISSION_GET_FAILURE: string = permission.defineType("PERMISSION_GET_FAILURE")

export const PERMISSION_GET_BY_PROFILE_REQUEST: string = permission.defineType("PERMISSION_GET_BY_PROFILE_REQUEST")
export const PERMISSION_GET_BY_PROFILE_SUCCESS: string = permission.defineType("PERMISSION_GET_BY_PROFILE_SUCCESS")
export const PERMISSION_GET_BY_PROFILE_FAILURE: string = permission.defineType("PERMISSION_GET_BY_PROFILE_FAILURE")

export const PERMISSION_ADD_REQUEST: string = permission.defineType("PERMISSION_ADD_REQUEST")
export const PERMISSION_ADD_SUCCESS: string = permission.defineType("PERMISSION_ADD_SUCCESS")
export const PERMISSION_ADD_FAILURE: string = permission.defineType("PERMISSION_ADD_FAILURE")

export const PERMISSION_POST_REQUEST: string = permission.defineType("PERMISSION_POST_REQUEST")
export const PERMISSION_POST_SUCCESS: string = permission.defineType("PERMISSION_POST_SUCCESS")
export const PERMISSION_POST_FAILURE: string = permission.defineType("PERMISSION_POST_FAILURE")

export const PERMISSION_DELETE_REQUEST: string = permission.defineType("PERMISSION_DELETE_REQUEST")
export const PERMISSION_DELETE_SUCCESS: string = permission.defineType("PERMISSION_DELETE_SUCCESS")
export const PERMISSION_DELETE_FAILURE: string = permission.defineType("PERMISSION_DELETE_FAILURE")
