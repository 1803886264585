/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, type ReactElement, useEffect } from "react"
import { type IpropsPOC } from "../_types"
import { ContainerButom, ContainerGroup, ContainerIcon, ContainerMails } from "../_styles"
import InputCustom2 from "../../../components/InputCustom2"
import { GLOBAL_STYLES } from "../../../styles/GLOBAL_STYLE"
import IconPlus2 from "../../../components/Icons/IconPlus2"
import { isStringEmpty } from "../../../utils/functions"
import { type IOptionString } from "../../../interfaces/IOption"
import Divisor from "../../../components/Divisor"
import ButtonCustom from "../../../components/ButtonCustom"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_PREFERENCE } from "../../../config/translation"
import { useTranslation } from "react-i18next"
import { INPUT_TYPE } from "../../../utils/constants"
import InputCustomSet from "../../../components/InputCustomSet"
import { useDispatch } from "react-redux"
import { putPreferenceRequest } from "../../../state/modules/preferences/actions"
import { type IPreference } from "../../../interfaces/IPreference"
import { useGlobalContext } from "../../../context/GlobalContext"
import { toast } from "react-toastify"

const Poc: React.FC<IpropsPOC> = ({
  mailsAlertaCambios,
  mailsUsuariosSinLogin,
  mailsRegistroUsuarios,
  mailsCifPricing,
  mailsNuevosClientes,
  mailsSolicitudFactura,
  mailsClienteAlertaSinContacto,
  mailsClienteSinComercialAsignado,
  clienteDiasSinContacto,
  clienteDiasSinEventos,
  days,
  canEdit
}): ReactElement => {
  const { t } = useTranslation()
  const [activeValidationMail, setActiveValidationMail] = useState(false)
  const [mailsAlertCambiosList, setMailsAlertCambiosList] = useState<IOptionString[]>([])
  const [mailsUsuariosSinLoginList, setMailsUsuariosSinLoginList] = useState<IOptionString[]>([])
  const [mailsRegistroUsuariosList, setMailsRegistroUsuariosList] = useState<IOptionString[]>([])
  const [mailsCifPricingList, setMailsCifPricingList] = useState<IOptionString[]>([])
  const [mailsNuevosClientesList, setMailsNuevosClientesList] = useState<IOptionString[]>([])
  const [mailsSolicitudFacturaList, setMailsSolicitudFacturaList] = useState<IOptionString[]>([])
  const [mailsClienteSinComercialAsignadoList, setMailsClienteSinComercialAsignadoList] = useState<IOptionString[]>([])
  const [mailsClienteAlertaSinContactoList, setMailsClienteAlertaSinContactoList] = useState<IOptionString[]>([])
  const [daysUpdate, setDaysUpdate] = useState<number>(0)
  const [clienteDiasSinEventosNumber, setClienteDiasSinEventosNumber] = useState<number>(0)
  const [clienteDiasSinContactoNumber, setClienteDiasSinContactoNumber] = useState<number>(0)
  const { themeContext } = useGlobalContext()
  const dispatch = useDispatch()
  const getMailsInString = (data): string => {
    const mails = data
      .filter(item => !isStringEmpty(item.label) && item.hidden === false)
      .map(item => item.label)
      .join(",")
      .toLowerCase()
    return isStringEmpty(mails) ? null : mails
  }
  const valMail = (value): boolean => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(value)

  const handleValidateMails = (): boolean => {
    let isAllOk = true
    let errors = []
    mailsClienteSinComercialAsignadoList
      .filter(item => !item.hidden && !isStringEmpty(item.label))
      .forEach(item => {
        const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.label)
        if (isAllOk && !regExpEmail) isAllOk = false
        if (!regExpEmail) {
          const newObj: any = item
          newObj.list = "mailsClienteSinComercialAsignadoList"
          errors = errors.concat(newObj)
        }
      })
    mailsClienteAlertaSinContactoList
      .filter(item => !item.hidden && !isStringEmpty(item.label))
      .forEach(item => {
        const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.label)
        if (isAllOk && !regExpEmail) isAllOk = false
        if (!regExpEmail) {
          const newObj: any = item
          newObj.list = "mailsClienteAlertaSinContactoList"
          errors = errors.concat(newObj)
        }
      })
    mailsAlertCambiosList
      .filter(item => !item.hidden && !isStringEmpty(item.label))
      .forEach(item => {
        if (!isStringEmpty(item.label)) {
          const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.label)
          if (isAllOk && !regExpEmail) isAllOk = false
          if (!regExpEmail) {
            const newObj: any = item
            newObj.list = "mailsAlertCambiosList"
            errors = errors.concat(newObj)
          }
        }
      })

    mailsUsuariosSinLoginList
      .filter(item => !item.hidden && !isStringEmpty(item.label))
      .forEach(item => {
        const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.label)
        if (isAllOk && !regExpEmail) isAllOk = false
        if (!regExpEmail) {
          const newObj: any = item
          newObj.list = "mailsUsuariosSinLoginList"
          errors = errors.concat(newObj)
        }
      })
    mailsRegistroUsuariosList
      .filter(item => !item.hidden && !isStringEmpty(item.label))
      .forEach(item => {
        const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.label)
        if (isAllOk && !regExpEmail) isAllOk = false
        if (!regExpEmail) {
          const newObj: any = item
          newObj.list = "mailsRegistroUsuariosList"
          errors = errors.concat(newObj)
        }
      })
    mailsCifPricingList
      .filter(item => !item.hidden && !isStringEmpty(item.label))
      .forEach(item => {
        const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.label)
        if (isAllOk && !regExpEmail) isAllOk = false
        if (!regExpEmail) {
          const newObj: any = item
          newObj.list = "mailsCifPricingList"
          errors = errors.concat(newObj)
        }
      })

    mailsNuevosClientesList
      .filter(item => !item.hidden && !isStringEmpty(item.label))
      .forEach(item => {
        const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.label)
        if (isAllOk && !regExpEmail) isAllOk = false
        if (!regExpEmail) {
          const newObj: any = item
          newObj.list = "mailsNuevosClientesList"
          errors = errors.concat(newObj)
        }
      })

    mailsSolicitudFacturaList
      .filter(item => !item.hidden && !isStringEmpty(item.label))
      .forEach(item => {
        const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(item.label)
        if (isAllOk && !regExpEmail) isAllOk = false
        if (!regExpEmail) {
          const newObj: any = item
          newObj.list = "mailsSolicitudFacturaList"
          errors = errors.concat(newObj)
        }
      })
    return isAllOk
  }

  const sendData = (): void => {
    const isOkMails = handleValidateMails()
    if (isOkMails) {
      const request: IPreference = {
        mailsAlertaCambios: getMailsInString(mailsAlertCambiosList),
        mailsRegistroUsuarios: getMailsInString(mailsRegistroUsuariosList),
        mailsUsuariosSinLogin: getMailsInString(mailsUsuariosSinLoginList),
        mailsCifPricing: getMailsInString(mailsCifPricingList),
        mailsNuevosClientes: getMailsInString(mailsNuevosClientesList),
        mailsSolicitudFactura: getMailsInString(mailsSolicitudFacturaList),
        mailsClienteSinComercialAsignado: getMailsInString(mailsClienteSinComercialAsignadoList),
        mailsClienteAlertaSinContacto: getMailsInString(mailsClienteAlertaSinContactoList),
        cantidadDiasCambios: daysUpdate,
        clienteDiasSinEventos: clienteDiasSinEventosNumber,
        clienteDiasSinContacto: clienteDiasSinContactoNumber
      }
      dispatch(putPreferenceRequest(request))
    } else {
      setActiveValidationMail(true)
      toast.error(t(TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT))
    }
  }

  const handleChange = (id, next): void => {
    const listTemporal = mailsAlertCambiosList.map(item => {
      if (item.value === id) item.label = next
      return item
    })
    setMailsAlertCambiosList(listTemporal)
  }
  const handleDelete = (id): void => {
    setMailsAlertCambiosList(
      mailsAlertCambiosList.map(item => {
        if (item.value === id) item.hidden = true
        return item
      })
    )
  }
  const handleAdd = (): void => {
    setMailsAlertCambiosList([
      ...mailsAlertCambiosList,
      { label: "", value: mailsAlertCambiosList.length.toString(), hidden: false }
    ])
  }

  // ----------------------------------
  const handleChange2 = (id, next): void => {
    const listTemporal = mailsUsuariosSinLoginList.map(item => {
      if (item.value === id) item.label = next
      return item
    })
    setMailsUsuariosSinLoginList(listTemporal)
  }
  const handleDelete2 = (id): void => {
    setMailsUsuariosSinLoginList(
      mailsUsuariosSinLoginList.map(item => {
        if (item.value === id) item.hidden = true
        return item
      })
    )
  }
  const handleAdd2 = (): void => {
    setMailsUsuariosSinLoginList([
      ...mailsUsuariosSinLoginList,
      { label: "", value: mailsUsuariosSinLoginList.length.toString(), hidden: false }
    ])
  }

  // ----------------------------------
  const handleChange3 = (id, next): void => {
    const listTemporal = mailsRegistroUsuariosList.map(item => {
      if (item.value === id) item.label = next
      return item
    })
    setMailsRegistroUsuariosList(listTemporal)
  }
  const handleDelete3 = (id): void => {
    setMailsRegistroUsuariosList(
      mailsRegistroUsuariosList.map(item => {
        if (item.value === id) item.hidden = true
        return item
      })
    )
  }
  const handleAdd3 = (): void => {
    setMailsRegistroUsuariosList([
      ...mailsRegistroUsuariosList,
      { label: "", value: mailsRegistroUsuariosList.length.toString(), hidden: false }
    ])
  }

  // ----------------------------------
  const handleChange4 = (id, next): void => {
    const listTemporal = mailsCifPricingList.map(item => {
      if (item.value === id) item.label = next
      return item
    })
    setMailsCifPricingList(listTemporal)
  }
  const handleDelete4 = (id): void => {
    setMailsCifPricingList(
      mailsCifPricingList.map(item => {
        if (item.value === id) item.hidden = true
        return item
      })
    )
  }
  const handleAdd4 = (): void => {
    setMailsCifPricingList([
      ...mailsCifPricingList,
      { label: "", value: mailsCifPricingList.length.toString(), hidden: false }
    ])
  }

  // ----------------------------------
  const handleChange5 = (id, next): void => {
    const listTemporal = mailsNuevosClientesList.map(item => {
      if (item.value === id) item.label = next
      return item
    })
    setMailsNuevosClientesList(listTemporal)
  }
  const handleDelete5 = (id): void => {
    setMailsNuevosClientesList(
      mailsNuevosClientesList.map(item => {
        if (item.value === id) item.hidden = true
        return item
      })
    )
  }
  const handleAdd5 = (): void => {
    setMailsNuevosClientesList([
      ...mailsNuevosClientesList,
      { label: "", value: mailsNuevosClientesList.length.toString(), hidden: false }
    ])
  }

  // ----------------------------------
  const handleChange6 = (id, next): void => {
    const listTemporal = mailsSolicitudFacturaList.map(item => {
      if (item.value === id) item.label = next
      return item
    })
    setMailsSolicitudFacturaList(listTemporal)
  }
  const handleDelete6 = (id): void => {
    setMailsSolicitudFacturaList(
      mailsSolicitudFacturaList.map(item => {
        if (item.value === id) item.hidden = true
        return item
      })
    )
  }
  const handleAdd6 = (): void => {
    setMailsSolicitudFacturaList([
      ...mailsSolicitudFacturaList,
      { label: "", value: mailsSolicitudFacturaList.length.toString(), hidden: false }
    ])
  }

  const handleChange8 = (id, next): void => {
    const listTemporal = mailsClienteSinComercialAsignadoList.map(item => {
      if (item.value === id) item.label = next
      return item
    })
    setMailsClienteSinComercialAsignadoList(listTemporal)
  }
  const handleDelete8 = (id): void => {
    setMailsClienteSinComercialAsignadoList(
      mailsClienteSinComercialAsignadoList.map(item => {
        if (item.value === id) item.hidden = true
        return item
      })
    )
  }
  const handleAdd8 = (): void => {
    setMailsClienteSinComercialAsignadoList([
      ...mailsClienteSinComercialAsignadoList,
      { label: "", value: mailsClienteSinComercialAsignadoList.length.toString(), hidden: false }
    ])
  }

  useEffect(() => {
    if (mailsAlertaCambios != null) setMailsAlertCambiosList(mailsAlertaCambios)
  }, [mailsAlertaCambios])

  useEffect(() => {
    if (mailsUsuariosSinLogin != null) setMailsUsuariosSinLoginList(mailsUsuariosSinLogin)
  }, [mailsUsuariosSinLogin])

  useEffect(() => {
    if (mailsRegistroUsuarios != null) setMailsRegistroUsuariosList(mailsRegistroUsuarios)
  }, [mailsRegistroUsuarios])

  useEffect(() => {
    if (mailsCifPricing != null) setMailsCifPricingList(mailsCifPricing)
  }, [mailsCifPricing])

  useEffect(() => {
    if (mailsNuevosClientes != null) setMailsNuevosClientesList(mailsNuevosClientes)
  }, [mailsNuevosClientes])

  useEffect(() => {
    if (mailsSolicitudFactura != null) setMailsSolicitudFacturaList(mailsSolicitudFactura)
  }, [mailsSolicitudFactura])

  useEffect(() => {
    if (mailsClienteAlertaSinContacto != null) setMailsClienteAlertaSinContactoList(mailsClienteAlertaSinContacto)
  }, [mailsClienteAlertaSinContacto])

  useEffect(() => {
    if (mailsClienteSinComercialAsignado != null)
      setMailsClienteSinComercialAsignadoList(mailsClienteSinComercialAsignado)
  }, [mailsClienteSinComercialAsignado])

  useEffect(() => {
    if (days != null) setDaysUpdate(days)
  }, [days])

  useEffect(() => {
    if (clienteDiasSinContacto != null) setClienteDiasSinContactoNumber(clienteDiasSinContacto)
  }, [clienteDiasSinContacto])

  useEffect(() => {
    if (clienteDiasSinEventos != null) setClienteDiasSinEventosNumber(clienteDiasSinEventos)
  }, [clienteDiasSinEventos])

  return (
    <>
      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "5px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.CHANGE_ALERT_EMAILS)}
          </div>
          {mailsAlertCambiosList.length > 0 &&
            mailsAlertCambiosList
              .filter(item => !item.hidden)
              .map(item => {
                return (
                  <div
                    key={item.value?.toString()}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <InputCustom2
                      disabled={!canEdit}
                      width="100%"
                      name={item?.value}
                      value={item.label}
                      handleChange={handleChange}
                      handleDelete={handleDelete}
                      errors={
                        activeValidationMail && !valMail(item?.label)
                          ? TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT
                          : ""
                      }
                    />
                  </div>
                )
              })}
          {canEdit && (
            <div style={{ width: "calc(100% - 45px)", display: "flex", justifyContent: "center", margin: "15px 0" }}>
              <ContainerIcon
                themeContext={themeContext}
                onClick={() => {
                  handleAdd()
                }}
                background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              >
                <IconPlus2 color="white" size={15} />
              </ContainerIcon>
            </div>
          )}
        </ContainerMails>
      </ContainerGroup>
      <Divisor />
      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "5px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.USER_WITHOUT_LOGIN_EMAIILS)}
          </div>
          {mailsUsuariosSinLoginList.length > 0 &&
            mailsUsuariosSinLoginList
              .filter(item => !item.hidden)
              .map(item => {
                return (
                  <div
                    key={item.value?.toString()}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <InputCustom2
                      disabled={!canEdit}
                      width="100%"
                      name={item?.value}
                      value={item.label}
                      handleChange={handleChange2}
                      handleDelete={handleDelete2}
                      errors={
                        activeValidationMail && !valMail(item?.label)
                          ? TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT
                          : ""
                      }
                    />
                  </div>
                )
              })}
          {canEdit && (
            <div style={{ width: "calc(100% - 45px)", display: "flex", justifyContent: "center", margin: "15px 0" }}>
              <ContainerIcon
                themeContext={themeContext}
                onClick={() => {
                  handleAdd2()
                }}
                background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              >
                <IconPlus2 color="white" size={15} />
              </ContainerIcon>
            </div>
          )}
        </ContainerMails>
      </ContainerGroup>
      <Divisor />
      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "5px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.USER_REGISTRATION_EMAILS)}
          </div>
          {mailsRegistroUsuariosList.length > 0 &&
            mailsRegistroUsuariosList
              .filter(item => !item.hidden)
              .map(item => {
                return (
                  <div
                    key={item.value?.toString()}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <InputCustom2
                      disabled={!canEdit}
                      width="100%"
                      name={item?.value}
                      value={item.label}
                      handleChange={handleChange3}
                      handleDelete={handleDelete3}
                      errors={
                        activeValidationMail && !valMail(item?.label)
                          ? TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT
                          : ""
                      }
                    />
                  </div>
                )
              })}
          {canEdit && (
            <div style={{ width: "calc(100% - 45px)", display: "flex", justifyContent: "center", margin: "15px 0" }}>
              <ContainerIcon
                themeContext={themeContext}
                onClick={() => {
                  handleAdd3()
                }}
                background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              >
                <IconPlus2 color="white" size={15} />
              </ContainerIcon>
            </div>
          )}
        </ContainerMails>
      </ContainerGroup>
      <Divisor />
      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "5px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.PRICING_FOR_CIF)}
          </div>
          {mailsCifPricingList.length > 0 &&
            mailsCifPricingList
              .filter(item => !item.hidden)
              .map(item => {
                return (
                  <div
                    key={item.value?.toString()}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <InputCustom2
                      disabled={!canEdit}
                      width="100%"
                      name={item?.value}
                      value={item.label}
                      handleChange={handleChange4}
                      handleDelete={handleDelete4}
                      errors={
                        activeValidationMail && !valMail(item?.label)
                          ? TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT
                          : ""
                      }
                    />
                  </div>
                )
              })}
          {canEdit && (
            <div style={{ width: "calc(100% - 45px)", display: "flex", justifyContent: "center", margin: "15px 0" }}>
              <ContainerIcon
                themeContext={themeContext}
                onClick={() => {
                  handleAdd4()
                }}
                background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              >
                <IconPlus2 color="white" size={15} />
              </ContainerIcon>
            </div>
          )}
        </ContainerMails>
      </ContainerGroup>
      <Divisor />
      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "5px" }}>{t(TRANSLATION_INDEX_PREFERENCE.NEW_CUSTOMER)}</div>
          {mailsNuevosClientesList.length > 0 &&
            mailsNuevosClientesList
              .filter(item => !item.hidden)
              .map(item => {
                return (
                  <div
                    key={item.value?.toString()}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <InputCustom2
                      disabled={!canEdit}
                      width="100%"
                      name={item?.value}
                      value={item.label}
                      handleChange={handleChange5}
                      handleDelete={handleDelete5}
                      errors={
                        activeValidationMail && !valMail(item?.label)
                          ? TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT
                          : ""
                      }
                    />
                  </div>
                )
              })}
          {canEdit && (
            <div style={{ width: "calc(100% - 45px)", display: "flex", justifyContent: "center", margin: "15px 0" }}>
              <ContainerIcon
                themeContext={themeContext}
                onClick={() => {
                  handleAdd5()
                }}
                background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              >
                <IconPlus2 color="white" size={15} />
              </ContainerIcon>
            </div>
          )}
        </ContainerMails>
      </ContainerGroup>
      <Divisor />
      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "5px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.REQUEST_INVOICE)}
          </div>
          {mailsSolicitudFacturaList.length > 0 &&
            mailsSolicitudFacturaList
              .filter(item => !item.hidden)
              .map(item => {
                return (
                  <div
                    key={item.value?.toString()}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <InputCustom2
                      disabled={!canEdit}
                      width="100%"
                      name={item?.value}
                      value={item.label}
                      handleChange={handleChange6}
                      handleDelete={handleDelete6}
                      errors={
                        activeValidationMail && !valMail(item?.label)
                          ? TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT
                          : ""
                      }
                    />
                  </div>
                )
              })}
          {canEdit && (
            <div style={{ width: "calc(100% - 45px)", display: "flex", justifyContent: "center", margin: "15px 0" }}>
              <ContainerIcon
                themeContext={themeContext}
                onClick={() => {
                  handleAdd6()
                }}
                background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              >
                <IconPlus2 color="white" size={15} />
              </ContainerIcon>
            </div>
          )}
        </ContainerMails>
      </ContainerGroup>
      <Divisor />

      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "5px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.CUSTOMER_WO_ASSIGMENT_COMMERCIAL)}
          </div>
          {mailsClienteSinComercialAsignadoList.length > 0 &&
            mailsClienteSinComercialAsignadoList
              .filter(item => !item.hidden)
              .map(item => {
                return (
                  <div
                    key={item.value?.toString()}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <InputCustom2
                      disabled={!canEdit}
                      width="100%"
                      name={item?.value}
                      value={item.label}
                      handleChange={handleChange8}
                      handleDelete={handleDelete8}
                      errors={
                        activeValidationMail && !valMail(item?.label)
                          ? TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT
                          : ""
                      }
                    />
                  </div>
                )
              })}
          {canEdit && (
            <div style={{ width: "calc(100% - 45px)", display: "flex", justifyContent: "center", margin: "15px 0" }}>
              <ContainerIcon
                themeContext={themeContext}
                onClick={() => {
                  handleAdd8()
                }}
                background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
                backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              >
                <IconPlus2 color="white" size={15} />
              </ContainerIcon>
            </div>
          )}
        </ContainerMails>
      </ContainerGroup>
      <Divisor />

      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "2px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.QUANTITY_DAYS_CHANGES)}
          </div>
          <div style={{ fontSize: "0.7rem", marginBottom: "5px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.QUANTITY_DAYS_CHANGES_SUBTITLE)}
          </div>
          <InputCustomSet
            disabled={!canEdit}
            type={INPUT_TYPE.NUMBER}
            name="days"
            value={daysUpdate}
            setValue={setDaysUpdate}
          />
        </ContainerMails>
      </ContainerGroup>
      <Divisor />

      <ContainerGroup>
        <ContainerMails themeContext={themeContext}>
          <div style={{ fontSize: "1.1rem", marginBottom: "2px" }}>
            {t(TRANSLATION_INDEX_PREFERENCE.CUSTOMER_WO_EVENTS)}
          </div>
          <InputCustomSet
            disabled={!canEdit}
            type={INPUT_TYPE.NUMBER}
            name="days"
            value={clienteDiasSinEventosNumber}
            setValue={setClienteDiasSinEventosNumber}
          />
        </ContainerMails>
      </ContainerGroup>
      {canEdit && (
        <ContainerButom themeContext={themeContext}>
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.CANCEL) ?? ""}
            background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
            width="100PX"
            onClick={() => {
              location.reload()
            }}
          />
          <ButtonCustom
            marginLeft
            label={t(TRANSLATION_INDEX_GLOBAL.ACCEPT) ?? ""}
            background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
            width="100PX"
            onClick={sendData}
          />
        </ContainerButom>
      )}
    </>
  )
}

export default Poc
