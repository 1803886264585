// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  postLoginChangeFailure,
  postLoginChangeSuccess,
  postLoginFailure,
  postLoginForgotFailure,
  postLoginForgotSuccess,
  postLoginSuccess
} from "../actions"

import { postLogin, postLoginChange, postLoginForgot } from "../services"

function* postLoginWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postLogin, request.payload)
    yield put(postLoginSuccess(data))
  } catch (error) {
    yield put(postLoginFailure(error))
  }
}

function* postLoginForgotWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postLoginForgot, request.payload)
    yield put(postLoginForgotSuccess(data))
  } catch (error) {
    yield put(postLoginForgotFailure(error))
  }
}

function* postLoginChangeWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(postLoginChange, request.payload)
    yield put(postLoginChangeSuccess(data))
  } catch (error) {
    yield put(postLoginChangeFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.LOGIN_POST_REQUEST, postLoginWorker)
  yield takeLatest(types.LOGIN_FORGOT_POST_REQUEST, postLoginForgotWorker)
  yield takeLatest(types.LOGIN_CHANGE_POST_REQUEST, postLoginChangeWorker)
}

export const sagas = [listWatcher]
