import React, { type ReactElement } from "react"
import { CloseIcon, Container, ContainerBlur, Menu } from "./_styles"
import { type Props } from "./_types"
import { menu } from "../../layouts/Header/data"
import SubItem from "./components/SubItem"
import { menuConfig } from "./dataConfig"
import { AiOutlineClose } from "react-icons/ai"
import { useGlobalContext } from "../../context/GlobalContext"

const MenuMobile: React.FC<Props> = ({ closeMenu }): ReactElement => {
  const { themeContext } = useGlobalContext()
  return (
    <Container themeContext={themeContext}>
      <ContainerBlur onClick={closeMenu} />
      <CloseIcon themeContext={themeContext} onClick={closeMenu}>
        <AiOutlineClose size={30} />
      </CloseIcon>
      <Menu themeContext={themeContext}>
        {menu.map(item => {
          return <SubItem key={item.label} menu={item} />
        })}
        {menuConfig.map(item => {
          return <SubItem key={item.label} menu={item} />
        })}
      </Menu>
    </Container>
  )
}

export default MenuMobile
