import React, { type ReactElement } from "react"
import { INPUT_TYPE } from "../../utils/constants"
import { Container, ContainerInput, Input, Label } from "./_styles"
import { type Props } from "./_types"
import { useTranslation } from "react-i18next"
import { useGlobalContext } from "../../context/GlobalContext"

const CheckboxCustom = ({
  name,
  label = "",
  value,
  options = [],
  handleChange,
  type = INPUT_TYPE.TEXT,
  placeHolder = "",
  width = "100%",
  marginLeft = false,
  disabled = false,
  iconLeft = false,
  iconRight = false,
  errors = "",
  autoFocus = false,
  touched,
  validError = true,
  isTextArea = false,
  style,
  borderRadius = "5px",
  colorType = ""
}: Props): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()

  return (
    <Container themeContext={themeContext} width={width} marginLeft={marginLeft} data-testid="containerInput">
      {options.map(item => {
        return (
          <ContainerInput key={item.value}>
            <Input
              themeContext={themeContext}
              key={item.value}
              value={item.value}
              onChange={() => {
                handleChange(name, item)
              }}
              checked={item.value === value?.value}
              type={INPUT_TYPE.RADIO}
            />
            <Label themeContext={themeContext}>{t(item.label ?? "")}</Label>
          </ContainerInput>
        )
      })}
    </Container>
  )
}

export default CheckboxCustom
