import React, { type ReactElement } from "react"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { Container } from "./_styles"
import { type Props } from "./_types"
import { useNavigate } from "react-router-dom"
import { getAuth } from "../../utils/functions"
import { useGlobalContext } from "../../context/GlobalContext"

const Item: React.FC<Props> = ({ label, route, setHoverActiveMain, submenu, permission }): ReactElement => {
  const [hoverActive, setHoverActive] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { themeContext } = useGlobalContext()
  const handleRoute = (): void => {
    if (route !== undefined) {
      setHoverActiveMain(false)
      navigate(route)
    }
  }
  if (!permission.some(item => getAuth()[2].includes(item))) return <></>

  return (
    <Container
      showPaddingRight={!(hoverActive && submenu != null && submenu?.length > 0)}
      themeContext={themeContext}
      data-testid="submenu-item"
      onMouseEnter={() => {
        setHoverActive(true)
      }}
      onMouseLeave={() => {
        setHoverActive(false)
      }}
      background={
        hoverActive ? GLOBAL_STYLES.BACKGROUND.TYPE_8[themeContext] : GLOBAL_STYLES.BACKGROUND.TYPE_7[themeContext]
      }
      color={GLOBAL_STYLES.LABEL.TYPE_2[themeContext]}
      onClick={() => {
        handleRoute()
      }}
    >
      {label}
    </Container>
  )
}

export default Item
