/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable prettier/prettier */
/* eslint-disable no-extra-boolean-cast */
import * as Yup from "yup"
import { schemeObject, schemeStringRequeredCuit } from "../../utils/constants"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_RESPONSE_CODE } from "../../config/translation"

export const deleteScheme = Yup.object().shape({})

export const schemaValideCuit = Yup.object().shape({
  cuit: schemeStringRequeredCuit()
})

export const schemaAdd = Yup.object().shape({
  cuit: schemeStringRequeredCuit(),
  razonSocial: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED).matches(/^[^\s]+/, TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER),
  clienteTipo: schemeObject(),
  esCargaCIF: schemeObject(),
  esImportador: Yup.object()
    .nullable()
    .when("esCargaCIF", {
      is: (val) => val?.value.toString() === "false",
      then: schema => schema.required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
      otherwise: schema => schema
    }),
  esExportador: Yup.object()
  .nullable()
  .when("esCargaCIF", {
    is: (val) => val?.value.toString() === "false",
    then: schema => schema.required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
    otherwise: schema => schema
  }),
  telefono: Yup.string().nullable().max(50, TRANSLATION_INDEX_GLOBAL.MAX_50).matches(/^[^\s]+/, TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER),
  canalOrigen: Yup.string().nullable().max(255, TRANSLATION_INDEX_GLOBAL.MAX_255).matches(/^[^\s]+/, TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER),
  email: Yup.string()
    .nullable()
    .matches(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/, TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT),
  domicilio: Yup.string().nullable().max(255, TRANSLATION_INDEX_GLOBAL.MAX_255).matches(/^[^\s]+/, TRANSLATION_INDEX_RESPONSE_CODE.ENTER_USER)
})

export const schemaContact = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .matches(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/, TRANSLATION_INDEX_GLOBAL.EMAIL_FORMAT_INCORRECT)
})
