/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"

import "../../styles/styles.css"

import { Calendar, dateFnsLocalizer } from "react-big-calendar"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import enUS from "date-fns/locale/en-US"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { useGlobalContext } from "../../context/GlobalContext"
import { TRANSLATION_INDEX_CALENDAR, TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_MENU } from "../../config/translation"
import ContainerPage from "../../components/ContainerPage"
import { useTranslation } from "react-i18next"

import IconPlus from "../../components/Icons/IconPlus"
import { ACTIONS, PERMISSION } from "../../utils/constants"
import AlertModal from "../../components/AlertModal"
import FormaddUpdate from "./forms/addUpdate/FormaddUpdate"
import { lang } from "./culture"
import { es } from "date-fns/locale/es"
import { getAuth, getCurrentLanguage } from "../../utils/functions"

import { STORAGE } from "../../utils/localStorage"
import { useDispatch, useSelector } from "react-redux"
import {
  addRemendersRequest,
  clearAllRemenderss,
  deleteRemendersRequest,
  getRemendersRequest,
  putRemendersRequest
} from "../../state/modules/remenders/actions"
import { remendersSelector } from "../../state/modules/remenders/_selectors"
import { toast } from "react-toastify"
import { addEventRequest, getTypeEventRequest } from "../../state/modules/event/actions"
import FormAddUpdate from "../Event/forms/addUpdate/FormaddUpdate"
import IconDelete from "../../components/Icons/IconDelete"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { eventSelector } from "../../state/modules/event/_selectors"

const mod = STORAGE.get(STORAGE.THEME) === "DARK" ? "./styles_dark.css" : "./styles_light.css"
import(`${mod}`)

const CalenderPage = (): ReactElement => {
  const { loadingContext, themeContext } = useGlobalContext()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const remenders = useSelector(remendersSelector)
  const eventsGlobal = useSelector(eventSelector)
  const { setLoadingContext } = useGlobalContext()
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [remenderSelected, setRemenderSelected] = React.useState<any>(null)
  const [action, setAction] = React.useState<string>("")
  const [openModalEvent, setOpenModalEvent] = React.useState(false)
  const [events, setEvents] = React.useState<any>([
    {
      fromDate: new Date(189234732423),
      toDate: new Date(189234732485),
      title: "Evento Test"
    }
  ])
  const locales = {
    es,
    en: enUS
  }

  const handleAdd = (): void => {
    handleOpenModal(ACTIONS.ADD.VALUE)
  }
  const handleOpenModal = (actionValue = ""): void => {
    if (typeof actionValue !== "string") actionValue = action
    if (openModal) {
      setRemenderSelected(null)
    }
    setAction(actionValue)
    setOpenModal(!openModal)
  }

  const handleOpenModalEvent = (): void => {
    setAction(openModalEvent ? "edit" : "add_event")
    setOpenModal(!openModal)
    setOpenModalEvent(!openModalEvent)
  }

  const handleDeleteRemender = (value: any): void => {
    setAction(ACTIONS.DELETE.VALUE)
  }

  const handleDispatch = (values: any): void => {
    switch (action) {
      case "add_event":
        dispatch(addEventRequest(values))
        break
      case ACTIONS.DELETE.VALUE:
        dispatch(deleteRemendersRequest(values.idRecordatorio))
        break
      case ACTIONS.ADD.VALUE:
        dispatch(addRemendersRequest(values))
        break
      case ACTIONS.EDIT.VALUE:
        dispatch(putRemendersRequest(values))
        break
      default:
        break
    }
  }

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
  })

  const getButtonToEdit = (): any => {
    let listButton: any[] = []

    if (action !== ACTIONS.EDIT.VALUE) return []

    if (getAuth(PERMISSION.RECORDATORIOS_ELIMINAR)[0])
      listButton = listButton.concat([
        {
          label: TRANSLATION_INDEX_CALENDAR.DELETE_REMENDER,
          background: GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext],
          backgroundHover: GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext],
          icon: <IconDelete size={13} />,
          click: handleDeleteRemender
        }
      ])

    if (getAuth(PERMISSION.EVENTOS_CREAR)[0])
      listButton = listButton.concat([
        {
          label: TRANSLATION_INDEX_CALENDAR.ADD_EVENT,
          icon: <IconPlus size={13} />,
          click: handleOpenModalEvent
        }
      ])

    return listButton
  }

  React.useEffect(() => {
    if (remenders.showToast) {
      if (remenders.hasErrMessage) {
        toast.error(t(remenders.errorCode))
      } else {
        toast.success(t(remenders.toastMessage))
        handleOpenModal()
        dispatch(getRemendersRequest(""))
      }
    }
  }, [remenders.showToast])

  React.useEffect(() => {
    if (eventsGlobal.showToast) {
      if (eventsGlobal.hasErrMessage) {
        toast.error(t(eventsGlobal.errorCode))
      } else {
        toast.success(t(eventsGlobal.toastMessage))
        handleOpenModal(ACTIONS.EDIT.VALUE)
        handleOpenModalEvent()
      }
    }
  }, [eventsGlobal.showToast])

  React.useEffect(() => {
    setLoadingContext(remenders.loading)
  }, [remenders])

  React.useEffect(() => {
    setEvents(
      remenders.data?.map(item => {
        return {
          id: item.idRecordatorio,
          fromDate: new Date(item.fechaHoraInicio),
          toDate: new Date(item.fechaHoraFin),
          title: `${item.cliente.razonSocial}${
            item?.contacto?.apellido ? ` (${item.contacto.apellido} ${item.contacto.nombre})` : ""
          }: ${item.comentario ?? ""}`
        }
      })
    )
  }, [remenders.data])

  React.useEffect(() => {
    if (getAuth(PERMISSION.EVENTOS_CREAR)[0]) dispatch(getTypeEventRequest())
    dispatch(getRemendersRequest(""))
    return () => {
      dispatch(clearAllRemenderss())
    }
  }, [])

  const onSelectEvent = (data): void => {
    const valueRemender = remenders.data?.find(item => item.idRecordatorio === data.id)
    setRemenderSelected(valueRemender)
    handleOpenModal(ACTIONS.EDIT.VALUE)
  }

  return (
    <ContainerPage
      title={`${t(TRANSLATION_INDEX_MENU.REMINDERS)}`}
      actions={[
        {
          label: t(TRANSLATION_INDEX_GLOBAL.ADD),
          icon: <IconPlus size={13} />,
          permission: PERMISSION.RECORDATORIOS_CREAR,
          click: () => {
            handleAdd()
          }
        }
      ]}
    >
      <>
        {openModal && (
          <AlertModal
            actions={getButtonToEdit()}
            activeCloseModal
            maxWidth="70%"
            closeModal={() => {
              handleOpenModal()
            }}
            title={`${t(TRANSLATION_INDEX_MENU.REMINDERS)} | ${t(
              TRANSLATION_INDEX_GLOBAL[action?.toUpperCase() ?? "-"]
            )}`}
          >
            <FormaddUpdate
              data={remenderSelected}
              action={action}
              setAction={setAction}
              closeModal={handleOpenModal}
              loading={loadingContext}
              accept={handleDispatch}
            />
          </AlertModal>
        )}
        {openModalEvent && (
          <AlertModal
            activeCloseModal
            maxWidth="70%"
            closeModal={() => {
              handleOpenModalEvent()
            }}
            title={`${t(TRANSLATION_INDEX_MENU.EVENTS)} | ${t(TRANSLATION_INDEX_GLOBAL.ADD)}`}
          >
            <FormAddUpdate
              data={{
                idRecordatorio: remenderSelected.idRecordatorio,
                cliente: remenderSelected.cliente,
                contacto: remenderSelected.contacto,
                fecha: remenderSelected.fechaHoraInicio,
                comentario: remenderSelected.comentario
              }}
              action={"add_event"}
              closeModal={handleOpenModalEvent}
              loading={loadingContext}
              accept={handleDispatch}
            />
          </AlertModal>
        )}
        <Calendar
          culture={getCurrentLanguage()}
          localizer={localizer}
          messages={lang[getCurrentLanguage()]}
          events={events ?? []}
          startAccessor="fromDate"
          onSelectEvent={onSelectEvent}
          endAccessor="toDate"
          style={{ height: `${window.innerHeight - 180}px`, width: "100%" }}
        />
      </>
    </ContainerPage>
  )
}

export default CalenderPage
