/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/no-unescaped-entities */
import React, { type ReactElement } from "react"

import { ACTIONS } from "../../../../utils/constants"

import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"

import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../../_schema"
import { ButtonDelete, Container, ContainerItem, Text } from "./_styles"
import { useDropzone } from "react-dropzone"
import { useGlobalContext } from "../../../../context/GlobalContext"
import { TRANSLATION_INDEX_GLOBAL } from "../../../../config/translation"
import { useTranslation } from "react-i18next"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"

const Step4 = ({ data, handleSubmit, setDataFiltered }: IFormListProps): ReactElement => {
  const [filesStep4, setFilesStep4] = React.useState<any[]>([])
  const [myFiles, setMyFiles] = React.useState<any[]>([])
  const { themeContext } = useGlobalContext()
  const { t } = useTranslation()

  const convertToBase64 = (file): void => {
    if (!filesStep4.some(item => item.nombreArchivo === file.name)) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        const base64 = reader.result
        setFilesStep4([...filesStep4, { nombreArchivo: file.name, contenidoArchivo: base64 }])
      }
    }
  }

  const onDrop = React.useCallback(
    acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles])
    },
    [myFiles]
  )

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop
  })
  acceptedFiles.forEach((file: any) => {
    convertToBase64(file)
  })

  const handleDelete = (file): void => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
  }

  React.useEffect(() => {
    if (myFiles.length > 0) {
      const newData = myFiles.map(item => {
        const newItem = {
          nombreArchivo: item.name,
          contenidoArchivo: filesStep4
            .find(file => file?.nombreArchivo === item?.name)
            ?.contenidoArchivo.split("base64,")[1]
        }
        return newItem
      })
      if (setDataFiltered) {
        setDataFiltered(newData)
      }
    } else if (setDataFiltered) setDataFiltered([])
  }, [myFiles, filesStep4])

  return (
    <Container onSubmit={handleSubmit}>
      <div style={{ marginBottom: "10px" }}>
        <section
          style={{
            width: "100%",
            textAlign: "center",
            margin: "20px 0"
          }}
        >
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p
              style={{
                padding: "35px 0",
                cursor: "pointer",
                border: `dotted 2px ${GLOBAL_STYLES.LABEL.TYPE_5[themeContext]}`
              }}
            >
              {t(TRANSLATION_INDEX_GLOBAL.CLICK_OR_DRAG)}
            </p>
          </div>
          <aside></aside>
        </section>
        {myFiles.length > 0 && (
          <div style={{ display: "flex", flexFlow: "row wrap" }}>
            <span style={{ marginRight: "15px" }}>{t(TRANSLATION_INDEX_GLOBAL.ATTANCHMENT)}:</span>
            {myFiles.map(item => {
              return (
                <ContainerItem key={item.name}>
                  <Text themeContext={themeContext}>{item.name}</Text>{" "}
                  <ButtonDelete
                    themeContext={themeContext}
                    onClick={() => {
                      handleDelete(item)
                    }}
                  >
                    X
                  </ButtonDelete>
                </ContainerItem>
              )
            })}
          </div>
        )}
      </div>
    </Container>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) accept(values)
    setSubmitting(false)
  }
})(Step4)
