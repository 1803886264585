import styled from "styled-components"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../../../interfaces/IStyle"

export const Container = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
export const Group = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const GroupButtom = styled.div<IStyle>`
  width: calc(100% - 30px);
  display: flex;
  padding: 10px 15px;
  justify-content: flex-end;
  border-top: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
`

export const ContainerGroup = styled.div<IStyle>`
  min-height: 70px;
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 45px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`

export const ContainerMessage = styled.div<IStyle>`
  min-height: 70px;
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 45px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`
