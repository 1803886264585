/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, type ReactElement } from "react"
import Select from "react-select"
import { Container, ContainerIcon, ContainerInput, Label } from "./_styles"
import { type Props } from "./_types"
import IconSearch from "../Icons/IconSearch"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { isStringEmpty } from "../../utils/functions"
import { FormHelperText } from "@mui/material"
import { useTranslation } from "react-i18next"
import { TRANSLATION_INDEX_GLOBAL } from "../../config/translation"
import { useGlobalContext } from "../../context/GlobalContext"

const SelectCustom = ({
  options = [],
  isMulti = false,
  components,
  isDisabled,
  errors,
  name,
  label = "",
  value,
  handleChange,
  iconLeft = false,
  iconRight = false,
  placeHolder = "",
  setOptionIsNull,
  width = "100%",
  marginLeft = false,
  validError = true,
  defaultValue,
  maxMenuHeight = 140,
  listTop = false,
  handleKeyPressEnter
}: //
// filterOption = []
Props): ReactElement => {
  const { t } = useTranslation()
  const [keyPressInput, setKeyPressInput] = React.useState<number>(0)
  const error = t(typeof errors === "string" ? errors : errors?.value)
  const { themeContext } = useGlobalContext()
  const handleKey = (name, e): void => {
    setKeyPressInput(e.keyCode)
  }

  useEffect(() => {
    if (keyPressInput === 13 && handleKeyPressEnter != null) {
      handleKeyPressEnter(name, 13)
      setKeyPressInput(0)
    }
  }, [value, keyPressInput])

  return (
    <Container themeContext={themeContext} data-testid="select" width={width} marginLeft={marginLeft}>
      {!isStringEmpty(label) && <Label themeContext={themeContext}>{label}</Label>}
      <ContainerInput>
        {iconLeft && (
          <ContainerIcon
            themeContext={themeContext}
            iconLeft={iconLeft}
            background={iconLeft && GLOBAL_STYLES.BACKGROUND.TYPE_6[themeContext]}
          >
            <IconSearch color="#495057" />
          </ContainerIcon>
        )}
        <Select
          aria-sort="ascending"
          menuPlacement={listTop ? "top" : "bottom"}
          // onKeyDown={e => {
          //   handleKey(name, e)
          // }}
          defaultValue={defaultValue}
          noOptionsMessage={() => t(TRANSLATION_INDEX_GLOBAL.NO_OPTIONS)}
          loadingMessage={() => t(TRANSLATION_INDEX_GLOBAL.LOADING)}
          maxMenuHeight={maxMenuHeight}
          // filterOption={filterOption || options}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              cursor: state.isDisabled ? "no-drop" : "pointer",
              border: !isStringEmpty(error)
                ? "1px solid red !important"
                : state.isFocused
                ? "1px solid #ececec !important"
                : " 1px solid #c0c0c0",
              boxShadow: state.isFocused ? " 0px 0px 0px 1px #bebebe  !important" : "none !important",
              minHeight: "42px",
              background: state.isDisabled && themeContext === "DARK" ? "#f6f6f6" : "",
              fontFamily: `${GLOBAL_STYLES.FONT.FAMILY}`,
              fontSize: "0.8rem !important",
              zIndex: 50,
              borderRadius: iconRight ? "5px 0px 0px 5px" : iconLeft ? "0px 5px 5px 0px" : "5px"
            }),
            noOptionsMessage: (baseStyle, state) => ({
              ...baseStyle,
              textAlign: "left"
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              color: `#585858`,
              // top: "35px",
              zIndex: 99999,
              marginBottom: listTop ? "0px" : "9px",
              marginTop: listTop ? "-6px" : "9px",
              top: listTop ? "" : "35px"
            }),
            container: (baseStyles, state) => ({
              ...baseStyles,
              width: "100%",
              fontSize: "0.8rem !important",
              fontFamily: `${GLOBAL_STYLES.FONT.FAMILY}`,
              borderShadow: "none !important"
            })
          }}
          isDisabled={isDisabled}
          name={name}
          isMulti={isMulti}
          isClearable
          isSearchable
          options={options}
          value={value}
          onKeyDown={e => {
            handleKey(name, e)
          }}
          onChange={e => {
            if (setOptionIsNull != null) {
              if (e === null) setOptionIsNull(true)
              else setOptionIsNull(false)
            }
            handleChange(name, e)
          }}
          placeholder={placeHolder}
          className="react-select__menu"
          classNamePrefix="react-select"
        />
      </ContainerInput>
      {validError && (
        <FormHelperText style={{ color: GLOBAL_STYLES.LABEL.TYPE_4[themeContext] }}>{error}</FormHelperText>
      )}
    </Container>
  )
}

export default SelectCustom
