import styled from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../interfaces/IStyle"

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center !important;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
`

export const Gretting = styled.div<IStyle>`
  position: relative;
  display: flex;
  align-items: center;
  height: calc(100vh - 120px);
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  font-size: 4rem;
`

export const ContainerRowTask = styled.div<IStyle>`
  display: flex;
  justify-content: flex-start;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
  padding: 5px 5px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
`
