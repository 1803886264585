// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getCustomerFailure,
  getCustomerSuccess,
  addCustomerSuccess,
  addCustomerFailure,
  deleteCustomerSuccess,
  deleteCustomerFailure,
  putCustomerSuccess,
  putCustomerFailure,
  getClientTypeSuccess,
  getAssignedCommercialSuccess,
  getAssignedCommercialFailure,
  getTermsSuccess,
  getTermsFailure,
  getCustomerByFilterFailure,
  getCustomerByFilterSuccess,
  getCustomerExcelSuccess,
  getCustomerExcelFailure,
  getCountryFailure,
  getCountrySuccess,
  getClientTypeFailure,
  getClassificationFailure,
  getClassificationSuccess,
  getCustomerByIdSuccess,
  getCustomerByIdFailure,
  postBulkAssignmentSuccess,
  postBulkAssignmentFailure,
  postReBulkAssignmentSuccess,
  postReBulkAssignmentFailure
} from "../actions"

import {
  addCustomer,
  bulkAssignmentCustomer,
  deleteCustomer,
  getAssignedCommercial,
  getClassification,
  getClientType,
  getCountries,
  getCustomer,
  getCustomerById,
  getCustomerExcel,
  getTerms,
  putCustomer,
  reBulkAssignmentCustomer
} from "../services"

function* getTermsWorker(): SagaIterator {
  try {
    const { data } = yield call(getTerms)
    yield put(getTermsSuccess(data))
  } catch (error) {
    yield put(getTermsFailure(error))
  }
}

function* getClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getCustomer, request.payload)
    yield put(getCustomerSuccess(data))
  } catch (error) {
    yield put(getCustomerFailure(error))
  }
}

function* getClientExcelWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getCustomerExcel, request.payload)
    yield put(getCustomerExcelSuccess(data))
  } catch (error) {
    yield put(getCustomerExcelFailure(error))
  }
}

function* getClientByFilterWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getCustomer, request.payload)
    yield put(getCustomerByFilterSuccess(data))
  } catch (error) {
    yield put(getCustomerByFilterFailure(error))
  }
}

function* getClientTypeWorker(): SagaIterator {
  try {
    const { data } = yield call(getClientType)
    yield put(getClientTypeSuccess(data))
  } catch (error) {
    yield put(getClientTypeFailure(error))
  }
}

function* getCountryWorker(): SagaIterator {
  try {
    const { data } = yield call(getCountries)
    yield put(getCountrySuccess(data))
  } catch (error) {
    yield put(getCountryFailure(error))
  }
}

function* getClassificationWorker(): SagaIterator {
  try {
    const { data } = yield call(getClassification)
    yield put(getClassificationSuccess(data))
  } catch (error) {
    yield put(getClassificationFailure(error))
  }
}

function* getAssignedCommercialWorker(): SagaIterator {
  try {
    const { data } = yield call(getAssignedCommercial)
    yield put(getAssignedCommercialSuccess(data))
  } catch (error) {
    yield put(getAssignedCommercialFailure(error))
  }
}

function* addClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addCustomer, request.payload)
    yield put(addCustomerSuccess(data))
  } catch (error) {
    yield put(addCustomerFailure(error))
  }
}

function* postBulkAssignmentWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(bulkAssignmentCustomer, request.payload)
    yield put(postBulkAssignmentSuccess(data))
  } catch (error) {
    yield put(postBulkAssignmentFailure(error))
  }
}

function* postReBulkAssignmentWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(reBulkAssignmentCustomer, request.payload)
    yield put(postReBulkAssignmentSuccess(data))
  } catch (error) {
    yield put(postReBulkAssignmentFailure(error))
  }
}
function* putClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(putCustomer, request.payload)
    yield put(putCustomerSuccess(data))
  } catch (error) {
    yield put(putCustomerFailure(error))
  }
}

function* deleteClientWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteCustomer, request.payload)
    yield put(deleteCustomerSuccess(data))
  } catch (error) {
    yield put(deleteCustomerFailure(error))
  }
}

function* getClientByIdWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getCustomerById, request.payload)
    yield put(getCustomerByIdSuccess(data))
  } catch (error) {
    yield put(getCustomerByIdFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.TERMS_GET_REQUEST, getTermsWorker)
  yield takeLatest(types.CUSTOMER_BY_ID_GET_REQUEST, getClientByIdWorker)
  yield takeLatest(types.CUSTOMER_GET_REQUEST, getClientWorker)
  yield takeLatest(types.CLASSIFICATION_GET_REQUEST, getClassificationWorker)
  yield takeLatest(types.CUSTOMER_EXCEL_GET_REQUEST, getClientExcelWorker)
  yield takeLatest(types.CUSTOMER_BY_FILTER_GET_REQUEST, getClientByFilterWorker)
  yield takeLatest(types.CLIENT_TYPE_GET_REQUEST, getClientTypeWorker)
  yield takeLatest(types.COUNTRY_GET_REQUEST, getCountryWorker)
  yield takeLatest(types.ASSIGNED_COMMERCIAL_GET_REQUEST, getAssignedCommercialWorker)
  yield takeLatest(types.CUSTOMER_ADD_REQUEST, addClientWorker)
  yield takeLatest(types.BULK_ASSIGMENT_POST_REQUEST, postBulkAssignmentWorker)
  yield takeLatest(types.REBULK_ASSIGMENT_POST_REQUEST, postReBulkAssignmentWorker)
  yield takeLatest(types.CUSTOMER_PUT_REQUEST, putClientWorker)
  yield takeLatest(types.CUSTOMER_DELETE_REQUEST, deleteClientWorker)
}

export const sagas = [listWatcher]
