/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerButtom, ContainerSerch, Group } from "./_styles"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_QUOTER
} from "../../../../config/translation"
import { useMobile } from "../../../../hook/useMobile"
import SelectCustom from "../../../../components/SelectCustom"
import { useSelector } from "react-redux"
import { customerSelector } from "../../../../state/modules/customer/_selectors"
import ButtonCustom from "../../../../components/ButtonCustom"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../../../context/GlobalContext"
import SelectAsyncCustom from "../../../../components/SelectAsyncCustom"
import CustomerService from "../../../../services/CustomerService"
import { customsSelector } from "../../../../state/modules/customs/_selectors"
import InputCustom from "../../../../components/InputCustom"
import { useResize } from "../../../../hook/useResize"
import { getAuth } from "../../../../utils/functions"
import { PERMISSION } from "../../../../utils/constants"
import CarrierService from "../../../../services/CarrierService"

const SearchUserClientApp = ({
  setDataFiltered = () => {},
  setFilters,
  setClearFilter,
  resetFilter,
  data,
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const screenSize = useResize()
  const { themeContext } = useGlobalContext()
  const customer = useSelector(customerSelector)
  const customs = useSelector(customsSelector)
  const handleSearch = (): void => {
    if (setFieldValue) setFieldValue("search", !values?.search)
    if (setFilters) setFilters(values)
  }

  const getUserCurrentToAssignmentCommercial = (): void => {
    const user = getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)
    if (!user[0] && setFieldValue) {
      const valueUser = customer.assignedCommercial.find(item => item.nombreUsuario === user[1])
      setFieldValue("comercialAsignado", {
        value: valueUser?.nombreUsuario,
        label: `${valueUser?.apellido?.toLocaleUpperCase() ?? "-"} ${valueUser?.nombre.toLocaleUpperCase() ?? "-"}`
      })
    }
  }

  const promiseOptions = async (inputValue: string): Promise<any> => {
    const response = await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(CustomerService(inputValue))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
    return response
  }
  const promiseOptionsCarrier = async (inputValue: string): Promise<any> => {
    const response = await new Promise(resolve => {
      if (inputValue.length > 2) {
        resolve(CarrierService(inputValue))
      } else {
        resolve([{ value: "-1", label: t(TRANSLATION_INDEX_GLOBAL.ENTER_THREE_CHARACTER), isDisabled: true }])
      }
    })
    return response
  }
  const handleResetFilters = (): void => {
    if (setClearFilter) setClearFilter(true)
  }

  React.useEffect(() => {
    if (resetFilter && handleReset) {
      if (setFieldValue) setFieldValue("clientes", null)
      if (setFieldValue && getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS))
        setFieldValue("comercialAsignado", null)
      if (setFieldValue) setFieldValue("sinComercialAsignado", null)
      handleReset()
      if (!getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)[0]) {
        getUserCurrentToAssignmentCommercial()
      }
    }
  }, [resetFilter])

  React.useEffect(() => {
    if (values?.year) if (setFieldValue) setFieldValue("year", values?.year.replace(/\D/g, ""))
    if (values?.year && values?.year.length > 4) if (setFieldValue) setFieldValue("year", values?.year.substring(0, 4))
  }, [values?.year])

  React.useEffect(() => {
    getUserCurrentToAssignmentCommercial()
  }, [customer.assignedCommercial])

  React.useEffect(() => {
    if (setFieldValue && values?.sinComercialAsignado) setFieldValue("comercialAsignado", null)
  }, [values?.sinComercialAsignado])

  React.useEffect(() => {
    if (setFieldValue) setFieldValue("search", false)
  }, [])

  return (
    <ContainerSerch>
      <Group>
        <InputCustom
          label={t(TRANSLATION_INDEX_QUOTER.YEAR) ?? ""}
          value={values?.year ?? ""}
          handleChange={handleChange}
          name="year"
          placeHolder={`${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(TRANSLATION_INDEX_QUOTER.YEAR)}` ?? ""}
          width={isMobile ? "100%" : `${screenSize / 3}px`}
          iconLeft
          marginLeft
          validError={false}
        />
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_QUOTER.MONTH_FROM) ?? ""}
            value={values?.mesDesde ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="mesDesde"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            marginLeft
            validError={false}
            // validError={false}
            options={["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map(item => {
              return {
                value: item,
                label: item
              }
            })}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_QUOTER.MONTH_TO) ?? ""}
            value={values?.mesHasta ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="mesHasta"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            marginLeft
            validError={false}
            // validError={false}
            options={["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map(item => {
              return {
                value: item,
                label: item
              }
            })}
          />
        )}
      </Group>
      <Group>
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_QUOTER.OPERATION_TYPE) ?? ""}
            value={values?.tipoOperacion ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="tipoOperacion"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            marginLeft
            validError={false}
            options={[
              {
                value: "IMPO",
                label: t(TRANSLATION_INDEX_QUOTER.IMPORT_2).toUpperCase()
              },
              {
                value: "EXPO",
                label: t(TRANSLATION_INDEX_QUOTER.EXPORT_2).toUpperCase()
              }
            ]}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE) ?? ""}
            value={values?.modoTransporte ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="modoTransporte"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            marginLeft
            validError={false}
            options={[
              { label: t(TRANSLATION_INDEX_QUOTER.AIR).toUpperCase() ?? "", value: "AIR" },
              { label: t(TRANSLATION_INDEX_QUOTER.SEA).toUpperCase() ?? "", value: "SEA" }
            ]}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_QUOTER.PLACE) ?? ""}
            value={values?.lugar ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="lugar"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            marginLeft
            validError={false}
            options={customs.places.map(item => {
              return {
                value: item.idLugar,
                label: item.descripcion.toUpperCase()
              }
            })}
          />
        )}
      </Group>
      <Group>
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSGNED_COMMERCIAL) ?? ""}
            value={values?.comercialAsignado ?? null}
            iconLeft
            handleChange={setFieldValue}
            name="comercialAsignado"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            marginLeft
            validError={false}
            isDisabled={!getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)[0]}
            options={customer.assignedCommercial.map(item => {
              return {
                value: item.nombreUsuario,
                label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
              }
            })}
          />
        )}
        {setFieldValue != null && (
          <SelectAsyncCustom
            isMulti
            marginLeft
            listTop={false}
            iconLeft
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            label={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER)}/${t(
              TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT
            )}`}
            value={values?.clientes}
            handleChange={setFieldValue}
            name="clientes"
            validError={false}
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_MORE) ?? ""}
            promiseOptions={promiseOptions}
          />
        )}
        {setFieldValue != null && (
          <SelectCustom
            label={t(TRANSLATION_INDEX_QUOTER.COUNTRY) ?? ""}
            value={values?.pais ?? null}
            iconLeft
            isMulti
            handleChange={setFieldValue}
            name="pais"
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_MORE) ?? ""}
            width={isMobile ? "100%" : `${screenSize / 3}px`}
            marginLeft
            validError={false}
            options={customer.countries.map(item => {
              return {
                value: item.idPais,
                label: item.descripcion.toUpperCase()
              }
            })}
          />
        )}
      </Group>
      <Group>
        {setFieldValue != null && (
          <SelectAsyncCustom
            listTop={false}
            iconLeft
            errors={errors?.countries}
            marginLeft
            isMulti
            width={isMobile ? "100%" : `${screenSize}px`}
            label={t(TRANSLATION_INDEX_QUOTER.CARRIER) ?? ""}
            value={values?.transportistas}
            handleChange={setFieldValue}
            name="transportistas"
            validError={false}
            placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_MORE) ?? ""}
            promiseOptions={promiseOptionsCarrier}
          />
        )}
        {getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)[0] && (
          <div
            style={{
              width: `${isMobile ? "100%" : `${screenSize / 4}px`}`,
              marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="sinComercialAsignado"
              value={values?.sinComercialAsignado}
              checked={values?.sinComercialAsignado}
              onChange={e => {
                if (setFieldValue) setFieldValue("sinComercialAsignado", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.WO_COMMERCIAL_ASSIGMENT)}</span>
          </div>
        )}
        <ContainerButtom
          style={{
            width: isMobile
              ? "100%"
              : `${screenSize / (getAuth(PERMISSION.CLIENTE_CONSULTAR_OTROS_USUARIOS)[0] ? 5.4 : 2.5)}px`
          }}
        >
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.CLEAR) ?? ""}
            background={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.GRAY_BG_IMAGE_HOVER[themeContext]}
            onClick={handleResetFilters}
            width={isMobile ? "100%" : "110px"}
          />
          <ButtonCustom
            label={t(TRANSLATION_INDEX_GLOBAL.SEARCH) ?? ""}
            background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
            backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
            onClick={handleSearch}
            width={isMobile ? "100%" : "80px"}
            marginLeft
          />
        </ContainerButtom>
      </Group>
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserClientApp)
