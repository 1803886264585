/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"

import ButtonCustom from "../../../../components/ButtonCustom"

import { useTranslation } from "react-i18next"

import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_QUOTER
} from "../../../../config/translation"
import { Container, Group, GroupButtom, ContainerGroup } from "./_styles"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { ACTIONS } from "../../../../utils/constants"
import "react-toastify/dist/ReactToastify.css"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { withFormik } from "formik"

import { useGlobalContext } from "../../../../context/GlobalContext"
import { toast } from "react-toastify"
import { t } from "i18next"

const SelectColumn = ({
  data,
  isUserPending = false,
  loading = false,
  action = "",
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  touched,
  validateForm,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()

  const buttonGroup = (): ReactElement => {
    return (
      <GroupButtom themeContext={themeContext}>
        <ButtonCustom
          onClick={closeModal}
          width="80px"
          type="button"
          label={t("global.cancel") ?? ""}
          background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
        />
        <ButtonCustom
          width="80px"
          type="submit"
          label={
            action === ACTIONS.DELETE.VALUE
              ? t(TRANSLATION_INDEX_GLOBAL.DELETE) ?? ""
              : t(TRANSLATION_INDEX_GLOBAL.CONFIRM) ?? ""
          }
          background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
          backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
          marginLeft
        />
      </GroupButtom>
    )
  }

  const items = [
    {
      label: t(TRANSLATION_INDEX_QUOTER.DATE),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="fecha"
              value={values?.fecha}
              checked={values?.fecha}
              onChange={e => {
                if (setFieldValue) setFieldValue("fecha", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_QUOTER.DATE)}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.TYPE),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="tipo"
              value={values?.tipo}
              checked={values?.tipo}
              onChange={e => {
                if (setFieldValue) setFieldValue("tipo", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_QUOTER.TYPE)}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.PLACE),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="lugar"
              value={values?.lugar}
              checked={values?.lugar}
              onChange={e => {
                if (setFieldValue) setFieldValue("lugar", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_QUOTER.PLACE)}</span>
          </div>
        </Group>
      )
    },
    {
      label: `${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER) ?? ""}/${
        t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT) ?? ""
      }`,
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="cliente"
              value={values?.cliente}
              checked={values?.cliente}
              onChange={e => {
                if (setFieldValue) setFieldValue("cliente", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CUSTOMER) ?? ""}/${
              t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.PROSPECT) ?? ""
            }`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.COUNTRY),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="pais"
              value={values?.pais}
              checked={values?.pais}
              onChange={e => {
                if (setFieldValue) setFieldValue("pais", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.COUNTRY) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.CUSTOMS_BROKER),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="despachante"
              value={values?.despachante}
              checked={values?.despachante}
              onChange={e => {
                if (setFieldValue) setFieldValue("despachante", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.CUSTOMS_BROKER) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.DOCUMENT),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="documento"
              value={values?.documento}
              checked={values?.documento}
              onChange={e => {
                if (setFieldValue) setFieldValue("documento", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.DOCUMENT) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="modoTransporte"
              value={values?.modoTransporte}
              checked={values?.modoTransporte}
              onChange={e => {
                if (setFieldValue) setFieldValue("modoTransporte", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE)}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.MANIFIEST),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="manifiesto"
              value={values?.manifiesto}
              checked={values?.manifiesto}
              onChange={e => {
                if (setFieldValue) setFieldValue("manifiesto", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.MANIFIEST) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.CURRENCY),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="moneda"
              value={values?.moneda}
              checked={values?.moneda}
              onChange={e => {
                if (setFieldValue) setFieldValue("moneda", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.CURRENCY) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.FREIGHT),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="flete"
              value={values?.flete}
              checked={values?.flete}
              onChange={e => {
                if (setFieldValue) setFieldValue("flete", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.FREIGHT) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.WEIGHT),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="peso"
              value={values?.peso}
              checked={values?.peso}
              onChange={e => {
                if (setFieldValue) setFieldValue("peso", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.WEIGHT) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.PACKS),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="bultos"
              value={values?.bultos}
              checked={values?.bultos}
              onChange={e => {
                if (setFieldValue) setFieldValue("bultos", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.PACKS) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.CARRIER),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="transportista"
              value={values?.transportista}
              checked={values?.transportista}
              onChange={e => {
                if (setFieldValue) setFieldValue("transportista", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.CARRIER) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.OFFICIALIZATION),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="oficializacion"
              value={values?.oficializacion}
              checked={values?.oficializacion}
              onChange={e => {
                if (setFieldValue) setFieldValue("oficializacion", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.OFFICIALIZATION) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.ARRIVAL),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="arribo"
              value={values?.arribo}
              checked={values?.arribo}
              onChange={e => {
                if (setFieldValue) setFieldValue("arribo", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.ARRIVAL) ?? ""}`}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSGNED_COMMERCIAL),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="comercialAsignado"
              value={values?.comercialAsignado}
              checked={values?.comercialAsignado}
              onChange={e => {
                if (setFieldValue) setFieldValue("comercialAsignado", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSGNED_COMMERCIAL)}</span>
          </div>
        </Group>
      )
    },
    {
      label: t(TRANSLATION_INDEX_QUOTER.SUPPLIER),
      components: (
        <Group themeContext={themeContext}>
          <div
            style={{
              width: "100%",
              // marginLeft: "10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              color: GLOBAL_STYLES.LABEL.TYPE_3[themeContext]
            }}
          >
            <input
              type="checkbox"
              name="proveedor"
              value={values?.proveedor}
              checked={values?.proveedor}
              onChange={e => {
                if (setFieldValue) setFieldValue("proveedor", e.target.checked)
              }}
            />
            <span style={{ marginLeft: "5px" }}>{`${t(TRANSLATION_INDEX_QUOTER.SUPPLIER) ?? ""}`}</span>
          </div>
        </Group>
      )
    }
  ]

  return (
    <>
      <Container onSubmit={handleSubmit}>
        <ContainerGroup themeContext={themeContext}>
          <>
            {items
              .sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
              .map(item => {
                return item.components
              })}
          </>
        </ContainerGroup>

        {buttonGroup()}
      </Container>
    </>
  )
}

export default withFormik<any, any>({
  mapPropsToValues: ({ data }): any => {
    return {
      fecha: data.some(item => item === "1"),
      arribo: data.some(item => item === "17"),
      oficializacion: data.some(item => item === "16"),
      transportista: data.some(item => item === "15"),
      bultos: data.some(item => item === "14"),
      peso: data.some(item => item === "13"),
      flete: data.some(item => item === "12"),
      moneda: data.some(item => item === "11"),
      documento: data.some(item => item === "10"),
      despachante: data.some(item => item === "9"),
      manifiesto: data.some(item => item === "8"),
      pais: data.some(item => item === "6"),
      cliente: data.some(item => item === "4"),
      lugar: data.some(item => item === "3"),
      tipo: data.some(item => item === "7"),
      proveedor: data.some(item => item === "18"),
      modoTransporte: data.some(item => item === "2"),
      comercialAsignado: data.some(item => item === "20")
    }
  },
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    const request = [
      values?.fecha ? "1" : null,
      values?.arribo ? "17" : null,
      values?.oficializacion ? "16" : null,
      values?.transportista ? "15" : null,
      values?.bultos ? "14" : null,
      values?.peso ? "13" : null,
      values?.flete ? "12" : null,
      values?.moneda ? "11" : null,
      values?.documento ? "10" : null,
      values?.despachante ? "9" : null,
      values?.manifiesto ? "8" : null,
      values?.pais ? "6" : null,
      values?.comercialAsignado ? "20" : null,
      values?.cliente ? "4" : null,
      values?.cliente ? "5" : null,
      values?.lugar ? "3" : null,
      values?.tipo ? "7" : null,
      values?.proveedor ? "18" : null,
      values?.modoTransporte ? "2" : null,
      "19"
    ]

    if (request.filter(item => item).length === 0) {
      toast.error(t("responseCode.noColumns"))
    } else if (accept != null) accept(request.filter(item => item))
    setSubmitting(false)
  }
})(SelectColumn)
