import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const Label = styled.div`
  margin-left: 10px;
`
