import styled from "styled-components"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../../../interfaces/IStyle"

export const Container = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
export const ContainerAll = styled.div`
  display: flex;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`
export const ContainerGroup = styled.div<IStyle>`
  min-height: 70px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-size: 0.8rem !important;
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const Group = styled.div<IStyle>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  /* overflow-x: ${({ overflowX }) => overflowX ?? ""}; */
`

export const GroupButtom = styled.div<IStyle>`
  width: calc(100% - 30px);
  display: flex;
  padding: 10px 15px;
  justify-content: flex-end;
  border-top: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
`
export const ContainerMessage = styled.div<IStyle>`
  min-height: 70px;
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 45px;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
`
export const ContainerScroll = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 170px);
  padding-right: 5px;
  margin-bottom: 15px;
  ::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    margin-top: 5px;
    background-color: #ececec;
    border-radius: 270px;
  }
  @media (max-width: 1080px) {
    overflow-y: hidden;
    height: auto;
    width: 99%;
  }
`
export const Input = styled.input<IStyle>`
  width: calc(100% - ${({ iconLeft, iconRight }) => (iconLeft === true || iconRight === true ? 40 : 0)}px);
  height: 30px;
  font-size: 0.8rem !important;
  padding: 5px 3px 5px 10px !important;
  margin: 0;
  border: ${({ border }) => border};
  font-weight: 400;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
  border-radius: ${({ iconLeft, iconRight, borderRadius }) =>
    iconRight === true
      ? `${borderRadius} 0px 0px ${borderRadius}`
      : iconLeft === true
      ? `0px ${borderRadius} ${borderRadius} 0px`
      : `${borderRadius}`};
  :hover {
    box-shadow: ${({ disabled }) => (disabled === true ? "" : "0px 0px 0px 1px #bebebe")};
  }
  ::placeholder {
    color: ${({ themeContext }) => (themeContext === "DARK" ? "#c2c2c2" : "#626262")};
  }
  :focus {
    box-shadow: 0px 0px 1px 1px #bebebe;
    outline: none;
  }
  :disabled {
    background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_4[themeContext]};
    cursor: no-drop;
  }
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const Textarea = styled.textarea<IStyle>`
  width: calc(100% - ${({ iconLeft, iconRight }) => (iconLeft === true || iconRight === true ? 40 : 0)}px);
  height: ${({ height }) => height};
  font-size: 0.8rem !important;
  padding: 5px 3px 5px 10px !important;
  margin: 0;
  resize: vertical;
  border: ${({ border }) => border};
  font-weight: 400;
  font-family: ${GLOBAL_STYLES.FONT.FAMILY};
  border-radius: ${({ iconLeft, iconRight, borderRadius }) =>
    iconRight === true
      ? `${borderRadius} 0px 0px ${borderRadius}`
      : iconLeft === true
      ? `0px ${borderRadius} ${borderRadius} 0px`
      : `${borderRadius}`};
  :hover {
    box-shadow: ${({ disabled }) => (disabled === true ? "" : "0px 0px 0px 1px #bebebe")};
  }
  ::placeholder {
    color: ${({ themeContext }) => (themeContext === "DARK" ? "#c2c2c2" : "#626262")};
  }
  :focus {
    box-shadow: 0px 0px 1px 1px #bebebe;
    outline: none;
  }
  :disabled {
    background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_4[themeContext]};
    cursor: no-drop;
  }
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const Label = styled.label<IStyle>`
  color: ${({ color, themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[color !== "" ? color ?? "LIGHT" : themeContext]};
  font-weight: 500;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  font-size: 0.8rem !important;
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`
