import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const ContainerIcons = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
`

export const ContainerSerch = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`

export const ContainerTable = styled.div<IStyle>`
  width: 100%;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  opacity: ${({ opacity }) => opacity};
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: opacity 3s;
  margin-top: -2px;
  margin-left: 2px;
  z-index: 10;
`

export const Stepper = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`
