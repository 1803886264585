export const STORAGE = {
  TOKEN: "access_token",
  LANG: "lang",
  NOTIFICATION: "notification",
  CHANGE_PASSWORD: "change_password",
  USER_NAME: "user_name",
  THEME: "THEME",
  get: (key: string) => {
    const value = localStorage.getItem(key)
    return value != null ? JSON.parse(value) : ""
  },
  set: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  remove: (key: string) => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  }
}
