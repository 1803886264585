import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  width: ${({ width }) => width};
  margin-left: ${({ marginLeft }) => (marginLeft ?? false ? "10px" : "")};
  margin-top: 5px;
  @media (max-width: 1080px) {
    margin-left: 0;
    margin-top: 10px;
  }
`

export const Label = styled.label<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-weight: 500;
  width: 100%;
  display: flex;
  font-size: 0.8rem !important;
  margin-bottom: 10px;
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`

export const Input = styled.input`
  width: calc(100% - 15px);
  height: 25px;
  font-size: 0.8rem !important;
  margin: 0;
  border: 2px solid #c0c0c0;
  padding: 5px;
  border-radius: 5px;
  :hover {
    border: 2px solid #cccccc;
  }
  :focus {
    border: 2px solid #72bc69;
    outline: none;
  }
`

export const Disabled = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
`
