import { expensiveba } from "./_duck"

export const EXPENSIVE_BA_GET_REQUEST: string = expensiveba.defineType("EXPENSIVE_BA_GET_REQUEST")
export const EXPENSIVE_BA_GET_SUCCESS: string = expensiveba.defineType("EXPENSIVE_BA_GET_SUCCESS")
export const EXPENSIVE_BA_GET_FAILURE: string = expensiveba.defineType("EXPENSIVE_BA_GET_FAILURE")

export const EXPENSIVE_BA_ADD_REQUEST: string = expensiveba.defineType("EXPENSIVE_BA_ADD_REQUEST")
export const EXPENSIVE_BA_ADD_SUCCESS: string = expensiveba.defineType("EXPENSIVE_BA_ADD_SUCCESS")
export const EXPENSIVE_BA_ADD_FAILURE: string = expensiveba.defineType("EXPENSIVE_BA_ADD_FAILURE")

export const EXPENSIVE_BA_PUT_REQUEST: string = expensiveba.defineType("EXPENSIVE_BA_PUT_REQUEST")
export const EXPENSIVE_BA_PUT_SUCCESS: string = expensiveba.defineType("EXPENSIVE_BA_PUT_SUCCESS")
export const EXPENSIVE_BA_PUT_FAILURE: string = expensiveba.defineType("EXPENSIVE_BA_PUT_FAILURE")

export const EXPENSIVE_BA_DELETE_REQUEST: string = expensiveba.defineType("EXPENSIVE_BA_DELETE_REQUEST")
export const EXPENSIVE_BA_DELETE_SUCCESS: string = expensiveba.defineType("EXPENSIVE_BA_DELETE_SUCCESS")
export const EXPENSIVE_BA_DELETE_FAILURE: string = expensiveba.defineType("EXPENSIVE_BA_DELETE_FAILURE")
