/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { type ReactElement } from "react"
import { Container, Label, ContainerInput } from "./_styles"
import { type Props } from "./_types"

import { isStringEmpty } from "../../utils/functions"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { useTranslation } from "react-i18next"
import { FormHelperText } from "@mui/material"
import { useGlobalContext } from "../../context/GlobalContext"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "./_style.css"
const RichTextCustom = ({
  name,
  label = "",
  value = "",
  errors = "",
  handleChange,
  subLabel = "",
  placeHolder = "",
  width = "100%",
  marginLeft = false,
  disabled = false,
  validError = true,
  style,
  height = "30px",
  borderRadius = "5px"
}: Props): ReactElement => {
  const { t } = useTranslation()
  const { themeContext } = useGlobalContext()
  const modules = {
    toolbar: [
      [
        {
          header: [1, 2, 3, 4, 5, 6, false]
        }
      ],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ color: [] }, { background: [] }],
      ["clean"]
    ]
  }
  return (
    <Container themeContext={themeContext} width={width} marginLeft={marginLeft} data-testid="containerInput">
      {!isStringEmpty(label) && (
        <Label themeContext={themeContext}>
          <div>{label}</div>
          <div
            style={{
              fontSize: "0.75rem"
            }}
          >
            {subLabel}
          </div>
        </Label>
      )}
      <ContainerInput>
        <>
          <ReactQuill
            theme="snow"
            value={value ?? ""}
            modules={modules}
            className="editor-input"
            onChange={e => {
              if (handleChange != null) handleChange(name ?? "", e)
            }}
          />
        </>
      </ContainerInput>
      {validError && (
        <FormHelperText style={{ color: GLOBAL_STYLES.LABEL.TYPE_4[themeContext] }}>{t(errors)}</FormHelperText>
      )}
    </Container>
  )
}

export default React.memo(RichTextCustom)
