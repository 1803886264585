import styled from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { type IStyle } from "../../interfaces/IStyle"

export const Container = styled.div<IStyle>`
  position: relative;
  width: 100%;
  background-color: white;
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-weight: 400;
`

export const ContainerText = styled.div<IStyle>`
  position: relative;
  width: 100%;
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext]};
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_2[themeContext]};
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-weight: 600;
`
