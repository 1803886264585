/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useTranslation } from "react-i18next"
import React, { type ReactElement } from "react"

import { type IPropSearch } from "./_types"
import { withFormik } from "formik"

import { ContainerSerch } from "./_styles"
import InputCustom from "../../../../components/InputCustom"
import { type IFormListProps } from "../../../../interfaces/IFormListProps"
import { TRANSLATION_INDEX_GLOBAL, TRANSLATION_INDEX_PROFILE } from "../../../../config/translation"
import { useMobile } from "../../../../hook/useMobile"

const SearchUserClientApp = ({
  setDataFiltered = () => {},
  data,
  isUserPending = false,
  values,
  errors,
  setSubmitting,
  isSubmitting,
  handleChange,
  setFieldValue,
  handleReset,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const handleSearch = (): void => {
    // TODO: hacer dispatch de busqueda
    let newList = data
    if (values?.descripcion)
      newList = newList.filter(item => item.descripcion.toLowerCase().includes(values.descripcion))

    setDataFiltered(newList)
  }

  React.useEffect(() => {
    handleSearch()
  }, [values])

  return (
    <ContainerSerch>
      <InputCustom
        value={values?.descripcion ?? ""}
        handleChange={handleChange}
        name="descripcion"
        placeHolder={
          `${t(TRANSLATION_INDEX_GLOBAL.SEARCH_FOR)} ${t(TRANSLATION_INDEX_PROFILE.COLUMNS.DESCRIPTION)}` ?? ""
        }
        width={isMobile ? "100%" : "300px"}
        iconLeft
      />
    </ContainerSerch>
  )
}

export default withFormik<IFormListProps, IPropSearch>({
  mapPropsToValues: (): IPropSearch => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { setSubmitting }): any => {}
})(SearchUserClientApp)
