import styled from "styled-components"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div`
  position: absolute;
  margin-top: -50px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: calc(100% + 50px);
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${GLOBAL_STYLES.BACKGROUND.TYPE_1.DARK};
`

export const ImageWrapperMain = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(/fondo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
`

export const ContainerLogin = styled.div`
  position: relative;
  width: 30%;
  height: calc(100% - 30px);
  display: flex;
  padding: 30px 10px 0 10px;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const Blur = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
`

export const ContainerLanguaje = styled.div`
  position: absolute;
  right: 40px;
  cursor: pointer;

  z-index: 999;
`

export const ContainerLanguajeActive = styled.div`
  width: 15%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 20px;
  cursor: pointer;
  top: 20px;
`

export const ContainerForm = styled.form`
  position: relative;
  padding: 25px 0 35px 0;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border: solid #cacaca 1px;
  border-radius: 10px;
  box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.75);
  @media (max-width: 900px) {
    width: 95%;
  }
`

export const ContainerFormForgot = styled.div`
  position: relative;
  padding: 10px 0 0px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
`

export const Logo = styled.img`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
`
export const ForgotPass = styled.h6`
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin: 15px 0;
`

export const ErrorLogin = styled.div`
  border: solid 1px #fc0000;
  background-color: #fff;
  border-radius: 8px;
  color: #fc0000;
  text-align: center;
  width: calc(90% - 20px);
  padding: 10px;
  margin: 10px auto;
  font-size: 0.9rem !important;
`

export const Label = styled.div`
  color: #12a470;
  font-size: 0.8375rem;
  padding-top: 5px;
  cursor: pointer;
`

export const GroupButtom = styled.div`
  width: calc(100% - 30px);
  display: flex;
  margin-top: 10px;
  padding: 10px 15px;
  justify-content: flex-end;
  border-top: ${GLOBAL_STYLES.BORDER.TYPE_2.LIGHT};
`

export const LabelChange = styled.div`
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 10px;
`
