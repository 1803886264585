/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from "styled-components"
import { type IStyle } from "../../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  margin-left: ${({ marginLeft }) => (marginLeft ? "10px" : "")};
  margin-right: ${({ marginRight }) => (marginRight ? "15px" : "")};
  width: ${({ width }) => width};
  margin-top: 5px;
  @media (max-width: 1080px) {
    width: calc(100% - 15px);
    margin-left: 0;
    margin-top: 15px;
  }
`

export const Label = styled.div<IStyle>`
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  font-weight: 500;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  font-size: 0.8rem !important;
  @media (max-width: 1080px) {
    font-size: 0.7rem !important;
  }
`
export const ContainerIcon = styled.div<IStyle>`
  position: relative;
  width: 40px;
  display: flex;
  z-index: 99;
  margin-right: -3px;
  justify-content: center;
  align-items: center;
  background-color: ${({ background }) => background};
  border-left: ${({ iconLeft }) => (iconLeft === true ? " 1px solid #c0c0c0" : "")};
  border-right: ${({ iconRight }) => (iconRight === true ? "1px solid #c0c0c0" : "1px solid #c0c0c0")};
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  border-radius: ${({ iconRight, borderRadius }) =>
    iconRight === true ? `0px ${borderRadius} ${borderRadius} 0px` : `${borderRadius} 0px 0px ${borderRadius}`};
  height: 40px;
  cursor: pointer;
`
