/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { type IProps } from "./interfaces"

import { getAuth } from "../../../../utils/functions"
import { useSelector } from "react-redux"
import { permissionSelector } from "../../../../state/modules/permissionByProfile/_selectors"
import { PERMISSION, PERMISSION_RELATIONAL } from "../../../../utils/constants"
import IconPlus from "../../../../components/Icons/IconPlus"
import IconMinus from "../../../../components/Icons/IconMinus"
import IconCheck from "../../../../components/Icons/IconCheck"
import { GLOBAL_STYLES } from "../../../../styles/GLOBAL_STYLE"
import { useGlobalContext } from "../../../../context/GlobalContext"

const MainContainerGroup: React.FC<IProps> = ({
  label,
  collapseSetting,
  setCollapseSetting,
  setPermissionsSelected,
  permissionSelected,
  section
}) => {
  const { openAllCollansePermission, setOpenAllCollansePermission, themeContext } = useGlobalContext()
  const permissions = useSelector(permissionSelector)
  const itemcount = section === "all" ? PERMISSION_RELATIONAL.all.count : PERMISSION_RELATIONAL.all[section].count

  const handleClickSection = (): void => {
    if (getAuth(PERMISSION.PERMISO_PERFIL_MODIFICAR)[0]) {
      if (section === "all") {
        if (itemcount !== permissionSelected.length) {
          setPermissionsSelected(permissions.data)
          setOpenAllCollansePermission(true)
        } else {
          setPermissionsSelected([])
          setOpenAllCollansePermission(false)
        }
      } else if (itemcount === permissionSelected.filter(item => item.group === section).length) {
        const newArray = permissions.data
          .filter(item => item.group === section && !permissionSelected.some(per => per.idPermiso === item.idPermiso))
          .concat(permissionSelected.filter(item => item.group !== section))
        setOpenAllCollansePermission(false)
        setPermissionsSelected([...newArray])
      } else {
        const newArray = permissions.data
          .filter(item => item.group === section && !permissionSelected.some(per => per.idPermiso === item.idPermiso))
          .concat(permissionSelected)
        setOpenAllCollansePermission(false)
        setPermissionsSelected([...newArray])
      }
    }
  }

  const isCheck =
    itemcount ===
    (section === "all" ? permissionSelected.length : permissionSelected.filter(item => item.group === section).length)

  React.useEffect(() => {
    if (openAllCollansePermission) {
      setCollapseSetting(true)
    }
  }, [openAllCollansePermission])

  return (
    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
      <IconCheck isCheck={isCheck} size={10} color="#fff" onclick={handleClickSection} />

      <span style={{ marginLeft: "10px" }}>{label}</span>

      {collapseSetting !== null && (
        <div
          role="button"
          style={{ marginLeft: "10px", marginTop: "3px" }}
          tabIndex={0}
          onKeyDown={() => {
            setCollapseSetting(!(collapseSetting ?? false))
          }}
          onClick={() => {
            setCollapseSetting(!(collapseSetting ?? false))
          }}
        >
          {!collapseSetting ?? false ? (
            <IconPlus size={12} color={GLOBAL_STYLES.LABEL.TYPE_2[themeContext]} />
          ) : (
            <IconMinus size={12} color={GLOBAL_STYLES.LABEL.TYPE_2[themeContext]} />
          )}
        </div>
      )}
    </div>
  )
}

export default MainContainerGroup
