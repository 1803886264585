import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getExpensiveBA = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/gastosbsas`)

export const addExpensiveBA = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/gastosbsas`, request)

export const putExpensiveBA = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/gastosbsas`, request)

export const deleteExpensiveBA = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/gastosbsas/${request}`)
