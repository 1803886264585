export const stylesConfig = (): string => {
  return `
    .react-datepicker-wrapper {
      width: calc(100% - 15px);
    }
    .react-datepicker-popper[data-placement^=bottom]{
      z-index: 999;
    }
    .errorDate {
      border: 1px solid red !important;
    }

    .react-datepicker__close-icon {
      right:-15px !important;
    }
  
    .react-datepicker__close-icon::after {
      background:transparent;
      color: #BEBEBE;
      font-size: 22px;
    }
  
    .date-picker input {
      width: 100%;
      height: 30px;
      font-size: 0.8rem !important;
      padding: 5px 3px 5px 10px !important;
      margin: 0;
      font-family: Source Sans Pro;
      font-weight: 500;
      border: 1px solid #c0c0c0 !important;
      border-radius: 5px;
    }
  
    .date-picker input:hover{
      border: 1px solid #cccccc !important;
    }
  
    .date-picker input: disabled {
      backgroud-color: red !important;
    }
  
    .date-picker input:focus {
      border: 1px solid #bebebe !important;
      outline: none;
    }
  `
}
