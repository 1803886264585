import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const LabelFloat = styled.div<IStyle>`
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  padding: 12px;
  border: solid 1px #444;
  position: absolute;
  font-size: 0.8rem;
  top: -18px;
  left: 10px;
  display: ${({ isActive }) => (isActive === true ? "flex" : "none")};
  width: ${({ width }) => width};
  height: auto;
  z-index: 999;
  ::after {
    content: "";
    display: block;
    position: absolute;
    left: -9px;
    border-right: rgba(0, 0, 0, 0.8) solid 10px;
    border-top: transparent solid 10px;
    border-bottom: transparent solid 10px;
    z-index: 999999;
  }
`
