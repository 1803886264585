/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { type ReactElement } from "react"
import { useTranslation } from "react-i18next"

import ContainerPage from "../../components/ContainerPage"
import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_MENU,
  TRANSLATION_INDEX_PROFILE,
  TRANSLATION_INDEX_QUOTER,
  TRANSLATION_INDEX_RESPONSE_CODE
} from "../../config/translation"

import "../../styles/styles.css"
import { ACTIONS, FORMAT_DATE_ONLY, PERMISSION } from "../../utils/constants"
import AlertModal from "../../components/AlertModal"
import FormaddUpdate from "./forms/addUpdate/FormaddUpdate"
import { ContainerIcons, ContainerTable } from "./_styles"
import Search from "./forms/search"
import DataTable from "react-data-table-component"
import { NoData } from "../../components/NoData"
import { customStylesContext } from "../../styles/_styleDatatable"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import ButtonCustom from "../../components/ButtonCustom"
import { isStringEmpty } from "../../utils/functions"
import { useGlobalContext } from "../../context/GlobalContext"
import { useDispatch, useSelector } from "react-redux"
import { useMobile } from "../../hook/useMobile"
import { toast } from "react-toastify"
import {
  addCustomerRequest,
  deleteCustomerRequest,
  getAssignedCommercialRequest,
  getCountryRequest,
  putCustomerRequest
} from "../../state/modules/customer/actions"
import IconExcel from "../../components/Icons/IconExcel"
import {
  customclear,
  customclearAll,
  getCustomsByIdRequest,
  getCustomsPlacesRequest,
  getCustomsRequest,
  postCustomsImportRequest
} from "../../state/modules/customs/actions"
import { customsSelector } from "../../state/modules/customs/_selectors"
import { type ICustomsFilter, type IErrorImport } from "../../interfaces/ICustoms"
import IconView from "../../components/Icons/IconView"
import IconImport from "../../components/Icons/IconImport"
import { format } from "date-fns"
import SelectColumn from "./forms/selectColumn/SelectColumn"
import ContainerLabelTwoRow from "../../components/ContainerLabelTwoRow"

const CustomsPage = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const customs = useSelector(customsSelector)
  const [idSelected, setIdSelected] = React.useState<number | null>(null)
  const [openModal, setOpenModal] = React.useState(false)
  const [pagination, setPagination] = React.useState({ pagina: 1, registrosPorPagina: 10 })
  const [resetPage, setResetPage] = React.useState(false)
  const [resetFilter, setResetFilter] = React.useState<boolean>(false)
  const { loadingContext, setLoadingContext, themeContext } = useGlobalContext()
  const [userSelected, setUserSelected] = React.useState<any | null>(null)
  const [action, setAction] = React.useState<string>("")
  const [filters, setFilters] = React.useState<any>(null)
  const [sortBy, setSortBy] = React.useState<any>({ columna: 1, direccion: "asc" })
  const [fileSelected, setfileSelected] = React.useState<any>(null)
  const [activeModalErrors, setActiveModalErrors] = React.useState(false)
  const [bgImportHover, setBgImportHover] = React.useState(false)
  const [openModalSelectColumn, setOpenModalSelectColumn] = React.useState<boolean>(false)
  const columnsStorage = localStorage.getItem("customsColumnSelected")
  if (!columnsStorage)
    localStorage.setItem("customsColumnSelected", JSON.stringify(["1", "7", "2", "3", "4", "5", "6", "15", "19"]))
  const [columnsActive, setColumnsActive] = React.useState(
    columnsStorage ? JSON.parse(columnsStorage) : ["1", "7", "2", "3", "4", "5", "6", "15", "19"]
  )

  const columns = [
    {
      id: "1",
      name: t(TRANSLATION_INDEX_QUOTER.DATE),
      selector: (row: any) => row.fecha.toUpperCase(),
      width: isMobile ? "100px" : `150px`,
      center: true,
      sortable: true,
      omit: !columnsActive.includes("1")
    },
    {
      id: "7",
      name: t(TRANSLATION_INDEX_QUOTER.TYPE),
      selector: (row: any) => t(TRANSLATION_INDEX_QUOTER[`${row.tipo.toUpperCase()}RT_2`]).toUpperCase(),
      center: true,
      width: isMobile ? "100px" : `150px`,
      sortable: true,
      omit: !columnsActive.includes("7")
    },
    {
      id: "2",
      name: t(TRANSLATION_INDEX_QUOTER.TRANSPORT_MODE),
      selector: (row: any) => t(TRANSLATION_INDEX_QUOTER[row.modoTransporte.toUpperCase()]).toUpperCase(),
      center: true,
      width: isMobile ? "100px" : `150px`,
      sortable: true,
      omit: !columnsActive.includes("2")
    },
    {
      id: "3",
      name: t(TRANSLATION_INDEX_QUOTER.PLACE),
      selector: (row: any) => row.lugar.descripcion.toUpperCase(),
      center: true,
      width: isMobile ? "100px" : `150px`,
      sortable: true,
      omit: !columnsActive.includes("3")
    },
    {
      id: "4",
      name: "CUIT",
      selector: (row: any) => row.cliente.cuit.toUpperCase(),
      width: isMobile ? "100px" : `150px`,
      center: true,
      sortable: true,
      omit: !columnsActive.includes("4")
    },
    {
      id: "5",
      name: t(TRANSLATION_INDEX_QUOTER.COMPANY_NAME),
      selector: (row: any) => row.cliente.razonSocial.toUpperCase(),
      width: isMobile ? "100px" : `350px`,
      sortable: true,
      omit: !columnsActive.includes("5"),
      cell: (row: any) => {
        return <ContainerLabelTwoRow label={row.cliente.razonSocial.toUpperCase()} />
      }
    },
    {
      id: "6",
      name: t(TRANSLATION_INDEX_QUOTER.COUNTRY),
      selector: (row: any) => row?.pais?.descripcion?.toUpperCase() ?? "-",
      width: isMobile ? "100px" : `150px`,
      center: true,
      sortable: true,
      omit: !columnsActive.includes("6")
    },
    {
      id: "15",
      name: t(TRANSLATION_INDEX_QUOTER.CARRIER),
      selector: (row: any) => row?.transportista ?? "-",
      width: isMobile ? "200px" : `200px`,
      center: true,
      omit: !columnsActive.includes("15")
    },
    {
      id: "20",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.ASSGNED_COMMERCIAL),
      selector: (row: any) => row?.comercialAsignado ?? "-",
      width: isMobile ? "200px" : `200px`,
      center: true,
      omit: !columnsActive.includes("20")
    },

    {
      id: "8",
      name: t(TRANSLATION_INDEX_QUOTER.MANIFIEST),
      selector: (row: any) => row?.manifiesto ?? "-",
      width: isMobile ? "100px" : `150px`,
      center: true,
      omit: !columnsActive.includes("8")
    },
    {
      id: "9",
      name: t(TRANSLATION_INDEX_QUOTER.CUSTOMS_BROKER),
      selector: (row: any) => `${row?.despachante} ${row?.despachanteRazonSocial ?? ""}`,
      width: isMobile ? "100px" : `350px`,
      cell: (row: any) => {
        return <ContainerLabelTwoRow label={`${row?.despachante} ${row?.despachanteRazonSocial ?? ""}`} />
      },
      omit: !columnsActive.includes("9")
    },
    {
      id: "10",
      name: t(TRANSLATION_INDEX_QUOTER.DOCUMENT),
      selector: (row: any) => row?.documento ?? "-",
      width: isMobile ? "100px" : `150px`,
      center: true,
      omit: !columnsActive.includes("10")
    },

    {
      id: "11",
      name: t(TRANSLATION_INDEX_QUOTER.CURRENCY),
      selector: (row: any) => row?.moneda ?? "-",
      width: isMobile ? "100px" : `150px`,
      center: true,
      omit: !columnsActive.includes("11")
    },

    {
      id: "12",
      name: t(TRANSLATION_INDEX_QUOTER.FREIGHT),
      selector: (row: any) => row?.flete ?? "-",
      width: isMobile ? "100px" : `150px`,
      center: true,
      omit: !columnsActive.includes("12")
    },
    {
      id: "13",
      name: t(TRANSLATION_INDEX_QUOTER.WEIGHT),
      selector: (row: any) => row?.peso ?? "-",
      width: isMobile ? "100px" : `150px`,
      center: true,
      grow: 5,
      omit: !columnsActive.includes("13")
    },
    {
      id: "14",
      name: t(TRANSLATION_INDEX_QUOTER.PACKS),
      selector: (row: any) => row?.bultos ?? "-",
      width: isMobile ? "100px" : `150px`,
      center: true,
      omit: !columnsActive.includes("14")
    },

    {
      id: "16",
      name: t(TRANSLATION_INDEX_QUOTER.OFFICIALIZATION),
      selector: (row: any) => (row?.oficializacion ? format(new Date(row?.oficializacion), FORMAT_DATE_ONLY) : ""),
      width: isMobile ? "100px" : `150px`,
      center: true,
      omit: !columnsActive.includes("16")
    },
    {
      id: "17",
      name: t(TRANSLATION_INDEX_QUOTER.ARRIVAL),
      selector: (row: any) => (row?.arribo ? format(new Date(row?.arribo), FORMAT_DATE_ONLY) : ""),
      width: isMobile ? "100px" : `150px`,
      center: true,
      omit: !columnsActive.includes("17")
    },
    {
      id: "18",
      name: t(TRANSLATION_INDEX_QUOTER.SUPPLIER),
      selector: (row: any) => row?.proveedor,
      width: isMobile ? "100px" : `200px`,
      center: true,
      cell: (row: any) => {
        return <ContainerLabelTwoRow label={row.proveedor} />
      },
      omit: !columnsActive.includes("18")
    },
    {
      id: "19",
      name: t(TRANSLATION_INDEX_PROFILE.COLUMNS.ACTIONS),
      cell: (row: any) => {
        return (
          <ContainerIcons>
            <ButtonCustom
              marginLeft
              label={t(TRANSLATION_INDEX_QUOTER.VIEW_DETAILS) ?? ""}
              onClick={() => {
                getCustomById(row.idAduanaOperacion)
              }}
              width="105px"
              background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
              backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
              icon={<IconView />}
            />
          </ContainerIcons>
        )
      },
      center: true,
      width: "120px"
    }
  ]
  const columnsStorageOrder = localStorage.getItem("ordrColumnCustoms")
  if (!columnsStorageOrder)
    localStorage.setItem("ordrColumnCustoms", JSON.stringify(["1", "7", "2", "3", "4", "5", "6", "15", "19"]))

  const handleChangeOrder = (data): void => {
    localStorage.setItem("ordrColumnCustoms", JSON.stringify(data.filter(item => item?.id).map(item => item?.id)))
  }

  const handleChangeHoverImport = (): void => {
    setBgImportHover(!bgImportHover)
  }
  const handleOpenModal = (action = ""): void => {
    if (openModal) dispatch(customclear())
    setAction(action)
    setOpenModal(!openModal)
  }

  const handleOpenModalSelectColumn = (): void => {
    setOpenModalSelectColumn(!openModalSelectColumn)
  }
  const handleOpenModalErrorr = (): void => {
    if (activeModalErrors) dispatch(customclear())
    setActiveModalErrors(!activeModalErrors)
  }

  const convertToBase64 = (file): void => {
    setLoadingContext(true)
    const reader = new FileReader()
    reader.readAsDataURL(file[0])
    reader.onload = function () {
      const base64 = reader.result
      setfileSelected({ nombreArchivo: file[0].name, contenidoArchivo: base64 })
      const inputFile = document.getElementById("file-1") as HTMLInputElement
      if (inputFile && inputFile?.value) inputFile.value = ""
    }
  }
  const getCustomById = (id): void => {
    setIdSelected(id)
    dispatch(getCustomsByIdRequest(id))
  }
  const getRequest = (): ICustomsFilter => {
    return {
      ordenamiento: sortBy,
      paginado: pagination,
      filtro: {
        tipo: filters?.tipoOperacion?.value ?? null,
        modoTransporte: filters?.modoTransporte?.value ?? null,
        paises:
          filters?.pais?.map(item => {
            return { idPais: item.value, descripcion: item.label }
          }) ?? null,
        lugar: { idLugar: filters?.lugar?.value, descripcion: filters?.lugar?.label },
        clientes:
          filters?.clientes?.map(item => {
            return { idCliente: item.value, descripcion: item.label }
          }) ?? null,
        anio: filters?.year ?? null,
        mesDesde: filters?.mesDesde?.value ?? null,
        mesHasta: filters?.mesHasta?.value ?? null,
        comercialAsignado: filters?.comercialAsignado?.value ?? null,
        transportistas:
          filters?.transportistas?.length > 0
            ? filters?.transportistas?.map(item => {
                return item.value
              }) ?? null
            : null,
        sinComercialAsignado: filters?.sinComercialAsignado ?? false
      }
    }
  }

  const handleExcel = (): void => {
    setAction(ACTIONS.EXPORT.VALUE)
    const request = getRequest()
    let filterRequest: string = ""
    if (!isStringEmpty(request?.filtro?.tipo ?? "")) {
      filterRequest = `${filterRequest}tipo=${request.filtro.tipo}&`
    }

    if (!isStringEmpty(request?.filtro?.modoTransporte ?? "")) {
      filterRequest = `${filterRequest}modoTransporte=${request.filtro.modoTransporte}&`
    }
    if (request?.filtro?.transportistas && request?.filtro?.transportistas.length > 0) {
      filterRequest = `${filterRequest}transportistas=${request.filtro.transportistas.map(item => item).join(",")}&`
    }
    if (request?.filtro?.paises && request?.filtro?.paises.length > 0) {
      filterRequest = `${filterRequest}paises=${request.filtro.paises.map(item => item.idPais).join(",")}&`
    }

    if (request?.filtro?.clientes && request?.filtro?.clientes.length > 0) {
      filterRequest = `${filterRequest}clientes=${request.filtro.clientes.map(item => item.idCliente).join(",")}&`
    }
    if (!isStringEmpty(request?.filtro?.lugar?.idLugar?.toString() ?? "")) {
      filterRequest = `${filterRequest}lugar=${request.filtro.lugar?.idLugar}&`
    }
    if (!isStringEmpty(request?.filtro?.mesDesde?.toString() ?? "")) {
      filterRequest = `${filterRequest}mesDesde=${request.filtro.mesDesde}&`
    }
    if (!isStringEmpty(request?.filtro?.mesHasta?.toString() ?? "")) {
      filterRequest = `${filterRequest}mesHasta=${request.filtro.mesHasta}&`
    }
    if (!isStringEmpty(request?.filtro?.anio?.toString() ?? "")) {
      filterRequest = `${filterRequest}anio=${request.filtro.anio}&`
    }
    if (!isStringEmpty(request?.filtro?.comercialAsignado?.toString() ?? "")) {
      filterRequest = `${filterRequest}comercialAsignado=${request.filtro.comercialAsignado}&`
    }
    filterRequest = `${filterRequest}sinComercialAsignado=${request.filtro.sinComercialAsignado}&`

    if (customs.data?.totalRegistros && customs.data?.totalRegistros > 100000)
      toast.error(t(TRANSLATION_INDEX_RESPONSE_CODE.LIMIT_TO_EXPORT))
    else window.open(`${process.env.REACT_APP_API_URL}/api/backoffice/v1/aduana/operaciones/exportar?${filterRequest}`)
  }

  const handleDispatch = (values: any): void => {
    switch (action) {
      case ACTIONS.DELETE.VALUE:
        dispatch(deleteCustomerRequest(values.idCliente))
        break
      case ACTIONS.ADD.VALUE:
        dispatch(addCustomerRequest(values))
        break
      case ACTIONS.EDIT.VALUE:
        dispatch(putCustomerRequest(values))
        break
      default:
        break
    }
  }

  const getData = (): void => {
    const request = getRequest()

    dispatch(getCustomsRequest(request))
  }

  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: false,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const handlePageChange = (page: number): void => {
    setPagination({ ...pagination, pagina: page })
  }

  const handleSort = (column, sortDirection): void => {
    setResetPage(true)
    setSortBy({ columna: column?.id ?? 1, direccion: sortDirection })
    setPagination({ ...pagination, pagina: 1 })
  }

  const handlePerRowsChange = (newPerPage: number, page: number): void => {
    setResetPage(true)
    setPagination({ registrosPorPagina: newPerPage, pagina: 1 })
  }

  const columnsError = [
    {
      name: t(TRANSLATION_INDEX_QUOTER.FILE_NAME),
      selector: (row: IErrorImport) => row.nombreArchivo.toUpperCase(),
      width: isMobile ? "250px" : `40%`,
      sortable: true
    },
    {
      name: t(TRANSLATION_INDEX_QUOTER.ERRORS),
      width: isMobile ? "250px" : `60%`,
      cell: (row: IErrorImport) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {row.errores.map(item => {
              return (
                <div key={item} style={{ width: "100%", padding: "10px 0" }}>
                  {t(TRANSLATION_INDEX_RESPONSE_CODE[item])}
                </div>
              )
            })}
          </div>
        )
      }
    }
  ]

  const handleInport = (
    <div style={{ marginTop: "0px" }}>
      <div style={{ marginBottom: "10px" }}>
        <input
          type="file"
          name="file-1"
          id="file-1"
          accept=".XLS,.XLSX,.ZIP,.RAR"
          style={{
            width: "0.1px",
            height: "0.1px",
            opacity: "0",
            position: "absolute",
            zIndex: -1
          }}
          className="inputfile inputfile-1"
          data-multiple-caption="{count} archivos seleccionados"
          onChange={e => {
            convertToBase64(e.target.files)
          }}
        />
        <label
          htmlFor="file-1"
          onMouseEnter={handleChangeHoverImport}
          onMouseLeave={handleChangeHoverImport}
          style={{
            borderRadius: "5px",
            backgroundImage: bgImportHover
              ? GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]
              : GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext],
            color: "white",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            padding: "5px",
            cursor: "pointer"
          }}
        >
          <IconImport />
          <span
            style={{
              fontSize: "0.9rem",
              fontWeight: "normal",
              marginLeft: "5px",
              paddingRight: "2px"
            }}
          >
            Importar
          </span>
        </label>
      </div>
    </div>
  )

  const handleChangeColumn = (values): void => {
    setColumnsActive(values)
    localStorage.setItem("customsColumnSelected", JSON.stringify(values))
    toast.success(t("global.successAdd"))
    handleOpenModalSelectColumn()
  }

  React.useEffect(() => {
    if (fileSelected) {
      fileSelected.contenidoArchivo = fileSelected.contenidoArchivo.split("base64,")[1]
      setLoadingContext(true)
      dispatch(postCustomsImportRequest(fileSelected))
      setfileSelected(null)
    }
  }, [fileSelected])

  React.useEffect(() => {
    if (filters) getData()
  }, [pagination, sortBy])

  React.useEffect(() => {
    if (filters) {
      setResetPage(true)
      setPagination({ ...pagination, pagina: 1 })
    }
  }, [filters])

  React.useEffect(() => {
    if (resetFilter) {
      setFilters(null)
      setResetFilter(false)
      dispatch(customclearAll())
    }
  }, [resetFilter])

  React.useEffect(() => {
    if (resetPage) setResetPage(false)
  }, [resetPage])

  React.useEffect(() => {
    if (customs.showToast) {
      if (customs.hasErrMessage) {
        toast.error(t(customs.errorCode))
      } else {
        toast.success(t(customs.toastMessage))
        if (action && action !== ACTIONS.EXPORT.VALUE) {
          handleOpenModal()
          getData()
        }
      }
    }
  }, [customs.showToast])

  React.useEffect(() => {
    setLoadingContext(customs.loading)
  }, [customs])

  React.useEffect(() => {
    if (customs.customsToUpdate) {
      const value = customs.customsToUpdate
      value.idAduanaOperacion = idSelected
      setUserSelected(value)
      handleOpenModal(ACTIONS.VIEW.VALUE)
    }
  }, [customs.customsToUpdate])

  React.useEffect(() => {
    if (customs.errorsImport?.erroresImportacion && customs.errorsImport?.erroresImportacion?.length > 0) {
      handleOpenModalErrorr()
    }
  }, [customs.errorsImport])

  React.useEffect(() => {
    dispatch(getAssignedCommercialRequest())
    dispatch(getCustomsPlacesRequest())
    dispatch(getCountryRequest())
    return () => {
      dispatch(customclearAll())
    }
  }, [])

  return (
    <ContainerPage
      title={`${t(TRANSLATION_INDEX_MENU.CUSTOMS)}`}
      actions={
        filters
          ? [
              {
                isButton: true,
                component: handleInport,
                permission: PERMISSION.ADUANA_IMPORTAR
              },
              {
                label: t(TRANSLATION_INDEX_GLOBAL.EXPORT),
                icon: <IconExcel size={13} />,
                permission: PERMISSION.ADUANA_CONSULTAR,
                click: handleExcel
              }
            ]
          : [
              {
                isButton: true,
                component: handleInport,
                permission: PERMISSION.ADUANA_IMPORTAR
              }
            ]
      }
    >
      <>
        {openModal && (
          <AlertModal
            maxWidth={isMobile ? "95%" : action === ACTIONS.DELETE.VALUE ? "50%" : "80%"}
            closeModal={() => {
              handleOpenModal()
            }}
            activeCloseModal
            title={`${t(TRANSLATION_INDEX_MENU.CUSTOMS)} | ${t(TRANSLATION_INDEX_GLOBAL[action.toUpperCase()])}`}
          >
            <FormaddUpdate
              data={userSelected}
              action={action}
              closeModal={handleOpenModal}
              loading={loadingContext}
              accept={handleDispatch}
            />
          </AlertModal>
        )}
        {openModalSelectColumn && (
          <AlertModal
            maxWidth={isMobile ? "95%" : "30%"}
            closeModal={() => {
              handleOpenModalSelectColumn()
            }}
            activeCloseModal
            title={`${t(TRANSLATION_INDEX_MENU.CUSTOMS)} | ${t(TRANSLATION_INDEX_GLOBAL.SELECT_COLUMNS)}`}
          >
            <SelectColumn data={columnsActive} closeModal={handleOpenModalSelectColumn} accept={handleChangeColumn} />
          </AlertModal>
        )}
        {activeModalErrors && (
          <AlertModal
            maxWidth={isMobile ? "95%" : action === ACTIONS.DELETE.VALUE ? "50%" : "80%"}
            closeModal={() => {
              handleOpenModalErrorr()
            }}
            activeCloseModal
            title={`${t(TRANSLATION_INDEX_MENU.CUSTOMS)} | ${t(TRANSLATION_INDEX_QUOTER.IMPORT_ERROR)}`}
          >
            <>
              <DataTable
                data={customs.errorsImport?.erroresImportacion ?? []}
                theme="solarized"
                noDataComponent={<NoData />}
                columns={columnsError}
                // dense
                pagination
                paginationComponentOptions={paginationComponentOptions}
                striped
                customStyles={customStylesContext(themeContext, GLOBAL_STYLES.BACKGROUND.TYPE_5, "auto")}
                defaultSortFieldId={1}
              />
            </>
          </AlertModal>
        )}

        <ContainerTable themeContext={themeContext} opacity={1}>
          <Search
            data={customs.data?.operaciones}
            setFilters={setFilters}
            resetFilter={resetFilter}
            setClearFilter={setResetFilter}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "5px",
              marginTop: "10px"
            }}
          >
            <ButtonCustom
              onClick={handleOpenModalSelectColumn}
              label={t(TRANSLATION_INDEX_GLOBAL.SELECT_COLUMNS) ?? ""}
              background={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE[themeContext]}
              backgroundHover={GLOBAL_STYLES.BUTTON.SUCCESS_BG_IMAGE_HOVER[themeContext]}
              width="auto"
            />
          </div>
          <DataTable
            data={customs.data?.operaciones ?? []}
            theme="solarized"
            noDataComponent={
              <NoData
                label={
                  loadingContext
                    ? ""
                    : filters
                    ? TRANSLATION_INDEX_GLOBAL.NO_DATA_2
                    : TRANSLATION_INDEX_GLOBAL.USE_SEARCH_TO_GET_RESULTS
                }
              />
            }
            columns={columns}
            dense
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
            striped
            customStyles={customStylesContext(themeContext)}
            paginationServer
            paginationTotalRows={customs?.data?.totalRegistros}
            paginationDefaultPage={1}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
            paginationResetDefaultPage={resetPage}
            onColumnOrderChange={cols => {
              handleChangeOrder(cols)
            }}
          />
        </ContainerTable>
      </>
    </ContainerPage>
  )
}

export default CustomsPage
