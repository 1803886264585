// SAGAS
import { put, takeLatest, call } from "redux-saga/effects"
import { type SagaIterator } from "redux-saga"
import * as types from "../types"

import {
  getRemendersFailure,
  getRemendersSuccess,
  addRemendersSuccess,
  addRemendersFailure,
  deleteRemendersSuccess,
  deleteRemendersFailure,
  putRemendersSuccess,
  putRemendersFailure,
  getTypeRemendersSuccess,
  getTypeRemendersFailure,
  getContactRemendersSuccess,
  getContactRemendersFailure
} from "../actions"

import {
  addRemenders,
  deleteRemenders,
  getContactByCustomer,
  getRemenders,
  getTypeRemenders,
  putRemenders
} from "../services"

function* getRemendersWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getRemenders, request.payload)
    yield put(getRemendersSuccess(data))
  } catch (error) {
    yield put(getRemendersFailure(error))
  }
}

function* getTypeRemendersWorker(): SagaIterator {
  try {
    const { data } = yield call(getTypeRemenders)
    yield put(getTypeRemendersSuccess(data))
  } catch (error) {
    yield put(getTypeRemendersFailure(error))
  }
}

function* getContactRemendersWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(getContactByCustomer, request.payload)
    yield put(getContactRemendersSuccess(data))
  } catch (error) {
    yield put(getContactRemendersFailure(error))
  }
}

function* addRemendersWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(addRemenders, request.payload)
    yield put(addRemendersSuccess(data))
  } catch (error) {
    yield put(addRemendersFailure(error))
  }
}

function* putRemendersWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(putRemenders, request.payload)
    yield put(putRemendersSuccess(data))
  } catch (error) {
    yield put(putRemendersFailure(error))
  }
}

function* deleteRemendersWorker(request: any): SagaIterator {
  try {
    const { data } = yield call(deleteRemenders, request.payload)
    yield put(deleteRemendersSuccess(data))
  } catch (error) {
    yield put(deleteRemendersFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.REMENDERS_GET_REQUEST, getRemendersWorker)
  yield takeLatest(types.REMENDERS_ADD_REQUEST, addRemendersWorker)
  yield takeLatest(types.REMENDERS_PUT_REQUEST, putRemendersWorker)
  yield takeLatest(types.REMENDERS_TYPE_GET_REQUEST, getTypeRemendersWorker)
  yield takeLatest(types.REMENDERS_CONTACT_GET_REQUEST, getContactRemendersWorker)
  yield takeLatest(types.REMENDERS_DELETE_REQUEST, deleteRemendersWorker)
}

export const sagas = [listWatcher]
