/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from "styled-components"
import { type IStyle } from "../../interfaces/IStyle"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"

export const Container = styled.div<IStyle>`
  position: relative;
  width: 98%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  border-radius: 5px;
  margin: 24px auto 20px auto;
  box-shadow: ${({ themeContext }) => GLOBAL_STYLES.BOX_SHADOW.TYPE_4[themeContext]};
`

export const ContainerHeader = styled.div<IStyle>`
  padding: ${({ active }) => (active ? "0.1rem 1.25rem" : "")};
  width: calc(100% - 40px);
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_1[themeContext]};
  color: ${({ themeContext }) => GLOBAL_STYLES.LABEL.TYPE_1[themeContext]};
  display: flex;
  border-bottom: ${({ themeContext }) => GLOBAL_STYLES.BORDER.TYPE_2[themeContext]};
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
`

export const ContainerTitle = styled.div<IStyle>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: ${({ margin }) => margin};
`

export const Title = styled.div<IStyle>`
  font-size: 1.4rem;
  font-weight: 400;
  height: 100%;
  line-height: ${({ lineHeight }) => lineHeight};
`
export const SubTitle = styled.div<IStyle>`
  font-size: 0.8rem;
  font-weight: 400;
  height: 100%;
  line-height: 130%;
`
export const ContainerAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div<IStyle>`
  padding: 10px 15px 15px 15px;
  width: calc(100% - 30px);
  background-color: ${({ themeContext }) => GLOBAL_STYLES.BACKGROUND.TYPE_2[themeContext]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  border-radius: 0px 0px 5px 5px;
`
