import { remenders } from "./_duck"
import * as types from "./types"

export const getRemendersRequest = remenders.createAction(types.REMENDERS_GET_REQUEST)
export const getRemendersSuccess = remenders.createAction(types.REMENDERS_GET_SUCCESS)
export const getRemendersFailure = remenders.createAction(types.REMENDERS_GET_FAILURE)

export const getTypeRemendersRequest = remenders.createAction(types.REMENDERS_TYPE_GET_REQUEST)
export const getTypeRemendersSuccess = remenders.createAction(types.REMENDERS_TYPE_GET_SUCCESS)
export const getTypeRemendersFailure = remenders.createAction(types.REMENDERS_TYPE_GET_FAILURE)

export const getContactRemendersRequest = remenders.createAction(types.REMENDERS_CONTACT_GET_REQUEST)
export const getContactRemendersSuccess = remenders.createAction(types.REMENDERS_CONTACT_GET_SUCCESS)
export const getContactRemendersFailure = remenders.createAction(types.REMENDERS_CONTACT_GET_FAILURE)

export const addRemendersRequest = remenders.createAction(types.REMENDERS_ADD_REQUEST)
export const addRemendersSuccess = remenders.createAction(types.REMENDERS_ADD_SUCCESS)
export const addRemendersFailure = remenders.createAction(types.REMENDERS_ADD_FAILURE)

export const putRemendersRequest = remenders.createAction(types.REMENDERS_PUT_REQUEST)
export const putRemendersSuccess = remenders.createAction(types.REMENDERS_PUT_SUCCESS)
export const putRemendersFailure = remenders.createAction(types.REMENDERS_PUT_FAILURE)

export const deleteRemendersRequest = remenders.createAction(types.REMENDERS_DELETE_REQUEST)
export const deleteRemendersSuccess = remenders.createAction(types.REMENDERS_DELETE_SUCCESS)
export const deleteRemendersFailure = remenders.createAction(types.REMENDERS_DELETE_FAILURE)

export const clearAllRemenderss = remenders.createAction(types.REMENDERS_CLEAR_ALL)
