/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { ContainerLogout, DisableInput, Label } from "../_styles"
import { useGlobalContext } from "../../../context/GlobalContext"
import { STORAGE } from "../../../utils/localStorage"
import { useDispatch, useSelector } from "react-redux"
import { getUserBackofficeNotificationRequest } from "../../../state/modules/userBackOffice/actions"
import { userBackofficeSelector } from "../../../state/modules/userBackOffice/_selectors"

const Notification = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { themeContext } = useGlobalContext()
  const userBackoffice = useSelector(userBackofficeSelector)

  const [activeNotification, setActiveNotification] = React.useState(STORAGE.get(STORAGE.NOTIFICATION) === 1)
  const notificationHandle = (val): void => {
    setActiveNotification(val)
    dispatch(getUserBackofficeNotificationRequest(val ? 1 : 0))
  }

  return (
    <ContainerLogout
      themeContext={themeContext}
      onClick={() => {
        notificationHandle(!activeNotification)
      }}
    >
      {userBackoffice.loading && <DisableInput />}
      <Label themeContext={themeContext}>{`${t("login.notification")}`}</Label>
      <input
        type="checkbox"
        checked={activeNotification}
        onChange={e => {
          notificationHandle(e.target.checked)
        }}
      />
    </ContainerLogout>
  )
}

export default Notification
