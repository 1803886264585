/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { type IUserBackoffice } from "../../interfaces/IUserBackoffice"

export const filterData = (data, filters): any => {
  let newList: IUserBackoffice[] = []
  if (filters?.search != null && filters?.search.length > 0 && filters?.estado != null) {
    newList = data?.filter(
      (item: IUserBackoffice) =>
        (item.email?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.nombre?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.usuario?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.apellido?.toLowerCase().includes(filters?.search.toLowerCase()) ||
          item.perfil?.descripcion?.toLowerCase().includes(filters?.search.toLowerCase())) &&
        item.estado.idEstado === filters?.estado?.value
    )
    return newList
  } else if (filters?.search && filters?.search.length > 0) {
    newList = data?.filter(
      (item: IUserBackoffice) =>
        item.email?.toLowerCase().includes(filters?.search.toLowerCase()) ||
        item.nombre?.toLowerCase().includes(filters?.search.toLowerCase()) ||
        item.usuario?.toLowerCase().includes(filters?.search.toLowerCase()) ||
        item.apellido?.toLowerCase().includes(filters?.search.toLowerCase()) ||
        item.perfil?.descripcion?.toLowerCase().includes(filters?.search.toLowerCase())
    )
    return newList
  } else if (filters?.estado) {
    newList = data?.filter((item: IUserBackoffice) => item.estado.idEstado === filters?.estado?.value)
    return newList
  } else {
    return data
  }
}
