import * as Yup from "yup"
import { schemeObject } from "../../utils/constants"
import { TRANSLATION_INDEX_GLOBAL } from "../../config/translation"

export const deleteScheme = Yup.object().shape({})

export const addUpdateScheme = Yup.object().shape({
  fechaHoraInicio: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  fechaHoraFin: Yup.string().required(TRANSLATION_INDEX_GLOBAL.REQUIRED),
  cliente: schemeObject()
})
