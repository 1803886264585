import { userBackoffice } from "./_duck"
import * as types from "./types"

export const getUserBackofficeRequest = userBackoffice.createAction(types.USER_BACKOFFICE_GET_REQUEST)
export const getUserBackofficeSuccess = userBackoffice.createAction(types.USER_BACKOFFICE_GET_SUCCESS)
export const getUserBackofficeFailure = userBackoffice.createAction(types.USER_BACKOFFICE_GET_FAILURE)

export const getUserBackofficeImageRequest = userBackoffice.createAction(types.USER_BACKOFFICE_IMAGE_GET_REQUEST)
export const getUserBackofficeImageSuccess = userBackoffice.createAction(types.USER_BACKOFFICE_IMAGE_GET_SUCCESS)
export const getUserBackofficeImageFailure = userBackoffice.createAction(types.USER_BACKOFFICE_IMAGE_GET_FAILURE)

export const getUserBackofficeImageURLRequest = userBackoffice.createAction(types.USER_BACKOFFICE_IMAGE_URL_GET_REQUEST)
export const getUserBackofficeImageURLSuccess = userBackoffice.createAction(types.USER_BACKOFFICE_IMAGE_URL_GET_SUCCESS)
export const getUserBackofficeImageURLFailure = userBackoffice.createAction(types.USER_BACKOFFICE_IMAGE_URL_GET_FAILURE)

export const getUserBackofficeNotificationRequest = userBackoffice.createAction(
  types.USER_BACKOFFICE_NOTIFICATION_GET_REQUEST
)
export const getUserBackofficeNotificationSuccess = userBackoffice.createAction(
  types.USER_BACKOFFICE_NOTIFICATION_GET_SUCCESS
)
export const getUserBackofficeNotificationFailure = userBackoffice.createAction(
  types.USER_BACKOFFICE_NOTIFICATION_GET_FAILURE
)

export const addUserBackofficeRequest = userBackoffice.createAction(types.USER_BACKOFFICE_ADD_REQUEST)
export const addUserBackofficeSuccess = userBackoffice.createAction(types.USER_BACKOFFICE_ADD_SUCCESS)
export const addUserBackofficeFailure = userBackoffice.createAction(types.USER_BACKOFFICE_ADD_FAILURE)

export const postUserBackofficeRequest = userBackoffice.createAction(types.USER_BACKOFFICE_POST_REQUEST)
export const postUserBackofficeSuccess = userBackoffice.createAction(types.USER_BACKOFFICE_POST_SUCCESS)
export const postUserBackofficeFailure = userBackoffice.createAction(types.USER_BACKOFFICE_POST_FAILURE)

export const deleteUserBackofficeRequest = userBackoffice.createAction(types.USER_BACKOFFICE_DELETE_REQUEST)
export const deleteUserBackofficeSuccess = userBackoffice.createAction(types.USER_BACKOFFICE_DELETE_SUCCESS)
export const deleteUserBackofficeFailure = userBackoffice.createAction(types.USER_BACKOFFICE_DELETE_FAILURE)
