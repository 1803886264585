/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, type ReactElement } from "react"

import { useTranslation } from "react-i18next"
import { type IFormListProps } from "../../interfaces/IFormListProps"
import { useMobile } from "../../hook/useMobile"
import { useDispatch, useSelector } from "react-redux"
import { useGlobalContext } from "../../context/GlobalContext"
import { customerSelector } from "../../state/modules/customer/_selectors"
import { type ICustomer, type ICustomerFilter } from "../../interfaces/ICustomer"
import {
  getAssignedCommercialRequest,
  getClassificationRequest,
  getClientTypeRequest,
  getCountryRequest,
  getCustomerClear,
  getCustomerClearAll,
  getCustomerRequest,
  postBulkAssignmentRequest,
  postReBulkAssignmentRequest
} from "../../state/modules/customer/actions"
import {
  TRANSLATION_INDEX_CUSTOMER_PROSPECT,
  TRANSLATION_INDEX_GLOBAL,
  TRANSLATION_INDEX_PERMISSION,
  TRANSLATION_INDEX_QUOTER
} from "../../config/translation"
import { MdPeopleAlt } from "react-icons/md"

import IconCheck from "../../components/Icons/IconCheck"
import { ToolTipRow } from "../CustomerProspect/_styles"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import { ACTIONS } from "../../utils/constants"
import { Container, ContainerGroup, Group } from "./_styles"
import DataTable from "react-data-table-component"
import { NoData } from "../../components/NoData"
import { customStylesContext } from "../../styles/_styleDatatable"
import { withFormik } from "formik"
import { addUpdateScheme, deleteScheme } from "../Profile/_schema"
import Search from "./components/search"
import SelectCustom from "../../components/SelectCustom"
import ButtonCustom from "../../components/ButtonCustom"
import ContainerPage from "../../components/ContainerPage"
import { toast } from "react-toastify"
import AlertModal from "../../components/AlertModal"

const CommercialBulkAssociates = ({
  isUserPending = false,
  handleOpenModalSelectedCustomer,
  action,
  closeModal = () => {},
  accept = () => {},
  setFieldValue,
  values,
  setSubmitting,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}: IFormListProps): ReactElement => {
  const [data, setData] = React.useState<ICustomer[]>([])
  const { t } = useTranslation()
  const [errorList, setErrorList] = React.useState<any>({ new: "", current: "" })
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const [openModalReassignment, setOpenModalReassignment] = React.useState<boolean>(false)
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const { themeContext, setLoadingContext, loadingContext } = useGlobalContext()
  const [filters, setFilters] = React.useState<any>(null)
  const [resetFilter, setResetFilter] = React.useState<boolean>(false)
  const customer = useSelector(customerSelector)
  const [firstLoad, setFirstLoad] = React.useState(true)

  const [selectedAll, setSelectedAll] = React.useState<boolean>(false)
  const [selectedAllCheck, setSelectedAllCheck] = React.useState<boolean>(false)
  const getRequest = (): ICustomerFilter => {
    return {
      ordenamiento: { columna: 1, direccion: "asc" },
      paginado: { pagina: -1, registrosPorPagina: 10 },
      filtro: {
        cuit: filters?.cuit ?? null,
        razonSocial: filters?.companyName ?? null,
        idTipoCliente: filters?.clientType?.value ?? null,
        comercialAsignado: filters?.assignedCommercial?.value ?? null,
        esCargaCIF: filters?.cifCargo?.value ?? null,
        esImportador: filters?.isImporter?.value ?? null,
        esExportador: filters?.isExporter?.value ?? null,
        procedencias: filters?.origins?.map(item => item.value) ?? [],
        destinos: filters?.destinations?.map(item => item.value) ?? [],
        sinComercialAsignado: filters?.sinComercialAsignado ?? false,
        paginaOrigen: "clientesABM"
      }
    }
  }
  const getData = (): void => {
    const request = getRequest()
    dispatch(getCustomerRequest(request))
  }
  const handleOpenModal = (): void => {
    setOpenModal(!openModal)
  }
  const handleOpenModalReassignment = (): void => {
    setOpenModalReassignment(!openModalReassignment)
  }

  const handleSubmitReassigment = (): void => {
    if (!values?.assignedCommercialNew?.value || !values?.assignedCommercialCurrent?.value)
      setErrorList({
        new: !values?.assignedCommercialNew?.value ? TRANSLATION_INDEX_GLOBAL.REQUIRED : "",
        current: !values?.assignedCommercialCurrent?.value ? TRANSLATION_INDEX_GLOBAL.REQUIRED : ""
      })
    else
      dispatch(
        postReBulkAssignmentRequest({
          comercialAsignadoOrigen: values?.assignedCommercialCurrent?.value,
          comercialAsignadoDestino: values?.assignedCommercialNew?.value
        })
      )
  }
  const paginationComponentOptions = {
    rowsPerPageText: t(TRANSLATION_INDEX_GLOBAL.ROWS_PER_PAGE),
    rangeSeparatorText: t(TRANSLATION_INDEX_GLOBAL.OF),
    selectAllRowsItem: false,
    selectAllRowsItemText: t(TRANSLATION_INDEX_GLOBAL.ALL)
  }

  const validateAllRowSelected = (): boolean => {
    let isAllChecked = true
    customer?.data?.clientes?.forEach(item => {
      if (
        !data
          .map(dat => {
            return dat.idCliente
          })
          .includes(item.idCliente)
      )
        isAllChecked = false
    })
    return isAllChecked
  }
  const handleAssigment = (): void => {
    if (data.length === 0 || !values?.assignedCommercial?.value)
      toast.error(t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMMERCIAL_NO_ADD))
    else setOpenModal(true)
  }
  const handleSubmitBulkAssigment = (): void => {
    dispatch(
      postBulkAssignmentRequest({
        comercialAsignado: values?.assignedCommercial?.value,
        idsClientes: data.map(item => item.idCliente)
      })
    )
  }
  const columns = [
    {
      id: "0",
      name: (
        <IconCheck
          isCheck={validateAllRowSelected()}
          size={10}
          onclick={() => {
            setSelectedAllCheck(selectedAll !== selectedAllCheck ? !selectedAll : !selectedAllCheck)
            setFirstLoad(false)

            setSelectedAll(selectedAll !== selectedAllCheck ? !selectedAll : !selectedAllCheck)
          }}
        />
      ),
      selector: (row: ICustomer) => row.razonSocial.toUpperCase(),
      cell: (row: ICustomer) => {
        return (
          <IconCheck
            isCheck={data.some(item => item?.idCliente === row.idCliente)}
            size={10}
            onclick={() => {
              handleChangeSelected(row)
            }}
          />
        )
      },
      width: `50px`,
      center: true
    },
    {
      id: "1",
      name: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMPANY_NAME),
      selector: (row: ICustomer) => row.razonSocial.toUpperCase(),
      cell: (row: ICustomer) => {
        return (
          <ToolTipRow
            onClick={() => {
              handleChangeSelected(row)
            }}
          >
            {row.razonSocial.toUpperCase()}
          </ToolTipRow>
        )
      },
      width: `calc(70% - 50px)`,
      sortable: true
    },
    {
      id: "2",
      name: "CUIT",
      selector: (row: ICustomer) => row.cuit,
      width: "30%",
      center: true,
      sortable: true
    }
  ]

  const handleChangeSelectedAll = (): void => {
    if (setData) {
      if (selectedAll) {
        const itemFiltered = customer.data?.clientes
          ? customer.data?.clientes.filter(
              item =>
                !data
                  .map(cus => {
                    return cus.idCliente
                  })
                  .includes(item.idCliente)
            )
          : []
        const newList = data.concat(itemFiltered)
        setData(newList)
      } else {
        const itemFiltered = data.filter(
          item =>
            !customer.data?.clientes
              .map(cus => {
                return cus.idCliente
              })
              .includes(item.idCliente)
        )
        setData(itemFiltered)
      }
    }
  }

  const handleChangeSelected = (selectedRow: ICustomer): void => {
    setFirstLoad(false)
    if (setData) {
      if (data.some(item => item.idCliente === selectedRow.idCliente)) {
        const newData = data.filter(item => item.idCliente !== selectedRow.idCliente)
        setData(newData)
        setSelectedAllCheck(false)
      } else {
        const newData = [...data, selectedRow]
        setData(newData)
        let selectAllRows = 0
        customer.data?.clientes?.forEach(item => {
          if (
            newData
              .map(selec => {
                return selec.idCliente
              })
              .includes(item.idCliente)
          )
            selectAllRows = selectAllRows + 1
        })
        if (selectAllRows === customer.data?.clientes.length) {
          setSelectedAll(true)
          setSelectedAllCheck(true)
        } else {
          setSelectedAll(false)
          setSelectedAllCheck(false)
        }
      }
    }
  }

  React.useEffect(() => {
    setLoadingContext(customer.loading)
  }, [customer])

  React.useEffect(() => {
    if (filters) {
      setData([])
      getData()
    }
  }, [filters])

  React.useEffect(() => {
    if (resetFilter) {
      setResetFilter(false)
      dispatch(getCustomerClearAll())
    }
  }, [resetFilter])

  useEffect(() => {
    if (customer.bulkassigmenteOk) {
      setOpenModal(false)
      setOpenModalReassignment(false)
      toast.success(t(TRANSLATION_INDEX_GLOBAL.SUCCESS))
      if (setFieldValue) setFieldValue("assignedCommercial", null)
    }
  }, [customer.bulkassigmenteOk])

  React.useEffect(() => {
    if (!firstLoad) handleChangeSelectedAll()
  }, [selectedAll])

  React.useEffect(() => {
    dispatch(getCustomerClear())
    setLoadingContext(true)
    dispatch(getClientTypeRequest())
    dispatch(getCountryRequest())
    dispatch(getClassificationRequest())
    dispatch(getAssignedCommercialRequest())

    return () => {
      dispatch(getCustomerClearAll())
    }
  }, [])
  return (
    <ContainerPage
      title={`${t(TRANSLATION_INDEX_PERMISSION.ASIGNACION_MASIVA_COMERCIAL)}`}
      actions={[
        {
          label: t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMMERCIAL_REASSIGMENT),
          icon: <MdPeopleAlt size={13} />,
          permission: "*",
          click: handleOpenModalReassignment
        }
      ]}
    >
      <Container onSubmit={handleSubmit}>
        {openModal && (
          <AlertModal
            maxWidth={isMobile ? "95%" : "50%"}
            closeModal={() => {
              handleOpenModal()
            }}
            activeCloseModal
            title={`${t(TRANSLATION_INDEX_GLOBAL.WARNING)}`}
          >
            <div style={{ padding: " 0px" }}>
              <div style={{ padding: "20px 25px", color: GLOBAL_STYLES.LABEL.TYPE_1[themeContext] }}>
                {t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.WARNING_TO_CONTINUE_ASSIGNMENT)}
              </div>
              <div style={{ width: "calc(100% - 5px)", borderBottom: GLOBAL_STYLES.BORDER.TYPE_2[themeContext] }}></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: " 0 15px",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                <ButtonCustom
                  label={t(TRANSLATION_INDEX_GLOBAL.CANCEL) ?? ""}
                  background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                  backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                  onClick={handleOpenModal}
                  width="auto"
                  marginLeft
                />
                <ButtonCustom
                  label={t(TRANSLATION_INDEX_GLOBAL.ACCEPT) ?? ""}
                  background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                  backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                  onClick={handleSubmitBulkAssigment}
                  width="auto"
                  marginLeft
                />
              </div>
            </div>
          </AlertModal>
        )}
        {openModalReassignment && (
          <AlertModal
            maxWidth={isMobile ? "95%" : "60%"}
            closeModal={() => {
              handleOpenModalReassignment()
            }}
            activeCloseModal
            title={`${t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMMERCIAL_REASSIGMENT)}`}
          >
            <div style={{ padding: " 0px" }}>
              <div
                style={{
                  padding: "30px 25px 50px",
                  color: GLOBAL_STYLES.LABEL.TYPE_1[themeContext],
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {setFieldValue != null && (
                  <SelectCustom
                    label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.CURRENT_COMMERCIAL) ?? ""}
                    value={values?.assignedCommercialCurrent ?? null}
                    handleChange={setFieldValue}
                    name="assignedCommercialCurrent"
                    placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                    width={isMobile ? "100%" : "100%"}
                    validError
                    maxMenuHeight={100}
                    errors={errorList?.current}
                    options={customer.assignedCommercial.map(item => {
                      return {
                        value: item.nombreUsuario,
                        label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
                      }
                    })}
                  />
                )}
                {setFieldValue != null && (
                  <SelectCustom
                    label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.NEW_COMMERCIAL) ?? ""}
                    value={values?.assignedCommercialNew ?? null}
                    handleChange={setFieldValue}
                    name="assignedCommercialNew"
                    placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                    width={isMobile ? "100%" : "100%"}
                    validError={true}
                    marginLeft
                    maxMenuHeight={100}
                    errors={errorList?.new}
                    options={customer.assignedCommercial.map(item => {
                      return {
                        value: item.nombreUsuario,
                        label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
                      }
                    })}
                  />
                )}
              </div>
              <div style={{ width: "calc(100% - 5px)", borderBottom: GLOBAL_STYLES.BORDER.TYPE_2[themeContext] }}></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: " 0 15px",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                <ButtonCustom
                  label={t(TRANSLATION_INDEX_GLOBAL.CANCEL) ?? ""}
                  background={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE[themeContext]}
                  backgroundHover={GLOBAL_STYLES.BUTTON.DANGER_BG_IMAGE_HOVER[themeContext]}
                  onClick={handleOpenModalReassignment}
                  width="auto"
                  marginLeft
                />
                <ButtonCustom
                  label={t(TRANSLATION_INDEX_GLOBAL.ACCEPT) ?? ""}
                  background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                  backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                  onClick={handleSubmitReassigment}
                  width="auto"
                  marginLeft
                />
              </div>
            </div>
          </AlertModal>
        )}
        <ContainerGroup themeContext={themeContext}>
          <Group themeContext="">
            <Search
              data={customer.data?.clientes}
              setFilters={setFilters}
              resetFilter={resetFilter}
              setClearFilter={setResetFilter}
            />
          </Group>

          <Group themeContext="">
            <div style={{ width: isMobile ? "100%" : "50%", display: "flex", flexDirection: "column" }}>
              <div style={{ textAlign: "center", margin: "15px 0" }}>{t(TRANSLATION_INDEX_QUOTER.FILTER_RESULT)}</div>
              <DataTable
                data={customer.data?.clientes ?? []}
                theme="solarized"
                noDataComponent={
                  <NoData
                    label={
                      loadingContext
                        ? ""
                        : filters
                        ? TRANSLATION_INDEX_GLOBAL.NO_DATA_2
                        : TRANSLATION_INDEX_GLOBAL.USE_SEARCH_TO_GET_RESULTS
                    }
                  />
                }
                columns={columns}
                dense
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                striped
                customStyles={customStylesContext(themeContext)}
                paginationPerPage={10}
                paginationDefaultPage={1}
              />
            </div>
            <div
              style={{
                marginLeft: isMobile ? "" : "10%",
                width: isMobile ? "100%" : "50%",
                display: "flex",
                paddingTop: isMobile ? "15px" : "",
                flexDirection: "column"
              }}
            >
              <div style={{ textAlign: "center", margin: "15px 0" }}>
                {t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMMERCIAL_TO_BE_ASSIGNED)}
              </div>
              <Group themeContext="" alignItems="flex-end">
                {setFieldValue != null && (
                  <SelectCustom
                    // label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.COMMERCIAL_TO_BE_ASSIGNED) ?? ""}
                    value={values?.assignedCommercial ?? null}
                    iconLeft
                    handleChange={setFieldValue}
                    name="assignedCommercial"
                    placeHolder={t(TRANSLATION_INDEX_GLOBAL.SELECT_OPTION) ?? ""}
                    width={isMobile ? "100%" : "100%"}
                    validError={false}
                    options={customer.assignedCommercial.map(item => {
                      return {
                        value: item.nombreUsuario,
                        label: `${item.apellido.toLocaleUpperCase()} ${item.nombre.toLocaleUpperCase()}`
                      }
                    })}
                  />
                )}
                <div>
                  <ButtonCustom
                    label={t(TRANSLATION_INDEX_CUSTOMER_PROSPECT.BULK_ASSIGNMENT) ?? ""}
                    background={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE[themeContext]}
                    backgroundHover={GLOBAL_STYLES.BUTTON.PRIMARY_BG_IMAGE_HOVER[themeContext]}
                    onClick={handleAssigment}
                    width="auto"
                    marginLeft
                  />
                </div>
              </Group>
              {/* <DataTable
                data={data}
                theme="solarized"
                noDataComponent={
                  <NoData label={loadingContext ? "" : t(TRANSLATION_INDEX_QUOTER.NO_CUSTOMER_SELECTED) ?? ""} />
                }
                columns={columnsSelected}
                dense
                pagination
                paginationComponentOptions={paginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                striped
                customStyles={customStylesContext(themeContext)}
                paginationDefaultPage={1}
              /> */}
            </div>
          </Group>
        </ContainerGroup>
      </Container>
    </ContainerPage>
  )
}

export default withFormik<IFormListProps, any>({
  mapPropsToValues: ({ data }): any => ({}),
  displayName: "Form",
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: ({ action }) => (action === ACTIONS.DELETE.VALUE ? deleteScheme : addUpdateScheme),
  handleSubmit: (values, { setSubmitting, props: { accept } }): any => {
    if (accept != null) accept(values)
    setSubmitting(false)
  }
})(CommercialBulkAssociates)
