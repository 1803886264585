import React, { type ReactElement } from "react"

import { Container, Label } from "./_styles"
import { type Props } from "./_types"
import IconCheck from "../Icons/IconCheck"
import IconPlus from "../Icons/IconPlus"
import { GLOBAL_STYLES } from "../../styles/GLOBAL_STYLE"
import IconMinus from "../Icons/IconMinus"
import { useGlobalContext } from "../../context/GlobalContext"

const ContainerIconLabel: React.FC<Props> = ({
  active,
  activeChildren = false,
  activeCollanse = null,
  setChildren = () => {},
  label,
  openAll = false,
  marginLeft = false,
  setCollapse = () => {},
  handleAddArray = () => {}
}): ReactElement => {
  const { setOpenAllCollansePermission, themeContext } = useGlobalContext()
  const handleClick = (): void => {
    setChildren(!activeChildren)
    handleAddArray()
  }
  return (
    <Container style={{ marginLeft: marginLeft ? "-30px" : "" }}>
      <IconCheck isCheck={active} size={10} color="#fff" onclick={handleClick} />
      <Label style={{ fontWeight: activeCollanse === null ? 100 : 700 }}>{label}</Label>
      {activeCollanse !== null && (
        <div
          role="button"
          style={{ marginLeft: "10px", marginTop: "3px" }}
          tabIndex={0}
          onKeyDown={() => {
            setOpenAllCollansePermission(false)
            setCollapse(!(activeCollanse ?? false))
          }}
          onClick={() => {
            setOpenAllCollansePermission(false)
            setCollapse(!(activeCollanse ?? false))
          }}
        >
          {!activeCollanse ?? false ? (
            <IconPlus size={12} color={GLOBAL_STYLES.LABEL.TYPE_2[themeContext]} />
          ) : (
            <IconMinus size={12} color={GLOBAL_STYLES.LABEL.TYPE_2[themeContext]} />
          )}
        </div>
      )}
    </Container>
  )
}

export default ContainerIconLabel
