import { type SagaIterator } from "redux-saga"
import { fork, all } from "redux-saga/effects"

// Sagas
import { sagas as loginSagas } from "./modules/login/sagas"
import { sagas as userAppClientSagas } from "./modules/userClientApp/sagas"
import { sagas as userBackofficeSagas } from "./modules/userBackOffice/sagas"
import { sagas as profileSagas } from "./modules/profile/sagas"
import { sagas as eventSagas } from "./modules/event/sagas"
import { sagas as remendersSagas } from "./modules/remenders/sagas"
import { sagas as referenceSagas } from "./modules/reference/sagas"
import { sagas as preferenceSagas } from "./modules/preferences/sagas"
import { sagas as permissionSagas } from "./modules/permissionByProfile/sagas"
import { sagas as authorizationSagas } from "./modules/authorization/sagas"
import { sagas as quoterSagas } from "./modules/Quoter/sagas"
import { sagas as customsSagas } from "./modules/customs/sagas"
import { sagas as customerSagas } from "./modules/customer/sagas"
import { sagas as ratingsSagas } from "./modules/ratings/sagas"
import { sagas as expensiveOriginSagas } from "./modules/expensiveOrigin/sagas"
import { sagas as expensiveBASagas } from "./modules/expensiveBA/sagas"
import { sagas as reportCommercialSagas } from "./modules/reportCommercial/sagas"
import { sagas as quoteTemplateSagas } from "./modules/quoteTemplate/sagas"

const allSagas = [
  ...loginSagas,
  ...userAppClientSagas,
  ...profileSagas,
  ...eventSagas,
  ...referenceSagas,
  ...preferenceSagas,
  ...userBackofficeSagas,
  ...permissionSagas,
  ...quoterSagas,
  ...authorizationSagas,
  ...customerSagas,
  ...customsSagas,
  ...ratingsSagas,
  ...expensiveBASagas,
  ...quoteTemplateSagas,
  ...expensiveOriginSagas,
  ...reportCommercialSagas,
  ...remendersSagas
]

export default function* rootSaga(): SagaIterator {
  yield all(allSagas.map(saga => fork(saga)))
}
