import { type AxiosPromise } from "axios"
import httpModule from "../../../../utils/http"

export const getTerms = async (): Promise<AxiosPromise<any>> => await httpModule.get(`/api/mobile/v1/usuarios/terms`)

export const getCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/clientes/listar`, request)

export const getClientType = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/clientes/tipos`)

export const getCountries = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/clientes/paises`)

export const getClassification = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/clientes/clasificaciones `)

export const getAssignedCommercial = async (): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/clientes/usuariosback `)

export const addCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/clientes`, request)

export const getCustomerExcel = async (request): Promise<AxiosPromise<any>> => {
  return await httpModule.get(`api/backoffice/v1/clientes/exportar?${request}`)
}

export const putCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.put(`/api/backoffice/v1/clientes`, request)

export const getCustomerById = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.get(`/api/backoffice/v1/clientes/${request}`)

export const deleteCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.delete(`/api/backoffice/v1/clientes/${request}`)

export const bulkAssignmentCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/clientes/asignacionmasiva`, request)

export const reBulkAssignmentCustomer = async (request): Promise<AxiosPromise<any>> =>
  await httpModule.post(`/api/backoffice/v1/clientes/reasignacionmasiva`, request)
